import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Table, Badge, NavLink, NavItem, Nav, Card, CardBody, TabContent, TabPane, Row, Col, CardHeader, CardFooter, Input, Button, Label, InputGroup, InputGroupAddon, FormGroup, UncontrolledTooltip, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { OmniApi, XOmniApi } from '../customAPI/OmniApi';
import { confirm } from "./Confirm";
import PageLock from '../utility-components/PageLock';
import classnames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { Icon } from '@iconify/react';
import circleSlice8 from '@iconify-icons/mdi/circle-slice-8';
import dragIcon from '@iconify/icons-mdi/drag';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import trashCanOutline from '@iconify/icons-mdi/trash-can-outline';
import pencilOutline from '@iconify/icons-mdi/pencil-outline';
import pencilIcon from '@iconify/icons-mdi/pencil';
import contentCopy from '@iconify/icons-mdi/content-copy';
import contentSave from '@iconify/icons-mdi/content-save';
import youtubeIcon from '@iconify/icons-mdi/youtube';
import { toast } from 'react-toastify';
import axios from 'axios';
import CustomCKEditor from '../utility-components/CustomCKEditor';
import ReactPlayer from 'react-player/youtube';
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import { Helmet } from 'react-helmet';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import LoaderWidget from '../components/Loader';
import FloatingSaveButton from './FloatingSaveButton';
import Select from 'react-select';
import PageTitle from '../components/PageTitle';
//contexts
import { UserContext } from '../custom-contexts/UserContext';
// components
import VariantDragInput from './VariantDragInputs';
import ImageUpload from './ImageUpload';
import { invalid } from 'moment';
import { commonHelper } from '../helpers/commonHelper';
import { ERROR_MESSAGES } from '../constants/layout';
import { FS1STATICURL, FS1URL } from '../constants';

const Product = props => {

    const { user } = useContext(UserContext);

    const [product, setProduct] = useState(null);

    const [listState, setListState] = useState(null);

    const [activeTab, setActiveTab] = useState('tab1');

    const [slugDisabled, setSlugDisabled] = useState(true);

    const [unsavedChanges, setUnsavedChanges] = useState(false);

    const toggle = async (tab) => {

        let error = false;

        if (tab === 'tab1') {
            if (!product.prodToken) {
                if (await validateProduct() === false) {
                    return toast.error('Product name is required', {
                        theme: 'colored',
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

                let newProduct = {
                    ...product
                }

                delete newProduct.prodToken

                if (!newProduct.prodSlug)
                    newProduct.prodSlug = generateSlug(newProduct.prodSlug || newProduct.prodName)

                // set optionOrder to prodOptions
                newProduct.prodOptions.forEach((option, i) => option.optionOrder = i)

                //set sortOrder for specialValues and specs
                newProduct.specialValues.forEach((specialValue, i) => specialValue.sortOrder = i)
                newProduct.specs.forEach((spec, i) => spec.sortOrder = i)

                // delete all variants from the product copy
                delete newProduct.variants;

                await OmniApi.post('/products', newProduct)
                    .then(res => {
                        if (res.data.hasOwnProperty('error'))
                            throw new Error('Error')
                        OmniApi.get(`/products/${res.data.prodToken}/full`)
                            .then(res => {
                                setProduct(res.data)
                                toast.success('Product successfully saved');
                                setUnsavedChanges(false);
                                props.history.replace(`/omni/catalog/product/${res.data.prodToken}`);
                                if (activeTab !== tab) {
                                    setActiveTab(tab);
                                    return
                                }
                            })
                    })
                    .catch(err => {
                        console.error(err)
                        error = true;
                        return
                    })
            }
        }

        if (error) return

        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    const findVariantOption = (optionToken) => {
        let varOption = product.prodOptions.find(option => option.optionToken === optionToken)
        return varOption?.optionName;
    }

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    const variantOptions = (varOptions) => {

        let keys = Object.getOwnPropertyNames(varOptions)
        let values = Object.values(varOptions)

        let optionPairs = keys.map(key => findVariantOption(key)).map((key, i) => { return { name: key ? key : '', value: values[i] } }).sort((a, b) => {
            let nameA = a.name.toUpperCase();
            let nameB = b.name.toUpperCase();
            return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
        });


        return optionPairs.filter(option => option.value).map((option, i) => (
            <div key={i}>
                <strong>{option.name}:</strong> {option.value} <br />
            </div>
        ))
    }

    useEffect(() => {
        let prodToken = props.match.params.prodToken;
        if (!prodToken) {
            setProduct({
                prodName: '',
                prodSlug: '',
                prodToken: '',
                prodImage: '',
                prodImages: [],
                prodOptions: [],
                specs: [],
                specialValues: [],
                variants: [],
                prodVisible: 1
            })

            return setActiveTab('tab2')
        }

        OmniApi.get(`/products/${prodToken}/full`)
            .then(res => {
                if (isEmpty(res.data))
                    throw new Error('Invalid Product Token')

                setProduct(res.data)
            })
            .catch(err => {
                props.history.replace('/omni/catalog/products')
                toast.error(err.message)
            })
    }, [])

    const handleChange = (changedProduct, changeType = '') => {
        if (changeType === 'newVariant' || changeType === 'newOption')
            return setProduct({ ...changedProduct });

        setUnsavedChanges(true)
        setProduct({ ...changedProduct });
    }

    const handleInputChange = (e) => {
        const target = e.target;
        const name = target.name;

        setUnsavedChanges(true)

        if (name === 'prodName')
            setValidState({
                ...validState,
                prodName: true
            })

        if (name === 'prodSlug')
            e.target.value = generateSlug(e.target.value)

        setProduct({
            ...product,
            [name]: e.target.value
        })
    }


    const handleSpecialValueChange = (e, index) => {
        const target = e.target;
        const name = target.name;

        setUnsavedChanges(true)

        let newProduct = {
            ...product
        }

        newProduct.specialValues[index][name] = e.target.value;

        setProduct({
            ...newProduct
        })
    }

    const handleSpecChange = (e, index) => {
        const target = e.target;
        const name = target.name;

        setUnsavedChanges(true)

        let newProduct = {
            ...product
        }

        newProduct.specs[index][name] = e.target.value;

        setProduct({
            ...newProduct
        })
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    }

    const generateSlug = (string) => string?.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");

    const [validState, setValidState] = useState({
        prodName: true,
        prodNumber: true
    })

    const validateProduct = async () => {

        let newValidState = {
            ...validState
        }

        if (!product.prodName)
            newValidState.prodName = false
            

        let valid = true;

        for (let state in newValidState) {
            if (newValidState[state] === false)
                valid = false
        }
        if(product?.variants){
            const varientValidation = await  checkValriation(product?.variants);
            if(varientValidation?.length > 0){
                newValidState.variants=true;
                valid = false;
            }
            
        }
        setValidState(newValidState);
        return valid
    }
    const checkValriation=async (variantsData)=>{
        const variantError=[];
        if(variantsData){
            await variantsData.map(async (item)=>{
                if(commonHelper.isEmptyString(item?.variantNumber)){
                    variantError.push(true);
                }
            });
            return variantError;
        } else {
            return true;
        }
    }
    // A post request to product only saves the product without the variants. All variants must be saved separately
    const saveProduct = async () => {

        if (await validateProduct() === false) {
            return toast.error(ERROR_MESSAGES.form_validation_error, {
                theme: 'colored',
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        // create a copy of product
        let newProduct = {
            ...product
        }

        if (!newProduct.prodToken) {
            delete newProduct.prodToken
        }

        if (!newProduct.prodSlug)
            newProduct.prodSlug = generateSlug(newProduct.prodSlug || newProduct.prodName)

        // set optionOrder to prodOptions
        newProduct.prodOptions.forEach((option, i) => option.optionOrder = i)

        //set sortOrder for specialValues and specs
        newProduct.specialValues.forEach((specialValue, i) => specialValue.sortOrder = i)
        newProduct.specs.forEach((spec, i) => spec.sortOrder = i)

        // delete all variants from the product copy
        delete newProduct.variants;

        let productSavingToast = toast.loading('Saving product...', {
            theme: 'light',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        // post the new product without the variants
        OmniApi.post(`/products${product.prodToken ? '/' + product.prodToken : ''}`, newProduct)
            .then(res => {
                // save the product in a new object
                let savedProduct = {
                    ...res.data
                }

                if (!product.prodToken) {
                    OmniApi.get(`/products/${savedProduct.prodToken}/full`)
                        .then(res => {
                            setProduct(res.data)
                            props.history.replace(`/omni/catalog/product/${res.data.prodToken}`);
                        })
                    toast.update(productSavingToast,
                        {
                            render: "Product Saved",
                            type: "success",
                            isLoading: false,
                            autoClose: null,
                            hideProgressBar: null,
                            closeOnClick: null,
                        });
                    return setUnsavedChanges(false)
                }

                if (product.variants === null || product.variants.length < 1)
                    return toast.update(productSavingToast,
                        {
                            render: "Product Saved",
                            type: "success",
                            isLoading: false,
                            autoClose: null,
                            hideProgressBar: null,
                            closeOnClick: null,
                        });;

                // create a promise array from the variants
                let promiseArray = product.variants.map((variant, i) => {
                    // delete variant options that do not have values
                    let newVariant = { ...variant };

                    // set sortOrder to variants
                    newVariant.sortOrder = `${i}`

                    // loop through options and delete option property if it does not have a value attached
                    for (let [key, value] of Object.entries(newVariant.variantOptions)) {
                        if (!value) {
                            delete newVariant.variantOptions[key]
                        }
                    }

                    return OmniApi.post(`/variants/${variant.variantToken}`, newVariant)
                        .then(res => res.data)
                        .catch(err => {})
                });
                // when all variants resolve
                Promise.all(promiseArray)
                    .then(res => {
                        // save variants to to new created object
                        savedProduct.variants = res;
                        // replace old product with new product object
                        setProduct({
                            ...savedProduct
                        })

                        toast.update(productSavingToast,
                            {
                                render: "Product Saved",
                                type: "success",
                                isLoading: false,
                                autoClose: null,
                                hideProgressBar: null,
                                closeOnClick: null,
                            });

                        setUnsavedChanges(false)

                        // toast.success('Successfully Saved', {
                        //     theme: 'light',
                        //     position: "top-right",
                        //     autoClose: 5000,
                        //     hideProgressBar: false,
                        //     closeOnClick: true,
                        //     pauseOnHover: true,
                        //     draggable: true,
                        //     progress: undefined,
                        // });
                    })
                    .catch(err => {
                        toast.update(productSavingToast,
                            {
                                render: "Error",
                                type: "error",
                                isLoading: false,
                                autoClose: null,
                                hideProgressBar: null,
                                closeOnClick: null,
                            });
                    })

                // toast.promise(
                //     promises,
                //     {
                //         pending: {
                //             render() {
                //                 return "Saving Product ..."
                //             },
                //             icon: true,
                //         },
                //         success: {
                //             render(res) {
                //                 return `Product saved`
                //             },
                //             // other options
                //             icon: true,
                //         },
                //         error: {
                //             render(err) {
                //                 // When the promise reject, data will contains the error
                //                 return `err`
                //             },
                //             icon: true,
                //         }
                //     }
                // )

            })
            .catch(err => {
                toast.update(productSavingToast,
                    {
                        render: "Error",
                        type: "error",
                        isLoading: false,
                        autoClose: null,
                        hideProgressBar: null,
                        closeOnClick: null,
                    });
            })
    }

    const getFS1Image = (img, size) => {
        return FS1URL+`${user.company.fs1GetToken}/${img}/${size}`
    }

    const handleListStateChange = (newState) => {
        let newProduct = {
            ...product
        }

        //save files from image pool dnd to product
        newProduct.prodImage = {
            file: newState.mainImage[0]?.file,
            name: newState.mainImage[0]?.name,
            type: newState.mainImage[0]?.type ?? ''
        }

        newProduct.prodImages = []

        newState.additionalImages.forEach(e => {
            newProduct.prodImages.push({
                file: e.file,
                name: e.name,
                type: e.type ?? ''
            })
        })

        setProduct(newProduct)

    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const handleOnDragEnd = result => {
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(product[destination.droppableId], source.index, destination.index);

            setProduct({
                ...product,
                [destination.droppableId]: items
            })

        } else {
            return
        }
    };

    async function deleteProduct() {

        if (await confirm(`Deleted products cannot be recovered. Do you want to delete '${product.prodName}'? `, "Deleting Product...", "Delete Product") === false) {
            return
        }

        const deleting = OmniApi.delete(`products/${product.prodToken}`)
            .then(res => {
                props.history.push('/omni/catalog/products')
            })
            .catch(err => {})

        toast.promise(
            deleting,
            {
                pending: {
                    render() {
                        return "Deleting product"
                    },
                    icon: true,
                },
                success: {
                    render(res) {
                        return `Product deleted`
                    },
                    // other options
                    icon: true,
                },
                error: {
                    render(err) {
                        // When the promise reject, data will contains the error
                        return `err`
                    },
                    icon: true,
                }
            }
        )

    }

    const addKeyValue = (field) => {
        setUnsavedChanges(true)

        if (field === 'specialValue')
            product.specialValues.push({
                valueName: '',
                value: ''
            })
        if (field === 'spec')
            product.specs.push({
                specName: '',
                specValue: ''
            })

        setProduct({ ...product })
    }

    const deleteKeyValue = (field, index) => {
        setUnsavedChanges(true)
        if (field === 'specialValue')
            product.specialValues.splice(index, 1);
        if (field === 'spec')
            product.specs.splice(index, 1);

        setProduct({ ...product })
    }

    const [brandList, setBrandList] = useState([]);

    useEffect(() => {
        OmniApi.get('/brands')
            .then(res => {
                setBrandList(res.data?.data)
            })
    }, [])

    const [selectedBrand, setSelectedBrand] = useState([])

    const brandTypeaheadRef = useRef(null);

    useEffect(() => {
        // this is used to set the value of the brand typeahead-select
        if (product && brandList.length) {
            if (!product.prodBrand)
                return
            let productBrand = brandList.find(brand => brand.brandToken === product.prodBrand);
            if (productBrand === undefined) {
                return
            }
            // once modified and the brand exists set the modified brand to the select
            if (productBrand)
                setSelectedBrand([productBrand])
        }
    }, [product, brandList.length])

    // const onSelectChange = (selection, field) => {
    //     setSelectedBrand(selection)
    //     let newProduct = {
    //         ...product
    //     }

    //     if (selection.length) {
    //         newProduct[field] = selection[0].brandToken;
    //     } else {
    //         newProduct[field] = ''
    //     }

    //     setProduct(newProduct)
    // }

    const [brandSelectInput, setBrandSelectInput] = useState('');

    const addToBrands = async (e) => {

        e.preventDefault()

        if (await confirm(`Add '${brandSelectInput}' to brands?`, "Adding new brand...", "Add") === false) {
            return
        }

        let newBrand;

        let newBrandList = [
            ...brandList
        ]

        let postBrand = OmniApi.post('/brands', {
            brandName: brandSelectInput
        })
            .then(res => {
                newBrand = {
                    brandToken: res.data.brandToken,
                    brandName: res.data.brandName
                }
                newBrandList.push(newBrand)

                setBrandList(newBrandList);
                let newProduct = {
                    ...product,
                    prodBrand: res.data.brandToken
                }
                setProduct(newProduct)
                brandTypeaheadRef.current.clear();
            })
            .then(() => {
                setSelectedBrand([newBrand])
            })
            .catch(err => {})

        toast.promise(
            postBrand,
            {
                pending: {
                    render() {
                        return `Saving "${brandSelectInput}" to brands...`
                    },
                    icon: true,
                },
                success: {
                    render(res) {
                        return `"${brandSelectInput}" saved to brands`
                    },
                    // other options
                    icon: true,
                },
                error: {
                    render(err) {
                        // When the promise reject, data will contains the error
                        return `err`
                    },
                    icon: true,
                }
            }
        )
    }

    const [collectionSelectInput, setCollectionSelectInput] = useState('');

    const [collectionList, setCollectionList] = useState([]);

    useEffect(() => {
        OmniApi.get('/collections')
            .then(res => setCollectionList(res.data))
    }, [])

    const [selectedCollections, setSelectedCollections] = useState([])

    useEffect(() => {
        // this is used to set the value of the brand typeahead-select
        if (product && collectionList.length) {
            if (!product.hasOwnProperty('prodCollections') || product.prodCollections.length === 0)
                return
            let productCollections = [];
            product.prodCollections.forEach(collection => {
                let newCollection = collectionList.find(item => item.collectionToken === collection);

                if (newCollection === undefined)
                    return

                productCollections.push(newCollection)
            })
            // once modified set the modified brand to the select
            setSelectedCollections(productCollections)
        }
    }, [product, collectionList.length])

    const onSelectChange = (selection, field) => {

        setUnsavedChanges(true)

        let newProduct = {
            ...product
        }

        if (field === 'prodBrand') {
            setSelectedBrand(selection)

            if (selection.length) {
                newProduct[field] = selection[0].brandToken;
            } else {
                newProduct[field] = ''
            }
        }

        if (field === 'prodCollections') {
            setSelectedCollections(selection)

            newProduct[field] = [];

            selection.forEach(s => newProduct[field].push(s.collectionToken))

        }

        if (field === 'prodAccessories') {
            setSelectedAccessories(selection)

            newProduct[field] = [];

            selection.forEach(s => newProduct[field].push(s.prodToken))

        }

        setProduct(newProduct)
    }

    const collectionsTypeaheadRef = useRef(null);

    const accessoriesTypeAhead = useRef(null);

    const [accessoriesSelectInput, setAccessoriesSelectInput] = useState('');

    const [productList, setProductList] = useState([]);

    useEffect(() => {
        OmniApi.get('/products')
            .then(res => setProductList(res.data))
    }, [])

    const [selectedAccessories, setSelectedAccessories] = useState([])

    useEffect(() => {
        // this is used to set the value of the brand typeahead-select
        if (product && productList.length) {
            if (!product.hasOwnProperty('prodAccessories') || product.prodAccessories.length === 0)
                return
            let productAccessories = [];
            product.prodAccessories.forEach(accessory => {
                let newAccessory = productList.find(item => item.prodToken === accessory)

                if (newAccessory === undefined)
                    return

                productAccessories.push(newAccessory)
            })
            console.log('PRODUCTACCESSORIES', productAccessories)
            // once modified set the modified collection to the select
            setSelectedAccessories(productAccessories)
        }
    }, [product, productList.length])

    const visibilityOptions = [
        { value: 1, label: 'Listed' },
        { value: 2, label: 'Unlisted' },
        { value: 3, label: 'Archived' }
    ]

    const onSelectVisibilityOptions = (selection) => {
        if (!product.prodToken) {
            return setProduct({
                ...product,
                prodVisible: selection.id
            })
        }
        console.log(selection.value);
        setProduct({
            ...product,
            prodVisible: selection.value
        })
        product.prodVisible=selection.value;
        console.log(product);
        let saveingPr = toast.loading('Updating product...', {
            theme: 'light',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
        OmniApi.post('/products/' + product.prodToken, product)
            .then(res => {
                toast.update(saveingPr,
                    {
                        render: "Product visibility updated",
                        type: "success",
                        isLoading: false,
                        autoClose: null,
                        hideProgressBar: null,
                        closeOnClick: null,
                    });
            })
            .catch(err => console.log(err))
    }

    const truncateString = (string, length) => {
        let slicedString = string.slice(0, length)
        if (string.length > length)
            slicedString = slicedString.concat('...')
        return slicedString
    }
    console.log('product')
    console.log(product);
    if (user && product)
    
        return (
            <>
                <PageLock unsavedChanges={unsavedChanges} setUnsavedChanges={setUnsavedChanges} />
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Product Detail | {process.env.REACT_APP_HEADER_TITLE}</title>
                </Helmet>
                <FloatingSaveButton handleSave={saveProduct} />
                <PageTitle
                    breadCrumbItems={[
                        { label: 'Products', path: '/omni/catalog/products' },
                        { label: `${product.prodToken ? (truncateString(product.prodName, 50) || <i>**No Name**</i>) : 'New product'}`, path: props.location.pathname, active: true },
                    ]}
                    title={product.prodToken ? (product.prodName || <i>**No Name**</i>) : 'New Product'}
                />
                <Nav tabs className="nav-pills bg-nav-pills nav-justified my-1 mb-2">
                    <NavItem key={1}>
                        <NavLink
                            href="#"
                            className={classnames({ active: activeTab === 'tab1' })}
                            onClick={() => toggle('tab1')}>
                            <span className="d-none d-lg-block"><strong>Variants</strong><Badge color={'danger'} className="ml-1">
                                {product.variants?.length}
                            </Badge></span>
                        </NavLink>
                    </NavItem>
                    <NavItem key={2}>
                        <NavLink
                            href="#"
                            className={classnames({ active: activeTab === 'tab2' })}
                            onClick={() => toggle('tab2')}>
                            <span className="d-none d-lg-block"><strong>Product</strong>
                                {(!validState.prodName) && <Badge color={'danger'} className="ml-1">
                                    invalid
                                </Badge>}
                            </span>
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={'tab1'}>
                        {/* 
                    Card - Full width
                    Buttons on top
                    Button add variant
                    Add option btn
                    same btns on bottom as well
                    Variant Card is image, title, price, option values, button to view all
                    when view all navigates to new page
                    when navigating back have variant flash yellow (same as stack overflow)
                */}
                        <Card>
                            <CardHeader className="d-flex justify-content-between align-items-center">
                                <h4 className="my-0">Variants</h4>
                            </CardHeader>
                            {product.variants?.length ?
                                product.variants.map((variant, index) => (
                                    <div key={variant.variantToken}>
                                        {(index > 0) ? <hr className="m-0" /> : ''}
                                        <CardBody className="position-relative">
                                            <Row className="d-flex justify-content-between" style={{ flexBasis: '100%' }}>
                                                <div style={{ width: '50%' }} className="d-flex">
                                                    <div style={{ width: '20%', flexShrink: 0, minWidth: '80px' }} className='ml-2 mr-2 mt-1'>
                                                        {/* this img src is temporary due to the way variantImage is implemented where it saves a file even if there is no image */}
                                                        <img style={{ height: '80px', width: '80px', borderRadius: '5px' }}
                                                            src={(variant.variantImage?.file && variant.variantImage.file !== '[]') ?
                                                                getFS1Image(variant.variantImage.file, 80) :
                                                                "http://dummyimage.com/80"} alt='product-img' />
                                                    </div>
                                                    <div><Link to={{
                                                        pathname: `/omni/catalog/product/${product.prodToken}/${variant.variantToken}`,
                                                        state: { product }
                                                    }} className="mb-2" style={{ fontSize: '20px' }}>{variant.variantName || variant.variantToken}</Link><br />
                                                        <div style={{ flexBasis: '100%' }}>
                                                            {variant.variantOptions ? variantOptions(variant.variantOptions) : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ width: '38%' }} className="d-flex justify-content-between">
                                                    <div style={{ width: '28%' }}>${variant.variantPrice}</div>
                                                    <div style={{ width: '28%' }}>Inventory: {variant.inventoryCount}</div>
                                                    <div style={{ width: '28%' }}>
                                                        {variant.inventoryCount > 0 ?
                                                            <><Icon icon={circleSlice8} color="#03D762" /><small>In Stock</small></>
                                                            :
                                                            <><Icon icon={circleSlice8} color="#E70435" /><small>Out of Stock</small></>}
                                                    </div>
                                                    {/* <div style={{width: '15%'}}>
                                    <UncontrolledDropdown style={{top: '-10%'}} className="float-right mb-4">
                                        <DropdownToggle tag="button" className="btn btn-sm btn-link no-arrow card-drop p-0">
                                            <i className="mdi mdi-dots-horizontal"></i>
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            <DropdownItem onClick={() => toggleEditBuyingAsinModalState(buyingAsin)}>Edit</DropdownItem>
                                            <DropdownItem onClick={() => deleteBuyingAsin(buyingAsin.id)}>Delete</DropdownItem>
                                            <hr className="my-0" />
                                            <DropdownItem>Refresh</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div> */}
                                                </div>
                                            </Row>
                                            {/* <div className="card-side d-flex flex-column">
                                <div className="btn card-side-button d-flex justify-content-center align-items-center">
                                    <Icon icon={pencilIcon} width="18px" height="18px"/>
                                </div>
                                <div className="btn card-side-button d-flex justify-content-center align-items-center">
                                    <Icon icon={refreshIcon} width="18px" height="18px"/>
                                </div>
                                <div className="btn card-side-button d-flex justify-content-center align-items-center">
                                    <Icon icon={deleteIcon} width="18px" height="18px"/>
                                </div>
                            </div> */}
                                        </CardBody>
                                    </div>
                                ))
                                :
                                // <CardBody>
                                //     <Row>
                                //         <Col sm={2}>
                                //             <Skeleton height={80} width={80} />
                                //         </Col>
                                //         <Col sm={5}>
                                //             <Skeleton /> <br />
                                //             <Skeleton style={{ width: '50%' }} /> <br />
                                //             <Skeleton /> <br />
                                //             <Skeleton style={{ width: '70%' }} />
                                //         </Col>
                                //     </Row>
                                // </CardBody>
                                <CardBody className="mx-4 my-2 bg-light rounded">
                                    <Col className="d-flex justify-content-center">
                                        <h5>No Variants Found</h5>
                                    </Col>
                                </CardBody>
                            }
                            <CardFooter>
                                <div className="d-flex justify-content-end">
                                    <div className="mb-0"><strong>Total Variants :</strong> {product.variants?.length || 0} </div>
                                </div>
                            </CardFooter>
                        </Card>
                        <Row>
                            <Col>
                                <VariantDragInput product={product} handleChange={handleChange} />
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={'tab2'}>
                        <Row>
                            <Col sm={9}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="prodName">Product Name <span className='required-field'>*</span></Label>
                                                    <Input type="text" name="prodName" id="prodName"
                                                        className={`${!validState.prodName ? 'invalid' : ''}`}
                                                        value={product.prodName}
                                                        onChange={(e) => handleInputChange(e)}
                                                    />
                                                    {!validState.prodName ? <div className="invalid-text">Name is required</div> : ''}
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="prodNumber">Product Number</Label>
                                                    <Input type="text" name="prodNumber" id="prodNumber"
                                                        className={''}
                                                        value={product.prodNumber}
                                                        onChange={(e) => handleInputChange(e)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>

                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="prodSlug">Product Handle</Label>
                                                    <InputGroup>
                                                        <Input type="text" name="prodSlug" id="prodSlug"
                                                            value={product.prodSlug}
                                                            onChange={(e) => handleInputChange(e)}
                                                            disabled={slugDisabled} />
                                                        <InputGroupAddon addonType="append">
                                                            <span
                                                                className="btn btn-secondary input-group-text py-0 px-1"
                                                                onClick={() => setSlugDisabled(!slugDisabled)}
                                                            >
                                                                <Icon icon={slugDisabled ? pencilIcon : contentSave} width="25" height="25" />
                                                            </span>
                                                        </InputGroupAddon>
                                                        <InputGroupAddon addonType="append">
                                                            <span
                                                                className="btn btn-secondary input-group-text py-0 px-1"
                                                                onClick={
                                                                    // this is necessary when there isn't a productSlug
                                                                    () => copyToClipboard(product.prodSlug
                                                                        ||
                                                                        generateSlug(product.prodName))}
                                                            >
                                                                <Icon icon={contentCopy} width="25" height="25" id="copy-slug" />
                                                            </span>
                                                            <UncontrolledTooltip placement="top" target="copy-slug" trigger="legacy">
                                                                '{product.prodSlug}' copied to clipboard
                                                            </UncontrolledTooltip>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                    <p className="mt-1 mb-0">
                                                        <strong>Product Handle:</strong>{` "/omni/catalog/product/${generateSlug(product.prodSlug) || '{product_slug}'}"`}
                                                    </p>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Product Description</Label>
                                                    {/* <SimpleMDEReact id={1} options={mdeOptions} 
                                            value={variant.variantDescription}
                                            onChange={(e) => setVariant({
                                                ...variant,
                                                variantDescription: e
                                            })} 
                                            /> */}
                                                    <CustomCKEditor
                                                        data={product.prodDescription}
                                                        onChange={(data) => {
                                                            setUnsavedChanges(true);
                                                            setProduct({
                                                                ...product,
                                                                prodDescription: data
                                                            })
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Product Highlights</Label>
                                                    <CustomCKEditor
                                                        data={product.prodHighlights}
                                                        onChange={(data) => {
                                                            setUnsavedChanges(true);
                                                            setProduct({
                                                                ...product,
                                                                prodHighlights: data
                                                            })
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="3">
                                <Card>
                                    <CardBody>
                                        <FormGroup>
                                            <Label><h5>Visibility</h5></Label>
                                            <Select
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                onChange={onSelectVisibilityOptions}
                                                value={visibilityOptions.find(option => product.prodVisible === option.value)}
                                                isSearchable={false}
                                                options={visibilityOptions}>
                                            </Select>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <h5>Brand</h5>
                                        <Typeahead
                                            ref={brandTypeaheadRef}
                                            paginate
                                            id="select2"
                                            labelKey="brandName"
                                            multiple={false}
                                            onInputChange={(e) => setBrandSelectInput(e)}
                                            options={brandList?.map(brand => {
                                                return {
                                                    id: brand.brandToken, brandToken: brand.brandToken, brandName: brand.brandName
                                                }
                                            })}
                                            renderMenu={(results, menuProps) => (
                                                results.length ?
                                                    <Menu {...menuProps}>
                                                        {results.map((result, index) => (
                                                            <MenuItem option={result} position={index} key={result.brandToken}>
                                                                {result.brandName}
                                                            </MenuItem>
                                                        ))}
                                                    </Menu>
                                                    :
                                                    <Menu {...menuProps}>
                                                        <div className="px-3"><strong>No results found.</strong> <br />
                                                            <a href="#" onClick={(e) => addToBrands(e)}><small>Click to add "{brandSelectInput}" to Brands</small></a></div>
                                                    </Menu>
                                            )}
                                            // emptyLabel={<div onClick={() => alert('clicked')}>hello</div>}
                                            onChange={(s) => onSelectChange(s, 'prodBrand')}
                                            selected={selectedBrand}
                                            placeholder="Add brand..."
                                        />
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <h5>Collections</h5>
                                        <Typeahead
                                            ref={collectionsTypeaheadRef}
                                            paginate
                                            maxResults={15}
                                            id="select2"
                                            labelKey="collectionName"
                                            multiple={true}
                                            onInputChange={(e) => setCollectionSelectInput(e)}
                                            options={collectionList.map(collection => {
                                                return {
                                                    id: collection.collectionToken, collectionToken: collection.collectionToken, collectionName: collection.collectionName
                                                }
                                            })}
                                            // renderMenu={(results, menuProps) => (
                                            //     results.length ?
                                            //     <Menu {...menuProps}>
                                            //       {results.filter(result => {
                                            //           return !selectedCollections.find(res => {
                                            //               return res.collectionToken === result.collectionToken
                                            //             })
                                            //         })
                                            //       .map((result, index) => (
                                            //         <MenuItem option={result} position={index} key={result.collectionToken}>
                                            //           {result.collectionName}
                                            //         </MenuItem>
                                            //       ))}
                                            //     </Menu>
                                            //     :
                                            //     <Menu {...menuProps}>
                                            //         <div className="px-3">No Matches Found</div>
                                            //     </Menu>
                                            //   )}
                                            // emptyLabel={<div onClick={() => alert('clicked')}>hello</div>}
                                            onChange={(s) => onSelectChange(s, 'prodCollections')}
                                            selected={selectedCollections}
                                            placeholder="Add collections..."
                                        />
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <h5>Accessories</h5>
                                        <Typeahead
                                            ref={accessoriesTypeAhead}
                                            paginate
                                            maxResults={15}
                                            id="select2"
                                            labelKey="prodName"
                                            multiple={true}
                                            onInputChange={(e) => setAccessoriesSelectInput(e)}
                                            options={productList.map(prod => {
                                                return {
                                                    id: prod.prodToken, prodToken: prod.prodToken, prodName: prod.prodName
                                                }
                                            })}
                                            onChange={(s) => onSelectChange(s, 'prodAccessories')}
                                            selected={selectedAccessories}
                                            placeholder="Add accessories..."
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ImagePoolDragAndDrop product={product} handleListStateChange={handleListStateChange} user={user} setUnsavedChanges={setUnsavedChanges} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <Card>
                                    <CardBody>
                                        <h3>Special Values</h3>
                                        <DragDropContext onDragEnd={handleOnDragEnd}>
                                            <Droppable className="d-flex" droppableId='specialValues'>
                                                {(provided) => (
                                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                                        {product.specialValues.map((specialValue, i) => (
                                                            <Draggable key={`${i}`} draggableId={`${i}`} index={i} className="d-flex">
                                                                {(provided) => (<div ref={provided.innerRef} {...provided.draggableProps} className="mb-1">
                                                                    <InputGroup>
                                                                        <InputGroupAddon {...provided.dragHandleProps} addonType="prepend">
                                                                            <span className="input-group-text py-0 px-1">
                                                                                <Icon icon={dragIcon} width="25" height="25" />
                                                                            </span>
                                                                        </InputGroupAddon>
                                                                        <Input
                                                                            placeholder="Key"
                                                                            name="valueName"
                                                                            value={specialValue.valueName}
                                                                            onChange={(e) => handleSpecialValueChange(e, i)}
                                                                        />
                                                                        <Input
                                                                            placeholder="Value"
                                                                            name="value"
                                                                            value={specialValue.value}
                                                                            onChange={(e) => handleSpecialValueChange(e, i)}
                                                                        />
                                                                        <InputGroupAddon addonType="append">
                                                                            <span className="btn input-group-text py-0 px-1"
                                                                                onClick={() => deleteKeyValue('specialValue', i)}
                                                                            >
                                                                                <Icon icon={trashCanOutline} width="25" height="25" />
                                                                            </span>
                                                                        </InputGroupAddon>
                                                                    </InputGroup>
                                                                </div>)}</Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                        <Row>
                                            <Col className="d-flex">
                                                <div className="btn" onClick={() => addKeyValue('specialValue')}><strong>+ Add Special Value...</strong></div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="6">
                                <Card>
                                    <CardBody>
                                        <h3>Specs</h3>
                                        <DragDropContext onDragEnd={handleOnDragEnd}>
                                            <Droppable className="d-flex" droppableId='specs'>
                                                {(provided) => (
                                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                                        {product.specs?.map((spec, i) => (
                                                            <Draggable key={`${i}`} draggableId={`${i}`} index={i} className="d-flex">
                                                                {(provided) => (<div ref={provided.innerRef} {...provided.draggableProps} className="mb-1">
                                                                    <InputGroup>
                                                                        <InputGroupAddon {...provided.dragHandleProps} addonType="prepend">
                                                                            <span className="input-group-text py-0 px-1">
                                                                                <Icon icon={dragIcon} width="25" height="25" />
                                                                            </span>
                                                                        </InputGroupAddon>
                                                                        <Input
                                                                            placeholder="Key"
                                                                            name="specName"
                                                                            value={spec.specName}
                                                                            onChange={(e) => handleSpecChange(e, i)}
                                                                        />
                                                                        <Input
                                                                            placeholder="Value"
                                                                            name="specValue"
                                                                            value={spec.specValue}
                                                                            onChange={(e) => handleSpecChange(e, i)}
                                                                        />
                                                                        <InputGroupAddon addonType="append">
                                                                            <span className="btn input-group-text py-0 px-1"
                                                                                onClick={() => deleteKeyValue('spec', i)}
                                                                            >
                                                                                <Icon icon={trashCanOutline} width="25" height="25" />
                                                                            </span>
                                                                        </InputGroupAddon>
                                                                    </InputGroup>
                                                                </div>)}</Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                        <Row>
                                            <Col className="d-flex">
                                                <div className="btn" onClick={() => addKeyValue('spec')}><strong>+ Add Spec...</strong></div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
                <Row className="mb-3">
                    <Col>
                        <div className="button-list d-flex justify-content-end">
                            {product?.prodToken &&
                                <Button color="danger" size="lg" onClick={deleteProduct} >Delete</Button>
                            }
                            {!product?.prodToken &&
                                <Button color="danger" size="lg" disabled={true}>Delete</Button>
                            }
                            <Button color="primary" size="lg" className="ml-3" onClick={saveProduct}>Save</Button>
                        </div>
                    </Col>
                </Row>
            </>
        )
    else
        return (
            <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
                <div>
                    <h4>Loading product...</h4>
                    <div className="bouncing-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        )
}

const ImagePoolDragAndDrop = props => {

    const { product, user } = props;

    const imageUploader = React.useRef(null);

    const [listState, setListState] = useState({
        mainImage: [],
        additionalImages: [],
        imagePool: []
    })

    useEffect(() => {
        props.handleListStateChange(listState)
    }, [listState])

    // created fullImagePool to store all of the images that are part of the pool even if they aren't being shown because they are also on the variant
    const [fullImagePool, setFullImagePool] = useState([])

    useEffect(() => {

        if (product.prodToken) {

            //tempImagePool stores all imagePool imgs before they are set to a Set
            let tempImagePool = [];

            if (product.variants?.length) {
                product.variants.forEach(vrnt => {
                    if (vrnt.variantImages)
                        tempImagePool.push(...vrnt.variantImages)
                    if (vrnt.variantImage?.file && (vrnt.variantImage.file !== [] && vrnt.variantImage.file !== "[]"))
                        tempImagePool.push(vrnt.variantImage)
                })

                // id's and statuses are deleted in order for the objects to only have a file property, so when 2 are alike they will be filtered out with Set 
                tempImagePool.forEach((img, i) => {
                    delete img.id
                    delete img.status
                })

                // filter out all duplicate imgs
                tempImagePool = tempImagePool.filter((v, i, a) => a.findIndex(t => (t.file === v.file)) === i)

                // assign a new id and status once all imgs are unique
                tempImagePool.forEach((image, i) => {
                    image.id = Math.random().toString(36).slice(-8);
                    image.status = 'poolImages';
                })
            }

            if (product?.prodImage?.file && (product?.prodImage?.file !== [] && product?.prodImage?.file !== "[]")) {
                product.prodImage.id = Math.random().toString(36).slice(-8);
                product.prodImage.status = 'mainImage';
            } else {
                product.prodImage = ''
            }
            console.log(typeof product?.prodImages);
            if (product?.prodImages?.length && typeof product?.prodImages!=='string') {
                product.prodImages.forEach((image) => {
                    image.id = Math.random().toString(36).slice(-8);
                    image.status = 'additionalImages'
                })
            }

            let newImagePool = [];

            // all imgs in the image pool are compared to the main image and the images in the additional images pool and all non duplicates are pushed to a new array
            tempImagePool.forEach((e) => {
                if (e.file === product.prodImage.file)
                    return

                for (let img of product.prodImages) {
                    if (e.file === img.file) {
                        return
                    }
                }
                newImagePool.push(e)
            })

            // send all unique images to the full image pool
            setFullImagePool(tempImagePool)

            setListState({
                mainImage: product.prodImage ? [product.prodImage] : [],
                additionalImages: [...product.prodImages],
                // imagePool in the DND component is assigned the value of the new array
                imagePool: [...newImagePool]
            })


        }

    }, [])

    // console.log(listState)

    const getList = id => listState[id];

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);
        removed.status = droppableDestination.droppableId;
        destClone.splice(droppableDestination.index, 0, removed);
        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };

    // when imgs are dragged from the ImagePool component the images are merely copied and placed in the new component but are not reomved from the imagePool
    const add = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [copied] = sourceClone.slice(droppableSource.index, droppableSource.index + 1);
        copied.status = droppableDestination.droppableId;
        destClone.splice(droppableDestination.index, 0, copied);

        let newImagePool = [];

        fullImagePool.forEach((e) => {

            if (e.file === listState['mainImage'][0]?.file)
                return

            for (let img of listState['additionalImages']) {
                if (e.file === img.file) {
                    return
                }
            }

            for (let img of destClone) {
                if (e.file === img.file) {
                    return
                }
            }

            newImagePool.push(e)
        })

        const result = {};
        result[droppableSource.droppableId] = newImagePool;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };

    const handleOnDragEnd = result => {
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }
        if (source.droppableId === destination.droppableId) {
            const items = reorder(getList(source.droppableId), source.index, destination.index);

            let state = { ...listState };
            state[source.droppableId] = items;
            setListState(state);
            props.setUnsavedChanges(true)
        } else {
            if (source.droppableId === 'imagePool') {
                const result = add(
                    getList(source.droppableId),
                    getList(destination.droppableId),
                    source,
                    destination
                );
                const state = { ...listState, ...result };
                setListState(state);
                props.setUnsavedChanges(true)
            } else {
                const result = move(
                    getList(source.droppableId),
                    getList(destination.droppableId),
                    source,
                    destination
                );
                const state = { ...listState, ...result };
                setListState(state);
                props.setUnsavedChanges(true)
            }
        }
    };


    const handleMultiImageUpload = (e) => {
        let images = e.target.files;

        const uploadImage = (img) => {

            let payload = img;

            return axios.post(FS1STATICURL+'upload', payload, {
                headers: {
                    'upload_token': user.company.fs1Token
                }
            })
                .then(res => {
                    let resImage = {};
                    resImage.file = res.data.path;
                    resImage.id = `${Math.random().toString(36).slice(-8)}`
                    resImage.status = 'additionalImages';

                    return resImage;
                })
        }

        let imagesArray = []

        for (let i = 0; i < images.length; i++) {
            let img = new FormData();
            img.append('ev_file', images[i]);

            imagesArray.push(img)
        }

        let promiseArray = Array.from(imagesArray).map(i => uploadImage(i));

        Promise.all(promiseArray)
            .then(res => {
                props.setUnsavedChanges(true)
                setListState({
                    ...listState,
                    additionalImages: [...listState.additionalImages, ...res]
                })
            })
            .catch(err => {})
    }

    const deleteImage = async (status, id, fileName) => {
        if (status === 'mainImage') {
            if (await confirm("Would you like to delete the Main Image?", "Deleting Main Image...", "Delete") === false) {
                return
            }
        }

        //check if image pending delete exits in image pool
        const newImage = fullImagePool.find(e => e.file === fileName)
        //if image in not in imagepool it will be removed from the product, so admin must confirm that action
        if (newImage === undefined) {
            if (await confirm("The image will be completely removed from this product. Would you like to proceed?", "Removing Image", "Confirm") === false) {
                return
            }
        }

        let newMainImage = listState.mainImage.filter(image => image.id !== id)

        // when images are deleted from the variant, if the images still exist within the product they are added to the image Pool component
        let newAdditionalImages = listState.additionalImages.filter(image => image.id !== id)

        let newImagePool = [];

        // al image pool images are compared to the remaining images aand if they are not duplicates then they are added back to the Image Pool component

        fullImagePool.forEach((e) => {

            if (e.file === newMainImage[0]?.file)
                return

            for (let img of newAdditionalImages) {
                if (e.file === img.file) {
                    return
                }
            }
            newImagePool.push(e)
        })

        setListState({
            mainImage: newMainImage,
            additionalImages: newAdditionalImages,
            imagePool: newImagePool
        });

        props.setUnsavedChanges(true)
    }

    function resolveYoutubeVideo(value) {
        let type;
        if (value.startsWith("https://youtu.be/")) { // short link
            value = value.substring(17);
            type = "youtube";
        } else if (value.startsWith("https://www.youtube.com/watch?v=")) { // regular url
            let listKeyIndex = value.indexOf("&list");
            if (listKeyIndex < 0) value = value.substring(32);
            else value = value.substring(32, listKeyIndex);
            type = "youtube";
        } else if (value.startsWith("https://www.youtube.com/embed/")) { // embed link
            value = value.substring(30);
            type = "youtube";
        } else if (value.search("https://www.youtube.com/embed/")) {  // iframe with embed link
            let fromIndex = value.search("https://www.youtube.com/embed/");
            fromIndex += 30;
            let endIndex = value.indexOf('"', fromIndex);
            value = value.substring(fromIndex, endIndex);
            type = "youtube";
        }
        return {
            file: value,
            type,
            id: Math.random().toString(36).slice(-8)
        }
    }

    const [addVideoModal, setAddVideoModal] = useState(false);

    const toggleAddVideoModal = () => {
        setAddVideoModal(!addVideoModal)
    }

    const saveYoutubeVideo = (link) => {

        const youtubeRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/

        if (!youtubeRegex.test(link))
            return null

        let newVideo = resolveYoutubeVideo(link)

        setListState({
            ...listState,
            additionalImages: [...listState.additionalImages, newVideo]
        })

        props.setUnsavedChanges(true)

        toggleAddVideoModal()
    }

    const uploadImage = (img) => {
        let payload = new FormData();

        payload.append('ev_file', img);

        axios.post(FS1STATICURL+'upload', payload, {
            headers: {
                'upload_token': user.company.fs1Token
            }
        })
            .then(res => {
                let mainImage = {
                    file: res.data.path,
                    id: Math.random().toString(36).slice(-8),
                    status: "mainImage"
                }
                props.setUnsavedChanges(true)
                setListState({ ...listState, mainImage: [mainImage] })
            })
    }

    const handleSingleImageUpload = (e) => {
        const file = e.target.files[0];
        console.log(file)
        if (file) {
            uploadImage(file);
        }
        else return
    }

    const handleOnDragStart = start => {
        // console.log(start)
    }

    const handleOnDragUpdate = update => {
        // console.log(update)
        // const {destination} = update;
        // if (destination.droppableId === 'mainImage'){
        //     if (listState.mainImage.length) return;
        // }

    }
    // function handleOnDragEnd(result) {
    //     console.log('dropresult', result)
    //     if (!result.destination) return;

    //     const items = Array.from(list);
    //     const [reorderedItem] = items.splice(result.source.index, 1);
    //     items.splice(result.destination.index, 0, reorderedItem);

    //     setList(items);
    // }
    return (
        <DragDropContext onDragStart={handleOnDragStart} onDragEnd={handleOnDragEnd} onDragUpdate={handleOnDragUpdate}>
            <Row className="mt-3">
                <Col>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col className="d-flex justify-content-between">
                                    <h3>Images</h3>
                                    <div className="button-list d-flex justify-items-center" >
                                        <Button color='primary' onClick={toggleAddVideoModal}>Upload Video</Button>
                                        <AddVideoModal modal={addVideoModal} toggle={toggleAddVideoModal} saveVideo={saveYoutubeVideo} />
                                        <Button color='primary' onClick={() => imageUploader.current.click()}>Upload Images</Button>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => {
                                                handleMultiImageUpload(e)
                                            }}
                                            multiple
                                            ref={imageUploader}
                                            style={{
                                                display: "none"
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="flex-nowrap">
                                <Col xs="auto">
                                    <h4>Main Image</h4>
                                    <Droppable droppableId="mainImage" direction="horizontal" isDropDisabled={listState.mainImage.length ? true : false}>
                                        {(provided, snapshot) => (
                                            <Card className="border overflow-auto">
                                                <CardBody>
                                                    <div {...provided.droppableProps} ref={provided.innerRef} className="d-flex">
                                                        {listState['mainImage'].length ? listState['mainImage'].map((item, i) => (
                                                            <ImageUpload
                                                                key={item.id}
                                                                id={item.id}
                                                                status={item.status}
                                                                type={item.type ?? false}
                                                                image={item.file}
                                                                deleteImage={deleteImage}
                                                                allowDelete={true}
                                                                imageSize={100}
                                                            />
                                                        ))
                                                            :
                                                            <ImageUpload
                                                                small={true}
                                                                handleImageUpload={handleSingleImageUpload}
                                                                deleteImage={deleteImage}
                                                                allowDelete={true}
                                                                imageSize={100}
                                                            />
                                                        }
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        )}
                                    </Droppable>
                                </Col>
                                <Col className="overflow-hidden">
                                    <h4>Additional Images</h4>
                                    <Droppable droppableId="additionalImages" direction="horizontal">
                                        {(provided) => (
                                            <Card className="border overflow-auto">
                                                <CardBody>
                                                    <div {...provided.droppableProps} ref={provided.innerRef} className="d-flex">
                                                        {listState['additionalImages'].length ? listState['additionalImages'].map((item, i) => (
                                                            <Draggable key={item.id} draggableId={item.id} index={i}>{(provided, snapshot) => (<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="mr-2">
                                                                <ImageUpload
                                                                    key={item.id}
                                                                    id={item.id}
                                                                    status={item.status}
                                                                    image={item.file}
                                                                    type={item.type ?? false}
                                                                    deleteImage={deleteImage}
                                                                    allowDelete={true}
                                                                    imageSize={100}
                                                                    noHover={snapshot.isDragging}
                                                                />
                                                            </div>)}</Draggable>
                                                        ))
                                                            :
                                                            <div className="d-flex justify-content-center align-items-center"
                                                                style={{ height: '100px', width: '100%', border: '2px dashed #E0E7ED', borderRadius: '0.25rem', color: '#E0E7ED' }}
                                                            >
                                                                <strong>No Images</strong>
                                                            </div>
                                                        }
                                                        {listState['additionalImages'].length ? provided.placeholder : ''}
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        )}
                                    </Droppable>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <h4>Image Pool<small>{" (all other images associated with this product)"}</small></h4>
                                    <Droppable droppableId="imagePool" direction="horizontal" isDropDisabled={true}>
                                        {(provided) => (
                                            <Card className="border overflow-auto">
                                                <CardBody>
                                                    <div {...provided.droppableProps} ref={provided.innerRef} className="d-flex">
                                                        {listState['imagePool'].length ? listState['imagePool'].map((item, i) => (
                                                            <Draggable key={item.id} draggableId={item.id} index={i}>{(provided, snapshot) => (<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="mr-2">
                                                                <ImageUpload
                                                                    key={item.id}
                                                                    id={item.id}
                                                                    status={item.status}
                                                                    type={item.type ?? false}
                                                                    image={item.file}
                                                                    deleteImage={deleteImage}
                                                                    allowDelete={false}
                                                                    imageSize={100}
                                                                    noHover={snapshot.isDragging}
                                                                />
                                                            </div>)}</Draggable>
                                                        ))
                                                            :
                                                            <div className="d-flex justify-content-center align-items-center"
                                                                style={{ height: '100px', width: '100%', border: '2px dashed #E0E7ED', borderRadius: '0.25rem', fontSize: '22px', color: '#E0E7ED' }}
                                                            >
                                                                <strong>No Images</strong>
                                                            </div>
                                                        }
                                                        {listState['imagePool'].length ? provided.placeholder : ''}
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        )}
                                    </Droppable>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </DragDropContext>
    )
}

const AddVideoModal = props => {

    const [videoLink, setVideoLink] = useState('');

    const [validLink, setValidLink] = useState(true)

    const { modal, toggle } = props;

    useEffect(() => {

        const youtubeRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/

        videoLink ? setValidLink(youtubeRegex.test(videoLink)) : setValidLink(true)

    }, [videoLink])

    return (
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Add Video
            </ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label for="videoLink">Add Youtube Link</Label>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <span className="input-group-text py-0 px-1">
                                <Icon icon={youtubeIcon} width="25px" />
                            </span>
                        </InputGroupAddon>
                        <Input type="text" name="videoLink" id="videoLink"
                            value={videoLink}
                            onChange={(e) => setVideoLink(e.target.value)}
                            placeholder="https://www.youtube.com/..."
                        />
                    </InputGroup>
                    {!validLink && <div className="text-danger mt-1">Invalid Youtube Link</div>}
                </FormGroup>
                {(validLink && videoLink) &&
                    <ReactPlayer
                        width="100%"
                        url={videoLink}
                        controls={true}
                    />
                }
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => props.saveVideo(videoLink)} disabled={!videoLink || !validLink}>
                    Save Video
                </Button>{' '}
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default Product;