import React, { useEffect, useState, useContext } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    UncontrolledTooltip
    // CustomInput,
    // Form,
    // FormGroup,
    // Label,
    // Input,
    // FormText,
    // InputGroup,
    // InputGroupAddon,
    // UncontrolledButtonDropdown,
    // Button,
    // DropdownToggle,
    // DropdownMenu,
    // DropdownItem,
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import axios from 'axios';
import { useHistory, Link, useLocation } from 'react-router-dom';
import {OmniApi} from '../customAPI/OmniApi';
import { UserContext } from '../custom-contexts/UserContext';
import { Helmet } from 'react-helmet';
import { Icon } from '@iconify/react';
import informationOutline from '@iconify/icons-mdi/information-outline';
import RemotePaginations from '../components/Datatables/RemotePagination';
import { commonHelper } from '../helpers/commonHelper';
import { CommonApiRequest } from '../services/commonApiRequest';
import { FS1URL } from '../constants';

const RemotePagination = ({ data, columns, page, sizePerPage, onTableChange, totalSize, showTotal = true, paginationTotalRenderer }) => {
    const { SearchBar } = Search;
    const { ExportCSVButton } = CSVExport;

    let history = useHistory();

    // The onCLick in the row event routes the user to the brand detail page when row is clicked on
    const rowEvents = {
        onClick: (e, row) => {

            const location = {
                pathname: `/omni/quote/${row.quoteToken}`,
                state: {
                    row
                }
            }

            history.push(location)
        }
    }

    const rowClasses = (row, rowIndex) => {
        return 'table-row';
    };

    const paginationOptions = {
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        sizePerPageList: [
            {
                text: '10',
                value: 10,
            },
            {
                text: '20',
                value: 20,
            },
            {
                text: '60',
                value: 60,
            },
            {
                text: '100',
                value: 100,
            },
        ], // A numeric array is also available. the purpose of above example is custom the text
    };

    return (
      <ToolkitProvider
        bootstrap4
        keyField="quoteToken"
        data={data}
        columns={columns}
        search
        exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
        {props => (
            <React.Fragment>
                <Row className="mb-2">

                    <Col className="text-right">
                        <ExportCSVButton {...props.csvProps} className="export-csv-btn btn btn-primary mr-3">
                            Export CSV
                        </ExportCSVButton>
                        <SearchBar {...props.searchProps} placeholder='Search quotes' />
                    </Col>
                </Row>

                <BootstrapTable
                    {...props.baseProps}
                    remote
                    bordered={false}
                    headerClasses="thead-light"
                    // defaultSorted={ defaultSorted }
                    pagination={ paginationFactory({ page, sizePerPage, totalSize, paginationTotalRenderer, showTotal }) }
                    wrapperClasses="table-responsive"
                    classes="product-table"
                    rowEvents={ rowEvents }
                    // selectRow={ selectRow }
                    rowClasses={ rowClasses }
                    hover
                    onTableChange={ onTableChange }
                    // noDataIndication={() => 'No results...'}
                />
            </React.Fragment>
        )}
    </ToolkitProvider>
  );
  }

const Quotes = () => {

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const history = useHistory();

    const { user } = useContext(UserContext);

    // const [selectedRows, setSelectedRows] = useState([]);
    const [quotes, setQuotes] = useState([]);

    const [fs1Url, setFs1Url] = useState(null);

    const [paginationValues, setPaginationValues] = useState({})
    useEffect(() => {
        console.log('Checked')
        setPaginationValues({
            page: parseInt(query.get('page')),
            data: [],
            sizePerPage: parseInt(query.get('sizePerPage')),
            totalSize: ''
        });
    },[]);
    // Gets the pagination query params from the url and sets the correct data to be displayed in that pages table
    // useEffect(() => {
    //     if (!location.search){
    //         query.set('page', 1);
    //         query.set('sizePerPage', 15);
    //         query.set('sortOrder', 'asc');
    //         query.set('sortField', 'quoteNumber');
    //         history.replace(location.pathname + "?" + query.toString());
    //     }
    //     const page = parseInt(query.get('page'));
    //     const sizePerPage = parseInt(query.get('sizePerPage'));
    //     const currentIndex = (page - 1) * sizePerPage;
    //     const sortOrder = query.get('sortOrder');
    //     const sortField = query.get('sortField');
    //     const searchText = query.get('searchText')
    //     // const page = parseInt(query.get('page'));
    //     // const sizePerPage = parseInt(query.get('sizePerPage'));
    //     // const currentIndex = (page - 1) * sizePerPage;

    //     // setPaginationValues({
    //     //     ...paginationValues,
    //     //     data: quotes ? quotes.slice(currentIndex, currentIndex + sizePerPage) : ''
    //     // })
        
    // }, [quotes])

    // useEffect(() => {
        
    //     OmniApi.get('/quotes')
    //     .then(res => setQuotes(res.data));

    //   }, []);
    // Set default pagination query params if none are existent
    // useEffect(() => {
    //     if (!location.search){
    //         query.set('page', 1);
    //         query.set('sizePerPage', 15);
    //         console.log('query', query)
    //         history.push(location.pathname + "?" + query.toString());
    //     }
    // }, [])

    const columns = [
        {
            dataField: 'quoteNumber',
            text: 'Quote Number',
            sort: true,
            style: { verticalAlign: 'middle' }
        },
        {
            dataField: 'customerObject.customerLastName',
            text: 'Customer Name',
            sort: true,
            formatter: (cell, row) => `${row.customerObject.customerLastName}, ${row.customerObject.customerFirstName}`,
            style: { verticalAlign: 'middle' }
        },
        {
            dataField: 'customerObject.customerEmail',
            text: 'Customer Email',
            sort: true,
            formatter: (cell, row) => `${row.customerObject.customerEmail}`,
            style: { verticalAlign: 'middle' }
        },
        {
            dataField: 'itemsQuantity',
            text: 'Total Variants',
            sort: true,
            formatter: (cell,row) => row?.itemsQuantity,
            style: { verticalAlign: 'middle' }
        },
        {
            dataField: 'combinedTotal',
            text: 'Total',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                if (order === 'asc') return parseFloat(rowA.combinedTotal) < parseFloat(rowB.combinedTotal) ? -1 : 1;
                else return parseFloat(rowB.combinedTotal) > parseFloat(rowA.combinedTotal) ? 1 : -1;
              },
            style: { verticalAlign: 'middle' },
            formatter: (cell, row, rowIndex) => {
                return(<div className='d-flex'>
                    <div>{cell}</div>
                    <Icon icon={informationOutline} style={{ marginLeft: '2px' }} color="#39afd1" id={`combined-total-${rowIndex}`} />
                    <UncontrolledTooltip placement="top" target={`combined-total-${rowIndex}`}>
                        Variants: {row.variantsTotal}<br />
                        Tax: {row.taxTotal}<br />
                        Shipping: {row.shippingTotal}
                    </UncontrolledTooltip>
                </div>)
            }
        }
    ];

    useEffect(() => {
        if (user){
            setFs1Url(FS1URL+`static/${user.company.fs1GetToken}`)
        }
        
    }, [user])

    // function getFS1Image(brandImage){
    //     return `https://fs1.cloudsnob.com/static/${user.company.fs1GetToken}/${brandImage}/50`
    // }

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Showing <strong>{from}</strong> to <strong>{to}</strong> of <strong>{size}</strong> Results
        </span>
    );

    const [tableChangeType, setTableChangeType] = useState('');

    // When pagination query params change, update the pagination values
    useEffect(() => {
        if (!location.search){
            query.set('page', 1);
            query.set('sizePerPage', 15);
            query.set('sortOrder', 'desc');
            query.set('sortField', 'quoteNumber');
            history.replace(location.pathname + "?" + query.toString());
        }
        const page = parseInt(query.get('page'));
        const sizePerPage = parseInt(query.get('sizePerPage'));
        const currentIndex = (page - 1) * sizePerPage;
        const sortOrder = query.get('sortOrder');
        const sortField = query.get('sortField');
        const searchText = query.get('searchText')

        let result = (quotes)?quotes:[];
        console.log(result);
        if(result?.data){
            setPaginationValues({
                page: page,
                sizePerPage: sizePerPage,
                data: result?.data,
                totalSize: (result?.total) ? result?.total : result.length
            })
        }

    }, [location.search,quotes])
    useEffect(() => {
        if (!location.search) {
            query.set('page', 1);
            query.set('sizePerPage', 15);
            query.set('sortOrder', 'desc');
            query.set('sortField', 'quoteNumber');
            history.push(location.pathname + "?" + query.toString());
        }
        let userToken = localStorage.getItem('OMNI_TOKEN');
        const page = parseInt(query.get('page'));
        const sizePerPage = parseInt(query.get('sizePerPage'));
        const sortOrder = query.get('sortOrder');
        const sortField = query.get('sortField');
        const searchQuery = query.get('searchText');
        const getUrlParams = commonHelper.buildQueryParams(page, sizePerPage, sortOrder, sortField, searchQuery);
        CommonApiRequest.getQuotes(getUrlParams)
        .then((res) => {
            setQuotes(res);
            // setPaginationValues({
            //     sortOrder,
            //     sortField,
            //     data: quotes,
            //     totalSize: (res?.total) ? res?.total : Math.round(quotes?.length),
            // });
            setTimeout(()=>{
                console.log(paginationValues);
            },1000);
            commonHelper.goOnToOfThePage();
        });
    }, [location.search]);

    const onTableChange = (type, { sortField, sortOrder, page, sizePerPage, searchText, filters }) => {
        setTableChangeType(type);
        if (type === 'pagination'){
            query.set('page', page);
            query.set('sizePerPage', sizePerPage);
        }
        if (type === 'sort'){
            query.set('sortField', sortField);
            query.set('sortOrder', sortOrder);
        }
        if (type === 'search'){
            query.set('searchText', searchText);
        }
        history.push(location.pathname + "?" + query.toString());
      }

    const { data, sizePerPage, page, totalSize } = paginationValues;
    if(user && data?.length > 0){
        return(
            <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Quotes | {process.env.REACT_APP_HEADER_TITLE}</title>
            </Helmet>
            <h2 className="page-title">
                Quotes
            </h2>
            <Card className="mt-2">
                <CardBody>
                <RemotePagination
                    data={ data }
                    page={ page }
                    columns={columns}
                    sizePerPage={ sizePerPage }
                    totalSize={ totalSize }
                    onTableChange={ onTableChange }
                    paginationTotalRenderer={customTotal}
                    showTotal={true}
                    searchPlaceHolder={"Search quotes..."}
                />
                    </CardBody>
                    </Card>
                </>
        )
    } else {
        return<div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
        <div>
        <h4>Loading Quotes...</h4>
            <div className="bouncing-loader">
                <div></div>
                <div></div>
                <div></div>
            </div>
            </div>
        </div>
    }
}

export default Quotes;