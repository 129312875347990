import { api,apiNode } from "../config/api/axiosInterceptor";
import { OmniApi } from "../customAPI/OmniApi";
export const CommonApiRequest = {
  getOrderDetail: async function (params) {
    const response = await api.get('/orders'+params);
    // returning the product returned by the API
    return response?.data
  },
  getCustomerList: async function (params) {
    // const response = await api.get('/customers'+params); // old 
    
    const response = await api.get('/customers'+params);  //implemented by Ijaz

    // returning the product returned by the API
    return response?.data
  },
  // no needed as customers listing moves to solr completly

  // getPendingCustomerList: async function (params) {
  //   const response = await api.get('/customers/solr'+params+'&fq=archive');
  //   // returning the product returned by the API
  //   return response?.data
  // },
  saveSingleProductInCollection: async function (params) {
    const response = await api.request({
      url: `/collectionprods`,
      method: "POST",
      data:params,
      // retrieving the signal value by using the property name
      signal: undefined,
    });
    // returning the product returned by the API
    return response?.data
  },
  deleteSingleProductInCollection: async function (collectionToken,productToken) {
    const response = await api.request({
      url: `/collectionprods/${collectionToken}/${productToken}`,
      method: "DELETE",
      // retrieving the signal value by using the property name
      signal: undefined,
    });
    // returning the product returned by the API
    return response?.data
  },
  saveShippingQoute: async function (params,quoteToken,customerToken) {
    api.defaults.headers.common['customerToken'] = customerToken
    const response = await api.request({
      url: `/quotes/${quoteToken}/shipping-quote`,
      method: "POST",
      data:params,
      // retrieving the signal value by using the property name
      signal: undefined,
    });
    // returning the product returned by the API
    return response?.data
  },
  removeShippingQoute: async function (quoteToken,customerToken,shippingQuoteToken) {
    api.defaults.headers.common['customerToken'] = customerToken
    const response = await api.request({
      url: `/quotes/${quoteToken}/${shippingQuoteToken}`,
      method: "DELETE",
      // retrieving the signal value by using the property name
      signal: undefined,
    });
    // returning the product returned by the API
    return response?.data
  },
  saveTaxByState: async function (params) {
    //api.defaults.headers.common['siteToken'] = companyToken
    const response = await api.request({
      url: `/taxByState`,
      method: "POST",
      data:params,
      // retrieving the signal value by using the property name
      signal: undefined,
    });
    // returning the product returned by the API
    return response?.data
  },
  getTaxBySiteToken: async function (companyToken) {
    const response = await api.request({
      url: `/taxByState/${companyToken}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: undefined,
    });
    // returning the product returned by the API
    return response?.data
  },
  getQuotes: async function (params) {
    const response = await api.request({
      url: `/quotes`+params,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: undefined,
    });
    // returning the product returned by the API
    return response?.data
  },
  removeTaxByState: async function (params) {
    //api.defaults.headers.common['siteToken'] = companyToken
    const response = await api.request({
      url: `/taxByState/${params}`,
      method: "DELETE",
      // retrieving the signal value by using the property name
      signal: undefined,
    });
    // returning the product returned by the API
    return response?.data
  },
  getProductList: async function (params) {
    const response = await api.get('/products/'+params);
    // returning the product returned by the API
    return response?.data
  },
  createNewCustomer: async function (params) {
    const response = await api.request({
      url: `/customers`,
      method: "POST",
      data:params,
      // retrieving the signal value by using the property name
      signal: undefined,
    });
    // returning the product returned by the API
    return response?.data
  },
  createCodeForGiftCard: async function (params) {
    const response = await apiNode.request({
      url: `/giftcards/getUniqueGiftCardCode`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: undefined,
    });
    // returning the product returned by the API
    return response?.data
  },
  createNewGiftCard: async function (params) {
    const response = await apiNode.request({
      url: `/giftcards`,
      method: "POST",
      data:params,
      // retrieving the signal value by using the property name
      signal: undefined,
    });
    // returning the product returned by the API
    return response?.data
  },
  getGiftCards: async function (params) {
    const response = await apiNode.request({
      url: `/giftcards`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: undefined,
    });
    // returning the product returned by the API
    return response?.data
  },
  getGiftCardDetail: async function (params) {
    const response = await apiNode.request({
      url: `/giftcards/`+params,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: undefined,
    });
    // returning the product returned by the API
    return response?.data
  },
  deleteGiftCard: async function (params) {
    const response = await apiNode.request({
      url: `/giftcards/`+params,
      method: "DELETE",
      // retrieving the signal value by using the property name
      signal: undefined,
    });
    // returning the product returned by the API
    return response?.data
  },
  updateGiftCard: async function (params,token) {
    const response = await apiNode.request({
      url: `/giftcards/`+token,
      method: "PUT",
      data:params,
      // retrieving the signal value by using the property name
      signal: undefined,
    });
    // returning the product returned by the API
    return response?.data
  },
  saveOrderAsDraft: async function (params) {
    
    const response = await apiNode.request({
      url: `/draft_orders/`,
      method: "POST",
      data:params,
      // retrieving the signal value by using the property name
      signal: undefined,
    });
    // returning the product returned by the API
    return response?.data
  },
  draftOrderList: async function (params) {
    const response = await apiNode.request({
      url: `/draft_orders/`+params,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: undefined,
    });
    // returning the product returned by the API
    return response?.data
  },
  getDraftOrderByToken: async function (params) {
    const response = await apiNode.request({
      url: `/draft_orders/byID/`+params,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: undefined,
    });
    // returning the product returned by the API
    return response?.data
  },
  getCustomerAddress: async function (params) {
    const response = await api.request({
      url: `/customers/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: undefined,
    });
    // returning the product returned by the API
    return response?.data
  },
  updateOrderAsDraft: async function (params) {
    const response = await apiNode.request({
      url: `/draft_orders/`,
      method: "POST",
      data:params,
      // retrieving the signal value by using the property name
      signal: undefined,
    });
    // returning the product returned by the API
    return response?.data
  },
  createCustomerAddress: async function (params) {
    const response = await api.request({
      url: `/addresses`,
      method: "POST",
      data:params,
      // retrieving the signal value by using the property name
      signal: undefined,
    });
    // returning the product returned by the API
    return response?.data
  },
  getGiftCardByToken: async function (params) {
    const response = await apiNode.request({
      url: `/giftcards/byCode/`+params,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: undefined,
    });
    // returning the product returned by the API
    return response?.data
  },
  getShippingMethoads: async function () {
    const response = await apiNode.request({
      url: `/shippingmethods`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: undefined,
    });
    // returning the product returned by the API
    return response?.data
  },
  createNewOrder: async function (params) {
    const response = await apiNode.request({
      url: `/orders`,
      method: "POST",
      data:params,
      // retrieving the signal value by using the property name
      signal: undefined,
    });
    // returning the product returned by the API
    return response?.data
  },
}