import axios, { AxiosError, AxiosResponse } from "axios"
import { toast } from 'react-toastify';
import { commonHelper } from "../../helpers/commonHelper";
const api = axios.create({
  baseURL: `${process.env.REACT_APP_OMNI_API}`
})
const apiNode = axios.create({
  baseURL: `${process.env.REACT_APP_OMNI_API_NODE}`
})


api.interceptors.request.use(async (config) => {
    let userToken = localStorage.getItem('OMNI_TOKEN');
  if (userToken) {
    config.headers['token'] = userToken;
  }
  api.defaults.headers.common['Content-Type'] = 'application/json';
  return config;
},
  error => {
    console.log(error);
  });
api.interceptors.response.use(
  async (response) => {
    
    if(commonHelper.isEmpty(response?.data)){
      if (typeof response.data !== 'object'){
          toast.error(`Server Error`)
          return Promise.reject(response);
        }

        if(response.data.hasOwnProperty('error')){
          toast.error(`Error`)
          return Promise.reject(response);
        }
        //toast.error(`Invalid user token`);
    }
    return response
  },
  (error) => {
    errorHandler(error);
  }
)

apiNode.interceptors.request.use(async (config) => {
  let userToken = localStorage.getItem('OMNI_TOKEN');
if (userToken) {
  config.headers['token'] = userToken;
}
apiNode.defaults.headers.common['Content-Type'] = 'application/json';
return config;
},
error => {
  console.log(error);
});
apiNode.interceptors.response.use(
async (response) => {
  
  if(commonHelper.isEmpty(response?.data)){
    if (typeof response.data !== 'object'){
        toast.error(`Server Error`)
        return Promise.reject(response);
      }

      if(response.data.hasOwnProperty('error')){
        toast.error(`Error`)
        return Promise.reject(response);
      }
      //toast.error(`Invalid user token`);
  }
  return response
},
(error) => {
  return errorHandler(error);
}
)
// defining a custom error handler for all APIs
const errorHandler = (error) => {
    //toast.error(`${error.response.status}: ${error.response.data.error.message}`);
    return Promise.reject(error);
}
export {api,apiNode}