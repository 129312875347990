import React, { useEffect, useContext, useState, useRef } from 'react';
import { FormGroup, Input, Row, Col, Label, Card, InputGroup, ButtonGroup, Button, CardBody, CustomInput, FormText, InputGroupAddon, ButtonToolbar, ListGroup, ListGroupItem, Badge } from 'reactstrap';
import { OmniApi } from '../customAPI/OmniApi';
import HyperDatepicker from '../components/Datepicker';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import Select from 'react-select';
import { Icon } from '@iconify/react';
import { Typeahead } from 'react-bootstrap-typeahead';
import accountStar from '@iconify/icons-mdi/account-star';
import closeBox from '@iconify/icons-mdi/close-box';
import { confirm } from './Confirm';
import { toast } from 'react-toastify';
import accountBox from '@iconify/icons-mdi/account-box';
import shoppingIcon from '@iconify/icons-mdi/shopping';
import shapeIcon from '@iconify/icons-mdi/shape';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { CountryList } from '../data/CountriesList';
import TimePicker from 'react-time-picker'
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

const Coupon = props => {

    const [coupon, setCoupon] = useState('');
    const [currentTime, setCurrentTime] = useState('');
    const [activeFromDate, setActiveFromDate] = useState({
        date: '',
        time: '',
        amOrPm: 'AM'
    })

    const [activeToDate, setActiveToDate] = useState({
        date: '',
        time: '',
        amOrPm: 'AM'
    })

    const [unsavedChanges, setUnsavedChanges] = useState(false)

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    useEffect(() => {
        let couponToken = props.match.params.couponToken;
        if (couponToken){
            OmniApi.get(`/couponcodes/${couponToken}`)
            .then(res => {

                if (isEmpty(res.data))
                    throw new Error('Invalid Coupon Token')

                let fromDate = (res.data.activeFromDate)?new Date(res.data.activeFromDate):new Date();
                if (!moment(fromDate).isValid()) {
                    setValidState({
                        ...validState,
                        fromDate: false,
                        fromTime: false,
                    })
                } else {
                    setActiveFromDate({
                        date: ((fromDate.getMonth() > 8) ? (fromDate.getMonth() + 1) : ('0' + (fromDate.getMonth() + 1))) + '/' + ((fromDate.getDate() > 9) ? fromDate.getDate() : ('0' + fromDate.getDate())) + '/' + fromDate.getFullYear(),
                        time: fromDate.getHours()+":"+fromDate.getMinutes()+":00",//fromDate.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'}),
                        amOrPm: fromDate.toLocaleTimeString().slice(-2)
                    });
                    setCurrentTime(fromDate.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'}).slice(0, -3));
                }
                
                if(res.data.activeToDate){
                    setEndTimeEnabled(true)
                    let toDate = new Date(res.data.activeToDate)
                    
                    setActiveToDate({
                        date: ((toDate.getMonth() > 8) ? (toDate.getMonth() + 1) : ('0' + (toDate.getMonth() + 1))) + '/' + ((toDate.getDate() > 9) ? toDate.getDate() : ('0' + toDate.getDate())) + '/' + toDate.getFullYear(),
                        time: toDate.getHours()+":"+toDate.getMinutes(),//toDate.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'}).slice(0, -3),
                        amOrPm: toDate.toLocaleTimeString().slice(-2)
                    })
                } else {
                    let toDate = new Date(res.data.activeFromDate)
                    setActiveToDate({
                        date: ((toDate.getMonth() > 8) ? (toDate.getMonth() + 1) : ('0' + (toDate.getMonth() + 1))) + '/' + ((toDate.getDate() > 9) ? toDate.getDate() : ('0' + toDate.getDate())) + '/' + toDate.getFullYear(),
                        time: toDate.getHours()+":"+toDate.getMinutes()+":00",//toDate.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'}).slice(0, -3),
                        amOrPm: toDate.toLocaleTimeString().slice(-2)
                    })
                }

                if(res.data.eligibleUses > 0)
                    setLimitCouponUsage(true)

                if (res.data.appliesToType === 'collections')
                    setSelectedCollections(res.data.appliesTo)

                if (res.data.appliesToType === 'products')
                    setSelectedProducts(res.data.appliesTo)

                setCoupon(res.data)
            })
            .catch(err => {
                props.history.replace('/omni/catalog/coupons')
                toast.error(err.message)
            })
        } else {

            let currentDateTime = new Date()
        
            setActiveFromDate({
                date: ((currentDateTime.getMonth() > 8) ? (currentDateTime.getMonth() + 1) : ('0' + (currentDateTime.getMonth() + 1))) + '/' + ((currentDateTime.getDate() > 9) ? currentDateTime.getDate() : ('0' + currentDateTime.getDate())) + '/' + currentDateTime.getFullYear(),
                time: currentDateTime.getHours()+":"+currentDateTime.getMinutes(),//currentDateTime.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'}).slice(0, -3),
                amOrPm: currentDateTime.toLocaleTimeString().slice(-2)
            })
            
            setCoupon({
                name: '',
                disabled: '',
                discountType: 'percent',
                discountValue: '',
                status: 1,
                minRequirementsType: 'none',
                appliesToType: 'order',
                customersEligibleType: 'all',
                customersEligible: null,
                minRequirements: "",
                maxRequirements: "",
                maxRequirementsType: "",
                canBeCombined: 0,
                eligibleUses: 0,
                locationsEligible: null,
                locationsEligibleType: "",
                onePerCustomer: 0,
                activeFromTime: '',
                activeToTime: ''
            })
        }
    }, [props.location])

    const handleActiveFromDateInputChange = (e,name='time') => {
        //const target = e.target;
        //const name = 'date';//target.name;
        
        setUnsavedChanges(true)

        if (name === 'date') {
            setValidState({
                ...validState,
                fromDate: moment(e).isValid()
            });
            const validDate = new Date(e);
            setActiveFromDate({
                ...activeFromDate,
                [name]: (validDate.getMonth()+1)+'/'+validDate.getDate()+"/"+validDate.getFullYear()//e.target.value
            })
        }

        if (name === 'time') {
            // Matches HH:MM 12-hour format, optional leading 0
            let timeRegex = /^(([0-1][0-9])|([0-9][0-4]))*:[0-5][0-9]/
            setValidState({
                ...validState,
                fromTime: timeRegex.test(e)
            });
            setActiveFromDate({
                ...activeFromDate,
                [name]: e//e.target.value
            })
        }
        
        
    }

    const handleFromAmOrPmClick = (e) => {
        setActiveFromDate({
            ...activeFromDate,
            amOrPm: e.target.value.toUpperCase()
        })

    }

    const handleActiveToDateInputChange = (e,name='time') => {
        //const target = e.target;
        //const name = target.name;

        setUnsavedChanges(true)

        if (name === 'date') {
            setValidState({
                ...validState,
                toDate: moment(e).isValid()
            })
            const validDate = new Date(e);
            setActiveToDate({
                ...activeToDate,
                [name]: (validDate.getMonth()+1)+'/'+validDate.getDate()+"/"+validDate.getFullYear()//e.target.value
            })
        }

        if (name === 'time') {
            // Matches HH:MM 12-hour format, optional leading 0
            let timeRegex = /^(([0-1][0-9])|([0-9][0-4]))*:[0-5][0-9]/
            setValidState({
                ...validState,
                toTime: timeRegex.test(e)
            });
            setActiveToDate({
                ...activeToDate,
                [name]: e//e.target.value
            })
        }

        
    }

    const handleToAmOrPmClick = (e) => {

        setActiveToDate({
            ...activeToDate,
            amOrPm: e.target.value.toUpperCase()
        })

    }

    const onSelectChange = (selection) => {
        setValidState({
            ...validState,
            selectedLocations: true
        })

        if(selection.value === 'freeshipping'){
            setValidState({
                ...validState,
                discountValue: true
            })
            return setCoupon({
                ...coupon,
                discountValue: '100',
                discountType: selection.value
            })
        }

        setCoupon({
            ...coupon,
            discountValue: '0',
            discountType: selection.value
        })
    }

    const onMinRequirementSelectChange = async (selection) => {

        if (selection.value !== 'none' && coupon.appliesToType !== 'order') 
            if (await confirm(`Setting a ${selection.label} will apply the coupon to the entire order. Would you like to proceed?`, `Changing Minimum Requirement`, `Set ${selection.label}`)) {
                return setCoupon({
                    ...coupon,
                    minRequirementsType: selection.value,
                    minRequirements: '',
                    appliesToType: 'order',
                    appliesTo: null
                })
            }

        if(selection.value === 'none'){
            setValidState({
                ...validState,
                minRequirements:{
                    moreThanZero: true,
                    noDecimal: true
                }
            })

            return setCoupon({
                ...coupon,
                minRequirementsType: 'none',
                minRequirements: null
            })}

        setCoupon({
            ...coupon,
            minRequirementsType: selection.value,
            minRequirements: ''
        })

    }

    const [endTimeEnabled, setEndTimeEnabled] = useState(false)

    const selectTypeOptions = [
        { value: 'percent', label: 'Percentage' },
        { value: 'fixed', label: 'Fixed Amount' },
        { value: 'freeshipping', label: 'Free Shipping' }
    ]

    const selectMinRequirementTypeOptions = [
        { value: 'none', label: 'None' },
        { value: 'totalSale', label: 'Minimum Purchase Amount' },
        { value: 'itemQuantity', label: 'Minimum Item Quantity' }
    ]

    const selectFreeShippingOptions = [
        {value: 'all', label: 'All Countries'},
        {value: 'selected', label: 'Select Countries'}
    ]

    const [limitCouponUsage, setLimitCouponUsage] = useState(false);

    const [customerList, setCustomerList] = useState([]);

    const customersTypeaheadRef = useRef(null);

    useEffect(() => {
        OmniApi.get('/customers')
        .then(res => setCustomerList(res.data))
    }, [])

    const removeSelectedCustomer = id => setCoupon({
        ...coupon,
        customersEligible: coupon.customersEligible.filter(customerId => customerId !== id)
    })

    const onCustomerSelectChange = (selection) => {
        if(!selection.length)
            return

        setValidState({
            ...validState,
            selectedCustomers: true
        })

        if (coupon.customersEligible){
            setCoupon({
                ...coupon,
                customersEligible: [
                    selection[0].customerToken,
                    ...coupon.customersEligible
                ]
            })
        } else {
            setCoupon({
                ...coupon,
                customersEligible: [selection[0].customerToken]
            })
        }

        setTimeout(() => {
            customersTypeaheadRef.current.clear()
        }, 250)
    }

    const [collectionList, setCollectionList] = useState([]);

    const [selectedCollections, setSelectedCollections] = useState([])

    const collectionsTypeaheadRef = useRef(null);

    useEffect(() => {
        OmniApi.get('/collections')
        .then(res => setCollectionList(res.data))
    }, [])

    const removeSelectedCollection = id => setSelectedCollections(selectedCollections.filter(collectionId => collectionId !== id))

    const onCollectionSelectChange = (selection) => {
        if(!selection.length)
            return

        setValidState({
            ...validState,
            selectedProducts: true,
            selectedCollections: true
        })

        setSelectedCollections([
            ...selectedCollections,
            selection[0].collectionToken
        ])

        setTimeout(() => {
            collectionsTypeaheadRef.current.clear()
        }, 250)
    }

    const [productList, setProductList] = useState([]);

    const [selectedProducts, setSelectedProducts] = useState([])

    const productsTypeaheadRef = useRef(null);

    useEffect(() => {
        OmniApi.get('/products')
        .then(res => setProductList(res.data))
    }, [])

    const removeSelectedProduct = id => setSelectedProducts(selectedProducts.filter(productId => productId !== id))

    const onProductSelectChange = (selection) => {
        if(!selection.length)
            return

        setValidState({
            ...validState,
            selectedProducts: true,
            selectedCollections: true
        })

        setSelectedProducts([
            ...selectedProducts,
            selection[0].prodToken
        ])

        setTimeout(() => {
            productsTypeaheadRef.current.clear()
        }, 250)
    }

    const onSelectFreeShippingOptionsSelect = (selection) => {
        setValidState({
            ...validState,
            selectedLocations: true
        })

        if(selection.value === 'selected'){
            return setCoupon({
                ...coupon,
                locationsEligibleType: selection.value
            })
        }

        if(selection.value === 'all'){
            return setCoupon({
                ...coupon,
                locationsEligibleType: selection.value
            })
        }
    }

    const onCountrySelectChange = (selection) => {
        setValidState({
            ...validState,
            selectedLocations: true
        })
        setCoupon({
            ...coupon,
            locationsEligible: selection.map(s => s.code)
        })
    }

    

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const setAppliesTo = async (e) => {

        let val = e.target.value;

        if (val === 'order') {

            setValidState({
                ...validState,
                selectedProducts: true,
                selectedCollections: true
            })
            
            return setCoupon({
                ...coupon, 
                appliesToType: val,
                appliesTo: null
            })
        }

        if((val === 'collections' || val === 'products') && (coupon.minRequirementsType === 'totalSale' || coupon.minRequirementsType === 'itemQuantity'))
            if (await confirm(`Applying the coupon to 'Selected ${capitalizeFirstLetter(val)}' will set the minimum requirements to 'none'. Would you like to proceed?`, `Changing Applies To`, `Apply to Selected ${capitalizeFirstLetter(val)}`)) {
                setValidState({
                    ...validState,
                    minRequirements:{
                        moreThanZero: true,
                        noDecimal: true
                    }
                })

                return setCoupon({
                    ...coupon, 
                    appliesToType: val,
                    minRequirementsType: 'none'
                })
            } else {
                return
            }

        setValidState({
            ...validState
        })

        setCoupon({
            ...coupon, 
            appliesToType: val
        })
    }

    const deleteCoupon = async () => {

        if (await confirm(`Delete '${coupon.name}' from coupons?`, "Deleting Coupon...", "Delete") === false) {
            return
        }

        let deleteCpn = OmniApi.delete(`/couponcodes/${coupon.couponToken}`)
        .then(res => props.history.replace('/omni/catalog/coupons'))
        .catch(err => console.error(err))

        toast.promise(
            deleteCpn,
            {
                pending: {
                    render() {
                        return "Deleting coupon..."
                    },
                    icon: true,
                },
                success: {
                    render(res) {
                        return `'${coupon.name}' deleted from coupons`
                    },
                    // other options
                    icon: true,
                },
                error: {
                    render(err) {
                        // When the promise reject, data will contains the error
                        return `err`
                    },
                    icon: true,
                }
            }
        )

    }

    const [validState, setValidState] = useState({
        name: true,
        fromDate: true,
        fromTime: true,
        toDate: true,
        toTime: true,
        discountValue: true,
        selectedCollections: true,
        selectedProducts: true,
        selectedCustomers: true,
        eligibleUses: true,
        selectedLocations: true,
        minRequirements:{
            moreThanZero: true,
            noDecimal: true
        }
    })

    const validateCoupon = () => {

        let newValidState = {
            ...validState
        }

        if (!coupon.name)
            newValidState.name = false

        if (!activeFromDate.date)
            newValidState.fromDate = false;

        if (!activeFromDate.time)
            newValidState.fromTime = false;
        
        if (endTimeEnabled){
            if (!activeToDate.date)
                newValidState.toDate = false;

            if (!activeToDate.time)
                newValidState.toTime = false;
        }
        if(coupon.discountType === 'percent' && coupon.discountValue <= 0 && !coupon?.discountValue?.match(/^[1-9][0-9]*$/)){
            newValidState.discountValue = false
        }

        if(coupon.discountType === 'fixed'  && parseInt(coupon.discountValue) <= 0 && !coupon?.discountValue?.match(/^[1-9][0-9]*$/)){
            newValidState.discountValue = false
        }

        if((coupon.minRequirementsType === "totalSale" || coupon.minRequirementsType === "itemQuantity") && coupon.minRequirements <= 0){
            newValidState.minRequirements.moreThanZero = false
        }

        if ((coupon.minRequirementsType === "totalSale" || coupon.minRequirementsType === "itemQuantity") && coupon.minRequirements.toString().includes('.')){
            newValidState.minRequirements.noDecimal = false
        }

        if(limitCouponUsage && coupon.eligibleUses <= 0){
            newValidState.eligibleUses = false
        }

        if (coupon.appliesToType === 'collections'){
            if(selectedCollections.length <= 0){
                newValidState.selectedCollections = false
            }
        }

        if (coupon.appliesToType === 'products'){
            if(selectedProducts.length <= 0){
                newValidState.selectedProducts = false
            }
        }

        if (coupon.customersEligibleType === 'selected'){
            if(!coupon.customersEligible || coupon.customersEligible.length <= 0){
                newValidState.selectedCustomers = false
            }
        } 

        if (coupon.discountType === 'freeshipping' && coupon.locationsEligibleType === 'selected'){
            if(!coupon.locationsEligible || coupon.locationsEligible.length <= 0){
                newValidState.selectedLocations = false
            }
        } 

        let valid = true;
        
        for(let state in newValidState){
            if (typeof newValidState[state] === 'object'){
                for(let key in newValidState[state]){
                    if (newValidState[state][key] === false)
                        valid = false
                }
            }
            else{
                if (newValidState[state] === false)
                    valid = false
            }
        }
        setValidState(newValidState)

        return valid
    }
    function convertUTCDateToLocalDate(date) {
        var newDate = new Date(date.getTime());
        var hours = date.getHours();
    
        newDate.setHours(hours);
    
        return newDate;   
    }
    const saveCoupon = async () => {

        if (await validateCoupon() === false){
            return toast.error('Required Fields', {
                theme: 'colored',
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        let newCoupon = {...coupon}

        if(!newCoupon.couponToken)
            delete newCoupon.couponToken;
        
        let fromDate = `${activeFromDate.date} ${activeFromDate.time}`;//new Date(`${activeFromDate.date} ${activeFromDate.time}`);
        newCoupon.activeFromDate = fromDate;
        if (endTimeEnabled){
            //let toDate = new Date(`${activeToDate.date} ${activeToDate.time}`).toISOString();
            const toDate = `${activeToDate.date} ${activeToDate.time}`;
            newCoupon.activeToDate = toDate;

        } else {
            newCoupon.activeToDate = "";
        }

        if (coupon.appliesToType === 'collections')
            newCoupon.appliesTo = selectedCollections

        if (coupon.appliesToType === 'products')
            newCoupon.appliesTo = selectedProducts

        if (coupon.appliesToType === 'order')
            newCoupon.appliesTo = []

        if (coupon.customersEligibleType === 'all')
            newCoupon.customersEligible = []
        
        if (coupon.locationsEligibleType === 'all')
            newCoupon.locationsEligible = []

        let postCoupon = OmniApi.post(`/couponcodes${newCoupon.couponToken ? '/' + newCoupon.couponToken : ''}`, newCoupon)
        .then(res => {
            setUnsavedChanges(false)
            props.history.replace(`${props.location.pathname}${newCoupon.couponToken ? '' : '/' + res.data.couponToken}`)})

        toast.promise(
            postCoupon,
            {
                pending: {
                    render() {
                        return "Saving coupon..."
                    },
                    icon: true,
                },
                success: {
                    render(res) {
                        return `Coupon saved`
                    },
                    // other options
                    icon: true,
                },
                error: {
                    render(err) {
                        // When the promise reject, data will contains the error
                        return `${err.data}`
                    },
                    icon: true,
                }
            }
        )

    }

    const getSummary = (value) => {
        switch(value){
            case 'percent':
                return `${coupon.discountValue}% off ${coupon.appliesTo?.length || ''} ${coupon.appliesToType}`
            case 'fixed':
                return `$${coupon.discountValue} off ${coupon.appliesTo?.length || ''} ${coupon.appliesToType}`
            case 'freeshipping':
                return `Free Shipping off ${coupon.appliesTo?.length || ''} ${coupon.appliesToType}`
        }
    }
    const currentDateTime = new Date()
    if(coupon)
   
    return(
        <>
        <Helmet>
                <meta charSet="utf-8" />
                <title>Coupon Detail | {process.env.REACT_APP_HEADER_TITLE}</title>
            </Helmet>
        <Row>
            <Col className="d-flex align-items-center justify-content-between mt-1">
                <h2>{coupon.couponToken ? 'Edit Coupon' : 'New Coupon'}</h2>
            <div className="d-flex bg-light shadow-sm" style={{ borderRadius: '30px', padding: '5px' }}>
                <Button size="sm" color={coupon.status > 0 ? 'primary' : 'light'} className="btn-rounded" 
                onClick={() => setCoupon({
                    ...coupon,
                    status: 1
                })}
                >Active</Button>
                <Button size="sm" color={coupon.status === 0 ? 'secondary' : 'light'} className="btn-rounded ml-1 no-arrow card-drop"
                onClick={() => setCoupon({
                    ...coupon,
                    status: 0
                })}
                >Inactive</Button>
            </div>
            </Col>
        </Row>
        <hr className="mt-1"/>
        <Col>
        <Row className="d-flex justify-content-center bg-light rounded mt-1 mb-2 mx-2">
            <Card className="my-2">
                <CardBody>
                    <Row>
                        <Col>
                        <div className="d-flex justify-content-between">
                        <h4><u>Active Dates</u></h4>
                        <div className="d-flex align-items-center">
                        <CustomInput type="checkbox" label="Set end time" 
                            id="endTimeEnabled"
                            checked={endTimeEnabled}
                            onChange={(e) => {
                                setValidState({
                                    ...validState,
                                    toDate: true,
                                    toTime: true
                                })
                                let toDate = new Date(activeFromDate.date)
                                setActiveToDate({
                                    date: ((toDate.getMonth() > 8) ? (toDate.getMonth() + 1) : ('0' + (toDate.getMonth() + 1))) + '/' + ((toDate.getDate() > 9) ? toDate.getDate() : ('0' + toDate.getDate())) + '/' + toDate.getFullYear(),
                                    time: toDate.getHours()+":"+toDate.getMinutes(),//toDate.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'}).slice(0, -3),
                                    amOrPm: toDate.toLocaleTimeString().slice(-2)
                                })
                                setEndTimeEnabled(e.target.checked)}}
                        />
                        </div>
                        </div>
                        </Col>
                    </Row>
                    <Row>
                <Col>
                <FormGroup style={{ width: '140px' }}>
                    <Label>Start Date</Label>
                    {/* <MaskedInput
                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                        placeholder="__/__/____"
                        className={`form-control ${!validState.fromDate ? 'invalid' : ''}`}
                        style={{ textAlign: 'left' }}
                        name="date"
                        value={activeFromDate.date}
                        onChange={handleActiveFromDateInputChange}
                    /> */}
                    <DatePicker
                        selectsStart
                        minDate={new Date()}
                        selected={ (activeFromDate.date)?new Date(activeFromDate.date):new Date() }  
                        onChange={ (e)=>{
                            handleActiveFromDateInputChange(e,'date');
                             }} 
                        style={{ textAlign: 'left' }}
                        className='form-control'
                        dateFormat="MM/dd/yyyy"
                        name="date"
                    ></DatePicker>
                    {!validState.fromDate ? <div className="invalid-text">Enter valid date</div> : ''}
                </FormGroup>
                </Col>
                <Col>
                    <FormGroup style={{ width: '200px' }}>
                        <Label>Start Time</Label>
                        <InputGroup>
                                {/* <MaskedInput
                                mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                                placeholder="__:__"
                                className={`form-control ${!validState.fromTime ? 'invalid' : ''}`}
                                name="time"
                                value={activeFromDate.time}
                                onChange={(e)=>handleActiveFromDateInputChange(e,'name')}
                                style={{ textAlign: 'left' }}
                                required
                                pattern="^(0?[1-9]|1[0-2]):[0-5][0-9]$"
                            /> */}
                            <TimePicker
                                amPmAriaLabel="Select AM/PM"
                                hourAriaLabel="Hour"
                                minuteAriaLabel="Minute"
                                nativeInputAriaLabel="Time"
                                onChange={(e)=>{
                                    handleActiveFromDateInputChange(e,'time')
                                }}
                                value={(activeFromDate.time)?activeFromDate.time:currentDateTime.getHours()+":"+currentDateTime.getMinutes()+":"+currentDateTime.getSeconds()}
                                clearIcon={null}
                                clockIcon={null}
                                format='hh:mm a'
                                minTime={currentDateTime.getHours()+":"+currentDateTime.getMinutes()+":"+currentDateTime.getSeconds()}
                                className={` ${!validState.fromTime ? 'invalid' : ''}`}
                            />
                                {/* <ButtonGroup onClick={handleFromAmOrPmClick} value={activeFromDate.amOrPm}>
                                    <Button type="radio" name="am-pm" value="am" color="primary" 
                                    outline={activeFromDate.amOrPm !== 'AM'}
                                    >AM</Button>
                                    <Button type="radio" name="am-pm" value="pm" color="primary" 
                                    outline={activeFromDate.amOrPm !== 'PM'}
                                    >PM</Button>
                                </ButtonGroup> */}
                        </InputGroup>
                        {!validState.fromTime ? <div className="invalid-text">Enter valid time in the following format '05:04'</div> : ''}
                    </FormGroup>
                </Col>
                {(endTimeEnabled) &&
                <>
                <Col>
                <FormGroup style={{ width: '140px' }}>
                    <Label>End Date</Label>
                    {/* <MaskedInput
                        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                        placeholder="__/__/____"
                        className={`form-control ${!validState.toDate ? 'invalid' : ''}`}
                        style={{ textAlign: 'left' }}
                        name="date"
                        value={activeToDate.date}
                        onChange={handleActiveToDateInputChange}
                    /> */}
                    <DatePicker
                        selectsStart
                        minDate={new Date(activeFromDate.date)}
                        selected={ new Date(activeToDate.date) }  
                        onChange={ (e)=>handleActiveToDateInputChange(e,'date') } 
                        style={{ textAlign: 'left' }}
                        className={`form-control ${!validState.toDate ? 'invalid' : ''}`}
                        dateFormat="MM/dd/yyyy"
                        name="date"
                    ></DatePicker>
                    {!validState.toDate ? <div className="invalid-text">Enter valid date</div> : ''}
                </FormGroup>
                </Col>
                <Col>
                    <FormGroup style={{ width: '200px' }}>
                        <Label>End Time</Label>
                        <InputGroup>
                                {/* <MaskedInput
                                mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                                placeholder="__:__"
                                className={`form-control ${!validState.toTime ? 'invalid' : ''}`}
                                name="time"
                                value={activeToDate.time}
                                onChange={handleActiveToDateInputChange}
                                style={{ textAlign: 'left' }}
                                required
                                pattern="^(0?[1-9]|1[0-2]):[0-5][0-9]$"
                                /> */}
                                {/* <ButtonGroup  onClick={handleToAmOrPmClick} value={activeToDate.amOrPm}>
                                    <Button type="radio" name="am-pm" value="am" color="primary" 
                                    outline={activeToDate.amOrPm !== 'AM'}
                                    >AM</Button>
                                    <Button type="radio" name="am-pm" value="pm" color="primary" 
                                    outline={activeToDate.amOrPm !== 'PM'}
                                    >PM</Button>
                                </ButtonGroup> */}
                                <TimePicker
                                    amPmAriaLabel="Select AM/PM"
                                    hourAriaLabel="Hour"
                                    minuteAriaLabel="Minute"
                                    nativeInputAriaLabel="Time"
                                    onChange={ (e)=>handleActiveToDateInputChange(e,'time') } 
                                    value={(activeToDate.time!=="0:0")?activeToDate.time:activeFromDate.time}
                                    clearIcon={null}
                                    clockIcon={null}
                                    format='hh:mm a'
                                    minTime={activeFromDate.time+":00"}
                                    className={` ${!validState.toTime ? 'invalid' : ''}`}
                                />
                        </InputGroup>
                        {!validState.toTime ? <div className="invalid-text">Enter valid time in the following format '05:04'</div> : ''}
                    </FormGroup>
                </Col>
                </>
                }
                </Row>
                </CardBody>
            </Card>
            </Row>
            </Col>
            <Row>
            <Col>
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="name">Coupon Code <span className='required-field'>*</span></Label>
                                    <Input name="name" id="name" value={coupon.name} 
                                    className={`${!validState.name ? 'invalid' : ''}`}
                                    placeholder="e.g. SPRINGSALE..."
                                    onChange={(e) => {
                                        setValidState({
                                            ...validState,
                                            name: true
                                        })
                                        setCoupon({
                                        ...coupon,
                                        name: e.target.value
                                    })}}/>
                                    {!validState.name ? <div className="invalid-text">Name required</div> : ''}
                                    <FormText>
                                        Customer will enter this coupon code at checkout
                                    </FormText>
                                </FormGroup>
                            </Col>
                            <Col>
                                <Row>
                                <Col className="d-flex flex-row">
                                <FormGroup>
                                    <Label for="Dropdown">Select Coupon Type</Label>
                                    <div style={{width: '170px' }} className="mr-0">
                                        <Select
                                        id="itemType"
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        onChange={onSelectChange}
                                        value={selectTypeOptions.find(option => option.value === coupon.discountType)}
                                        isSearchable={false}
                                        options={selectTypeOptions}>
                                        </Select>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                <Label for="discountValue">Coupon Value <span className='required-field'>*</span></Label>
                                <InputGroup style={{ width: '150px' }}>
                                    {(coupon.discountType === 'fixed') &&
                                    <InputGroupAddon addonType="prepend">$</InputGroupAddon>}
                                    <Input type="number" placeholder="Value..." id="discountValue"
                                    className={`${!validState.discountValue ? 'invalid' : ''}`}
                                    name="discountValue"
                                    value={coupon.discountValue}
                                    onChange={(e) => {
                                        if (parseInt(coupon.discountValue) >= 0)
                                            setValidState({
                                                ...validState,
                                                discountValue: true
                                            })
                                        setCoupon({
                                            ...coupon,
                                            discountValue: e.target.value
                                        })
                                    }}
                                    disabled={coupon.discountType === 'freeshipping'} 
                                    />
                                    {(coupon.discountType === 'percent' || coupon.discountType === 'freeshipping') &&
                                    <InputGroupAddon addonType="append">%</InputGroupAddon>}
                                </InputGroup>
                                {!validState.discountValue ? <div className="invalid-text">Coupon value cannot be less than 0</div> : ''}
                            </FormGroup>
                            </Col>
                            </Row>
                            </Col>
                        </Row>
                        {(coupon.discountType === 'freeshipping') &&
                         <Row>
                            <Col md={'auto'}>
                                <FormGroup>
                                    <Label><h5><u>Free Shipping</u></h5></Label>
                                    <div className="mr-0" style={{ width: '220px' }}>
                                        <Select
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        onChange={onSelectFreeShippingOptionsSelect}
                                        value={selectFreeShippingOptions.find(option => option.value === coupon.locationsEligibleType)
                                        ||
                                        {id: 'all', label: 'All Countries'}
                                        }
                                        isSearchable={false}
                                        options={selectFreeShippingOptions}>
                                        </Select>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col>
                            <FormGroup>
                                <Label><h5>Select Countries:</h5></Label>
                                <Typeahead
                                inputProps={{
                                    className: `${!validState.selectedLocations ? 'invalid' : ''}`
                                }}
                                paginate
                                maxResults={15}
                                id="select2"
                                labelKey="name"
                                multiple
                                options={CountryList.map(country => country)}
                                // emptyLabel={<div onClick={() => alert('clicked')}>hello</div>}
                                onChange={onCountrySelectChange}
                                selected={(coupon.locationsEligible) ?
                                coupon.locationsEligible.map(locCode => CountryList.find(cntry => cntry.code === locCode))
                                .filter(loc => loc !== undefined)
                                :
                                []
                                }
                                placeholder="Select country..."
                                disabled={coupon.locationsEligibleType === 'all'}
                                />
                                {!validState.selectedLocations ? <div className="invalid-text">No locations selected</div> : ''}
                                {(coupon.locationsEligibleType === 'all') && <div className="overlay rounded"></div>}
                            </FormGroup>
                            </Col>
                            </Row>}
                        <hr />
                        <Row>
                            <Col>
                                <h5>Usage Limits</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <FormGroup>
                                <CustomInput type="checkbox" label="Limit number of uses" 
                                id="limitEligibleUses"
                                checked={limitCouponUsage}
                                onChange={(e) => {
                                    setValidState({
                                        ...validState,
                                        eligibleUses: true
                                    })
                                    setLimitCouponUsage(e.target.checked)}}
                                />
                            </FormGroup>
                                <FormGroup>
                                <Input 
                                type="number"
                                placeholder="Number of uses..." 
                                min="0"
                                value={coupon.eligibleUses}
                                className={`${!validState.eligibleUses ? 'invalid' : ''}`}
                                onChange={(e) => {
                                    let val = e.target.value;
                                    if (val > 0){
                                        setValidState({
                                            ...validState,
                                            eligibleUses: true
                                        })
                                    }
                                    setCoupon({
                                    ...coupon,
                                    eligibleUses: val
                                })}}
                                disabled={!limitCouponUsage}
                                />
                                {!validState.eligibleUses ? <div className="invalid-text">Number of uses must be set to more than 0</div> : ''}
                            </FormGroup>
                            </Col>
                            <Col>
                            <FormGroup>
                                <CustomInput type="checkbox" label="Limit to one use per customer" 
                                id="onePerCustomer"
                                checked={coupon.onePerCustomer > 0}
                                onChange={(e) => setCoupon({
                                    ...coupon,
                                    onePerCustomer: e.target.checked ? 1 : 0
                                })}
                                />
                            </FormGroup>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                               <h5>Minimum Requirements</h5> 
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex flex-row">
                                <FormGroup>
                                    <Label>Select Minimum Requirement</Label>
                                    <div className="mr-0" style={{ width: '300px' }}>
                                        <Select
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        onChange={onMinRequirementSelectChange}
                                        value={selectMinRequirementTypeOptions.find(option => option.value === coupon.minRequirementsType)}
                                        isSearchable={false}
                                        options={selectMinRequirementTypeOptions}>
                                        </Select>
                                    </div>
                                </FormGroup>
                                {(coupon.minRequirementsType !== 'none') &&
                                <FormGroup>
                                {(coupon.minRequirementsType === 'totalSale') && <Label for="minRequirements">Amount</Label>}
                                {(coupon.minRequirementsType === 'itemQuantity') && <Label for="minRequirements">Qty</Label>}
                                <InputGroup style={{ width: '150px' }}>
                                    {(coupon.minRequirementsType === 'totalSale') &&
                                    <InputGroupAddon addonType="prepend">$</InputGroupAddon>}
                                    {(coupon.minRequirementsType === 'itemQuantity') &&
                                    <InputGroupAddon addonType="prepend">#</InputGroupAddon>}
                                    <Input type="number" id="minRequirements" name="minRequirements"
                                    min="0"
                                    className={`${(!validState.minRequirements.moreThanZero || !validState.minRequirements.noDecimal) ? 'invalid' : ''}`}
                                    value={coupon.minRequirements}
                                    onChange={(e) => {
                                        let val = e.target.value;

                                        let parsedVal = parseFloat(val);

                                        if(Number.isInteger(parsedVal) && val > 0){
                                            setValidState({
                                                ...validState,
                                                minRequirements:{
                                                    moreThanZero: true,
                                                    noDecimal: true
                                                }
                                            })
                                        } else if (Number.isInteger(parsedVal)) {
                                            setValidState({
                                                ...validState,
                                                minRequirements:{
                                                    ...validState.minRequirements,
                                                    noDecimal: true
                                                }
                                            })
                                        } else if (val > 0) {
                                            setValidState({
                                                ...validState,
                                                minRequirements:{
                                                    ...validState.minRequirements,
                                                    moreThanZero: true
                                                }
                                            })
                                        }

                                        setCoupon({
                                        ...coupon,
                                        minRequirements: val
                                    })}
                                    }
                                    />
                                    {!validState.minRequirements.moreThanZero ? <div className="invalid-text">Value cannot be 0 or less</div> : ''}
                                    {!validState.minRequirements.noDecimal ? <div className="invalid-text">Value cannot include decimal</div> : ''}
                                </InputGroup>
                            </FormGroup>}
                            </Col>
                        </Row>
                </CardBody>
            </Card>
        </Col>
        <Col sm={4}>
            <Card>
                <div className="table-responsive">
                    <table className="table mb-0">
                        {/* <thead className="bg-light">
                            <tr>
                                <th>Coupon Summary</th>
                            </tr>
                        </thead> */}
                        <tbody>
                            <tr className="bg-light">
                                <td className="text-center"><strong>Coupon Summary</strong><span><Badge color={coupon.status > 0 ? 'primary' : 'secondary'} pill className="ml-1">
                                {coupon.status > 0 ? 'Active' : 'Inactive'}
                        </Badge></span></td>
                            </tr>
                            {coupon.activeFromDate &&
                            <tr>
                                <td className="text-center"><strong>Active from:</strong> {new Date(coupon.activeFromDate).toLocaleString()}</td>
                            </tr>
                            }
                            {coupon.activeToDate &&
                            <tr>
                                <td className="text-center"><strong>Expires by:</strong> {new Date(coupon.activeToDate).toLocaleString()}</td>
                            </tr>
                            }
                            {(coupon.discountValue) &&
                            <tr>
                                <td className="text-center">{getSummary(coupon.discountType)}</td>
                            </tr>}
                            {(coupon.customersEligibleType === 'selected') &&
                             <tr>
                                <td className="text-center">Limited to {coupon.customersEligible?.length || 0} customers</td>
                            </tr>}
                            {((coupon.minRequirementsType === 'itemQuantity' || coupon.minRequirementsType === 'totalSale') && coupon.minRequirements > 0) &&
                            <tr>
                                <td className="text-center">Minimum purchase of {(coupon.minRequirementsType === 'itemQuantity' && coupon.minRequirements > 0) ? `${coupon.minRequirements} items` : `$${coupon.minRequirements}`}</td>
                            </tr>}
                            {(limitCouponUsage && coupon.eligibleUses > 0) &&
                             <tr>
                                <td className="text-center">Limit of {coupon.eligibleUses} uses</td>
                            </tr>}
                            {(coupon.onePerCustomer > 0) &&
                             <tr>
                                <td className="text-center">Limit of 1 per customer</td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
            </Card>
            <Card>
            <div className="table-responsive">
                    <table className="table mb-0">
                        {/* <thead className="bg-light">
                            <tr>
                                <th>Coupon Summary</th>
                            </tr>
                        </thead> */}
                        <tbody>
                            <tr className="bg-light">
                                <td className="text-center"><strong>Usage History</strong></td>
                            </tr>
                            <tr>
                                <td className="text-center"><strong>{coupon.timesUsed || 0}</strong> use{coupon.timesUsed !==  1 ? 's' : ''}</td>
                            </tr>
                            {(coupon.moneySaved) &&  
                            <tr>
                                <td className="text-center">Total saved: $<strong>{coupon.moneySaved}</strong></td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </Card>
        </Col>
        </Row>
        <Row>
        <Col>
            <Card>
                <CardBody>
                    <Row className="mb-2">
                        <Col>
                            <h4><u>Customer Eligibility</u></h4>
                        </Col>
                    </Row>
                    <Row>
                    <Col>
                        <ButtonToolbar className="justify-content-center mb-2">
                            <ButtonGroup
                            onClick={(e) => setCoupon({...coupon, customersEligibleType: e.target.value})}
                            >
                                <Button color="primary" outline={coupon.customersEligibleType !== 'all'} 
                                className="btn-rounded" value="all"
                                >
                                    All Customers
                                </Button>
                                <Button color="primary" outline={coupon.customersEligibleType !== 'selected'}
                                className="btn-rounded" value="selected">
                                    Selected Customers
                                </Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                        <Card>
                            <CardBody>
                        <FormGroup>
                            <Label for="itemType">Select Customers</Label>
                            <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <span className="input-group-text py-0 px-1">
                                    <Icon icon={accountBox} width="25" height="25" />
                                </span>
                            </InputGroupAddon>
                            <Typeahead
                            ref={customersTypeaheadRef}
                            inputProps={{
                                className: `${!validState.selectedCustomers ? 'invalid' : ''}`
                            }}
                            paginate
                            maxResults={15}
                            id="select2"
                            labelKey="customerEmail"
                            multiple={false}
                            options={customerList.map(customer => customer)}
                            // emptyLabel={<div onClick={() => alert('clicked')}>hello</div>}
                            onChange={onCustomerSelectChange}
                            placeholder="Select customer..."
                            />
                            </InputGroup>
                            {!validState.selectedCustomers ? <div className="invalid-text">No customers selected</div> : ''}
                        </FormGroup>
                            <ListGroup flush>
                                {(coupon.customersEligible) && 
                                coupon.customersEligible.map(customerId => customerList.find(customer => customer.customerToken === customerId))
                                .filter(customer => customer !== undefined)
                                .map(customer => (
                                    <ListGroupItem className="d-flex">
                                        <div className="d-flex align-items-center"><strong>{customer.customerFirstName} {customer.customerLastName}</strong></div>
                                        <div className="mx-1 d-flex align-items-center">⚬</div>
                                        <div className="d-flex align-items-center">{customer.customerEmail}</div>
                                        <div className="btn ml-auto p-0 d-flex align-items-center" onClick={() => removeSelectedCustomer(customer.customerToken)}>
                                        <Icon icon={closeBox} color="#fa5c7c" width="30" height="30" />
                                        </div>
                                    </ListGroupItem>
                                ))}
                            </ListGroup>
                        </CardBody>
                        {(coupon.customersEligibleType === 'all') && <div className="overlay rounded"></div>}
                        </Card>
                    </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
        <Col>
            <Card>
                <CardBody>
                <Row className="mb-2">
                        <Col>
                            <h4><u>Applies To</u></h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <ButtonToolbar className="justify-content-center mb-2">
                            <ButtonGroup onClick={setAppliesTo}
                            >
                                <ButtonGroup>
                                    <Button color="primary" 
                                    outline
                                    active={coupon.appliesToType === 'order'}
                                    className="btn-rounded" value="order">
                                        Entire Order
                                    </Button>
                                    <Button color="primary"
                                    outline
                                    active={coupon.appliesToType === 'collections'}
                                    className="btn-rounded" value="collections">
                                        Selected Collections
                                    </Button>
                                    <Button color="primary"
                                    outline
                                    active={coupon.appliesToType === 'products'}
                                    className="btn-rounded" value="products">
                                        Selected Products
                                    </Button>
                                </ButtonGroup>
                            </ButtonGroup>
                        </ButtonToolbar>
                        <Card>
                            <CardBody>
                                {(coupon.appliesToType === 'collections')
                                && 
                                <>
                                    <FormGroup>
                                    <Label for="itemType">Select Collections</Label>
                                    <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <span className="input-group-text py-0 px-1">
                                            <Icon icon={shapeIcon} width="25" height="25" />
                                        </span>
                                    </InputGroupAddon>
                                    <Typeahead
                                    ref={collectionsTypeaheadRef}
                                    inputProps={{
                                        className: `${!validState.selectedCollections ? 'invalid' : ''}`
                                    }}
                                    paginate
                                    maxResults={15}
                                    id="select2"
                                    labelKey="collectionName"
                                    multiple={false}
                                    options={collectionList.map(collection => collection)}
                                    // emptyLabel={<div onClick={() => alert('clicked')}>hello</div>}
                                    onChange={onCollectionSelectChange}
                                    placeholder="Select collection..."
                                    />
                                    </InputGroup>
                                </FormGroup>
                            <ListGroup flush>
                                {(selectedCollections) &&
                                selectedCollections.map(collectionId => collectionList.find(collection => collection.collectionToken === collectionId))
                                .filter(collection => collection !== undefined)
                                .map(collection => (
                                    <ListGroupItem className="d-flex">
                                        <div className="d-flex align-items-center"><strong>{collection.collectionName}</strong></div>
                                        <div className="btn ml-auto p-0 d-flex align-items-center" onClick={() => removeSelectedCollection(collection.collectionToken)}>
                                        <Icon icon={closeBox} color="#fa5c7c" width="30" height="30" />
                                        </div>
                                    </ListGroupItem>
                                ))}
                            </ListGroup>
                            {!validState.selectedCollections ? <div className="invalid-text">No collections selected</div> : ''}
                            </>
                            }
                            {(coupon.appliesToType === 'products')
                                && 
                                <>
                                    <FormGroup>
                                    <Label for="itemType">Select Products</Label>
                                    <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <span className="input-group-text py-0 px-1">
                                            <Icon icon={shoppingIcon} width="25" height="25" />
                                        </span>
                                    </InputGroupAddon>
                                    <Typeahead
                                    ref={productsTypeaheadRef}
                                    inputProps={{
                                        className: `${!validState.selectedProducts ? 'invalid' : ''}`
                                    }}
                                    paginate
                                    maxResults={15}
                                    id="select2"
                                    labelKey="prodName"
                                    multiple={false}
                                    options={productList.map(product => product)}
                                    // emptyLabel={<div onClick={() => alert('clicked')}>hello</div>}
                                    onChange={onProductSelectChange}
                                    placeholder="Select product..."
                                    />
                                    </InputGroup>
                                </FormGroup>
                            <ListGroup flush>
                                {(selectedProducts) &&
                                selectedProducts.map(productId => productList.find(product => product.prodToken === productId))
                                .filter(product => product !== undefined)
                                .map(product => (
                                    <ListGroupItem className="d-flex">
                                        <div className="d-flex align-items-center"><strong>{product.prodName}</strong></div>
                                        <div className="btn ml-auto p-0 d-flex align-items-center" onClick={() => removeSelectedProduct(product.prodToken)}>
                                        <Icon icon={closeBox} color="#fa5c7c" width="30" height="30" />
                                        </div>
                                    </ListGroupItem>
                                ))}
                            </ListGroup>
                            {!validState.selectedProducts ? <div className="invalid-text">No products selected</div> : ''}
                            </>
                            }
                        </CardBody>
                        {(coupon.appliesToType === 'order') && <div className="overlay rounded d-flex justify-content-center align-items-center">
                                <h5>Coupon Applies to entire order</h5>
                            </div>}
                        </Card>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
        </Row>
        <Row className="mb-3">
            <Col>
                <div className="button-list d-flex justify-content-end">
                    <Button color="danger" size="lg" disabled={!coupon.couponToken}
                    onClick={deleteCoupon}
                    >Delete</Button>
                    <Button color="primary" size="lg" className="ml-3" 
                    onClick={saveCoupon}
                    >Save</Button>
                </div>
            </Col>
        </Row>
        </>
    )
    else
        return (
            <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
            <div>
            <h4>Loading coupon...</h4>
                <div className="bouncing-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                </div>
            </div>
            )

}

export default Coupon;
