import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    Input, InputGroup, InputGroupAddon, Button,
    Badge,
    Spinner
    // CustomInput,
    // Form,
    // FormGroup,
    // Label,
    // Input,
    // FormText,
    // InputGroup,
    // InputGroupAddon,
    // UncontrolledButtonDropdown,
    // Button,
    // DropdownToggle,
    // DropdownMenu,
    // DropdownItem,
} from 'reactstrap';
import { Icon } from '@iconify/react';
import plusOutline from '@iconify/icons-mdi/plus-outline';
import circleSlice8 from '@iconify-icons/mdi/circle-slice-8';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
// import { select } from '@redux-saga/core/effects';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { OmniApi } from '../customAPI/OmniApi';
import { UserContext } from '../custom-contexts/UserContext';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import plusThick from '@iconify/icons-mdi/plus-thick';
import minusThick from '@iconify/icons-mdi/minus-thick';
import contentSave from '@iconify/icons-mdi/content-save';
import loadingIcon from '@iconify/icons-mdi/loading';
import { toast } from 'react-toastify';
import { commonHelper } from '../helpers/commonHelper';
import debounce from 'lodash.debounce'

const RemotePagination = ({ data, columns, page, sizePerPage, onTableChange, totalSize, showTotal = true, paginationTotalRenderer }) => {
    const { ExportCSVButton } = CSVExport;

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const history = useHistory();

    const { user } = useContext(UserContext);

    // The onCLick in the row event routes the user to the product detail page when row is clicked on
    const rowEvents = {
        onClick: (e, row) => {

            const location = {
                pathname: `/omni/catalog/product/${row.prodToken}`,
                state: {
                    row
                }
            }

            history.push(location)
        }
    }

    const rowClasses = (row, rowIndex) => {
        return 'table-row';
    };


    const [queryObject, setQueryObject] = useState({})

    useEffect(() => {

        let tempObject = {}

        for (let pair of query.entries()) {
            tempObject[pair[0]] = pair[1]
        }

        setQueryObject(tempObject)

    }, [])

    const addExpandedToUrl = (rowToken = '') => {
        query.set('expandedProduct', rowToken)

        // need to fix issue where on url change another solr search issued. It doesn't effect the page but is still an uneccesary api call
        // there's an additional issue where the query param remains on close.
        //history.replace(location.pathname + '?' + query.toString(), { from: queryObject });
    }

    const clearUrlRef = useRef(null);

    const expandRow = {
        expanded: [query.get('expandedProduct')],
        // rows that don't have variants should not be expandable
        nonExpandable: [],
        onExpand: (row, isExpand, rowIndex, e) => {
            if(row?.prodToken){
                if (!isExpand && row?.prodToken) {
                    clearUrlRef.current = setTimeout(addExpandedToUrl, 500)
                } else {
                    clearTimeout(clearUrlRef.current)
                }
            }
        },
        className: 'expanded-variants noHover rounded-bottom',
        parentClassName: 'expanded-products rounded-top',
        renderer: (row, rowIndex) => {
            if (row.totalVariants > 0) {
                return (<ExpandedRow prodToken={row.prodToken} user={user} addExpandedToUrl={addExpandedToUrl} />)
            }
            else return <div className="w-100 d-flex justify-content-center"><h5>No Variants</h5></div>
        },
        showExpandColumn: true,
        onlyOneExpanding: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            //addExpandedToUrl()
            //return isAnyExpands ? <i className="dripicons-minus" /> : <i className="dripicons-plus" />;
        },
        expandColumnRenderer: ({ expanded }) => {
            return expanded ? <i className="dripicons-minus" /> : <i className="dripicons-plus" />;
        },
        expandByColumnOnly: true
    };

    const paginationOptions = {
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        sizePerPageList: [
            {
                text: '10',
                value: 10,
            },
            {
                text: '20',
                value: 20,
            },
            {
                text: '60',
                value: 60,
            },
            {
                text: '100',
                value: 100,
            },
        ], // A numeric array is also available. the purpose of above example is custom the text
    };

    const [searchTerm, setSearchTerm] = useState('');

    const searchChange = (e) => {
        //return false;
        let result = e//.target.value;
        //close expanded row on search
        //addExpandedToUrl()
        let page = parseInt(query.get('page'));
        if (page > 1)
            query.set('page', '1')
        query.set('searchTerm', result);
        if(!query?.page){
            query.set('page', 1);
        }
        if(!query?.sizePerPage){
            query.set('sizePerPage', 15);
        }
        if(!query?.sortOrder){
            query.set('sortOrder', 'asc');
        }
        if(!query?.sortField){
            query.set('sortField', 'prodName');
        }
        history.push(location.pathname + "?" + query.toString());
    }

    let searchUrl = query.get('searchTerm')

    useEffect(() => {
        let result = query.get('searchTerm')
        setSearchTerm(result);
    }, [searchUrl])

    const [selectedRows, setSelectedRows] = useState([]);

    const selectRow = {
        mode: 'checkbox',
        style: { background: '#CCD0FB' },
        clickToSelect: false,
        onSelectAll: (isSelect, rows, e) => {
            if (isSelect) {
                setSelectedRows(rows.map(e => e.prodToken));
            } else {
                setSelectedRows([]);
            }
        },
        onSelect: (row, isSelect, rowIndex, e) => {
            // console.log(row, isSelect, rowIndex);
            if (isSelect) {
                setSelectedRows([...selectedRows, row.prodToken]);
            } else {
                setSelectedRows(selectedRows.filter(e => e !== row.prodToken));
            }
        },
        selectionRenderer: ({ checked, disabled }) => {
            return (
                <div className="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        className="custom-control-input row-checkbox"
                        checked={checked}
                        disabled={disabled}
                        onChange={() => { }}
                    />
                    <label
                        className="custom-control-label row-checkbox-label"
                        onClick={e => {
                            e.preventDefault();
                        }}></label>
                </div>
            );
        },
        selectionHeaderRenderer: ({ indeterminate, ...rest }) => {
            return (
                <div className="custom-control custom-checkbox select-header">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck1"
                        ref={input => {
                            if (input) input.indeterminate = indeterminate;
                        }}
                        {...rest}
                        onChange={() => { }}
                    />
                    <label
                        className="custom-control-label"
                        htmlFor="customCheck1"
                        onClick={e => {
                            e.preventDefault();
                        }}></label>
                </div>
            );
        },
    };

    useEffect(() => {
        if (!searchTerm) {
            addExpandedToUrl()
        } else {
            //addExpandedToUrl(data[0]?.prodToken)
        }
    }, [searchTerm, JSON.stringify(data)])

    const serachData = useCallback(debounce((e)=>{
        query.set('searchTerm', e);
        searchChange(e);
    }, 900), []);

    return (
        <ToolkitProvider
            bootstrap4
            keyField="prodToken"
            data={data}
            columns={columns}
            exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
            {props => (
                <React.Fragment>
                    <Row className="mb-2">
                        <Col className="text-right form-inline justify-content-end">
                            <input className="form-control" placeholder='Search by product name' value={searchTerm} onInput={(e)=>{
                                e.persist()
                                setSearchTerm(e.target.value)
                                serachData(e.target.value)
                                }}
                            />
                        </Col>
                    </Row>

                    <BootstrapTable
                        {...props.baseProps}
                        remote
                        bordered={false}
                        rowStyle={{ background: searchTerm ? '#d6e1ff' : 'white' }}
                        headerClasses="thead-light"
                        // defaultSorted={ defaultSorted }
                        pagination={paginationFactory({ page, sizePerPage, totalSize, paginationTotalRenderer, showTotal })}
                        // wrapperClasses="table-responsive"
                        classes="product-table"
                        expandRow={expandRow}
                        rowClasses={rowClasses}
                        hover
                        onTableChange={onTableChange}
                    // noDataIndication={() => 'No results...'}
                    />
                </React.Fragment>
            )}
        </ToolkitProvider>
    );
}

const InventoryList = () => {

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const history = useHistory();

    const { user } = useContext(UserContext);

    // const [selectedRows, setSelectedRows] = useState([]);
    const [products, setProducts] = useState([]);

    const [paginationValues, setPaginationValues] = useState({
        page: null,
        data: [],
        sizePerPage: null,
        sortOrder: null,
        sortField: null,
        totalSize: null
    })

    // Gets the pagination query params from the url and sets the correct data to be displayed in that pages table
    // useEffect(() => {
    //     const page = parseInt(query.get('page'));
    //     const sizePerPage = parseInt(query.get('sizePerPage'));
    //     const currentIndex = (page - 1) * sizePerPage;

    //     setPaginationValues({
    //         ...paginationValues,
    //         data: products ? products.slice(currentIndex, currentIndex + sizePerPage) : ''
    //     })
    // }, [products])

    // Set default pagination query params if none are existent
    // useEffect(() => {
    //     if (!location.search){
    //         query.set('page', 1);
    //         query.set('sizePerPage', 15);
    //         console.log('query', query)
    //         history.push(location.pathname + "?" + query.toString());
    //     }
    // }, [])

    const columns = [
        {
            dataField: 'prodImage',
            text: 'Image',
            sort: false,
            formatter: (cell, row) => {
                if (row.prodImage.file)
                    return (
                        <div style={{ 'width': '50px', 'height': '50px', 'overflow': 'hidden' }} className="rounded">
                            {fs1Url && <img src={`${fs1Url}/${row.prodImage.file}/50`} />}
                        </div>
                    )
                else
                    return (
                        <div style={{ 'width': '50px', 'height': '50px', 'overflow': 'hidden' }} className="rounded">
                            <img src='http://dummyimage.com/50' />
                        </div>
                    )
            }
        },
        {
            dataField: 'prodName',
            text: 'Product Name',
            sort: true
        },
        {
            dataField: 'totalCount',
            text: 'Inventory',
            sort: false,
            formatter: (cell, row) => {
                return (
                    <div><strong>{(row?.inventoryCount)?row.inventoryCount:0}</strong> in stock from <strong>{row.totalVariants}</strong> variants</div>
                )
            }
        }
    ];


    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Showing <strong>{from}</strong> to <strong>{to}</strong> of <strong>{size}</strong> Results
        </span>
    );

    const [tableChangeType, setTableChangeType] = useState('');

    // When pagination query params change, update the pagination values
    useEffect(() => {

        if (!location.search) {
            query.set('page', 1);
            query.set('sizePerPage', 15);
            query.set('sortOrder', 'asc');
            query.set('sortField', 'prodName');
            query.set('searchTerm', '');
            history.replace(location.pathname + "?" + query.toString());
        }

        const page = parseInt(query.get('page'));
        const sizePerPage = parseInt(query.get('sizePerPage'));
        const sortOrder = query.get('sortOrder');
        const sortField = query.get('sortField');
        const searchQuery = query.get('searchTerm');
        const expandedProduct = query.get('expandedProduct');
        console.log(commonHelper.solrUrlParams(page, sizePerPage, sortOrder, sortField, searchQuery));
        OmniApi.get(`/products${commonHelper.solrUrlParams(page, sizePerPage, sortOrder, sortField, searchQuery)}`)
            .then(res => {
                if(!expandedProduct){
                    commonHelper.goOnToOfThePage();
                }
                setPaginationValues({
                    page,
                    sizePerPage,
                    sortOrder,
                    sortField,
                    data: res.data.docs,
                    totalSize: res.data.numFound
                })
                if (searchQuery && res.data.docs.length) {
                    query.set('expandedProduct', res.data.docs[0]?.prodToken)
                    history.push(location.pathname + "?" + query.toString());
                } else {
                    query.set('expandedProduct', '')
                    history.push(location.pathname + "?" + query.toString());
                }
            })

    }, [location.search])

    const [fs1Url, setFs1Url] = useState(null);

    useEffect(() => {
        if (user) {
            const fs1UrlData = commonHelper.returnFs1StaticImagesUrl(user.company.fs1GetToken,null);
            setFs1Url(fs1UrlData);
        }

    }, [user])

    const solrUrlParams = (page, sizePerPage, sortOrder, sortField, searchQuery) => {
        let startIndex = (page - 1) * sizePerPage;

        const getQueryForBackend = (query) => {
            return `catchAll:"*${query
                .split(' ')
                .map((e) =>
                    e
                        .replace(/^[^A-Za-z0-9]|[^A-Za-z0-9]$/g, '')
                        .replace(/^\.|\.$/g, '')
                        .replace(/[^A-Za-z0-9 \.]/g, ' ')
                        .trim()
                        .replace(/ +/g, ' ')
                )
                .filter((e) => e !== '')
                .join('* *')}*"~2`;
        }

        return `/solr?start=${startIndex}&sort=${sortField}&q=${getQueryForBackend(searchQuery)}&rows=${sizePerPage}&sortOrder=${sortOrder}`.toString();
    }

    const onTableChange = (type, { sortField, sortOrder, page, sizePerPage }) => {
        setTableChangeType(type);
        if (type === 'pagination') {
            query.set('page', page);
            query.set('sizePerPage', sizePerPage);
        }
        if (type === 'sort') {
            query.set('sortField', sortField);
            query.set('sortOrder', sortOrder);
        }
        history.push(location.pathname + "?" + query.toString());
    }

    const { data, sizePerPage, page, totalSize } = paginationValues;

    // const alterTableData = (token, sum) => {
    //     let updatedRow = data.find(row => row.prodToken === token).totalCount = sum;
    //     data.filter(row => row.prodToken !== token);
    // //     data = [{
    // //         ...data,
    // //         updatedRow
    // //     }]
    // // }

    if (user) {
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Inventory | {process.env.REACT_APP_HEADER_TITLE}</title>
                </Helmet>
                <h2 className="page-title">
                    Inventory
                </h2>
                <Card className="mt-2">
                    <CardBody>
                        <RemotePagination
                            data={data}
                            page={page}
                            columns={columns}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            onTableChange={onTableChange}
                            paginationTotalRenderer={customTotal}
                            showTotal={true}
                        />
                    </CardBody>
                </Card>
            </>
        )
    } else {
        return (
            <h1>Loading</h1>
        )
    }
}

const ExpandedRow = props => {

    const { addExpandedToUrl, prodToken } = props;


    let expandedRowRef = useRef(null);

    // let myVar;

    useEffect(() => {
        addExpandedToUrl(prodToken)
    }, [])

    const [expandedProduct, setExpandedProduct] = useState('');

    useEffect(() => {
        OmniApi.get('/products/' + prodToken + '/full')
            .then(res => setExpandedProduct(res.data))
            .then(res => setTimeout(() => expandedRowRef.current?.scrollIntoView({ block: "center", behavior: 'smooth' }), 200))
    }, [])

    const getFS1Image = (img, size) => {
        return commonHelper.returnFs1StaticImagesUrl(props.user.company.fs1GetToken,size,true,img);//`https://fs1.cloudsnob.com/static/${props.user.company.fs1GetToken}/${img}/${size}`
    }

    const findVariantOption = (optionToken) => {
        let varOption = expandedProduct.prodOptions.find(option => option.optionToken === optionToken)
        return varOption?.optionName;
    }

    const variantOptions = (varOptions) => {

        let keys = Object.getOwnPropertyNames(varOptions)
        let values = Object.values(varOptions)

        let optionPairs = keys.map(key => findVariantOption(key)).map((key, i) => { return { name: key ? key : '', value: values[i] } }).sort((a, b) => {
            let nameA = a.name.toUpperCase();
            let nameB = b.name.toUpperCase();
            return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
        });


        return optionPairs.filter(option => option.value).map((option, i) => (
            <div key={i}>
                <strong>{option.name}:</strong> {option.value} <br />
            </div>
        ))
    }

    const ExpandedVariant = props => {

        const { variant, i,onUpdateVariant } = props;
        const [tempInventoryNumber, setTempInventoryNumber] = useState(variant.inventoryCount);
        const [inventoryNumber, setInventoryNumber] = useState(variant.inventoryCount);
        const [mainInventoryCount, setMainInventoryCount] = useState(variant.inventoryCount);

        const [inventoryUpdateType, setInventoryUpdateType] = useState('add');

        const [inventoryTotal, setInventoryTotal] = useState(variant.inventoryCount);

        const [pendingInventoryTotal, setPendingInventoryTotal] = useState('');
        const [whileSaving, setWhileSaving] = useState(false);


        function updateInventory(variantToken) {

            // let prevInventory = inventoryTotal;
            // console.log('PREV NUMBER', prevInventory)
            // let updatedInventory = tempInventoryNumber
            setWhileSaving(true);
            OmniApi.post('/variants/' + variantToken, { inventoryCount: tempInventoryNumber })
                .then(res => {
                    setWhileSaving(false);
                    toast.success("Inventory Updated")
                    setTempInventoryNumber(res.data.inventoryCount)
                    setInventoryTotal(res.data.inventoryCount);
                    if(res?.status==200){
                        setInventoryNumber(res?.data?.inventoryCount)
                        setMainInventoryCount(res?.data?.inventoryCount)
                    }
                })
                // .then(res => setTimeout(() => addExpandedToUrl(prodToken), 3000))
                .catch(err => {setWhileSaving(false);})
        }


        return (
            <>
                <Row>
                    <Col>
                        <div className="d-flex justify-content-between mb-2 mx-5" style={{ flexBasis: '100%' }}>
                            <div style={{ width: '35%' }} className="d-flex">
                                <div style={{ width: '60px', flexShrink: 0 }} className='ml-2 mr-2 mt-1'>
                                    {/* this img src is temporary due to the way variantImage is implemented where it saves a file even if there is no image */}
                                    <img style={{ height: '60px', width: '60px', borderRadius: '5px' }}
                                        src={(variant.variantImage?.file && variant.variantImage.file !== '[]') ?
                                            getFS1Image(variant.variantImage.file, 60) :
                                            "http://dummyimage.com/60"} alt='product-img' />
                                </div>
                                <div style={{ overflow: 'hidden' }}>
                                    <Link
                                        to={{
                                            pathname: `/omni/catalog/product/${expandedProduct.prodToken}/${variant.variantToken}`,
                                            state: { expandedProduct }
                                        }}
                                        className="mb-2 addClick" style={{ fontSize: '20px' }}
                                    >{variant.variantName || variant.variantToken}</Link><br />
                                    <div style={{ flexBasis: '100%' }}>
                                        {variant.variantOptions ? variantOptions(variant.variantOptions) : ''}
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: '10%', flexShrink: '1' }} className="d-flex flex-column align-items-center">
                                <div style={{ height: '1rem', width: '100%' }}></div>
                                <div>${variant.variantPrice}</div>
                            </div>
                            <div style={{ width: '15%', flexShrink: '1' }} className="d-flex flex-column align-items-center">
                                <div><u>Inventory</u></div>
                                <div><strong>{inventoryTotal}</strong></div>
                                {/* <div>{(tempInventoryNumber && (pendingInventoryTotal !== inventoryTotal)) &&
                                <Badge color={'secondary-lighten'}>
                                {pendingInventoryTotal}
                                </Badge>
                                }
                            </div>     */}
                            </div>
                            <div style={{ width: '35%', flexShrink: '1' }} className="d-flex align-items-center justify-content-end">
                                {/* <InputGroup className="d-flex justify-content-end">
                            <InputGroupAddon addonType="prepend">
                                    <Button
                                        className="py-0 px-1 addClick"
                                        color="secondary"
                                        size="sm"
                                        style={{ height: '38px' }}
                                        outline
                                        active={inventoryUpdateType === 'add'}
                                        onClick={() => setInventoryUpdateType('add')}
                                    >
                                        Add
                                    </Button>
                                </InputGroupAddon>
                                <InputGroupAddon addonType="prepend">
                                    <Button
                                        className="py-0 px-1 addClick"
                                        color="secondary"
                                        size="sm"
                                        style={{ height: '38px' }}
                                        outline
                                        active={inventoryUpdateType === 'set'}
                                        onClick={() => setInventoryUpdateType('set')}
                                    >
                                        Set Total
                                    </Button>
                                </InputGroupAddon>
                            <Input 
                            style={{ maxWidth: '80px' }}
                            type="number"
                            className="addClick"
                            value={tempInventoryNumber || ''}
                            onChange={(e) => setTempInventoryNumber(e.target.value)}
                            placeholder="0"
                                />
                                <InputGroupAddon addonType="append">
                                    <Button
                                        className="py-0 px-1 addClick"
                                        color="primary"
                                        size="sm"
                                        style={{ height: '38px' }}
                                        onClick={() => updateInventory(variant.variantToken)}
                                    >
                                        Save
                                    </Button>
                                </InputGroupAddon>
                        </InputGroup> */}
                                <div className="d-flex shadow-sm px-2 py-1" style={{ borderRadius: '30px' }}>
                                    <div className='d-flex align-items-center gap-3' style={{ gap: 7 }}>
                                        {/* <div className='mr-1 addClick' style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                            onClick={() => {
                                                const data = parseInt(inventoryNumber) - parseInt(tempInventoryNumber) > 0 ? parseInt(inventoryNumber) - parseInt(tempInventoryNumber) : 0
                                                setInventoryNumber(data)
                                                setTempInventoryNumber(data)
                                            }}>
                                            Remove By {tempInventoryNumber}
                                        </div> */}
                                        {/* <div className="bg-secondary d-flex justify-content-center align-items-center rounded-circle addClick mr-1 position-relative"
                                            style={{ width: '30px', minWidth: 30, height: '30px' }}
                                            onClick={() => {
                                                const data = parseInt(inventoryNumber) - parseInt(tempInventoryNumber) > 0 ? parseInt(inventoryNumber) - parseInt(tempInventoryNumber) : 0
                                                setInventoryNumber(data)
                                                setTempInventoryNumber(data)
                                            }}
                                            title={`Remove By ${tempInventoryNumber}`}
                                        >
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '-7px',
                                                    width: 30,
                                                    border: '1px solid black',
                                                    background: 'white',
                                                    borderRadius: '10px',
                                                    fontSize: '10px',
                                                    textAlign: 'center',
                                                    right: '-10px'
                                                }}
                                            >{tempInventoryNumber}</div>
                                            <Icon icon={minusThick} color="white" width="24" height="24" />
                                        </div> */}
                                        <div className="bg-secondary d-flex justify-content-center align-items-center rounded-circle addClick mr-1 position-relative"
                                            style={{ width: '30px', minWidth: 30, height: '30px' }}
                                            onClick={() => {
                                                setTempInventoryNumber(parseInt(tempInventoryNumber) - 1)
                                                setInventoryNumber(parseInt(tempInventoryNumber) - 1)
                                            }}
                                            title={`Remove By 1`}

                                        >
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '-7px',
                                                    width: 25,
                                                    border: '1px solid black',
                                                    background: 'white',
                                                    borderRadius: '10px',
                                                    fontSize: '10px',
                                                    textAlign: 'center',
                                                    right: '-10px'
                                                }}
                                            >1</div>
                                            <Icon icon={minusThick} color="white" width="24" height="24" />
                                        </div>
                                    </div>
                                    <Input
                                        style={{ maxWidth: '100px', textAlign: 'center' }}
                                        type="number"
                                        className="addClick"
                                        value={tempInventoryNumber || ''}
                                        onChange={(e) => {
                                            setTempInventoryNumber(e.target.value)
                                            setInventoryNumber(parseInt(e.target.value))
                                        }}
                                        // onBlur={(e) => {
                                        //     setInventoryNumber(e.target.value)
                                        //     // setTempInventoryNumber(e.target.value)
                                        // }}
                                        placeholder="0"
                                    />
                                    <div className="d-flex align-items-center ml-2" style={{ gap: 7 }}>
                                        <div className="bg-secondary d-flex justify-content-center align-items-center rounded-circle addClick ml-1 position-relative"
                                            style={{ width: '30px', minWidth: 30, height: '30px' }}
                                            onClick={() => {
                                                setTempInventoryNumber(parseInt(tempInventoryNumber) + 1)
                                                setInventoryNumber(parseInt(tempInventoryNumber) + 1)
                                            }}
                                            title={`Add By 1`}
                                        >
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '-7px',
                                                    width: 25,
                                                    border: '1px solid black',
                                                    background: 'white',
                                                    borderRadius: '10px',
                                                    fontSize: '10px',
                                                    textAlign: 'center',
                                                    right: '-10px'
                                                }}
                                            >1</div>
                                            <Icon icon={plusThick} color="white" width="24" height="24" />
                                        </div>
                                        {/* Multiple add button */}
                                        
                                        {/* <div className="bg-secondary d-flex justify-content-center align-items-center rounded-circle addClick ml-1 position-relative"
                                            style={{ width: '30px', minWidth: 30, height: '30px' }}
                                            onClick={() => {
                                                const data = parseInt(inventoryNumber) + parseInt(tempInventoryNumber)
                                                setInventoryNumber(data)
                                                setTempInventoryNumber(data)
                                            }}
                                            title={`Add By ${tempInventoryNumber}`}
                                        >
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '-7px',
                                                    width: 25,
                                                    border: '1px solid black',
                                                    background: 'white',
                                                    borderRadius: '10px',
                                                    fontSize: '10px',
                                                    textAlign: 'center',
                                                    right: '-10px'
                                                }}
                                            >{tempInventoryNumber}</div>
                                            <Icon icon={plusThick} color="white" width="24" height="24" />
                                        </div> */}

                                        {/* <div className='ml-1 addClick' style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                            onClick={() => {
                                                const data = parseInt(inventoryNumber) + parseInt(tempInventoryNumber)
                                                setInventoryNumber(data)
                                                setTempInventoryNumber(data)
                                            }}
                                        >
                                            Add By {tempInventoryNumber}
                                        </div> */}
                                    </div>
                                </div>
                                <div className="d-flex align-items-center ml-2">
                                {whileSaving &&
                                    <Button color="primary test" className="btn-icon addClick"
                                        onClick={() => updateInventory(variant.variantToken)}
                                        disabled={true}
                                    >
                                        <Icon icon={loadingIcon} rotate={3} width="20" height="20" className='rotate' />
                                    </Button>
                                }
                                {!whileSaving &&
                                    <Button color="primary test" className="btn-icon addClick"
                                        onClick={() => updateInventory(variant.variantToken)}
                                        disabled={Number(inventoryNumber) === Number(mainInventoryCount)}
                                    >
                                        <Icon icon={contentSave} width="20" height="20" />
                                    </Button>
                                }
                                </div>

                            </div>
                        </div>
                    </Col>
                </Row>
                {(i < (expandedProduct.variants.length - 1)) && <hr className="mt-0" />}
            </>
        )
    }

    if (expandedProduct)
        return (
            <div ref={expandedRowRef}>
                {expandedProduct.variants.map((variant, i) => (
                    <ExpandedVariant variant={variant} i={i} key={variant.variantToken} onUpdateVariant={()=>{}}/>
                ))}
            </div>
        )
    else
        return (
            <>
                <Row className="d-flex justify-content-center">
                    <Spinner color="primary" size="sm" />
                </Row>
            </>
        )
}

export default InventoryList;
