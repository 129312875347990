import React, { useEffect, useState, useContext } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    // CustomInput,
    // Form,
    // FormGroup,
    // Label,
    // Input,
    // FormText,
    // InputGroup,
    // InputGroupAddon,
    // UncontrolledButtonDropdown,
    // Button,
    // DropdownToggle,
    // DropdownMenu,
    // DropdownItem,
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import axios from 'axios';
import { useHistory, Link, useLocation } from 'react-router-dom';
import {OmniApi} from '../customAPI/OmniApi';
import { UserContext } from '../custom-contexts/UserContext';
import { Helmet } from 'react-helmet';
import { commonHelper } from '../helpers/commonHelper';
import RemotePaginations from '../components/Datatables/RemotePagination';

const RemotePagination = ({ data, columns, page, sizePerPage, onTableChange, totalSize, showTotal = true, paginationTotalRenderer }) => {
    const { SearchBar } = Search;
    const { ExportCSVButton } = CSVExport;

    let history = useHistory();

    // The onCLick in the row event routes the user to the brand detail page when row is clicked on
    const rowEvents = {
        onClick: (e, row) => {

            const location = {
                pathname: `/omni/catalog/brand/${row.brandToken}`,
                state: {
                    row
                }
            }

            history.push(location)
        }
    }

    const rowClasses = (row, rowIndex) => {
        return 'table-row';
    };

    const paginationOptions = {
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        sizePerPageList: [
            {
                text: '10',
                value: 10,
            },
            {
                text: '20',
                value: 20,
            },
            {
                text: '60',
                value: 60,
            },
            {
                text: '100',
                value: 100,
            },
        ], // A numeric array is also available. the purpose of above example is custom the text
    };

    return (
      <ToolkitProvider
        bootstrap4
        keyField="brandToken"
        data={data}
        columns={columns}
        search
        exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
        {props => (
            <React.Fragment>
                <Row className="mb-2">
                    <Col>
                    <Link to="/omni/catalog/brand" className="btn btn-danger">
                        <i className="mdi mdi-plus-circle mr-2"></i>Add Brand
                    </Link>
                    </Col>
                    <Col className="text-right">
                        <ExportCSVButton {...props.csvProps} className="export-csv-btn btn btn-primary mr-3">
                            Export CSV
                        </ExportCSVButton>
                        <SearchBar {...props.searchProps} placeholder='Search by brand name' />
                    </Col>
                </Row>

                <BootstrapTable
                    {...props.baseProps}
                    remote
                    bordered={false}
                    headerClasses="thead-light"
                    // defaultSorted={ defaultSorted }
                    pagination={ paginationFactory({ page, sizePerPage, totalSize, paginationTotalRenderer, showTotal }) }
                    wrapperClasses="table-responsive"
                    classes="product-table"
                    rowEvents={ rowEvents }
                    // selectRow={ selectRow }
                    rowClasses={ rowClasses }
                    hover
                    onTableChange={ onTableChange }
                    // noDataIndication={() => 'No results...'}
                />
            </React.Fragment>
        )}
    </ToolkitProvider>
  );
  }

const Brands = () => {

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const history = useHistory();

    const { user } = useContext(UserContext);

    // const [selectedRows, setSelectedRows] = useState([]);
    const [brands, setBrands] = useState([]);

    const [fs1Url, setFs1Url] = useState(null);

    const [paginationValues, setPaginationValues] = useState({
        page: parseInt(query.get('page')),
        data: [],
        sizePerPage: parseInt(query.get('sizePerPage')),
        totalSize: ''
    })

    // Gets the pagination query params from the url and sets the correct data to be displayed in that pages table
    // useEffect(() => {
    //     const currentIndex = (page - 1) * sizePerPage;
    //     setPaginationValues({
    //         ...paginationValues,
    //         data: brands ? brands.slice(currentIndex, currentIndex + sizePerPage) : ''
    //     })
    // }, [brands])

    useEffect(() => {
        if (!location.search){
            query.set('page', 1);
            query.set('sizePerPage', 15);
            query.set('searchTerm', '');
            query.set('sortOrder', 'asc');
            query.set('sortField', 'brandName');
            history.replace(location.pathname + "?" + query.toString());
        }
        const page = (parseInt(query.get('page')))?parseInt(query.get('page')):1;
        const sizePerPage = parseInt(query.get('sizePerPage'));
        const sortOrder = query.get('sortOrder');
        const sortField = query.get('sortField');
        const searchQuery = query.get('searchTerm');
        
        const queryParams = (commonHelper.buildQueryParams(page, sizePerPage, sortOrder, sortField, searchQuery));
        console.log(queryParams)
        OmniApi.get('/brands'+queryParams)
        .then(res =>{
            console.log(res?.data?.data);
            setBrands(res.data?.data);
            setPaginationValues({
                page,
                sizePerPage,
                sortOrder,
                sortField,
                data: res?.data?.data,
                totalSize: res?.data?.total
            })
        });
        commonHelper.goOnToOfThePage();
      }, [location.search]);

    // Set default pagination query params if none are existent
    // useEffect(() => {
    //     if (!location.search){
    //         query.set('page', 1);
    //         query.set('sizePerPage', 15);
    //         console.log('query', query)
    //         history.push(location.pathname + "?" + query.toString());
    //     }
    // }, [])

    const columns = [
        {
            dataField: '',
            text: 'Image',
            sort: false,
            formatter: (cell, row) => {
                if (row.brandImage)
                return (
                <div style={{'width': '50px', 'height': '50px', 'overflow': 'hidden'}} className="rounded">    
                    {fs1Url && <img src={`${fs1Url}/${row.brandImage}/50`} />}
                </div>
                )
                else
                return(
                    <div style={{'width': '50px', 'height': '50px', 'overflow': 'hidden'}} className="rounded">    
                        <img src={`${commonHelper.returnDummyImagesUrl(50)}`} />
                    </div> 
                )
            }
        },
        {
            dataField: 'brandName',
            text: 'Brand Name',
            sort: true,
            style: { verticalAlign: 'middle' }
        },
        {
            dataField: 'totalProducts',
            text: 'Total Products',
            sort: false,
            style: { verticalAlign: 'middle' }
        },
        {
            dataField: 'totalVariants',
            text: 'Total Variants',
            sort: false,
            style: { verticalAlign: 'middle' }
        }
    ];

    useEffect(() => {
        if (user){
            const fs1UrlData = commonHelper.returnFs1StaticImagesUrl(user.company.fs1GetToken,null);
            setFs1Url(fs1UrlData)
        }
        
    }, [user])

    // function getFS1Image(brandImage){
    //     return `https://fs1.cloudsnob.com/static/${user.company.fs1GetToken}/${brandImage}/50`
    // }

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Showing <strong>{from}</strong> to <strong>{to}</strong> of <strong>{size}</strong> Results
        </span>
    );

    const [tableChangeType, setTableChangeType] = useState('');
    console.log('CHANGE TYPE', tableChangeType);

    // When pagination query params change, update the pagination values
    // useEffect(() => {
    //     if (!location.search){
    //         query.set('page', 1);
    //         query.set('sizePerPage', 15);
    //         query.set('sortOrder', 'asc');
    //         query.set('sortField', 'brandName');
    //         history.replace(location.pathname + "?" + query.toString());
    //     }
    //     const page = parseInt(query.get('page'));
    //     const sizePerPage = parseInt(query.get('sizePerPage'));
    //     const currentIndex = (page - 1) * sizePerPage;
    //     const sortOrder = query.get('sortOrder');
    //     const sortField = query.get('sortField');
    //     const searchText = query.get('searchTerm')

    //     let result = brands;

    //     if (searchText) {
    //         //  If user search something
    //         //  You can ignore this part, it's just some search logic
    //         result = result.filter((row) => {
    //           for (let cidx = 0; cidx < columns.length; cidx += 1) {
    //             const column = columns[cidx];
    //             let targetValue = row[column.dataField];
    //             if (targetValue !== null && typeof targetValue !== 'undefined') {
    //               targetValue = targetValue.toString().toLowerCase();
    //               if (targetValue.indexOf(searchText.toLowerCase()) > -1) {
    //                 return true;
    //               }
    //             }
    //           }
    //           return false;
    //         });
    //       }
        
    //     if (sortOrder === 'asc') {
    //         result = result.sort((a, b) => {
    //         if (a[sortField] > b[sortField]) {
    //             return 1;
    //         } else if (b[sortField] > a[sortField]) {
    //             return -1;
    //         }
    //         return 0;
    //         });
    //     } else {
    //         result = result.sort((a, b) => {
    //         if (a[sortField] > b[sortField]) {
    //             return -1;
    //         } else if (b[sortField] > a[sortField]) {
    //             return 1;
    //         }
    //         return 0;
    //         });
    //     }

    //     console.log('result', result)

    //     setPaginationValues({
    //         page: page,
    //         sizePerPage: sizePerPage,
    //         data: result.slice(currentIndex, currentIndex + sizePerPage),
    //         totalSize: result.length
    //     })

    // }, [location.search, brands])

    const onTableChange = (type, { sortField, sortOrder, page, sizePerPage, searchText, filters }) => {
        setTableChangeType(type);
        if (type === 'pagination'){
            query.set('page', page);
            query.set('sizePerPage', sizePerPage);
        }
        if (type === 'sort'){
            query.set('sortField', sortField);
            query.set('sortOrder', sortOrder);
        }
        if (type === 'search'){
            query.set('searchTerm', searchText);
        }
        history.push(location.pathname + "?" + query.toString());

        console.log('SORT', sortField, sortOrder, searchText, filters);
      }

    const { data, sizePerPage, page, totalSize } = paginationValues;

    if(user){
        return(
            <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Brands | {process.env.REACT_APP_HEADER_TITLE}</title>
            </Helmet>
            <h2 className="page-title">
                Brands
            </h2>
            <Card className="mt-2">
                <CardBody>
                <RemotePaginations
                    data={ data }
                    page={ page }
                    columns={columns}
                    sizePerPage={ sizePerPage }
                    totalSize={ totalSize }
                    onTableChange={ onTableChange }
                    paginationTotalRenderer={customTotal}
                    showTotal={true}
                    rowClickPath={{ path: '/omni/catalog/brand', token: 'brandToken' }}
                    searchPlaceHolder={"Search Brands"}
                    isNewButtonText="New Brand"
                            isNewButtonCallback={()=>{
                                console.log('New Brand');
                                history.push("/omni/catalog/brand");
                            }}
                />
                    </CardBody>
                    </Card>
                </>
        )
    } else {
        return(
            <h1>Loading</h1>
        )
    }
}

export default Brands;