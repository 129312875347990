import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import RemotePaginationInterface from '../../Interfaces/PropsInterfaces/RemotePaginationInterface';
import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Row, Col, Card, CardBody, Badge, Button } from 'reactstrap';
import FilterComponent from '../../custom-components/Filter';

const RemotePaginations = ({
    data,
    columns,
    page,
    sizePerPage,
    onTableChange,
    totalSize,
    showTotal = true,
    paginationTotalRenderer,
    rowClickPath,
    userType,
    onCustomerToggle,
    searchPlaceHolder,
    isNewButtonText,
    isNewButtonCallback
}: RemotePaginationInterface) => {
    const { ExportCSVButton } = CSVExport;
    const [searchTerm, setSearchTerm] = useState('helo');
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const history = useHistory();

    // The onCLick in the row event routes the user to the brand detail page when row is clicked on
    const rowEvents = {
        onClick: (e, row) => {
            if (rowClickPath) {
                const location = {
                    pathname: `${rowClickPath?.path}/${row?.[rowClickPath?.token]}`,
                    state: {
                        row,
                    },
                };
                history.push(location);
            }
        },
    };

    const rowClasses = (row, rowIndex) => {
        return 'table-row';
    };
    const searchChange = (e) => {
        let result = e.target.value;
        let page = parseInt(query.get('page'));
        if (page > 1) query.set('page', '1');
        query.set('searchTerm', result);
        history.push(location.pathname + '?' + query.toString());
    };
    const onStatusFilter = (filterVal) =>{
        onCustomerToggle(filterVal);
    }
    useEffect(() => {
        let result = query.get('searchTerm');
        setSearchTerm(result);
    }, [location.search]);
    return (
        <ToolkitProvider
            bootstrap4
            keyField="orderToken"
            data={data}
            columns={columns}
            exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
            {(props) => (
                <React.Fragment>
                    <Row className="mb-2">
                        <Col>
                        {isNewButtonText && isNewButtonCallback && (
                                <Button
                                    color="primary"
                                    type="button"
                                    onClick={() => isNewButtonCallback()}
                                    className="export-csv-btn btn btn-primary mr-3">
                                    {isNewButtonText}
                                </Button>
                            )}
                        </Col>
                        <Col className="text-right form-inline justify-content-end">
                            {/* commented by Ijaz */}

                            {/* {userType && (
                                <Button
                                    color="primary"
                                    type="button"
                                    onClick={() => onCustomerToggle(userType === 'all' ? 'pending' : 'all')}
                                    className="export-csv-btn btn btn-primary mr-3">
                                    {userType === 'all' ? 'Pending Customers' : 'Active Customers'}
                                </Button>
                            )} */}
                          {
                                userType && (
                                <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                                  <FilterComponent onStatusFilter={onStatusFilter} />
                                </div>
                               )
                          }

                            <ExportCSVButton {...props.csvProps} className="export-csv-btn btn btn-primary mr-3">
                                Export CSV
                            </ExportCSVButton>
                            <input
                                className="form-control"
                                placeholder={(searchPlaceHolder)?searchPlaceHolder:"Search orders"}
                                value={searchTerm || ''}
                                onChange={(e) => searchChange(e)}
                            />
                        </Col>
                    </Row>

                    <BootstrapTable
                        {...props.baseProps}
                        remote
                        bordered={false}
                        headerClasses="thead-light"
                        // defaultSorted={ defaultSorted }
                        pagination={paginationFactory({
                            page,
                            sizePerPage,
                            totalSize,
                            paginationTotalRenderer,
                            showTotal,
                        })}
                        wrapperClasses="table-responsive"
                        classes="order-table"
                        rowEvents={rowEvents}
                        // selectRow={ selectRow }
                        rowClasses={rowClasses}
                        hover
                        onTableChange={onTableChange}
                        // noDataIndication={() => 'No results...'}
                    />
                </React.Fragment>
            )}
        </ToolkitProvider>
    );
};
export default RemotePaginations;
