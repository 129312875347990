import React, { useContext, useEffect, useState } from 'react';
import {
    Card,
    CardImg,
    CardBody,
    CardTitle,
    CardText,
    CardSubtitle,
    CardLink,
    Row,
    Col,
    InputGroup,
    InputGroupAddon,
    Input,
    FormGroup,
    ButtonGroup,
} from 'reactstrap';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { UserContext } from '../custom-contexts/UserContext';
import { OmniApi } from '../customAPI/OmniApi';
import SimpleBar from 'simplebar-react';
import { Icon } from '@iconify/react';
import magnifyIcon from '@iconify/icons-mdi/magnify';
import launchIcon from '@iconify/icons-mdi/launch';
import Collection from './Collection';
import { Helmet } from 'react-helmet';
import { height } from 'britecharts-react';
import { commonHelper } from '../helpers/commonHelper';

const CollectionCards = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const history = useHistory();

    console.log(location);

    const [collections, setCollections] = useState('');

    const [collectionList, setCollectionList] = useState([]);

    const [searchTerm, setSearchTerm] = useState(query.get('searchText'));
    const [percentageHeight, setPercentageHeight] = useState(0);

    const { user } = useContext(UserContext);

    const getFS1Image = (img, size) => {
        console.log("checking",img);
        return commonHelper.returnFs1StaticImagesUrl(user.company.fs1GetToken, size, true, img)//`https://fs1.cloudsnob.com/static/${user.company.fs1GetToken}/${img}/${size}`;
    };

    useEffect(() => {
        OmniApi.get('/collections')
            .then((res) => {
                commonHelper.goOnToOfThePage();
                setCollections(res.data);
                setCollectionList(res.data);
            })
            .catch((err) => { });
    }, []);

    useEffect(() => {
        const windowHeight = window.innerHeight;
        let percentrage = (windowHeight * 49) / 100;
        if (windowHeight >= 900 && windowHeight <= 1050) {
            percentrage = (windowHeight * 59) / 100;
        } else if (windowHeight >= 800 && windowHeight <= 900) {
            percentrage = (windowHeight * 48) / 100;
        } else if (windowHeight >= 700 && windowHeight <= 800) {
            percentrage = (windowHeight * 53) / 100;
        } else if (windowHeight >= 600 && windowHeight <= 700) {
            percentrage = (windowHeight * 45) / 100;
        } else if (windowHeight >= 500 && windowHeight <= 600) {
            percentrage = (windowHeight * 40) / 100;
        }
        setPercentageHeight(percentrage);
        window.addEventListener('resize', () => {
            const windowHeight = window.innerHeight;
            let percentrage = (windowHeight * 49) / 100;
            if (windowHeight >= 900 && windowHeight <= 1050) {
                percentrage = (windowHeight * 59) / 100;
            } else if (windowHeight >= 800 && windowHeight <= 900) {
                percentrage = (windowHeight * 48) / 100;
            } else if (windowHeight >= 700 && windowHeight <= 800) {
                percentrage = (windowHeight * 53) / 100;
            } else if (windowHeight >= 600 && windowHeight <= 700) {
                percentrage = (windowHeight * 46) / 100;
            } else if (windowHeight >= 500 && windowHeight <= 600) {
                percentrage = (windowHeight * 40) / 100;
            }
            setPercentageHeight(percentrage);
        })
        let result = [...collections];

        let searchText = query.get('searchText');

        console.log('searchText', searchText);

        setSearchTerm(searchText);

        if (searchText) {
            //  If user search something
            //  You can ignore this part, it's just some search logic
            // result = result.filter((row) => {
            //   for (let cidx = 0; cidx < columns.length; cidx += 1) {
            //     const column = columns[cidx];
            //     let targetValue = row[column.dataField];
            //     if (targetValue !== null && typeof targetValue !== 'undefined') {
            //       targetValue = targetValue.toString().toLowerCase();
            //       if (targetValue.indexOf(searchText.toLowerCase()) > -1) {
            //         return true;
            //       }
            //     }
            //   }
            //   return false;
            // });

            result = result.filter(
                (collection) =>
                    collection.collectionName.toString().toLowerCase().indexOf(searchText.toLowerCase()) > -1
            );
        }

        setCollectionList(result);
    }, [location.search, collections]);

    const truncateString = (string, length) => {
        let slicedString = string.slice(0, length);
        if (string.length > length) slicedString = slicedString.concat('...');
        return slicedString;
    };

    const setSearchQuery = (term) => {
        query.set('searchText', term);

        history.replace(location.pathname + '?' + query.toString());
    };

    if (user) {
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Collections | {process.env.REACT_APP_HEADER_TITLE}</title>
                </Helmet>
                <Row>
                    <Col>
                        <h2 className="page-title">Collections</h2>
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-1">
                        <Card
                            className="pt-3 mb-0 mt-0"
                            style={{ borderBottom: '4px solid #A7BCD6', borderRadius: '.25rem .25rem 0 0' }}>
                            <Row>
                                <Col className="d-flex justify-content-between">
                                    <FormGroup className="ml-3">
                                        <Link to="/omni/catalog/collection" className="btn btn-danger">
                                            <i className="mdi mdi-plus-circle mr-2"></i>Create collection
                                        </Link>
                                        <Link to="/omni/settings/site" className="btn btn-info ml-3" >
                                            Collection Settings
                                        </Link>
                                    </FormGroup>
                                    <FormGroup className="mr-3">
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <span className="input-group-text py-0 px-1">
                                                    <Icon icon={magnifyIcon} width="25" height="25" />
                                                </span>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="Search Collections..."
                                                value={searchTerm}
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <SimpleBar style={{ maxHeight: percentageHeight + "px" }}>
                    <Row className="mx-1 my-2 d-flex">
                        {collectionList.map((collection) => (
                            <Col md={6} lg={4} xl={3} className="d-flex justify-content-center">
                                <Card
                                    style={{ minWidth: '280px', maxWidth: '280px', height: '450px' }}
                                    className="shadow-sm">
                                    <CardBody className="d-block">
                                        <CardTitle className="d-flex justify-content-center">
                                            <h4 className="text-truncate" title={collection.collectionName}>
                                                <Link to={`/omni/catalog/collection/${collection.collectionToken}`}>
                                                    {collection.collectionName}
                                                </Link>
                                            </h4>
                                        </CardTitle>
                                        {/* <CardSubtitle as="h6" className="text-muted">
                                Support card subtitle
                            </CardSubtitle> */}
                                    </CardBody>
                                    <div
                                        style={{ height: '250px', overflow: 'hidden', backgroundColor: '#EEF2F7' }}
                                        className="d-flex align-items-center rounded">
                                        <CardImg
                                            src={getFS1Image(collection.collectionImage, 300)}
                                            className="img-fluid"
                                        />
                                    </div>
                                    <CardBody>
                                        <h5 className="text-center">Description:</h5>
                                        <CardText style={{ height: '100px' }} className="text-center">
                                            {truncateString(collection.collectionDescription, 100)}
                                        </CardText>
                                        {/* <CardText><strong>Collection Handle:</strong><br /><Link>{`"/omni/catalog/collections/${collection.collectionToken}"`}</Link></CardText> */}
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </SimpleBar>
                <div className="d-flex justify-content-end mt-2">
                    <div>
                        <strong style={{ fontSize: '1.4em' }}>Collections: </strong>
                        {`Showing ${collectionList.length} of ${collections.length}`}
                    </div>
                </div>
            </>
        );
    } else {
        return <h1>Loading</h1>;
    }
};

export default CollectionCards;
