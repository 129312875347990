import React, { useEffect, useState, useContext } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    // CustomInput,
    // Form,
    // FormGroup,
    // Label,
    // Input,
    // FormText,
    // InputGroup,
    // InputGroupAddon,
    // UncontrolledButtonDropdown,
    // Button,
    // DropdownToggle,
    // DropdownMenu,
    // DropdownItem,
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {OmniApi} from '../customAPI/OmniApi';
import { UserContext } from '../custom-contexts/UserContext';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { commonHelper } from '../helpers/commonHelper';

const RemotePagination = ({ data, columns, page, sizePerPage, onTableChange, totalSize, showTotal = true, paginationTotalRenderer }) => {
    const { SearchBar } = Search;
    const { ExportCSVButton } = CSVExport;

    let history = useHistory();

    // The onCLick in the row event routes the user to the navigation detail page when row is clicked on
    const rowEvents = {
        onClick: (e, row) => {

            const location = {
                pathname: `/omni/navigation/${row.navigationToken}`,
                state: {
                    row
                }
            }

            history.push(location)
        }
    }

    const rowClasses = (row, rowIndex) => {
        return 'table-row';
    };

    const paginationOptions = {
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        sizePerPageList: [
            {
                text: '10',
                value: 10,
            },
            {
                text: '20',
                value: 20,
            },
            {
                text: '60',
                value: 60,
            },
            {
                text: '100',
                value: 100,
            },
        ], // A numeric array is also available. the purpose of above example is custom the text
    };

    return (
      <ToolkitProvider
        bootstrap4
        keyField="navigationToken"
        data={data}
        columns={columns}
        search
        exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
        {props => (
            <React.Fragment>
                <Row className="mb-2">
                    <Col>
                    <Link to="/omni/navigation" className="btn btn-danger">
                        <i className="mdi mdi-plus-circle mr-2"></i>Add Navigation
                    </Link>
                    </Col>
                    <Col className="text-right">
                        <SearchBar {...props.searchProps} placeholder='Search by navigation' />
                    </Col>
                </Row>

                <BootstrapTable
                    {...props.baseProps}
                    remote
                    bordered={false}
                    headerClasses="thead-light"
                    // defaultSorted={ defaultSorted }
                    pagination={ paginationFactory({ page, sizePerPage, totalSize, paginationTotalRenderer, showTotal }) }
                    wrapperClasses="table-responsive"
                    classes="navigation-table"
                    bordered={ false }
                    rowEvents={ rowEvents }
                    // selectRow={ selectRow }
                    rowClasses={ rowClasses }
                    hover
                    onTableChange={ onTableChange }
                    // noDataIndication={() => 'No results...'}
                />
            </React.Fragment>
        )}
    </ToolkitProvider>
  );
  }

const Navigation = () => {

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const history = useHistory();

    const { user } = useContext(UserContext);

    // const [selectedRows, setSelectedRows] = useState([]);
    const [navigations, setNavigations] = useState([]);

    const [paginationValues, setPaginationValues] = useState({
        page: parseInt(query.get('page')),
        data: [],
        sizePerPage: parseInt(query.get('sizePerPage')),
        totalSize: ''
    })

    // Gets the pagination query params from the url and sets the correct data to be displayed in that pages table
    useEffect(() => {
        const page = parseInt(query.get('page'));
        const sizePerPage = parseInt(query.get('sizePerPage'));
        const currentIndex = (page - 1) * sizePerPage;

        setPaginationValues({
            ...paginationValues,
            data: navigations ? navigations.slice(currentIndex, currentIndex + sizePerPage) : ''
        })
    }, [navigations])

    useEffect(() => {
        OmniApi.get(`/navigations`)
        .then(res => setNavigations(res.data));
      }, []);


    const columns = [
        {
            dataField: '',
            text: 'Image',
            sort: false,
            formatter: (cell, row) => {
                if (row.navigationImage)
                return (
                <div style={{'width': '50px', 'height': '50px', 'overflow': 'hidden'}} className="rounded">    
                    <img src={getFS1Image(row.navigationImage)} />
                </div>
                )
                else
                return(
                    <div style={{'width': '50px', 'height': '50px', 'overflow': 'hidden'}} className="rounded">    
                        <img src='http://dummyimage.com/50' />
                    </div> 
                )
            }
        },
        {
            dataField: 'name',
            text: 'Navigation Name',
            sort: true,
            style: { verticalAlign: 'middle' }
        }
    ];

    function getFS1Image(navigationImage){
        return commonHelper.returnFs1ImagesUrl(`${user.company.fs1GetToken}/${navigationImage}/50`)
    }

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Showing <strong>{from}</strong> to <strong>{to}</strong> of <strong>{size}</strong> Results
        </span>
    );

    const [tableChangeType, setTableChangeType] = useState('');
    console.log('CHANGE TYPE', tableChangeType);

    // When pagination query params change, update the pagination values
    useEffect(() => {
        if (!location.search){
            query.set('page', 1);
            query.set('sizePerPage', 15);
            query.set('sortOrder', 'asc');
            query.set('sortField', 'name');
            history.replace(location.pathname + "?" + query.toString());
        }
        const page = parseInt(query.get('page'));
        const sizePerPage = parseInt(query.get('sizePerPage'));
        const currentIndex = (page - 1) * sizePerPage;
        const sortOrder = query.get('sortOrder');
        const sortField = query.get('sortField');
        const searchText = query.get('searchText')

        let result = navigations;

        if (searchText) {
            //  If user search something
            //  You can ignore this part, it's just some search logic
            result = result.filter((row) => {
              for (let cidx = 0; cidx < columns.length; cidx += 1) {
                const column = columns[cidx];
                let targetValue = row[column.dataField];
                if (targetValue !== null && typeof targetValue !== 'undefined') {
                  targetValue = targetValue.toString().toLowerCase();
                  if (targetValue.indexOf(searchText.toLowerCase()) > -1) {
                    return true;
                  }
                }
              }
              return false;
            });
          }
        
        if (sortOrder === 'asc') {
            result = result.sort((a, b) => {
            if (a[sortField] > b[sortField]) {
                return 1;
            } else if (b[sortField] > a[sortField]) {
                return -1;
            }
            return 0;
            });
        } else {
            result = result.sort((a, b) => {
            if (a[sortField] > b[sortField]) {
                return -1;
            } else if (b[sortField] > a[sortField]) {
                return 1;
            }
            return 0;
            });
        }

        console.log('result', result)

        setPaginationValues({
            page: page,
            sizePerPage: sizePerPage,
            data: result.slice(currentIndex, currentIndex + sizePerPage),
            totalSize: result.length
        })

    }, [location.search, navigations])

    const onTableChange = (type, { sortField, sortOrder, page, sizePerPage, searchText, filters }) => {
        setTableChangeType(type);
        if (type === 'pagination'){
            query.set('page', page);
            query.set('sizePerPage', sizePerPage);
        }
        if (type === 'sort'){
            query.set('sortField', sortField);
            query.set('sortOrder', sortOrder);
        }
        if (type === 'search'){
            query.set('searchText', searchText);
        }
        history.push(location.pathname + "?" + query.toString());

        console.log('SORT', sortField, sortOrder, searchText, filters);
      }

    const { data, sizePerPage, page, totalSize } = paginationValues;

    if(user){
        return(
            <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Navigation | {process.env.REACT_APP_HEADER_TITLE}</title>
            </Helmet>
            <h2 className="page-title">
                Navigations
            </h2>
            <Card className="mt-2">
                <CardBody>
                <RemotePagination
                    data={ data }
                    page={ page }
                    columns={columns}
                    sizePerPage={ sizePerPage }
                    totalSize={ totalSize }
                    onTableChange={ onTableChange }
                    paginationTotalRenderer={customTotal}
                    showTotal={true}
                />
                    </CardBody>
                    </Card>
                </>
        )
    } else {
        return(
            <h1>Loading</h1>
        )
    }
}

export default Navigation;