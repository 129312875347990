// flow
import React, { useEffect, useState, useContext, useRef } from 'react';
import {
    Row, Col, Card, CardBody, CardFooter, CardHeader, CardImg, CardText,
    ListGroup, ListGroupItem, Badge, Button, UncontrolledTooltip, Label, Progress,
    Media, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input,
    FormGroup, UncontrolledButtonDropdown, Table, InputGroup, InputGroupAddon,
    Form, CustomInput, Modal, ModalBody, ModalHeader, ModalFooter
} from 'reactstrap';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import moment from "moment";
// import CurrencyInput from '../input-masks/CurrencyInput';
import { confirm } from "./Confirm";
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
import { Helmet } from 'react-helmet';

import Spinner from '../components/Spinner';

//icons
import { Icon } from '@iconify/react';
import checkIcon from '@iconify-icons/uil/check';
import multiplyIcon from '@iconify-icons/uil/multiply';
import alertIcon from '@iconify/icons-mdi/alert';
import moneyBillSlash from '@iconify-icons/uil/money-bill-slash';
import userIcon from '@iconify-icons/uil/user';
import envelopeIcon from '@iconify-icons/uil/envelope';
import phoneIcon from '@iconify-icons/uil/phone';
import creditCardSearchOutline from '@iconify-icons/mdi/credit-card-search-outline';
import truckOutline from '@iconify-icons/mdi/truck-outline';
import ipNetworkOutline from '@iconify-icons/mdi/ip-network-outline';
import cartCheck from '@iconify-icons/mdi/cart-check';
import eyeCircleOutline from '@iconify-icons/mdi/eye-circle-outline';
import accountEyeOutline from '@iconify-icons/mdi/account-eye-outline';
import bullseyeIcon from '@iconify-icons/mdi/bullseye';
import alertDecagramOutline from '@iconify-icons/mdi/alert-decagram-outline';
import creditCardCheckOutline from '@iconify-icons/mdi/credit-card-check-outline';
import receiptIcon from '@iconify-icons/mdi/receipt';
import dotsVertical from '@iconify-icons/mdi/dots-vertical';
import fileDocumentOutline from '@iconify-icons/mdi/file-document-outline';
import dotsHorizontal from '@iconify-icons/mdi/dots-horizontal';
import phoneForward from '@iconify-icons/mdi/phone-forward';
import contentCopy from '@iconify-icons/mdi/content-copy';
import linkVariant from '@iconify-icons/mdi/link-variant';
import emailSendOutline from '@iconify-icons/mdi/email-send-outline';
import plusThick from '@iconify-icons/mdi/plus-thick';
import packageVariantClosed from '@iconify-icons/mdi/package-variant-closed';
import printerOutline from '@iconify-icons/mdi/printer-outline';
import fileExportOutline from '@iconify-icons/mdi/file-export-outline';


//import images
import AmexImg from '../assets/images/payments/american-express.png';
import MastercardImg from '../assets/images/payments/master.png';
import DiscoverImg from '../assets/images/payments/discover.png';
import VisaImg from '../assets/images/payments/visa.png';

import { OmniApi } from '../customAPI/OmniApi';
import { UserContext } from '../custom-contexts/UserContext';

import Img from '../../src/assets/images/headphones-img.jpg';
import ListGroups from '../pages/uikit/ListGroups';
import CurrencyInput from '../utility-input-components/CurrencyInput';
import deleteIcon from '@iconify/icons-mdi/delete';
import { IS_ORDER_CANCEL } from '../constants/layout';
import informationOutline from '@iconify/icons-mdi/information-outline';
import { commonHelper } from '../helpers/commonHelper';

const OrderDetail = props => {

    const { user } = useContext(UserContext);

    const testOrder = {
        id: '#BM31',
        order_status: 'Packed',
        items: [
            { id: 1, name: 'The Military Duffle Bag', quantity: 3, price: '$128', total: '$384' },
            { id: 2, name: 'Mountain Basket Ball', quantity: 1, price: '$199', total: '$199' },
            { id: 3, name: 'Wavex Canvas Messenger Bag', quantity: 5, price: '$180', total: '$900' },
            { id: 4, name: 'The Utility Shirt', quantity: 2, price: '$79', total: '$158' },
        ],
        gross_total: '$1641',
        shipping_charge: '$23',
        tax: '$19.22',
        net_total: '$1683.22',
        shipping: {
            provider: 'Stanley Jones',
            address_1: '795 Folsom Ave, Suite 600',
            address_2: 'San Francisco, CA 94107',
            phone: '(123) 456-7890 ',
            mobile: '(+01) 12345 67890',
        },
        billing: {
            type: 'Credit Card',
            provider: 'Visa ending in 2851',
            valid: '02/2020',
        },
        contact: {
            contactName: 'Stanley Jones',
            email: 'test@test.com',
            phone: '(123) 456-7890 '
        },
        delivery: {
            provider: 'UPS Delivery',
            order_id: '#BM31',
            payment_mode: 'COD',
        },
    };

    const [order, setOrder] = useState('');

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    useEffect(() => {
        let orderToken = props.match.params.orderToken;
        if (!orderToken) {
            return
        }

        OmniApi.get(`/orders/${orderToken}`)
            .then(res => {
                if (isEmpty(res.data))
                    throw new Error('Invalid Order Token')

                setOrder(res.data)
            })
            .catch(err => {
                props.history.replace('/omni/orders')
                toast.error(err.message)
            })

    }, [props.history, props.match.params.orderToken])

    function rerenderOrder() {
        OmniApi.get(`/orders/${order.orderToken}`)
            .then(res => setOrder({
                ...res.data
            }));
    }

    const getFS1Image = (img, size) => {
        console.log(img);
        return commonHelper.returnFs1ImagesUrl(`${user.company.fs1GetToken}/${img.file ?? img}/${size}`)
    }

    console.log('ORDERDETAIL', order)

    const [historyModal, setHistoryModal] = useState(false);

    const toggleHistoryModal = () => {
        setHistoryModal(!historyModal);
    }

    function getStatusColor(id) {
        if (id === 'os_voided')
            return 'danger'
        if (id === 'os_processing')
            return 'secondary'
        if (id === 'os_shipped')
            return 'success'
        if (id === 'os_awaiting_fulfillment')
            return 'warning'
        if (id === 'os_pickup_ready')
            return 'success'
        if (id === 'os_completed')
            return 'info'
        if (id === 'os_refunded')
            return 'danger'
        if (id === 'os_partial_refund')
            return 'danger'
        if (id === 'os_cancelled')
            return 'danger'

        return 'primary'
    }

    function getStatusName(id) {
        if (id === 'os_voided')
            return 'Payment Voided'
        if (id === 'os_processing')
            return 'Processing'
        if (id === 'os_shipped')
            return 'Shipped'
        if (id === 'os_awaiting_fulfillment')
            return 'Awaiting Fulfillment'
        if (id === 'os_pickup_ready')
            return 'Ready for Pickup'
        if (id === 'os_completed')
            return 'Completed'
        if (id === 'os_refunded')
            return 'Refunded'
        if (id === 'os_partial_refund')
            return 'Partially Refunded'
        if (id === 'os_cancelled')
            return 'Cancelled'

        return 'primary'
    }

    const updateStatus = (newStatusId, newStatusName) => {
        // let postOrderStatus = OmniApi.post(`/orders/status/${order.orderToken}/${newStatusId}`,{processingStatus:'ps_5'})
        let postOrderStatus = OmniApi.post(`/orders/status/${order.orderToken}`, { processingStatus: 'ps_5' })
            .then(res => console.log(res.data))
            .catch(err => { console.log(err) })
            .finally(res => rerenderOrder())

        toast.promise(
            postOrderStatus,
            {
                pending: {
                    render() {
                        return "Status changing..."
                    },
                    icon: true,
                },
                success: {
                    render(res) {
                        return `Status updated to "${newStatusName}"`
                    },
                    // other options
                    icon: true,
                },
                error: {
                    render(err) {
                        // When the promise reject, data will contains the error
                        return `err`
                    },
                    icon: true,
                }
            }
        )

    }

    async function cancelOrder() {
        if (await confirm(`Are you sure you want to cancel this order?`, "Cancelling Order", "Cancel Order") === false) {
            return
        }

        updateStatus('os_cancelled', 'Cancelled')

    }
    console.log("order", order);


    if (order)
        return (
            <>
                {/* NEW FEATURE */}
                {/* <Row>
            <Col className="d-flex justify-content-between my-3">
                <div className="d-flex align-items-center">
                <h1>Order # {order.orderNumber}</h1>
                <div className="d-flex align-items-center ml-3">
                <h4>Order Status :</h4>
                    <h5 className="d-flex align-items-center ml-1"><span className="badge badge-primary">placeholder</span></h5>
                </div>
                <div className="d-flex align-items-center ml-3">
                <h4>Payment Status :</h4>
                    <h5 className="d-flex align-items-center ml-1"><span className="badge badge-primary">placeholder</span></h5>
                </div>
                <div className="d-flex align-items-center ml-3">
                <h4>Shipping Status :</h4>
                    <h5 className="d-flex align-items-center ml-1"><span className="badge badge-primary">placeholder</span></h5>
                </div>
                </div>
                <div className="d-flex justify-content-end">
                <div className="d-flex mr-3 button list align-items-center">
                    <Button onClick={() => toggleHistoryModal()}>History Log</Button>
                </div>
                <HistoryModal toggle={toggleHistoryModal} state={historyModal}/>
                <UncontrolledButtonDropdown>
                    <DropdownToggle tag="button" className="btn btn-lg btn-link no-arrow card-drop p-0">
                        <Icon icon={dotsHorizontal} color="grey" width="40" height="40" />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem header>HEADER</DropdownItem>
                        <DropdownItem>Print Invoice</DropdownItem>
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
                </div>
            </Col>
        </Row> */}
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Order Detail | {process.env.REACT_APP_HEADER_TITLE}</title>
                </Helmet>
                <Col>
                    <div className="d-flex justify-content-between">
                        <div className="d-flex">
                            <h2>Order # {order.orderNumber}</h2>
                            <div className="d-flex align-items-center ml-3">
                                <h3 className="d-flex align-items-center ml-1"><span className={`badge badge-${getStatusColor(order.statusAndTerms.processingStatus.statusName)}`}>{order.statusAndTerms.processingStatus.statusName}</span></h3>
                            </div>
                        </div>

                        <UncontrolledButtonDropdown>
                            <DropdownToggle tag="button" className="btn btn-lg btn-link no-arrow card-drop p-0">
                                <Icon icon={dotsHorizontal} color="grey" width="40" height="40" />
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem header>Additional Actions</DropdownItem>
                                {!IS_ORDER_CANCEL?.includes(order?.statusAndTerms?.processingStatus?.statusToken) &&
                                    <DropdownItem className="text-danger" onClick={cancelOrder}>Cancel Order</DropdownItem>
                                }
                                {IS_ORDER_CANCEL?.includes(order?.statusAndTerms?.processingStatus?.statusToken) &&
                                    <DropdownItem className="text-danger text-muted" title='Order already canceled'>Cancel Order</DropdownItem>
                                }
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </div>
                    {order?.statusAndTerms?.paymentTerm?.statusToken === 'pt_2' &&
                        <p className='text-muted'>Payment Terms: Purchase Order - Invoice Me</p>
                    }
                    {order?.statusAndTerms?.paymentTerm?.statusToken === 'pt_1' &&
                        <p className='text-muted'>Payment Terms: Web Payment - Paid in Full</p>
                    }
                </Col>
                <Row>
                    <Col>
                        <OrderStatuses statuses={order.statusAndTerms} setOrder={setOrder} order={order} rerenderOrder={rerenderOrder} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <OrderProductList orderProducts={order.orderProducts} getFS1Image={getFS1Image} />
                    </Col>


                </Row>
                <Row>
                    <Col md={8}>
                        <ShippingLabelsOnly orderToken={order.orderToken} />
                    </Col>
                    <Col>
                        <OrderSummary checkoutObject={order.checkoutObject} />
                    </Col>
                    {/* <Col sm={4}>
                        <Card>
                            <CardBody>
                                <div className="d-flex">
                                    <h3>Status: <span className={`ml-2 badge badge-${getStatusColor(order.orderStatus)}-lighten`}>{getStatusName(order.orderStatus)}</span></h3>
                                </div>
                                {order.orderStatus !== 'os_cancelled' &&
                                    order.orderStatus !== 'os_completed' ?
                                    <h6 className="mb-3">
                                        <span className="flashing-bg bg-light rounded p-1">
                                            <Icon icon={alertIcon} />
                                            <Icon icon={alertIcon} />
                                            <span className="text-danger">   Additional actions are required:   </span>
                                            <Icon icon={alertIcon} />
                                            <Icon icon={alertIcon} />
                                        </span>
                                    </h6>
                                    :
                                    <h6 className="mb-3">
                                        <span className="flashing-bg bg-light rounded p-1">
                                            No additional actions are required at this time
                                        </span>
                                    </h6>
                                }
                                {order.orderStatus !== 'os_shipped' &&
                                    order.orderStatus !== 'os_pickup_ready' &&
                                    order.orderStatus !== 'os_cancelled' &&
                                    order.orderStatus !== 'os_completed' &&
                                    order.orderStatus !== 'os_processing'
                                    &&
                                    <Button className="btn-rounded" block outline color="info" onClick={() => updateStatus('os_processing', 'Processing')}>
                                        Start Processing
                                    </Button>
                                }
                                {order.orderStatus !== 'os_awaiting_fulfillment' &&
                                    order.orderStatus !== 'os_cancelled' &&
                                    order.orderStatus !== 'os_shipped' &&
                                    order.orderStatus !== 'os_completed' &&
                                    order.orderStatus !== 'os_pickup_ready'
                                    &&
                                    <Button className="btn-rounded" block outline color="success" onClick={() => updateStatus('os_pickup_ready', 'Pickup Ready')}>
                                        Ready for Pickup
                                    </Button>
                                }
                                {order.orderStatus !== 'os_awaiting_fulfillment' &&
                                    order.orderStatus !== 'os_cancelled' &&
                                    order.orderStatus !== 'os_shipped' &&
                                    order.orderStatus !== 'os_completed'
                                    &&
                                    <Button className="btn-rounded" block outline color="success" onClick={() => updateStatus('os_shipped', 'Shipped')}>
                                        Shipped
                                    </Button>
                                }
                                {order.orderStatus !== 'os_awaiting_fulfillment' &&
                                    order.orderStatus !== 'os_cancelled' &&
                                    order.orderStatus !== 'os_completed' &&

                                    <Button className="btn-rounded" block outline color="info" onClick={() => updateStatus('os_completed', 'Completed')}>
                                        Complete Order
                                    </Button>
                                }
                            </CardBody>
                        </Card>
                    </Col> */}
                </Row>
                <Row>

                    <Col lg={6} xl={3}>
                        <Card style={{ height: '207px' }}>
                            <CardBody>
                                <h4 className="header-title mb-3">Customer Info</h4>
                                <CustomerInfo customerInfo={order.customerObject} customerToken={order.customerToken} checkoutObject={order.checkoutObject} />
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg={6} xl={3}>
                        <Card style={{ height: '207px' }}>
                            <CardBody>
                                <h4 className="header-title mb-3">Shipping Address</h4>
                                <ShippingInfo orderShippingAddress={order.orderShippingAddress} order={order} />
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg={6} xl={3}>
                        <Card style={{ height: '207px' }}>
                            <CardBody className="position-relative">
                                <h4 className="header-title mb-3">Billing Address</h4>
                                <BillingAddress order={order} />
                                <div className="position-absolute h-100 w-100 rounded d-flex justify-content-center align-items-center" style={{ top: '0px', left: '0px', backgroundColor: 'rgba(238, 242, 247, 0.55)' }}>
                                    <h4>Coming Soon...</h4>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg={6} xl={3}>
                        <Card style={{ height: '207px' }}>
                            <CardBody>
                                <h4 className="header-title my-0">Customer Order Note</h4>
                                <small>by customer</small><br />
                                <hr style={{ float: 'left', width: '85%', height: '1px', backgroundColor: '#D6E0EC' }} className="mt-0 mb-1" /><br />
                                <OrderNote customerNote={order.orderNotePublic} />
                            </CardBody>
                            <CardFooter></CardFooter>
                        </Card>
                    </Col>

                </Row>
                <Row>
                    <Col sm={12}>
                        <PaymentTimeline paymentHistory={order.paymentHistory} orderToken={order.orderToken} rerenderOrder={rerenderOrder} order={order}/>
                    </Col>
                </Row>
                {/* NEW FEATURES */}
                {/* <Row>
            <Col md={6}>
                <Card style={{ height: '512px'}}>
                <CardBody>
                <h3 className="mt-0">Order Actions</h3>
                    <OrderActions />
                </CardBody>
                </Card>
            </Col>
            <Col md={6}>
                <PaymentVerificationCenter />
            </Col>
        </Row> */}
                {/* <Row>
            <Col md={6}>
                <ShippingLabels orderToken={order.orderToken} />
            </Col>
            <Col md={6}>
                <ShippingLabelsOnly orderToken={order.orderToken} />
            </Col>
        </Row> */}
                {/* <Row>
            <Col>
                <Notes />
            </Col>
        </Row> */}
            </>
        )
    else
        return (
            <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
                <div>
                    <h4>Loading order...</h4>
                    <div className="bouncing-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        )
}

const OrderProductList = props => {


    const { orderProducts, getFS1Image } = props;

    return (
        <Card>
            {/* <CardHeader className="d-flex bg-light justify-content-around" style={{height: '52.88px'}}>
                        <div style={{width: '50%'}}><strong>Product</strong></div>
                        <div style={{width: '16%'}}><strong>Price</strong></div>
                        <div style={{width: '16%'}}><strong>Qty</strong></div>
                        <div style={{width: '16%'}}><strong>SubTotal</strong></div>
                    </CardHeader> */}
            {orderProducts && orderProducts.map((orderProduct, index) => (
                <div key={index}>
                    {(index > 0) ? <hr className="m-0" /> : ''}
                    <CardBody>
                        <Row className="d-flex flex-nowrap">
                            <div style={{ overflow: "hidden", minWidth: '80x', maxWidth: '80px', maxHeight: '80px', flexShrink: 0 }} className="rounded">
                                <img style={{ height: '80px', width: '80px' }}
                                    src={(orderProduct.product.variantImage && orderProduct.product.variantImage.file !== "" && orderProduct.product.variantImage.file !== '[]') ?
                                        getFS1Image(orderProduct.product.variantImage ?? orderProduct.product.prodImage.file, 80) :
                                        "http://dummyimage.com/80"}
                                    alt=""
                                />
                            </div>
                            <div className="ml-2"><Link to={`/omni/catalog/product/${orderProduct.product.prodToken}`} className="mb-2" style={{ fontSize: '20px' }}>
                                {orderProduct.product.prodName}
                            </Link><br />
                                {orderProduct.product.variantOptions && orderProduct.product.variantOptions.map((option, i) => (
                                    <div key={i}>
                                        <strong>{option.optionName}: </strong>{option.optionValue}<br />
                                    </div>
                                ))}
                            </div>
                            <div className="ml-auto" style={{ width: '13%', minWidth: '13%' }}>${parseFloat(orderProduct.product.variantPrice).toFixed(2)}</div>
                            <div style={{ width: '13%', minWidth: '13%' }}>x {orderProduct.cartProdQuantity}</div>
                            <div style={{ width: '13%', minWidth: '13%' }}>= ${parseFloat(orderProduct.pricing.total).toFixed(2)}</div>
                        </Row>
                    </CardBody>
                </div>
            ))}
            <CardFooter>
                {/* NEW FEATURE */}
                {/* <div className="d-flex justify-content-between">
                            <p className="mb-0"><a href="#">Print Pick List...</a></p>
                            <p className="mb-0">Total Products : {orderProducts.length}</p>
                        </div> */}
                <div className="d-flex justify-content-end">
                    <p className="mb-0">Total Products : {orderProducts.length}</p>
                </div>
            </CardFooter>
        </Card>
    )
}

const PaymentTimeline = props => {
    console.log('PaymentTimeline');
    console.log(props?.order?.orderTotalPaid);
    const getPaymentHistoryToken = props.paymentHistory.find(item => item?.action !== "refund" && item?.transactionType !== "check");
    const getPaymentImage = (ccBrand) => {

        let ccBrandLowercase = ccBrand?.toLowerCase()

        if (ccBrandLowercase === 'amex')
            return AmexImg;
        if (ccBrandLowercase === 'visa')
            return VisaImg;
        if (ccBrandLowercase === 'discover')
            return DiscoverImg;
        if (ccBrandLowercase === 'mastercard')
            return MastercardImg;
        else
            return null;
    }

    const epochToLocal = (epoch) => {
        let d = new Date(0);
        return d.setUTCSeconds(epoch);
    }

    const { paymentHistory } = props;

    const [refundModal, setRefundModal] = useState(false);

    function toggleRefundModal() {
        setRefundModal(!refundModal);
    }

    const [checkModal, setCheckModal] = useState(false);

    function toggleCheckModal() {
        setCheckModal(!checkModal);
    }

    async function voidCharge() {

        if (await confirm(`Are you sure you want to void this charge? This cannot be reversed and the order will be cancelled.`, "Void Payment", "Void") === false) {
            return
        }

        OmniApi.post(`/orders/void/${props.orderToken}/${getPaymentHistoryToken?.paymentHistoryToken}`)
            .then(res => console.log(res.data))
            .catch(err => console.log('error', err))
            .finally(res => props.rerenderOrder())

    }

    function getBadgeColor(action) {
        if (action === 'charge')
            return 'success'
        if (action === 'refund')
            return 'warning'
        if (action === 'void')
            return 'danger'
        return 'primary'
    }

    async function deleteCheck(token) {

        if (await confirm(`Are you sure you want to delete this check? This cannot be reversed.`, "Delete Check", "Delete") === false) {
            return
        }

        OmniApi.delete(`/payment/${token}`)
            .then(res => toast.success(`Check successfully cancelled. Current Paid amount:${res.data.totalCurrentPaid}`))
            .catch(err => toast.error('Error cancelling check'))
            .finally(res => props.rerenderOrder())
    }

    return (
        <Card>
            <CardBody>
                {/* NEW FEATURES */}
                {/* <div className="d-flex justify-content-between mb-1">
                    <div className="d-flex">
                        <h3>Payment Timeline</h3>
                        <h4 className="ml-2 d-flex align-items-center">
                            <span className="badge badge-success-lighten ml-2">Paid</span>
                            <span className="badge badge-secondary-lighten ml-2">Authorized</span>
                            <span className="badge badge-secondary-lighten ml-2">Captured</span>
                            <span className="badge badge-danger-lighten ml-2">Voided</span>
                            <span className="badge badge-warning-lighten ml-2">Refunded</span>
                            <span className="badge badge-warning-lighten ml-2">Partially Refunded</span>
                        </h4>
                    </div>
                    <UncontrolledButtonDropdown>
                    <DropdownToggle tag="button" className="btn btn-lg btn-link no-arrow card-drop p-0">
                        <Icon icon={dotsVertical} width="30" height="30" />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem header>Payment Options</DropdownItem>
                        <DropdownItem>Charge Again</DropdownItem>
                        <DropdownItem>Record Payment</DropdownItem>
                        <DropdownItem>Create Refund</DropdownItem>
                        <DropdownItem>Void Charge</DropdownItem>
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
                </div> */}
                <div className="d-flex justify-content-between mb-1">
                    <h3>Payment Timeline</h3>
                    {!IS_ORDER_CANCEL?.includes(props?.order?.statusAndTerms?.processingStatus?.statusToken) &&
                    <UncontrolledButtonDropdown>
                        <DropdownToggle tag="button" className="btn btn-lg btn-link no-arrow card-drop p-0">
                            <Icon icon={dotsVertical} width="30" height="30" />
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem header>Payment Options</DropdownItem>
                            <DropdownItem onClick={toggleRefundModal} disabled={(props?.order?.orderTotalPaid > 0)?false:true} title={(props?.order?.orderTotalPaid > 0)?'':'There is amount no remain to refund'}>Create Refund</DropdownItem>
                            <DropdownItem onClick={toggleCheckModal}>Enter Check</DropdownItem>
                            <DropdownItem onClick={voidCharge} disabled={(props?.order?.orderTotalPaid > 0)?false:true} title={(props?.order?.orderTotalPaid > 0)?'':'There is amount no remain to refund'}>Void Charge</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    }
                    <RefundModal toggle={toggleRefundModal} modal={refundModal} orderToken={props.orderToken} rerenderOrder={props.rerenderOrder} />
                    <CheckModal toggle={toggleCheckModal} modal={checkModal} orderToken={props.orderToken} rerenderOrder={props.rerenderOrder} />
                </div>
                <Table borderless>
                    <thead>
                        <tr className="border-bottom">
                            <th style={{ width: '16.66%' }}><strong>Type</strong></th>
                            <th style={{ width: '16.66%' }}><strong>Amount</strong></th>
                            <th style={{ width: '23%' }}><strong>Date and Time</strong></th>
                            <th style={{ width: '10%' }}><strong>User</strong></th>
                            <th style={{ width: '16.66%' }}><strong>Method</strong></th>
                            <th style={{ width: '16.66%' }}><strong>Notes</strong></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {paymentHistory.map((payment, index) => (
                            <tr className="border-bottom" key={index}>
                                <th>
                                    {/* <span className="mr-1 font-weight-bold">Action:</span> */}
                                    <Badge className={`bg-${getBadgeColor(payment.action)} p-1`} key={index}>
                                        {payment.action}
                                    </Badge>
                                </th>
                                <td>
                                    {/* <span className="mr-1 font-weight-bold">Amount:</span> */}
                                    ${parseFloat(payment.amount).toFixed(2)}
                                </td>
                                <td>
                                    {/* <span className="mr-1 font-weight-bold">Time:</span> */}
                                    {moment(epochToLocal(payment.dateTime)).format('l')} {moment(epochToLocal(payment.dateTime)).format('LT')}
                                </td>
                                <td>TBD</td>
                                <td>
                                    {payment.transactionType === 'cash' &&
                                        'cash'
                                    }
                                    {payment.transactionType === 'check' &&
                                        'Check'
                                    }
                                    {payment.transactionType === 'card' &&
                                        <>
                                            <img src={getPaymentImage(typeof payment?.paymentObject == "string" ? JSON.parse(payment?.paymentObject)?.xCardType : payment?.paymentObject?.xCardType)} height="18" alt="card-img" />
                                            <span className="ml-3">*{typeof payment?.paymentObject == "string" ? JSON.parse(payment?.paymentObject)?.xMaskedCardNumber?.slice(JSON.parse(payment?.paymentObject)?.xMaskedCardNumber?.length - 4) :
                                                payment?.paymentObject?.xMaskedCardNumber.slice(payment?.paymentObject?.xMaskedCardNumber?.length - 4)
                                            }</span> <br />
                                        </>
                                    }
                                </td>
                                <td className="ml-2">
                                    {payment?.note ? <div className="btn btn-lg btn-link no-arrow card-drop p-0"><Icon icon={fileDocumentOutline} color="grey" width="27" height="27" /></div> : ''}
                                </td>
                                <td>
                                    {payment.transactionType === 'check' &&
                                        <Button className='link-button p-0' color="link" style={{ color: '#6c757d', top: '0px', right: '5px', fontSize: '25px' }}
                                            onClick={() => deleteCheck(payment.paymentHistoryToken)}
                                        ><Icon icon={deleteIcon} /></Button>}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    {/* <tfoot>
                        <tr>
                            <td colspan="6" className="text-right"><strong>Total Paid:</strong>  TBD</td>
                        </tr>
                    </tfoot> */}
                </Table>
            </CardBody>
        </Card>
    )
}

const OrderSummary = props => {

    const { checkoutObject } = props;
    

    return (
        <Card>
            <div className="table-responsive">
                <table className="table mb-0">
                    {/* <thead className="bg-light">
                        <tr>
                            <th>Description</th>
                            <th>Price</th>
                        </tr>
                    </thead> */}
                    <tbody>
                        <tr>
                            <td>Subtotal :</td>
                            <td className="text-right">{parseFloat(checkoutObject.pricing.subTotal).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td>Shipping Charge :</td>
                            <td className="text-right">{checkoutObject.pricing.shipping ? `${parseFloat(checkoutObject.pricing.shipping).toFixed(2)}` : '0'}</td>
                        </tr>
                        <tr>
                            <td>Estimated Tax : </td>
                            <td className="text-right">{checkoutObject.pricing.tax ? `${parseFloat(checkoutObject.pricing.tax).toFixed(2)}` : '0'}</td>
                        </tr>
                        <tr>
                            <td>Coupons :</td>
                            <td className="text-right"><span className="float-left badge badge-secondary-lighten">{checkoutObject.couponCodes ? `${checkoutObject.couponCodes[0]}` : ''}</span>{checkoutObject.pricing.totalCouponDiscount ? `-$${parseFloat(checkoutObject.pricing.totalCouponDiscount).toFixed(2)}` : '0'}</td>
                        </tr>
                        <tr>
                            <th>Total :</th>
                            <th className="text-right">${parseFloat(checkoutObject.pricing.total).toFixed(2)}</th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Card>
    );
};

const ShippingInfo = props => {

    const { orderShippingAddress, order } = props;

    return (
        <React.Fragment>
            <h5>{order.contactFirstName} {order.contactLastName}</h5>

            <address className="mb-0 font-14 address-lg">
                {orderShippingAddress.address}, {orderShippingAddress.address2}
                <br />
                {orderShippingAddress.city}, {orderShippingAddress.state} {orderShippingAddress.zip}
                <br />
                <abbr title="Phone">P:</abbr> {orderShippingAddress.phone} <br />
                <abbr title="Mobile">M:</abbr> {orderShippingAddress.mobile}
            </address>
        </React.Fragment>
    );
};

// billing info
const BillingInfo = props => {
    const details = props.details || {};
    return (
        <React.Fragment>
            <ul className="list-unstyled mb-0">
                <li>
                    <p className="mb-2">
                        <span className="font-weight-bold mr-2">Payment Type:</span> {details.type}
                    </p>
                    <p className="mb-2">
                        <span className="font-weight-bold mr-2">Provider:</span> {details.provider}
                    </p>
                    <p className="mb-2">
                        <span className="font-weight-bold mr-2">Valid Date:</span> {details.valid}
                    </p>
                    <p className="mb-0">
                        <span className="font-weight-bold mr-2">CVV:</span> xxx
                    </p>
                </li>
            </ul>
        </React.Fragment>
    );
};

const BillingAddress = props => {

    // const {orderShippingAddress} = props;

    // const mockData = {
    //     avsVerified: true,
    //     zipVerified: false
    // }

    return (
        // <React.Fragment>
        //     <address className="mb-0 font-14 address-lg">
        //         {orderShippingAddress.address}, {orderShippingAddress.address2}
        //         <br />
        //         {orderShippingAddress.city}, {orderShippingAddress.state} {orderShippingAddress.zip}
        //         <br />
        //         <abbr title="Phone">P:</abbr> {orderShippingAddress.phone} <br />
        //         <abbr title="Mobile">M:</abbr> {orderShippingAddress.mobile}
        //     </address>
        //     <div className="mt-1">
        //         <span className="badge badge-secondary-lighten badge-pill mr-1">
        //             AVS Verified: {mockData.avsVerified ? <Icon icon={checkIcon} color="green" width="11" height="11" /> : <Icon icon={multiplyIcon} color="red" width="9" height="9" />} 
        //         </span>
        //         <span className="badge badge-secondary-lighten badge-pill mr-1">
        //             ZIP Verified: {mockData.zipVerified ? <Icon icon={checkIcon} color="green" width="11" height="11" /> : <Icon icon={multiplyIcon} color="red" width="9" height="9" />} 
        //         </span>
        //     </div>
        // </React.Fragment>
        <>
            <span className="badge badge-secondary-lighten badge-pill mr-1">
                AVS Verified: {props.order.avsMatched ? <Icon icon={checkIcon} color="green" width="11" height="11" /> : <Icon icon={multiplyIcon} color="red" width="9" height="9" />}
            </span>
            <span className="badge badge-secondary-lighten badge-pill mr-1">
                ZIP Verified: {props.order.zipMatched ? <Icon icon={checkIcon} color="green" width="11" height="11" /> : <Icon icon={multiplyIcon} color="red" width="9" height="9" />}
            </span>
        </>
    );
};

const CustomerInfo = props => {

    const { customerInfo, customerToken, checkoutObject } = props;

    const copyToClipboard = (e, text) => {
        e.preventDefault()
        navigator.clipboard.writeText(text);
    }

    return (
        <React.Fragment>
            <address className="mb-0 font-17 address-lg">
                <p className="font-weight-bold font-17"><abbr title="User">
                    <Icon icon={userIcon} width="24" height="24" /></abbr>
                    <span>{(customerInfo.customerFirstName && customerInfo.customerLastName) ? `${customerInfo.customerFirstName} ${customerInfo.customerLastName}` : <i>****Missing Name****</i>}</span>
                    <Link to={`/omni/customer/${customerToken}`} id="user-link" className="ml-1"><Icon icon={linkVariant} width="20" height="20" /></Link>
                    <br /></p>
                <p><abbr title="Email"><Icon icon={envelopeIcon} width="24" height="24" /></abbr> {customerInfo.customerEmail}
                    <a href="#" id="copy-email" onClick={(e) => copyToClipboard(e, customerInfo.customerEmail)} className="ml-1"><Icon icon={contentCopy} width="20" height="20" /></a>
                    {/* <a href="#" id="send-email" onClick={(e) => e.preventDefault()}><Icon icon={emailSendOutline} width="22" height="22" /></a> */}
                    <br /></p>
                <UncontrolledTooltip placement="top" target="copy-email" trigger="legacy">
                    '{customerInfo.customerEmail}' copied to clipboard
                </UncontrolledTooltip>
                <p><abbr title="Phone"><Icon icon={phoneIcon} width="24" height="24" /></abbr> {checkoutObject.contactPhone || customerInfo.customerPhone}
                    <a href="#" id="copy-phone" onClick={(e) => copyToClipboard(e, checkoutObject.contactPhone || customerInfo.customerPhone)} className="ml-1"><Icon icon={contentCopy} width="20" height="20" /></a>
                </p>
                <UncontrolledTooltip placement="top" target="copy-phone" trigger="legacy">
                    '{checkoutObject.contactPhone || customerInfo.customerPhone}' copied to clipboard
                </UncontrolledTooltip>
            </address>
        </React.Fragment>
    );
};

const OrderNote = props => {
    return (
        <React.Fragment>
            <p>{props.customerNote}</p>
        </React.Fragment>

    )
}

const PaymentVerificationCenter = () => {
    return (
        <Card>
            <CardBody>
                <h4 className="header-title mb-3">Payment Verification Center</h4>

                <ListGroup>
                    <ListGroupItem className="d-flex align-items-center payment-list">
                        <Icon icon={truckOutline} width="28" height="28" className="mr-2" /> Address Billing/Shipping Match
                        <div style={{ width: '40%', height: '100%' }} className="ml-auto text-right">dasds</div>
                    </ListGroupItem>
                    <ListGroupItem className="d-flex align-items-center payment-list">
                        <Icon icon={creditCardSearchOutline} width="28" height="28" className="mr-2" /> Billing AVS/ZIP Match
                        <div style={{ width: '40%', height: '100%' }} className="ml-auto text-right">dasds</div>
                    </ListGroupItem>
                    <ListGroupItem className="d-flex align-items-center payment-list">
                        <Icon icon={bullseyeIcon} width="28" height="28" className="mr-2" /> IP address - Location
                        <div style={{ width: '40%', height: '100%' }} className="ml-auto text-right">dasds</div>
                    </ListGroupItem>
                    <ListGroupItem className="d-flex align-items-center payment-list">
                        <Icon icon={cartCheck} width="28" height="28" className="mr-2" /> First Successful Order
                        <div style={{ width: '40%', height: '100%' }} className="ml-auto text-right">dasds</div>
                    </ListGroupItem>
                    <ListGroupItem className="d-flex align-items-center payment-list">
                        <Icon icon={eyeCircleOutline} width="28" height="28" className="mr-2" /> No Fraud Watch
                        <div style={{ width: '40%', height: '100%' }} className="ml-auto text-right">dasds</div>
                    </ListGroupItem>
                    <ListGroupItem className="d-flex align-items-center payment-list">
                        <Icon icon={accountEyeOutline} width="28" height="28" className="mr-2" /> Fraud Watch Status
                        <div style={{ width: '40%', height: '100%' }} className="ml-auto text-right">dasds</div>
                    </ListGroupItem>
                    <ListGroupItem className="d-flex align-items-center payment-list">
                        <Icon icon={creditCardCheckOutline} width="28" height="28" className="mr-2" /> EYE4Fraud Status
                        <div style={{ width: '40%', height: '100%' }} className="ml-auto text-right">dasds</div>
                    </ListGroupItem>
                    <ListGroupItem className="d-flex align-items-center payment-list">
                        <Icon icon={alertDecagramOutline} width="28" height="28" className="mr-2" /> Flags
                        <div style={{ width: '40%', height: '100%' }} className="ml-auto text-right">dasds</div>
                    </ListGroupItem>
                </ListGroup>
            </CardBody>
        </Card>
    );
};

const OrderActions = () => {
    return (
        <>
            <Card style={{ height: '87px' }} className="mt-3">
                <CardBody className="d-flex align-items-center">
                    <h4>Processing Status :</h4>
                    <h3>
                        <span className="badge badge-success-lighten ml-2">Ongoing</span>
                        <span className="badge badge-danger-lighten ml-2">Stopped</span>
                        <span className="badge badge-secondary-lighten ml-2">Cancelled</span>
                        <span className="badge badge-warning-lighten ml-2">In Review</span>
                    </h3>
                </CardBody>
            </Card>
            <Card style={{ height: '87px' }}>
                <CardBody className="d-flex align-items-center justify-content-between">
                    <Label className="mt-1">
                        <h4>Tags : </h4>
                    </Label>
                    <Select
                        isMulti={true}
                        options={[
                            { value: 'chocolate', label: 'Chocolate' },
                            { value: 'strawberry', label: 'Strawberry' },
                            { value: 'vanilla', label: 'Vanilla' },
                        ]}
                        className="react-select"
                        classNamePrefix="react-select"></Select>
                </CardBody>
            </Card>
            <Card style={{ height: '87px' }}>
                <CardBody className="d-flex align-items-center justify-content-between">
                    <Label className="mt-1">
                        <h4>Shipping status :</h4>
                    </Label>
                    <Progress multi style={{ width: '95%' }}>
                        <Progress bar style={{ backgroundColor: '#C2C6FB' }} color="#ADB4F9" value="33.3">Processed</Progress>
                        <Progress bar style={{ backgroundColor: '#99A1F8' }} value="33.3">Shipped</Progress>
                        <Progress bar value="33.3">Delivered</Progress>
                        {/* <Progress bar color="danger" value="100">!!</Progress> */}
                    </Progress>
                </CardBody>
            </Card>
            {/* <Card style={{ height: '87px'}}>
                <CardBody className="d-flex align-items-center">
                    <h4>RMA :</h4>
                    <h3>
                        <span className="badge badge-success-lighten ml-2">Completed</span>
                    </h3>
                </CardBody>
            </Card> */}
        </>
    )
}

const Notes = () => {

    const { user } = useContext(UserContext);

    const [notes, setNotes] = useState([
        {
            name: 'Jake',
            dateTime: '12-3-20',
            content: 'Lots of ncanscbsadiojksa;ndbvghoacnsacjmksabfbeadsivojm;pc,ksnbfasdf'
        },
        {
            name: 'Robert',
            dateTime: '12-2-20, 4:15',
            content: 'fdakvuhdsjoachubgvudbhvisajcohudsvbdsivjsoadjcuasgyvcsicjdihubckjcabhcbusjacs'
        }
    ]);

    const newDate = new Date();

    const [noteForm, setNoteForm] = useState({
        name: user.name,
        dateTime: newDate.toLocaleString(),
        content: ''
    })

    useEffect(() => {
        setNoteForm({
            ...noteForm,
            name: user.name
        })
    }, [noteForm, user])

    const submitNote = (e) => {
        e.preventDefault()

        setNotes([
            noteForm,
            ...notes
        ])

        setNoteForm({
            ...noteForm,
            content: ''
        })
    }

    return (
        <Card>
            <CardBody className="pb-1">
                <h4>Admin Notes</h4>
                <form action="#" className="comment-area-box mb-3 border">
                    <textarea
                        rows="4"
                        className="form-control border-0"
                        style={{ resize: 'none' }}
                        value={noteForm.content}
                        onChange={(e) => setNoteForm({
                            ...noteForm,
                            content: e.target.value
                        })}
                        placeholder="Add note..."></textarea>
                    <div className="p-1 bg-light d-flex  align-items-center justify-content-between">
                        <FormGroup className="d-flex align-items-center mb-0">
                            <CustomInput type="checkbox" id="exampleCustomInline" label="Visible to admin only" inline />
                            <CustomInput type="checkbox" id="exampleCustomInline2" label="Visible to customer" inline />
                            <CustomInput type="checkbox" id="exampleCustomInline3" label="Notify customer" inline />
                        </FormGroup>
                        <button className="btn btn-sm btn-success" onClick={(e) => submitNote(e)}>
                            <i className="uil uil-message mr-1"></i>Submit
                        </button>
                    </div>
                </form>
                {notes.map((note, i) => (
                    <Media className="mt-3 mx-2" key={i}>
                        <img className="mr-2 rounded" src="https://picsum.photos/50" alt="" height="32" />
                        <Media body>
                            <div className="d-flex justify-content-between">
                                <h5 className="m-0">{note.name}</h5>
                                <UncontrolledDropdown className="float-right">
                                    <DropdownToggle tag="button" className="btn btn-sm btn-link no-arrow card-drop p-0">
                                        <i className="mdi mdi-dots-horizontal"></i>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem>Edit</DropdownItem>
                                        <DropdownItem>Delete</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                            <p className="text-muted mb-0">
                                <small>{note.dateTime}</small>
                            </p>

                            <p className="my-1">{note.content}</p>

                            <div className="d-flex">
                                <div>
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-link text-muted p-0">
                                        <i className="uil uil-heart mr-1"></i>{' '}
                                        Like
                                    </Link>
                                    <Link to="#" className="btn btn-sm btn-link text-muted p-0 pl-2">
                                        <i className="uil uil-comments-alt mr-1"></i> Reply
                                    </Link>
                                </div>
                                <div>
                                    <span className="badge badge-light badge-pill ml-4">Admin only</span>
                                    <span className="badge badge-light badge-pill ml-1">Visible to customer</span>
                                    <span className="badge badge-light badge-pill ml-1">Customer notified</span>
                                </div>
                            </div>
                        </Media>
                    </Media>
                ))}
            </CardBody>
        </Card>
    )
}

const ShippingLabels = props => {

    useEffect(() => {
        OmniApi.get(`/shipping/${props.orderToken}`)
    }, [props.orderToken])

    const [packages, setPackages] = useState([
        {
            number: 1,
            label: false
        },
        {
            number: 2,
            label: true
        }
    ])

    const addPackage = () => {
        setPackages([
            ...packages,
            {
                number: packages.length + 1,
                label: false
            }
        ])
    }

    const [modal, setModal] = useState({
        open: false,
        state: {
            number: '',
            weight: '',
            height: '',
            width: '',
            length: ''
        }
    });

    console.log('modal', modal)

    const toggle = (shippingPackage = '') => {
        if (!modal.open) {
            let selectedPackage = packages.find(x => x.number === shippingPackage.number)

            setModal({
                open: true,
                state: {
                    ...modal.state,
                    ...selectedPackage
                }
            })
        } else {
            setModal({
                open: false,
                state: {}
            })
        }
    }

    const ShippingLabelModal = () => {

        const [tempShippingLabel, setTempShippingLabel] = useState({
            weight: modal.state.weight,
            height: modal.state.height,
            width: modal.state.width,
            length: modal.state.length,
        })

        const [selectedShippingOption, setSelectedShippingOption] = useState(null);

        const [labelLoading, setLabelLoading] = useState(false);

        const [shippingOptions, setShippingOptions] = useState(null);

        const handleInputChange = (e) => {
            const target = e.target;
            const name = target.name;

            setTempShippingLabel({
                ...tempShippingLabel,
                [name]: e.target.value,
            })

            setShippingOptions(null);
            setSelectedShippingOption(null);
        }

        const [additionalDimensions, setAdditionalDimensions] = useState(false);

        const toggleAdditionalDimensions = (e) => {
            e.preventDefault()
            setAdditionalDimensions(true);
        }

        const getShippingOptions = () => {

            setLabelLoading(true);

            let queryParams = new URLSearchParams(tempShippingLabel);
            queryParams.set("order", props.orderToken);

            OmniApi.get(`shipping/rates?${queryParams}`)
                .then(res => {
                    res.data.sort((a, b) => {
                        return a.rate - b.rate;
                    });
                    setShippingOptions(res.data)
                })
                .finally(() => setLabelLoading(false));

        }

        const saveShippingLabel = () => {

            let payload = {
                methodId: selectedShippingOption.methodId,
                order: props.orderToken,
                packages: [{
                    ...tempShippingLabel
                }],
                serviceCode: selectedShippingOption.serviceCode
            }

            OmniApi.post('shipping/generateLabel', payload)
                .finally(res => console.log(res));

        }

        console.log('shippingOptions', shippingOptions)

        console.log(selectedShippingOption && selectedShippingOption.methodId)

        return (
            <Modal isOpen={modal.open} toggle={toggle}>
                <ModalHeader toggle={toggle} size="lg">
                    Package # {modal.state.number}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="package-weight">Weight <small>(lbs)</small></Label>
                                <Input type="number" name="weight" id="package-weight" placeholder="0" min="0"
                                    value={tempShippingLabel.weight} onChange={(e) => handleInputChange(e)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    {additionalDimensions ?
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="package-height">Height <small>(in.)</small></Label>
                                    <Input type="number" name="height" id="package-height" placeholder="0" min="0"
                                        value={tempShippingLabel.height} onChange={(e) => handleInputChange(e)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="package-width">Width <small>(in.)</small></Label>
                                    <Input type="number" name="width" id="package-width" placeholder="0" min="0"
                                        value={tempShippingLabel.width} onChange={(e) => handleInputChange(e)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="package-length">Length <small>(in.)</small></Label>
                                    <Input type="number" name="length" id="package-length" placeholder="0" min="0"
                                        value={tempShippingLabel.length} onChange={(e) => handleInputChange(e)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        :
                        <FormGroup>
                            <a href="#" onClick={(e) => toggleAdditionalDimensions(e)}>Additional Dimensions...</a>
                        </FormGroup>
                    }
                    <FormGroup>
                        {(!shippingOptions && !labelLoading) &&
                            <Button color="primary" disabled={!tempShippingLabel.weight} className="d-flex align-items-center" onClick={() => getShippingOptions()}>
                                Get Available Options <Icon icon={fileExportOutline} width="18px" height="18px" />
                            </Button>
                        }
                        {(!shippingOptions && labelLoading) &&
                            <Button color="primary" disabled className="d-flex align-items-center">
                                <Spinner className="spinner-border-sm" tag="span" color="white" />
                            </Button>
                        }
                        {shippingOptions &&
                            <>
                                <Label>Select Shipping Option</Label>
                                <Select
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="shippingOption"
                                    isSearchable={false}
                                    onChange={(e) => setSelectedShippingOption(e.value.option)}
                                    options={
                                        shippingOptions.map(option => (
                                            { value: { option }, label: `${option.methodId} - $${option.rate} - ${option.description}` }
                                        ))
                                    }></Select>
                            </>
                        }
                    </FormGroup>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-between">
                    <div className="btn no-arrow card-drop p-0" onClick={toggle}>
                        Cancel
                    </div>
                    <Button color="primary" onClick={() => saveShippingLabel()} disabled={!selectedShippingOption}>
                        Save
                    </Button>{' '}
                </ModalFooter>
            </Modal>
        )
    }

    return (
        <Card>
            <CardHeader>
                <h4 className="mb-2">Shipping and Tracking</h4>
            </CardHeader>
            <CardBody>
                <ListGroup flush>
                    {packages.map((shippingPackage, i) => {
                        if (shippingPackage.label) {
                            return (
                                <ListGroupItem className="d-flex justify-content-between" key={i}>
                                    <span className="mr-4"><Icon icon={packageVariantClosed} width="25" height="25" className="mr-1" />{shippingPackage.number}</span>
                                    <div style={{ width: '60%' }} className="d-flex justify-content-between align-items-center">
                                        <span>UPS</span> <span><a href="#">543232482349234</a></span> <span><a href="#"><Icon icon={printerOutline} width="30" height="30" color="grey" /></a></span>
                                    </div>
                                    <span className="float-right"><UncontrolledButtonDropdown direction="left">
                                        <DropdownToggle tag="button" className="btn btn-lg btn-link no-arrow card-drop p-0">
                                            <Icon icon={dotsVertical} width="30" height="30" />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem>Delete Package</DropdownItem>
                                            <DropdownItem header>Shipping Label</DropdownItem>
                                            <DropdownItem><span className="ml-2">Create</span></DropdownItem>
                                            <DropdownItem><span className="ml-2">Delete</span></DropdownItem>
                                            <DropdownItem><span className="ml-2">View</span></DropdownItem>
                                            <DropdownItem><span className="ml-2">Download</span></DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                    </span>
                                </ListGroupItem>
                            )
                        } else {
                            return (
                                <ListGroupItem key={i}>
                                    <Icon icon={packageVariantClosed} width="25" height="25" /> {shippingPackage.number} -
                                    <Button type="button" color="primary" size='sm' className="ml-1" onClick={() => toggle(shippingPackage)}>
                                        Create Label
                                    </Button>
                                    <span className="float-right"><UncontrolledButtonDropdown direction="left">
                                        <DropdownToggle tag="button" className="btn btn-lg btn-link no-arrow card-drop p-0">
                                            <Icon icon={dotsVertical} width="30" height="30" />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem>Delete Package</DropdownItem>
                                            <DropdownItem header>Shipping Label</DropdownItem>
                                            <DropdownItem className="ml-2">Create</DropdownItem>
                                            <DropdownItem className="ml-2">Delete</DropdownItem>
                                            <DropdownItem className="ml-2">View</DropdownItem>
                                            <DropdownItem className="ml-2">Download</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                    </span>
                                </ListGroupItem>
                            )
                        }
                    })}
                </ListGroup>
                <Button type="button" color="primary" className="mt-2 ml-3" onClick={() => addPackage()}>
                    <Icon icon={plusThick} /> Add Package
                </Button>
            </CardBody>
            <CardFooter>
                <span className="float-right">Total Packages: {packages.length}</span>
            </CardFooter>
            <ShippingLabelModal />
        </Card>
    )
}

const ShippingLabelsOnly = props => {

    const [shippingLabels, setShippingLabels] = useState([]);

    useEffect(() => {
        OmniApi.get(`/shipping/${props.orderToken}`)
            .then(res => setShippingLabels(res.data))
    }, [props.orderToken])

    function renderLabels() {
        OmniApi.get(`/shipping/${props.orderToken}`)
            .then(res => setShippingLabels(res.data))
    }


    const [labelModal, setLabelModal] = useState(false);

    function toggleLabelModal() {
        setLabelModal(!labelModal)
    }

    const [viewLabelModal, setViewLabelModal] = useState({
        open: false,
        base64Img: ''
    })

    const toggleViewLabelModal = (base64Img = '') => {
        if (!viewLabelModal.open) {

            setViewLabelModal({
                open: true,
                base64Img
            })
        } else {
            setViewLabelModal({
                open: false,
                base64Img: ''
            })
        }
    }

    const [base64ImgForPrint, setBase64ImgForPrint] = useState('')

    const labelRef = useRef();

    function printImage(img) {
        setBase64ImgForPrint(img)
    }

    const handlePrint = useReactToPrint({
        onAfterPrint: () => setBase64ImgForPrint(''),
        content: () => labelRef.current,
    })

    // this ensures that the base64ImgForPrint is set before printing the document
    useEffect(() => {
        if (base64ImgForPrint)
            handlePrint()
    }, [base64ImgForPrint, handlePrint])


    const ShippingLabelModal = () => {

        const [tempShippingLabel, setTempShippingLabel] = useState({
            weight: '',
            height: '',
            width: '',
            length: ''
        })

        const [selectedShippingOption, setSelectedShippingOption] = useState(null);

        const [labelLoading, setLabelLoading] = useState(false);

        const [shippingOptions, setShippingOptions] = useState(null);

        const handleInputChange = (e) => {
            const target = e.target;
            const name = target.name;

            setTempShippingLabel({
                ...tempShippingLabel,
                [name]: e.target.value,
            })

            setShippingOptions(null);
            setSelectedShippingOption(null);
        }

        const [additionalDimensions, setAdditionalDimensions] = useState(false);

        const toggleAdditionalDimensions = (e) => {
            e.preventDefault()
            setAdditionalDimensions(true);
        }

        const getShippingOptions = () => {

            setLabelLoading(true);

            let queryParams = new URLSearchParams(tempShippingLabel);
            queryParams.set("order", props.orderToken);

            OmniApi.get(`shipping/rates?${queryParams}`)
                .then(res => {
                    res.data.sort((a, b) => {
                        return a.rate - b.rate;
                    });
                    setShippingOptions(res.data)
                })
                .finally(() => setLabelLoading(false));

        }

        const saveShippingLabel = () => {

            let payload = {
                methodId: selectedShippingOption.methodId,
                order: props.orderToken,
                packages: [{
                    ...tempShippingLabel
                }],
                serviceCode: selectedShippingOption.serviceCode
            }

            let generateLabel = OmniApi.post('shipping/generateLabel', payload)
                .then(res => renderLabels())
                .catch(err => console.error(err))

            toast.promise(
                generateLabel,
                {
                    pending: {
                        render() {
                            return "Generating label..."
                        },
                        icon: true,
                    },
                    success: {
                        render(res) {
                            return `Label successfully created`
                        },
                        // other options
                        icon: true,
                    },
                    error: {
                        render(err) {
                            // When the promise reject, data will contains the error
                            return `err`
                        },
                        icon: true,
                    }
                }
            )

            toggleLabelModal()

        }

        return (
            <Modal isOpen={labelModal} toggle={toggleLabelModal}>
                <ModalHeader toggle={toggleLabelModal} size="lg">
                    Create Shipping Label
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="package-weight">Weight <small>(lbs)</small></Label>
                                <Input type="number" name="weight" id="package-weight" placeholder="0" min="0"
                                    value={tempShippingLabel.weight} onChange={(e) => handleInputChange(e)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    {additionalDimensions ?
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="package-height">Height <small>(in.)</small></Label>
                                    <Input type="number" name="height" id="package-height" placeholder="0" min="0"
                                        value={tempShippingLabel.height} onChange={(e) => handleInputChange(e)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="package-width">Width <small>(in.)</small></Label>
                                    <Input type="number" name="width" id="package-width" placeholder="0" min="0"
                                        value={tempShippingLabel.width} onChange={(e) => handleInputChange(e)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="package-length">Length <small>(in.)</small></Label>
                                    <Input type="number" name="length" id="package-length" placeholder="0" min="0"
                                        value={tempShippingLabel.length} onChange={(e) => handleInputChange(e)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        :
                        <FormGroup>
                            <a href="#" onClick={(e) => toggleAdditionalDimensions(e)}>Additional Dimensions...</a>
                        </FormGroup>
                    }
                    <FormGroup>
                        {(!shippingOptions && !labelLoading) &&
                            <Button color="primary" disabled={!tempShippingLabel.weight} className="d-flex align-items-center" onClick={() => getShippingOptions()}>
                                Get Available Options <Icon icon={fileExportOutline} width="18px" height="18px" />
                            </Button>
                        }
                        {(!shippingOptions && labelLoading) &&
                            <Button color="primary" disabled className="d-flex align-items-center">
                                <Spinner className="spinner-border-sm" tag="span" color="white" />
                            </Button>
                        }
                        {shippingOptions &&
                            <>
                                <Label>Select Shipping Option</Label>
                                <Select
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    name="shippingOption"
                                    isSearchable={false}
                                    onChange={(e) => setSelectedShippingOption(e.value.option)}
                                    options={
                                        shippingOptions.map(option => (
                                            { value: { option }, label: `${option.methodId.toUpperCase()} - $${option.rate} - ${option.description}` }
                                        ))
                                    }></Select>
                            </>
                        }
                    </FormGroup>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-between">
                    <div className="btn no-arrow card-drop p-0" onClick={toggleLabelModal}>
                        Cancel
                    </div>
                    <Button color="primary" onClick={() => saveShippingLabel()} disabled={!selectedShippingOption}>
                        Generate Label
                    </Button>{' '}
                </ModalFooter>
            </Modal>
        )
    }

    return (
        <Card>
            <CardHeader className="d-flex justify-content-between">
                <h4 className="mb-2 d-flex align-items-center">Shipping Labels</h4>
                <Button type="button" color="primary" onClick={() => toggleLabelModal()}>
                    <Icon icon={plusThick} /> Add Label
                </Button>
            </CardHeader>
            <CardBody>
                <ListGroup flush>
                    {shippingLabels.filter((label) => label.packages.length).length > 0 ?
                        shippingLabels.filter((label) => label.packages.length).map((label, i) => (
                            <ListGroupItem className="d-flex justify-content-between" key={i}>
                                <div className="d-flex justify-content-between" style={{ width: '90%' }}>
                                    <div style={{ width: '10%' }} className="d-flex"><Icon icon={packageVariantClosed} width="25" height="25" className="mr-1" /><strong>{i + 1}</strong></div>
                                    <div><strong>{label.packages[0].billingWeight}</strong> lbs</div>
                                    <div>⚬</div>
                                    {/* <div style={{ width: '60%' }} clatifyssName="d-flex justify-content-between align-items-center">
                        <span>UPS</span> <span><a href="#">543232482349234</a></span> <span><a href="#"><Icon icon={printerOutline} width="30" height="30" color="grey" /></a></span>
                        </div> */}
                                    <div><strong>{label.packages[0].method.toUpperCase()}</strong></div>
                                    <div>⚬</div>
                                    <div><a href={label.packages[0].trackingLink} target="_blank">{label.packages[0].trackingNumber}</a></div>
                                    <div>⚬</div>
                                    <div>$<strong>{label.charges}</strong></div>
                                    <div>⚬</div>
                                    <div>
                                        <Button color={'primary'} outline className="btn-icon py-0 px-1" onClick={() => printImage(label.packages[0].label)}>
                                            <Icon icon={printerOutline} width="26" height="26" />
                                        </Button>
                                    </div>
                                </div>
                                <div style={{ width: '10%' }} className="d-flex justify-content-end"><UncontrolledButtonDropdown direction="left">
                                    <DropdownToggle tag="button" className="btn btn-lg btn-link no-arrow card-drop p-0">
                                        <Icon icon={dotsVertical} width="30" height="30" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem header>Shipping Label</DropdownItem>
                                        <DropdownItem onClick={() => toggleViewLabelModal(label.packages[0].label)}><span className="ml-2">View</span></DropdownItem>
                                        <DropdownItem onClick={() => printImage(label.packages[0].label)}><span className="ml-2">Print</span></DropdownItem>
                                        {/* <DropdownItem><a href={'data:image/png;base64,' + label.packages[0].label} download={label.packages[0].method.toUpperCase() + '_tracking-' + label.packages[0].trackingNumber + '.png'} className="ml-2">Download</a></DropdownItem> */}
                                        <DropdownItem href={'data:image/png;base64,' + label.packages[0].label}
                                            download={label.packages[0].method.toUpperCase() + '-tracking_' + label.packages[0].trackingNumber + '.png'}
                                        ><span className="ml-2">Download</span></DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                                </div>
                            </ListGroupItem>
                        ))
                        :
                        <div className="h-100 w-100 bg-light py-2 rounded d-flex justify-content-center">
                            <h5>No Shipping Labels</h5>
                        </div>
                    }
                </ListGroup>
            </CardBody>
            <CardFooter>
                <span className="float-right">Total Packages: {shippingLabels.filter(label => label.packages.length).length}</span>
            </CardFooter>
            <ViewLabelModal modal={viewLabelModal} toggle={toggleViewLabelModal} />
            <ShippingLabelModal />
            <div className="d-none">
                <LabelToPrint base64ImgForPrint={base64ImgForPrint} ref={labelRef} />
            </div>
        </Card>
    )
}

const ViewLabelModal = props => {

    const { modal, toggle } = props;

    return (
        <Modal
            isOpen={modal.open}
            toggle={toggle}
        >
            <ModalHeader toggle={toggle}>Shipping Label</ModalHeader>
            <ModalBody>
                <img src={`data:image/png;base64,${modal.base64Img}`} className="w-100"></img>
            </ModalBody>
        </Modal>
    )

}

class LabelToPrint extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <img src={`data:image/png;base64,${this.props.base64ImgForPrint}`} className="w-100 mt-1"></img>
        )
    }
}

const HistoryModal = props => {

    const { state, toggle } = props;

    return (
        <Modal
            isOpen={state}
            toggle={toggle}
            className="modal-right justify-content-start"
            size="md"
            style={{ 'width': '300px' }}
        >
            <ModalHeader toggle={toggle}>History Log</ModalHeader>
            <ModalBody>
                <ListGroup flush>
                    {/* <ListGroupItem>Ongoing</ListGroupItem>
                    <ListGroupItem>Stopped</ListGroupItem>
                    <ListGroupItem>Refunded</ListGroupItem>
                    <ListGroupItem>Partially paid</ListGroupItem>
                    <ListGroupItem>Voided</ListGroupItem>
                    <ListGroupItem>Again</ListGroupItem>
                    <ListGroupItem>Ongoing</ListGroupItem>
                    <ListGroupItem>Stopped</ListGroupItem>
                    <ListGroupItem>Refunded</ListGroupItem> */}
                    <ListGroupItem>Partially paid</ListGroupItem>
                    <ListGroupItem>Voided</ListGroupItem>
                    <ListGroupItem>Again</ListGroupItem>
                    <ListGroupItem>Ongoing</ListGroupItem>
                    <ListGroupItem>Stopped</ListGroupItem>
                    <ListGroupItem>Refunded</ListGroupItem>
                    <ListGroupItem>Partially paid</ListGroupItem>
                    <ListGroupItem>Voided</ListGroupItem>
                    <ListGroupItem>Again</ListGroupItem>
                </ListGroup>
            </ModalBody>
        </Modal>
    )
}

const RefundModal = props => {

    const { modal, orderToken } = props;

    const toggle = () => {
        props.toggle()
        setRefundAmount('')
    }

    const [refundAmount, setRefundAmount] = useState('');

    const [refundHasDecimals, setRefundHasDecimals] = useState(true);

    const [fullRefundCheckbox, setFullRefundCheckbox] = useState(false);

    useEffect(() => {
        if (fullRefundCheckbox)
            setRefundAmount('')
    }, [fullRefundCheckbox])

    // determines that there 2 decimal numbers included in the refund
    useEffect(() => {
        let decimalRegex = /^[0-9]*\.[0-9]{2}$/;

        refundAmount ? setRefundHasDecimals(decimalRegex.test(refundAmount)) : setRefundHasDecimals(true)

    }, [refundAmount])

    const processRefund = async () => {

        if (await confirm(`${refundAmount ? `This order will be refunded the amount of $${refundAmount}` : `This order will be refunded in full`}. Please Confirm.`, "Processing Refund", "Confirm") === false) {
            return
        }

        let refundObject;

        if (fullRefundCheckbox) {
            refundObject = { refundAmount: 'full' }
        } else {
            refundObject = { refundAmount }
        }

        OmniApi.post(`/orders/refund/${props.orderToken}`, refundObject)
            .then(res => {
               // console.log('res amount:', res);
                if (res.data.status === 'error')
                    throw new Error(res.data.refundObject.xError)
                if(refundObject.refundAmount === 'full' && res.data.status === 'approved'){
                    toast.success(`Refund amount of $${res.data.totalPaid} was successfully processed`);
                }else{
                    toast.success(`Refund amount of $${refundAmount} was successfully processed`);
                }
                toggle()
            })
            .catch(err => {
                toast.error(`${err}`);
            })
            .finally(res => props.rerenderOrder())

    }

    return (
        <Modal isOpen={modal} toggle={toggle} centered size="sm">
            <ModalHeader toggle={toggle} className="modal-colored-header bg-primary">
                Create Refund
            </ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>Enter Refund Amount</Label>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <span className="input-group-text py-0 px-2">
                                $
                            </span>
                        </InputGroupAddon>
                        {/* <CurrencyInput placeholder="0.00" type="text" 
                        name="refundAmount" id="refundAmount" 
                        value={refundAmount}
                        onChange={(e) => setRefundAmount(e.target.value)}
                        disabled={fullRefundCheckbox}
                        /> */}
                        <CurrencyInput name="refundAmount" id="refundAmount" value={refundAmount} onChange={(e) => setRefundAmount(e.target.value)} disabled={fullRefundCheckbox} />
                    </InputGroup>
                    {(!refundHasDecimals && !fullRefundCheckbox) && <div className="text-danger">Refund amount must include dollars and cents. (example: 10.99)</div>}
                </FormGroup>
                <FormGroup>
                    <CustomInput type="checkbox" id="fullRefundCheckbox" label="Full Refund"
                        value={fullRefundCheckbox}
                        onChange={() => setFullRefundCheckbox(!fullRefundCheckbox)}
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={processRefund} disabled={((!refundHasDecimals && !fullRefundCheckbox) || (!refundAmount && !fullRefundCheckbox))}>
                    Submit
                </Button>{' '}
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )
}

const CheckModal = props => {

    const { modal, orderToken } = props;

    const toggle = () => {
        setCheckAmount(0)
        props.toggle()
    }

    const [checkAmount, setCheckAmount] = useState(0);

    const [loading, setLoading] = useState(false)

    const processCheck = async () => {

        if (await confirm(`A check in the amount of $${checkAmount} will be applied to this order. Please Confirm.`, "Applying Check...", "Confirm") === false) {
            return
        }

        let refundObject;
        setLoading(true)
        OmniApi.post(`/payment/record/${orderToken}`, {
            amount: checkAmount,
            transactionType: 'check',
            transactionDetails: 'test check',
            action: "charge"
        })
            .then(res => {
                console.log({ res })
                setLoading(false)
                toast.success(`A check in the amount of $${checkAmount} has been successfully applied to this order`);
                toggle()
            })
            .catch(err => {
                setLoading(false)
                toast.error(`${err}`);
            })
            .finally(res => props.rerenderOrder())

    }

    return (
        <Modal isOpen={modal} toggle={toggle} centered size="sm">
            <ModalHeader toggle={toggle} className="modal-colored-header bg-primary">
                Enter Check
            </ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>Enter Check Amount</Label>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <span className="input-group-text py-0 px-2">
                                $
                            </span>
                        </InputGroupAddon>
                        {/* <CurrencyInput placeholder="0.00" type="text" 
                        name="refundAmount" id="refundAmount" 
                        value={refundAmount}
                        onChange={(e) => setRefundAmount(e.target.value)}
                        disabled={fullRefundCheckbox}
                        /> */}
                        <CurrencyInput name="checkAmount" id="refundAmount" value={checkAmount} onChange={(e) => setCheckAmount(e.target.value)} />
                    </InputGroup>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={processCheck} disabled={!checkAmount || loading}>
                    Submit
                </Button>{' '}
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    )
}

const OrderStatuses = ({ statuses, setOrder, order, rerenderOrder }) => {

    const [statusAndTerms, setStatusAndTerms] = useState(null);

    useEffect(() => {
        OmniApi.get('/statusAndTerms')
            .then(res => setStatusAndTerms(res.data))
            .catch(err => toast.error('Error retreiving Status and Terms'))
    }, [])

    const onSelectChange = async (select, element) => {

        if (await confirm(`Updating ${element.name} status to ${select.label}. Please confirm`, "Status Update", "Confirm") === false) {
            return
        }

        if (element.name === 'processing') {
            OmniApi.post('/orders/status/' + order.orderToken, {
                processingStatus: select.value
            })
                .then(res => {
                    rerenderOrder()
                    toast.success('Processing status updated')
                })
                .catch(err => toast.error('Error updating status'))
        }

        if (element.name === 'shipping') {
            OmniApi.post('/orders/status/' + order.orderToken, {
                shippingStatus: select.value
            })
                .then(res => {
                    rerenderOrder()
                    toast.success('Shipping status updated')
                })
                .catch(err => toast.error('Error updating status'))
        }

        if (element.name === 'payment') {
            OmniApi.post('/orders/status/' + order.orderToken, {
                paymentStatus: select.value
            })
                .then(res => {
                    rerenderOrder()
                    toast.success('Payment status updated')
                })
                .catch(err => toast.error('Error updating status'))
        }

    }
    console.log(order?.statusAndTerms?.processingStatus?.statusName)
    return (
        <Card>
            <CardBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Processing Status: </Label>
                            {IS_ORDER_CANCEL?.includes(order?.statusAndTerms?.processingStatus?.statusToken) &&
                                <div>{order?.statusAndTerms?.processingStatus?.statusName}</div>
                            }
                            {!IS_ORDER_CANCEL?.includes(order?.statusAndTerms?.processingStatus?.statusToken) &&
                                <Select
                                    id="itemType"
                                    name="processing"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    onChange={onSelectChange}
                                    isSearchable={false}
                                    value={
                                        statusAndTerms?.processingStatus.map(status => ({ value: status.statusToken, label: status.statusName }))
                                            .find(status => status.value === statuses.processingStatus.statusToken)
                                    }
                                    hideSelectedOptions
                                    options={statusAndTerms?.processingStatus.map(status => ({ value: status.statusToken, label: status.statusName }))
                                    }
                                >
                                </Select>
                            }
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>Shipping Status: </Label>
                            {IS_ORDER_CANCEL?.includes(order?.statusAndTerms?.processingStatus?.statusToken) &&
                                <div>{order?.statusAndTerms?.shippingStatus?.statusName}</div>
                            }
                            {!IS_ORDER_CANCEL?.includes(order?.statusAndTerms?.processingStatus?.statusToken) &&
                                <Select
                                    id="itemType"
                                    name="shipping"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    value={
                                        statusAndTerms?.shippingStatus.map(status => ({ value: status.statusToken, label: status.statusName }))
                                            .find(status => status.value === statuses.shippingStatus.statusToken)
                                    }
                                    onChange={onSelectChange}
                                    isSearchable={false}
                                    hideSelectedOptions
                                    options={
                                        statusAndTerms?.shippingStatus.map(status => ({ value: status.statusToken, label: status.statusName }))
                                    }
                                >
                                </Select>
                            }
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>Payment Status: </Label>
                            {IS_ORDER_CANCEL?.includes(order?.statusAndTerms?.processingStatus?.statusToken) &&
                                <div>{order?.statusAndTerms?.paymentStatus?.statusName}</div>
                            }
                            {!IS_ORDER_CANCEL?.includes(order?.statusAndTerms?.processingStatus?.statusToken) &&
                                <Select
                                    id="itemType"
                                    name="payment"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    value={
                                        statusAndTerms?.paymentStatus.map(status => ({ value: status.statusToken, label: status.statusName }))
                                            .find(status => status.value === statuses.paymentStatus.statusToken)
                                    }
                                    onChange={onSelectChange}
                                    isSearchable={false}
                                    hideSelectedOptions
                                    options={
                                        statusAndTerms?.paymentStatus.map(status => ({ value: status.statusToken, label: status.statusName }))
                                    }>
                                </Select>
                            }
                        </FormGroup>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default OrderDetail;