import React, { useEffect, useState, useContext } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    CustomInput,
    Badge,
    Button,
    Spinner
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Icon } from '@iconify/react';
import eyeOutline from '@iconify/icons-mdi/eye-outline';
import eyeOffOutline from '@iconify/icons-mdi/eye-off-outline';
import archiveOutline from '@iconify/icons-mdi/archive-outline';
// import { select } from '@redux-saga/core/effects';
import { useHistory, Link, useLocation } from 'react-router-dom';
import {OmniApi} from '../customAPI/OmniApi';
import { UserContext } from '../custom-contexts/UserContext';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { Loader } from 'react-bootstrap-typeahead';
import { commonHelper } from '../helpers/commonHelper';

const RemotePagination = ({ data, columns, page, sizePerPage, onTableChange, totalSize, showTotal = true, paginationTotalRenderer, user }) => {

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const history = useHistory();

    // The onClick in the row event routes the user to the product detail page when row is clicked on
    const rowEvents = {
        onClick: (e, row) => {

            const location = {
                pathname: `/omni/catalog/product/${row.prodToken}`,
                state: {
                    row
                }
            }

            history.push(location)
        }
    }

    const rowClasses = (row, rowIndex) => {
        return 'table-row';
    };

    const paginationOptions = {
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        sizePerPageList: [
            {
                text: '10',
                value: 10,
            },
            {
                text: '20',
                value: 20,
            },
            {
                text: '60',
                value: 60,
            },
            {
                text: '100',
                value: 100,
            },
        ], // A numeric array is also available. the purpose of above example is custom the text
    };

    const [searchTerm, setSearchTerm] = useState('');

    const searchChange = (e) => {
        let result = e.target.value
        let page = parseInt(query.get('page'));
        if (page > 1)
            query.set('page', '1')
        query.set('searchTerm', result);
        history.push(location.pathname + "?" + query.toString());
    }

    useEffect(()=>{
        let result = query.get('searchTerm')
        setSearchTerm(result);
    }, [location.search])

    const [selectedRows, setSelectedRows] = useState([]);

    const selectRow = {
        mode: 'checkbox',
        style: { background: '#CCD0FB' },
        clickToSelect: false,
        onSelectAll: (isSelect, rows, e) => {
            if (isSelect){
                setSelectedRows(rows.map(e => e.prodToken));
            } else {
                setSelectedRows([]);
            }
          },
        onSelect: (row, isSelect, rowIndex, e) => {
            // console.log(row, isSelect, rowIndex);
            if (isSelect){
                setSelectedRows([...selectedRows, row.prodToken]);
            } else {
                setSelectedRows(selectedRows.filter(e => e !== row.prodToken));
            }
          },
        selectionRenderer: ({ checked, disabled }) => {
            return (
                <div className="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        className="custom-control-input row-checkbox"
                        checked={checked}
                        disabled={disabled}
                        onChange={() => {}}
                    />
                    <label
                        className="custom-control-label row-checkbox-label"
                        onClick={e => {
                            e.preventDefault();
                        }}></label>
                </div>
            );
        },
        selectionHeaderRenderer: ({ indeterminate, ...rest }) => {
            return (
                <div className="custom-control custom-checkbox select-header">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck1"
                        ref={input => {
                            if (input) input.indeterminate = indeterminate;
                        }}
                        {...rest}
                        onChange={() => {}}
                    />
                    <label
                        className="custom-control-label"
                        htmlFor="customCheck1"
                        onClick={e => {
                            e.preventDefault();
                        }}></label>
                </div>
            );
        },
    };

    console.log('selected', selectedRows)

    const [visibilityValues, setVisibilityValues] = useState({
        1: false,
        2: false,
        3: false
    });

    const visibilityOnClick = (e) => {
        const target = e.target;
        const name = target.name;
        
        // setVisibilityValues({
        //     ...visibilityValues,
        //     [name]: target.checked
        // })

        let page = parseInt(query.get('page'));
        if (page > 1)
            query.set('page', '1')

        let newVisibilityValues = {
            ...visibilityValues,
            [name]: target.checked
        }

        setVisibilityValues(newVisibilityValues)

        let valuesChecked = []
        for (let value in newVisibilityValues){
            if (newVisibilityValues[value])
                valuesChecked.push(value)
        }
        
        if(valuesChecked.length === 0){
            query.delete('fq')
            return history.push(location.pathname + "?" + query.toString());
        }
        
        let visibilityQuery = ''
        if (valuesChecked.length > 0)
            visibilityQuery = (`prodVisible:${valuesChecked[0]}`) 
        if (valuesChecked.length > 1){
            valuesChecked.forEach((value, i) => {
                if(i === 0)
                    return
                else
                    visibilityQuery = (`${visibilityQuery} prodVisible:${value}`)
            })
        }
        
        visibilityQuery = `(${visibilityQuery})`

        // query.set('fq', visibilityQuery)

        query.delete('fq')

        history.push(location.pathname + "?" + query.toString() + '&fq=' + visibilityQuery);
    }

    useEffect(() => {
        let filteredVisibility = query.get('fq')

        if(filteredVisibility){
            let checkedArr = {}
            checkedArr['1'] = filteredVisibility.includes('1')
            checkedArr['2'] = filteredVisibility.includes('2')
            checkedArr['3'] = filteredVisibility.includes('3')

            return setVisibilityValues(checkedArr)
        }

    }, [])

    const [csvLoading, setCsvLoading] = useState(false);

    const getCsv = () => {

        function downloadCsv(blob, fileName) {
            var linkElement = document.createElement('a');
    
            var url = window.URL.createObjectURL(blob);
    
            linkElement.setAttribute('href', url);
            linkElement.setAttribute("download", fileName);
    
            var clickEvent = new MouseEvent("click", {
                "view": window,
                "bubbles": true,
                "cancelable": false
            });
            linkElement.dispatchEvent(clickEvent);
        }

        setCsvLoading(true)

        OmniApi.get('/sites')
        .then(({data}) => {
            let queryParams = new URLSearchParams({
                site: data[0].siteToken,
                format: 'csv_for_google',
                linktypes: 'products'
            });
            

            OmniApi.get( `/sitemap/download?${queryParams}`, {
                responseType: 'blob',

            })
            .then(({data}) => {
                downloadCsv(data, 'products.csv')
            })
        })
        .catch(err => console.error(err))
        .finally(() => setCsvLoading(false))
    }

    // useEffect(() => {
    //     // let filteredVisibility = query.get('fq')
    //     // console.log('filteredvisibility', filteredVisibility)
    //     // if(filteredVisibility){
    //     //     let checkedArr = {}
    //     //     checkedArr['1'] = filteredVisibility.includes('1')
    //     //     checkedArr['2'] = filteredVisibility.includes('2')
    //     //     checkedArr['3'] = filteredVisibility.includes('3')

    //     //     return setVisibilityValues(checkedArr)
    //     // }
    //     let valuesChecked = []
    //     for (let value in visibilityValues){
    //         console.log(value)
    //         if (visibilityValues[value])
    //             valuesChecked.push(value)
    //     }
        
    //     if(valuesChecked.length === 0){
    //         query.delete('fq')
    //         return history.push(location.pathname + "?" + query.toString());
    //     }
        
    //     let visibilityQuery = ''
    //     if (valuesChecked.length > 0)
    //         visibilityQuery = (`prodVisible:${valuesChecked[0]}`) 
    //     if (valuesChecked.length > 1){
    //         valuesChecked.forEach((value, i) => {
    //             if(i === 0)
    //                 return
    //             else
    //                 visibilityQuery = (`${visibilityQuery} prodVisible:${value}`)
    //         })
    //     }
        
    //     visibilityQuery = `(${visibilityQuery})`

    //     query.set('fq', visibilityQuery)

    //     history.push(location.pathname + "?" + query.toString());

    // }, [visibilityValues])

    return (
      <ToolkitProvider
        bootstrap4
        keyField="prodToken"
        data={data}
        columns={columns}
        >
        {props => (
            <React.Fragment>
                <Row className="mb-2">
                    <Col xs={'auto'}>
                    <Link to="/omni/catalog/product" className="btn btn-danger">
                        <i className="mdi mdi-plus-circle mr-2"></i>Add Product
                    </Link>
                    </Col>
                    <Col className="text-right form-inline justify-content-end">
                        <Button className="export-csv-btn mr-3 mb-0" color="primary" onClick={getCsv}
                        disabled={csvLoading}
                        >
                            {(!csvLoading) ? 'Export CSV' : <Spinner size="sm"/>}
                        </Button>
                        <UncontrolledButtonDropdown className="mr-2">
                            <DropdownToggle color="light" caret>
                                Visibility Filter: 
                                {(visibilityValues[1]) && 
                                <Badge color={'primary'} className="ml-1">
                                    listed
                                </Badge>}
                                {(visibilityValues[2]) && 
                                <Badge color={'primary'} className="ml-1">
                                    unlisted
                                </Badge>}
                                {(visibilityValues[3]) && 
                                <Badge color={'primary'} className="ml-1">
                                    archived
                                </Badge>}
                            </DropdownToggle>
                            <DropdownMenu>
                            <div style={{ width: '130px' }} className="mx-auto my-1" >
                                <CustomInput type="checkbox" id="1" name="1" label="Listed"
                                checked={visibilityValues['1']}
                                onChange={visibilityOnClick}
                                />
                                <DropdownItem divider />
                                <CustomInput type="checkbox" id="2" name="2" label="Unlisted" 
                                checked={visibilityValues['2']}
                                onChange={visibilityOnClick}
                                />
                                <DropdownItem divider />
                                <CustomInput type="checkbox" id="3" name="3" label="Archived" 
                                checked={visibilityValues['3']}
                                onChange={visibilityOnClick}
                                />
                            </div>
                                {/* <DropdownItem header>Header</DropdownItem>
                                <DropdownItem disabled>Action</DropdownItem>
                                <DropdownItem>Another Action</DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem>Another Action</DropdownItem> */}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                        <input className="form-control" placeholder='Search by product name' value={searchTerm} onChange={(e) => searchChange(e)}/>
                    </Col>
                </Row>

                <BootstrapTable
                    {...props.baseProps}
                    remote
                    bordered={false}
                    headerClasses="thead-light"
                    // defaultSorted={ defaultSorted }
                    pagination={ paginationFactory({ page, sizePerPage, totalSize, paginationTotalRenderer, showTotal }) }
                    wrapperClasses="table-responsive"
                    classes="product-table"
                    bordered={ false }
                    rowEvents={ rowEvents }
                    // selectRow={ selectRow }
                    rowClasses={ rowClasses }
                    hover
                    onTableChange={ onTableChange }
                    // noDataIndication={() => 'No results...'}
                />
            </React.Fragment>
        )}
    </ToolkitProvider>
  );
  }

const Products = () => {

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const history = useHistory();

    const { user } = useContext(UserContext);

    // const [selectedRows, setSelectedRows] = useState([]);
    const [products, setProducts] = useState([]);

    const [paginationValues, setPaginationValues] = useState({
        page: null,
        data: [],
        sizePerPage: null,
        sortOrder: null,
        sortField: null,
        totalSize: null
    })

    // Gets the pagination query params from the url and sets the correct data to be displayed in that pages table
    // useEffect(() => {
    //     const page = parseInt(query.get('page'));
    //     const sizePerPage = parseInt(query.get('sizePerPage'));
    //     const currentIndex = (page - 1) * sizePerPage;

    //     setPaginationValues({
    //         ...paginationValues,
    //         data: products ? products.slice(currentIndex, currentIndex + sizePerPage) : ''
    //     })
    // }, [products])

    // Set default pagination query params if none are existent
    // useEffect(() => {
    //     if (!location.search){
    //         query.set('page', 1);
    //         query.set('sizePerPage', 15);
    //         console.log('query', query)
    //         history.push(location.pathname + "?" + query.toString());
    //     }
    // }, [])

    const columns = [
        {
            dataField: '',
            text: 'Image',
            sort: false,
            formatter: (cell, row) => {
                if (row.prodImage.file)
                return (
                    // MAY HAVE TO REMOVE THIS SINCE IT CHNAGES THE BACKGROUND OF IMAGES WITH TRANSPARENT BACKGROUNDS
                <div style={{'width': '50px', 'height': '50px', 'overflow': 'hidden'}} className="rounded d-flex align-items-center bg-light">    
                    {fs1Url && <img src={`${fs1Url}/${row.prodImage.file}/50`} />}
                </div>
                )
                else
                return(
                    <div style={{'width': '50px', 'height': '50px', 'overflow': 'hidden'}} className="rounded d-flex align-items-center">    
                        <img src='http://dummyimage.com/50' />
                    </div> 
                )
            }
        },
        {
            dataField: 'prodName',
            text: 'Item Name',
            sort: true,
            style: { verticalAlign: 'middle', width: '40%', maxWidth: '50%' }
        },
        {
            dataField: 'inventoryCount',
            text: 'Inventory',
            sort: false,
            formatter: (cell, row) => {
                return (
                <div><strong>{(row?.inventoryCount)?row?.inventoryCount:0}</strong> in stock from <strong>{row.totalVariants}</strong> variants</div>
            )},
            style: { verticalAlign: 'middle' }
        },
        {
            dataField: 'prodBrand',
            text: 'Brand',
            sort: true,
            style: { verticalAlign: 'middle' },
            formatter: (cell,row) => {
                    return row?.brandName//brandList.find(brand => brand.brandToken === cell)?.brandName
            }
        },
        {
            dataField: 'prodVisible',
            text: 'Visibility',
            sort: false,
            style: { verticalAlign: 'middle' },
            formatter: (cell) => {
                if (cell === 1){
                    return <span className="d-flex align-items-center"><Icon icon={eyeOutline} color="#dedede" width="22" height="22" className="mr-1" /> Listed</span>
                } else if (cell === 2){
                    return <span className="d-flex align-items-center"><Icon icon={eyeOffOutline} color="#dedede" width="22" height="22" className="mr-1" /> Unlisted</span>
                } else if (cell === 3){
                    return <span className="d-flex align-items-center"><Icon icon={archiveOutline} color="#dedede" width="22" height="22" className="mr-1" /> Archived</span>
                }
            }
        }
    ];

    // const selectRow = {
    //     mode: 'checkbox',
    //     style: { background: '#5bc0de' },
    //     clickToSelect: false,
    //     onSelectAll: (isSelect, rows, e) => {
    //         if (isSelect){
    //             setSelectedRows(rows.map(e => e.brandToken))
    //         } else {
    //             setSelectedRows([])
    //         }
    //       },
    //     onSelect: (row, isSelect, rowIndex, e) => {
    //         // console.log(row, isSelect, rowIndex);
    //         if (isSelect){
    //             setSelectedRows([...selectedRows, row.brandToken])
    //         } else {
    //             // this.setState({ selectedRows: this.state.selectedRows.filter(e => e !== row.brandToken) });
    //             setSelectedRows(selectedRows.filter(e => e !== row.brandToken))
    //         }
    //       },
    //     selectionRenderer: ({ checked, disabled }) => {
    //         return (
    //             <div className="custom-control custom-checkbox">
    //                 <input
    //                     type="checkbox"
    //                     className="custom-control-input row-checkbox"
    //                     checked={checked}
    //                     disabled={disabled}
    //                     onChange={() => {}}
    //                 />
    //                 <label
    //                     className="custom-control-label row-checkbox-label"
    //                     onClick={e => {
    //                         e.preventDefault();
    //                     }}></label>
    //             </div>
    //         );
    //     },
    //     selectionHeaderRenderer: ({ indeterminate, ...rest }) => {
    //         return (
    //             <div className="custom-control custom-checkbox select-header">
    //                 <input
    //                     type="checkbox"
    //                     className="custom-control-input"
    //                     id="customCheck1"
    //                     ref={input => {
    //                         if (input) input.indeterminate = indeterminate;
    //                     }}
    //                     {...rest}
    //                     onChange={() => {}}
    //                 />
    //                 <label
    //                     className="custom-control-label"
    //                     htmlFor="customCheck1"
    //                     onClick={e => {
    //                         e.preventDefault();
    //                     }}></label>
    //             </div>
    //         );
    //     },
    // };


    // function getFS1Image(prodImage){
    //     return `https://fs1.cloudsnob.com/static/${user.company.fs1GetToken}/${prodImage}/50`
    // }

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Showing <strong>{from}</strong> to <strong>{to}</strong> of <strong>{size}</strong> Results
        </span>
    );

    const [tableChangeType, setTableChangeType] = useState('');

    const [brandList, setBrandList] = useState('');

    useEffect(() => {
        // OmniApi.get('/brands')
        // .then(res => {
        //     setBrandList(res.data)
        // })
    }, [])

    // When pagination query params change, update the pagination values
    useEffect(() => {
        if (!location.search){
            query.set('page', 1);
            query.set('sizePerPage', 15);
            query.set('sortOrder', 'asc');
            query.set('sortField', 'prodName');
            query.set('searchTerm', '');
            history.replace(location.pathname + "?" + query.toString());
        }

        const page = parseInt(query.get('page'));
        const sizePerPage = parseInt(query.get('sizePerPage'));
        const sortOrder = query.get('sortOrder');
        const sortField = query.get('sortField');
        const searchQuery = query.get('searchTerm');
        const visibilityFilter = query.get('fq');
        
        OmniApi.get(`/products${commonHelper.solrUrlParams(page, sizePerPage, sortOrder, sortField, searchQuery, visibilityFilter)}`)
        .then(res => {
            commonHelper.goOnToOfThePage();
            setProducts(res.data.docs);
            setPaginationValues({
                page,
                sizePerPage,
                sortOrder,
                sortField,
                data: res.data.docs,
                totalSize: res.data.numFound
            })
        })

    }, [location.search])

    const [fs1Url, setFs1Url] = useState(null);

    useEffect(() => {
        if (user){
            
            
            const fs1UrlData = commonHelper.returnFs1StaticImagesUrl(user?.company?.fs1GetToken,null);
            setFs1Url(fs1UrlData);
        }
        
    }, [user])

    const solrUrlParams = ( page, sizePerPage, sortOrder, sortField, searchQuery, visibilityFilter) => {
        let startIndex = (page - 1) * sizePerPage;

        const getQueryForBackend = (query) => {
            return `catchAll:"*${query
                .split(' ')
                .map((e) =>
                  e
                    .replace(/^[^A-Za-z0-9]|[^A-Za-z0-9]$/g, '')
                    .replace(/^\.|\.$/g, '')
                    .replace(/[^A-Za-z0-9 \.]/g, ' ')
                    .trim()
                    .replace(/ +/g, ' ')
                )
                .filter((e) => e !== '')
                .join('* *')}*"~2`;
        }

        return `/solr?start=${startIndex}&sort=${sortField}&q=${getQueryForBackend(searchQuery)}&rows=${sizePerPage}&sortOrder=${sortOrder}${visibilityFilter ? `&fq=${visibilityFilter}` : ''}`.toString();
    }

    const onTableChange = (type, { sortField, sortOrder, page, sizePerPage}) => {
        setTableChangeType(type);
        if (type === 'pagination'){
            query.set('page', page);
            query.set('sizePerPage', sizePerPage);
        }
        if (type === 'sort'){
            query.set('sortField', sortField);
            query.set('sortOrder', sortOrder);
        }
        history.push(location.pathname + "?" + query.toString());
    }

    const { data, sizePerPage, page, totalSize } = paginationValues;

    if(user && products){
        return(
            <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Products | {process.env.REACT_APP_HEADER_TITLE}</title>
            </Helmet>
            <h2 className="page-title">
                Products
            </h2>
            <Card className="mt-2">
                <CardBody>
                <RemotePagination
                    data={ data }
                    page={ page }
                    columns={columns}
                    sizePerPage={ sizePerPage }
                    totalSize={ totalSize }
                    onTableChange={ onTableChange }
                    paginationTotalRenderer={customTotal}
                    showTotal={true}
                    user={user}
                />
                    </CardBody>
                    </Card>
                </>
        )
    } else {
        return(
            <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
            <div>
            <h4>Loading product table...</h4>
                <div className="bouncing-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                </div>
            </div>
        )
    }
}

export default Products;