import React, { useEffect, useContext, useState, useCallback } from 'react';
import { Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledTooltip, Card, CardBody, FormGroup, Label, Input, Button, CardHeader, ListGroup, ListGroupItem, Table, CardFooter, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupText, ButtonGroup, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, InputGroupAddon, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import { Helmet } from 'react-helmet';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import classnames from 'classnames';
import Icon from '@iconify/react';
import account from '@iconify/icons-mdi/account';
import cartIcon from '@iconify/icons-mdi/cart';
import cash from '@iconify/icons-mdi/cash';
import moment from 'moment';
import Select from 'react-select';
import { CommonApiRequest } from '../services/commonApiRequest';
import { commonHelper } from '../helpers/commonHelper';
import MaskedInput from 'react-text-mask';
import { UserContext } from '../custom-contexts/UserContext';
import closeBox from '@iconify/icons-mdi/close-box';
import { toast } from 'react-toastify';
import debounce from 'lodash.debounce';
import AsyncSelect from 'react-select/async';
import { Tooltip } from 'reactstrap';
import { confirm } from './Confirm';
import { Typeahead } from 'react-bootstrap-typeahead';
import { StateList } from '../data/StateList';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import circleEditOutline from '@iconify/icons-mdi/circle-edit-outline';
import trashCanOutline from '@iconify/icons-mdi/trash-can-outline';
import { MESSAGES, RESPONSESTATUSES } from '../constants';
const Sale = props => {
    const [customerData, setCustomerData] = useState([]);
    const [productData, setProdctData] = useState([]);
    const [enableNewCustomer, setEnableNewCustomer] = useState(false);
    const [customerInfo, setCustomerInfo] = useState({});
    const [selectedCustomer, setSelectedCustomer] = useState({});
    const [currentSelectedProduct, setCurrentSelectedProduct] = useState({});
    const [selectedProductData, setSelectedProductData] = useState([]);
    const [fs1Url, setFs1Url] = useState(null);
    const { user } = useContext(UserContext);
    const [subTotalAmount, setSubTotalAmount] = useState(0.00);
    const [selectProductModal, setSelectProductModal] = useState(false);
    const [customerModal, setCustomerModal] = useState(false);
    const [discountModal, setDiscountModal] = useState(false);
    const [shippingChargeModal, setShippingChargeModal] = useState(false);
    const [discountData, setDiscountData] = useState();
    const [searchTerm, setSearchTerm] = useState("");
    const [isProductLoading, setIsProductLoading] = useState(false);
    const [isCustomProduct, setIsCustomProduct] = useState(false);
    const [tooltipForDiscount, setTooltipForDiscount] = useState(false);
    const toggleToolTipForDiscount = () => setTooltipForDiscount(!tooltipForDiscount);
    const [tooltipForShipping, setTooltipForShipping] = useState(false);
    const toggleTooltipForShipping = () => setTooltipForShipping(!tooltipForShipping);
    const [shippingData, setShippingData] = useState();
    const [shippingAddressModal, setShippingAddressModal] = useState(false);
    const [shippingAddressInfomation, setShippingAddressInfomation] = useState({});
    const [markAsPaidModal, setMarkAsPaidModal] = useState(false);
    const [isButtonDisable, setIsButtonDisable] = useState(false);
    const [customerAddress, setCustomerAddress] = useState([]);
    const [selectedCustomerAddress, setSelectedCustomerAddress] = useState({});
    const [draftData, setDraftData] = useState({});
    const [payViaCreditCradModal, setPayViaCreditCradModal] = useState(false);
    const [payViaGiftCardModal, setPayViaGiftCardModal] = useState(false);
    const [paymentInfoData, setPaymentInfoData] = useState();
    const [useShippingAddressAsBillingAddres, setUseShippingAddressAsBillingAddres] = useState(false);
    const [billingAddressData, setBillingAddressData] = useState({});
    const history = useHistory();
    const [editCustomerInformation,setEditCustomerInformation] = useState(false);
    const [editCustomerAddressInformation,setEditCustomerAddressInformation] = useState(false);
    const [tempSelectedProduct, setTempSelectedProduct] = useState([]);
    const [draftNumbner, setDraftNumbner] = useState('');
    const toggleProductModal = () => {
        if (!selectProductModal) {
            setSearchTerm("");
            getProductData();
        }
        setSelectProductModal(!selectProductModal)
    }
    const toggleCustomerModal = () => {
        setCustomerModal(!customerModal)
    }
    const toggleDiscountModal = () => {
        setDiscountModal(!discountModal)
    }
    const toggleShippingChargeModal = () => {
        setShippingChargeModal(!shippingChargeModal)
    }
    const toggleShippingAddressModal = () => {
        setShippingAddressModal(!shippingAddressModal)
    }
    const toggleMarkAsPaidModal = () => {
        setMarkAsPaidModal(!markAsPaidModal)
    }
    const togglePayViaCreditCradModal = () => {
        setPayViaCreditCradModal(!payViaCreditCradModal)
    }
    const togglePayGiftCradModal = () => {
        setPayViaGiftCardModal(!payViaGiftCardModal)
    }
    useEffect(() => {
        if (props?.match?.params?.token) {
            CommonApiRequest.getDraftOrderByToken(props?.match?.params?.token).then((response) => {
                console.log(response);
                setDraftData(response);
                setDraftNumbner(response?.draftNumber);
                if (response?.products) {
                    setSelectedProductData(response?.products);
                    setDiscountData(response?.discount);
                    if (response?.customer?.value) {
                        setEditCustomerInformation(true);
                        setSelectedCustomer(response?.customer);
                        setCustomerInfo(response?.customer?.value);
                        setShippingData(response?.shipping);
                        if (response?.customer?.value) {
                            getCustomerAddress(response?.customer?.value?.customerToken);
                            setTimeout(() => {
                                if (response?.address) {
                                    setEditCustomerAddressInformation(true);
                                    setSelectedCustomerAddress(response?.address)
                                }
                            }, 500);
                        }
                    }
                    setTimeout(() => {
                        setSubTotalAmount(commonHelper.calculateCartSubtotal(response?.products));
                    }, 500);
                }
            })
        }
    }, [props.match.params])
    useEffect(() => {
        if (user !== null && user !== undefined) {
            const fs1UrlData = commonHelper.returnFs1StaticImagesUrl(user?.company?.fs1GetToken, null);
            setFs1Url(fs1UrlData);
        }
    }, [user])
    useEffect(() => {
        getProductData()
        getCustomerData();
        
    }, [])

    const getCustomerData = (term = '') => {
        const dataNew = [];
        CommonApiRequest.getCustomerList(`${commonHelper.solrUrlParams(1, 100, 'desc', 'dateCreated', term)}`).then(async (res) => {
            if (res?.docs) {
                res.docs.map((data) => {
                    dataNew.push({ label: data?.customerFirstName + " " + data?.customerLastName + " - " + data?.customerEmail, value: data });
                    setCustomerData(dataNew);
                })
            }
        });
    }
    const getProductData = (term = '') => {
        let buildParams = commonHelper.solrUrlParams(1, 20, 'asc', 'prodName', term);
        buildParams = buildParams + '&search_for=pos';
        CommonApiRequest.getProductList(`${buildParams}`).then(async (res) => {
            setIsProductLoading(false)
            setProdctData(res.docs);
        });
    }

    const selectProduct = (data) => {
        const dataNew = [...selectedProductData];
        if (dataNew.find((item) => item.value.prodToken === data.value.prodToken)) {
            const dataIndex = dataNew.findIndex((item) => item.token === data.value.prodToken);
            dataNew[dataIndex].qyt = dataNew[dataIndex].qyt + 1;
            setSelectedProductData(dataNew);
            setCurrentSelectedProduct("");
            calCulateSubTotal(dataNew)
        } else {
            dataNew.push({
                label: data?.label,
                value: data?.value,
                qyt: 1,
                price: 10,
                token: data?.value?.prodToken,
            });
            setSelectedProductData(dataNew);
            setCurrentSelectedProduct("");
            calCulateSubTotal(dataNew)
        }

    }
    const calCulateSubTotal = (data) => {
        let dataNew = 0;
        data.map((item) => {
            dataNew = dataNew + (item?.price * item?.qyt);
            setSubTotalAmount(dataNew);
        });
    }
    const newCustomerCallBack = (data) => {
        const dataNew = [...customerData];
        dataNew.push({ label: data?.customerFirstName + " " + data?.customerLastName, value: data });
        setCustomerData(dataNew);
        setCustomerInfo(data);
        setSelectedCustomer({ label: data?.customerFirstName + " " + data?.customerLastName, value: data });
        toggleCustomerModal();
    }
    const discountDataCallback = (data) => {
        setDiscountData(data);
        toggleDiscountModal();
    }
    const getFS1Image = (img, size) => {
        return commonHelper.returnFs1ImagesUrl(`${user?.company?.fs1GetToken}/${img}/${size}`);
    }
    const selectProducts = (evnt, data, variant = null) => {
        if (evnt?.target?.dataset?.type === 'variant') {
            const addNewProduct = [...selectedProductData];
            variant.prodToken = data.prodToken;
            variant.prodName = data.prodName?.[0];
            variant.prodImage = data.prodImage;
            variant.brandName = data.brandName
            var getProdCollection;
            if (evnt?.target?.checked) {
                getProdCollection = commonHelper.returnCartCollectionOfProduct(variant, 'variant');
                getProdCollection.qyt = 1
                addNewProduct.push(getProdCollection);
                setSelectedProductData(addNewProduct);
            } else {
                getProdCollection = commonHelper.removeItemFromCart(addNewProduct, variant?.variantToken);
                setSelectedProductData(getProdCollection);
            }
            setTimeout(() => {
                setSubTotalAmount(commonHelper.calculateCartSubtotal(addNewProduct));
            }, 500);
        } else if (evnt?.target?.dataset?.type === 'product') {
            const addNewProduct = [...selectedProductData];
            if (data?.variantInfo?.length > 0) {
                data.variantInfo.map((item) => {
                    item.prodToken = data.prodToken;
                    item.prodImage = data.prodImage;
                    item.brandName = data.brandName;
                    item.prodName = data.prodName?.[0];
                    if (evnt?.target?.checked) {
                        const getProdCollection = commonHelper.returnCartCollectionOfProduct(item, 'variant');
                        getProdCollection.qyt = 1
                        addNewProduct.push(getProdCollection);
                        setSelectedProductData(addNewProduct);
                    } else {
                        getProdCollection = commonHelper.removeItemFromCart(addNewProduct, item?.variantToken);
                        setSelectedProductData(getProdCollection);
                    }
                });
            }
            setTimeout(() => {
                setSubTotalAmount(commonHelper.calculateCartSubtotal(addNewProduct));
            }, 500);
        }
    }
    const removeProduct = (token) => {
        let addNewProduct = [...selectedProductData];
        const getProdCollection = commonHelper.removeItemFromCart(addNewProduct, token);
        setSelectedProductData(getProdCollection);
        setTimeout(() => {
            setSubTotalAmount(commonHelper.calculateCartSubtotal(getProdCollection));
        }, 500)
    }
    const searchingCustomer = useCallback(debounce((e) => {
        getCustomerData(e);
    }, 500), [])
    const serachData = useCallback(debounce((e) => {
        setIsProductLoading(true)
        getProductData(e)
    }, 900), []);
    const loadOptions = (
        inputValue,
        callback
    ) => {
        searchingCustomer(inputValue);
        setTimeout(() => {
            callback(customerData)
        }, 2000)
    };
    const shippingCallbackForm = (data) => {
        setShippingData(data);
        toggleShippingChargeModal();
    }
    const updateQyt = async (token, qyt) => {
        if (parseInt(qyt) > 0) {
            const addNewProduct = [...selectedProductData];
            const getProdCollection = commonHelper.updateItemFromCart(addNewProduct, token, qyt);
            setSelectedProductData(getProdCollection);
            setSubTotalAmount(commonHelper.calculateCartSubtotal(addNewProduct));
        } else {
            if (parseInt(qyt) <= 0) {
                if (await confirm("Are your sure you want to remove this product from cart", "Removing item", "Remove")) {
                    removeProduct(token);
                }
            }
        }
    }
    const completPayment = (data) => {
       if (data === "cash") {
            //markAsPaidOrder();
            toggleMarkAsPaidModal();
        } else if (data === "draft") {
            //markAsPaidOrder();
            if (selectedProductData?.length > 0) {
                setIsButtonDisable(true)
                saveAsDraft();
            } else {
                alert('Please add at-least one product in your cart')
            }
        } else if (data === "draft_update") {
            //markAsPaidOrder();
            if (selectedProductData?.length > 0) {
                setIsButtonDisable(true)
                updateDraft();
            } else {
                alert('Please add at-least one product in your cart')
            }
        } else if (data === "card") {
            togglePayViaCreditCradModal();
        } else if (data === "gift_card") {
            togglePayGiftCradModal();
        } else if (data === "pay_later") {
            paymentCallbackData({type:'pay_later',paymentInfo:{}});
        }
    }
    const markAsPaidOrder = () => {
        const orderSnippet = {
            product: selectedProductData,
            customer: selectedCustomer,
            shipping: shippingAddressInfomation,
            paymentType: 'paid',
            subTotal: subTotalAmount,
            shippingData: shippingData,
            discount: discountData,
            total: commonHelper.calCulateTotal(subTotalAmount, shippingData?.shippingValue, discountData, 0)
        }
    }
    const saveAsDraft = () => {
        const address = selectedCustomerAddress;
        if (selectedCustomerAddress) {
            address.addressToken = selectedCustomerAddress?.value?.addressToken
        }
        const orderSnippet = {
            products: selectedProductData,
            customer: selectedCustomer,
            shipping: shippingAddressInfomation,
            paymentType: 'paid',
            subtotal: subTotalAmount,
            shipping: shippingData,
            discount: discountData,
            total: commonHelper.calCulateTotal(subTotalAmount, shippingData?.shippingValue, discountData, 0),
            address: address
        }
        let savingDraftToaster = toast.loading('Saving order as draft...', {
            theme: 'light',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
        CommonApiRequest.saveOrderAsDraft(orderSnippet).then((response) => {
            setIsButtonDisable(false)
            toast.update(savingDraftToaster,
                {
                    render: "Draft Saved",
                    type: "success",
                    isLoading: false,
                    autoClose: null,
                    hideProgressBar: null,
                    closeOnClick: null,
                });
            history.push("/omni/catalog/order/draft/edit/"+response?.id);
        }).catch((error) => {
            setIsButtonDisable(false)
            toast.update(savingDraftToaster,
                {
                    render: error?.response?.data?.error?.message,
                    type: "error",
                    isLoading: false,
                    autoClose: null,
                    hideProgressBar: null,
                    closeOnClick: null,
                });
        });
    }
    const updateDraft = () => {
        const address = selectedCustomerAddress;
        if (selectedCustomerAddress) {
            address.addressToken = selectedCustomerAddress?.value?.addressToken
        }
        const orderSnippet = {
            products: selectedProductData,
            customer: selectedCustomer,
            shipping: shippingAddressInfomation,
            paymentType: 'paid',
            subtotal: subTotalAmount,
            shipping: shippingData,
            discount: discountData,
            total: commonHelper.calCulateTotal(subTotalAmount, shippingData?.shippingValue, discountData, 0),
            draftOrderToken: draftData?.draftOrderToken,
            address: address
        }
        let savingDraftToaster = toast.loading('Saving order as draft...', {
            theme: 'light',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
        CommonApiRequest.updateOrderAsDraft(orderSnippet).then((response) => {
            setIsButtonDisable(false)
            toast.update(savingDraftToaster,
                {
                    render: "order updated!",
                    type: "success",
                    isLoading: false,
                    autoClose: null,
                    hideProgressBar: null,
                    closeOnClick: null,
                });
        }).catch((error) => {
            setIsButtonDisable(false)
            toast.update(savingDraftToaster,
                {
                    render: error?.response?.data?.error?.message,
                    type: "error",
                    isLoading: false,
                    autoClose: null,
                    hideProgressBar: null,
                    closeOnClick: null,
                });
        });
    }
    const getCustomerAddress = async (token) => {
        CommonApiRequest.getCustomerAddress(token).then((response) => {
            const dataNew = [{label:'Select Address',value:{}}];
            if (response?.addresses) {
                response.addresses.map((data) => {
                    dataNew.push({ label: data?.addressFirstName + " " + data?.addressLastName + " - " + data?.address, value: data });
                    setCustomerAddress(dataNew);
                })
            }
        });
    }
    const setShippingAddresCallback = (data) => {
        const newCustomerData = customerAddress;
        newCustomerData.push({ label: data?.addressFirstName + " " + data?.addressLastName + " - " + data?.address, value: data });
        setCustomerAddress(newCustomerData);
        setSelectedCustomerAddress({ label: data?.addressFirstName + " " + data?.addressLastName + " - " + data?.address, value: data });
        toggleShippingAddressModal()
    }
    const paymentCallbackData=(data)=>{
        const paymentData = {
            type:data?.type,
            paymentInfo:data?.paymentInfo
        };
        setPaymentInfoData(paymentData);
        const address = selectedCustomerAddress;
        if (selectedCustomerAddress) {
            address.addressToken = selectedCustomerAddress?.value?.addressToken
        }
        
        const orderSnippet = {
            products: selectedProductData,
            customer: selectedCustomer,
            shippingAddress: address,
            paymentType: paymentData?.type,
            subtotal: subTotalAmount,
            shipping: shippingData,
            discount: discountData,
            total: commonHelper.calCulateTotal(subTotalAmount, shippingData?.shippingValue, discountData, 0),
            address: address,
            payment:paymentData
        }
        if(draftData?.draftOrderToken){
            orderSnippet.draftToken = draftData?.draftOrderToken;
        }
        completeTheOrder(orderSnippet)
    }
    const tempSelectProduct=(evnt, data, variant = null)=>{
        if (evnt?.target?.dataset?.type === 'variant') {
            const addNewProduct = [...tempSelectedProduct];
            variant.prodToken = data.prodToken;
            variant.prodName = data.prodName?.[0];
            variant.prodImage = data.prodImage;
            variant.brandName = data.brandName
            var getProdCollection;
            if (evnt?.target?.checked) {
                getProdCollection = commonHelper.returnCartCollectionOfProduct(variant, 'variant');
                getProdCollection.qyt = 1
                addNewProduct.push(getProdCollection);
                setTempSelectedProduct(addNewProduct);
            } else {
                getProdCollection = commonHelper.removeItemFromCart(addNewProduct, variant?.variantToken);
                setTempSelectedProduct(getProdCollection);
            }
        } else if (evnt?.target?.dataset?.type === 'product') {
            const addNewProduct = [...tempSelectedProduct];
            if (data?.variantInfo?.length > 0) {
                data.variantInfo.map((item) => {
                    item.prodToken = data.prodToken;
                    item.prodImage = data.prodImage;
                    item.brandName = data.brandName;
                    item.prodName = data.prodName?.[0];
                    if (evnt?.target?.checked) {
                        const getProdCollection = commonHelper.returnCartCollectionOfProduct(item, 'variant');
                        getProdCollection.qyt = 1
                        addNewProduct.push(getProdCollection);
                        setTempSelectedProduct(addNewProduct);
                    } else {
                        getProdCollection = commonHelper.removeItemFromCart(addNewProduct, item?.variantToken);
                        setTempSelectedProduct(getProdCollection);
                    }
                });
            }
        }
    }
    const addProductToCart=()=>{
        const addNewProduct = [...tempSelectedProduct];
        setSelectedProductData(addNewProduct);
        setTimeout(() => {
            setSubTotalAmount(commonHelper.calculateCartSubtotal(addNewProduct));
            toggleProductModal();
        }, 100);
    }
    const completeTheOrder=(data)=>{
        let savingOrder = toast.loading(MESSAGES.ORDERCREATING, {
            theme: 'light',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
        CommonApiRequest.createNewOrder(data).then((response)=>{
            console.log(response);
            if(response?.status===RESPONSESTATUSES.success){
                toast.update(savingOrder,
                    {
                        render: MESSAGES.ORDERSAVED,
                        type: "success",
                        isLoading: false,
                        autoClose: null,
                        hideProgressBar: null,
                        closeOnClick: null,
                    });
                history.push("/omni/catalog/order/"+response?.data?.orderToken);
            }
        }).catch((error)=>{
            toast.update(savingOrder,
                {
                    render: error?.response?.data?.error?.message,
                    type: "error",
                    isLoading: false,
                    autoClose: null,
                    hideProgressBar: null,
                    closeOnClick: null,
                });
        })
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>New Sale | {process.env.REACT_APP_HEADER_TITLE}</title>
            </Helmet>
            <Row>
                <Col className="d-flex align-items-center justify-content-between mt-1">
                    <h2>{(props?.match?.params?.token)?'Draft #'+draftNumbner:'New Order'}</h2>
                </Col>
            </Row>
            <hr className="mt-1" />
            <Col></Col>
            <Row>
                <Col md={8}>
                    <Row>
                        <Col>
                            <Card className={classnames('widget-flat',)}>
                                <CardHeader className="d-flex justify-content-between">
                                    <h4 className="mb-2 d-flex align-items-center">Add Products</h4>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col >
                                            <FormGroup>
                                                <Label for="name">Search Product</Label>
                                                <Input placeholder='Search product' onClick={() => { 
                                                    if(selectedProductData?.length <= 0){
                                                        setTempSelectedProduct([])
                                                    }
                                                    toggleProductModal() 
                                                }} value={""} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h4>Selected Products</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <ListGroup flush>
                                                <Table striped>
                                                    <tbody>
                                                        {selectedProductData?.length > 0 && selectedProductData?.map((item, index) => {
                                                            return <tr key={index}>
                                                                <td>
                                                                    <div className='d-inline-flex pt-1'>
                                                                        <div style={{ 'width': '30px', 'height': '30px', 'overflow': 'hidden' }} className="rounded d-flex align-items-center bg-light">
                                                                            {fs1Url && <img src={`${fs1Url}/${item?.prodImage?.file}/50`} alt=""/>}
                                                                        </div>
                                                                        <div className='ml-2'>
                                                                            {item?.prodName} - {item?.variantName}
                                                                            <div className='prod-name'>
                                                                                {item?.variantOptions?.length > 0 &&
                                                                                    <small>
                                                                                        {item?.variantOptions?.map((options) => {
                                                                                            return options.optionName + ": " + options.optionValue + " , "
                                                                                        })}
                                                                                    </small>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                {/* <td>
                                                                    <Input style={{ 'width': '100px' }} type='number' value={item?.qyt} onChange={(e)=>{
                                                                        updateQyt(item.variantToken,e.target.value)
                                                                    }}></Input>
                                                                </td>
                                                                <td>
                                                                    <div className='pt-1'>${item?.price}</div>
                                                                </td> */}
                                                                <td>
                                                                    <Row>
                                                                        <Col md={1} sm={12}></Col>
                                                                        <Col md={3} sm={12}><div className='pt-1 text-center'>${item?.price}</div></Col>
                                                                        <Col md={3} sm={12}>
                                                                            <Input type='number' value={item?.qyt} onChange={(e) => {
                                                                                updateQyt(item.variantToken, e.target.value)
                                                                            }} />
                                                                        </Col>
                                                                        <Col md={3} sm={12}>
                                                                            <div className='pt-1 text-center'>${(item?.price*item?.qyt)}</div>
                                                                        </Col>
                                                                        <Col md={2} sm={12}>
                                                                            <Button color='secondary' size='small' className='p-1'>
                                                                                <Icon  className='cursor-pointer' icon={trashCanOutline} color="white" width="20" height="20" onClick={() => { removeProduct(item?.variantToken) }} />
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>

                                                                </td>
                                                            </tr>
                                                        })
                                                        }
                                                    </tbody>
                                                </Table>
                                                {selectedProductData?.length <= 0 &&
                                                    <ListGroupItem className='p-0'>
                                                        <div className="h-100 w-100 bg-light py-2 rounded d-flex justify-content-center">
                                                            <h5>No Product Selected</h5>
                                                        </div>
                                                    </ListGroupItem>
                                                }
                                            </ListGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card className={classnames('widget-flat',)}>
                                <CardHeader className="d-flex justify-content-between">
                                    <h4 className="mb-2 d-flex align-items-center">Payment</h4>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-responsive">
                                        <table className="table mb-0">
                                            <tbody>
                                                <tr>
                                                    <td>Subtotal </td>
                                                    <td></td>
                                                    <td className="text-right">${(subTotalAmount > 0) ? subTotalAmount.toFixed(2) : '0.00'}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {subTotalAmount > 0 &&
                                                            <span className='border-bottom-payment text-primary' onClick={() => toggleShippingChargeModal()}>{(shippingData?.shippingName) ? 'Edit Shipping Charge' : 'Add Shipping Charge'} </span>
                                                        }
                                                        {subTotalAmount <= 0 &&
                                                            <>
                                                                <span className='border-bottom-payment text-primary text-muted' id='TooltipForShipping'>Add Shipping Charge </span>
                                                                <Tooltip
                                                                    isOpen={tooltipForShipping}
                                                                    target="TooltipForShipping"
                                                                    toggle={toggleTooltipForShipping}
                                                                >
                                                                    Can not add shipping charges without any product
                                                                </Tooltip>
                                                            </>
                                                        }
                                                    </td>
                                                    <td>
                                                        {(shippingData?.shippingName) &&
                                                            <span className="float-left badge badge-secondary-lighten">
                                                                {(shippingData?.shippingName) ? shippingData?.shippingName : ''}
                                                            </span>
                                                        }
                                                    </td>
                                                    <td className="text-right">{(shippingData?.shippingValue) ? commonHelper.returnWithCurrency((shippingData?.shippingValue).toFixed(2)) : commonHelper.returnWithCurrency('0.00')}</td>
                                                </tr>
                                                <tr>
                                                    <td><span>Estimated Tax  </span></td>
                                                    <td>
                                                        Not calculated
                                                    </td>
                                                    <td className="text-right">{commonHelper.returnWithCurrency('0.00')}</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {subTotalAmount > 0 &&
                                                            <span className='border-bottom-payment text-primary' onClick={() => toggleDiscountModal()}>{(discountData?.reason) ? 'Edit discount' : 'Add discount'} </span>
                                                        }
                                                        {subTotalAmount <= 0 &&
                                                            <>
                                                                <span id='ToolTopForDiscount' className='border-bottom-payment text-muted text-primary' >{(discountData?.reason) ? 'Edit discount' : 'Add discount'} </span>
                                                                <Tooltip
                                                                    isOpen={tooltipForDiscount}
                                                                    target="ToolTopForDiscount"
                                                                    toggle={toggleToolTipForDiscount}
                                                                >
                                                                    Can not add discount without any product
                                                                </Tooltip>
                                                            </>
                                                        }
                                                    </td>
                                                    <td>
                                                        {(discountData?.value) &&
                                                            <span className="float-left badge badge-secondary-lighten">
                                                                {(discountData?.reason) ? discountData?.reason : ''}
                                                            </span>
                                                        }
                                                    </td>
                                                    <td className="text-right">
                                                        {
                                                            (discountData?.value) ? "- " + commonHelper.calCulateDiscountAmount(discountData, subTotalAmount) : commonHelper.returnWithCurrency('0.00')
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Total </th>
                                                    <td></td>
                                                    <th className="text-right">
                                                        ${commonHelper.calCulateTotal(subTotalAmount, shippingData?.shippingValue, discountData, 0)}
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                                <CardFooter>
                                    <Row>
                                        <Col className='text-right'>
                                            {props?.match?.params?.token &&
                                                <Button disabled={isButtonDisable} color='primary' onClick={() => { completPayment('draft_update') }}>Update draft</Button>
                                            }
                                            {!props?.match?.params?.token &&
                                                <Button disabled={(isButtonDisable || selectedProductData?.length <= 0)?true:false} color='primary' onClick={() => { completPayment('draft') }}>Save as draft</Button>
                                            }
                                            <ButtonGroup color='primary' className='ml-3 mr-0' disabled={(isButtonDisable || selectedProductData?.length <= 0)?true:false}>
                                                <UncontrolledDropdown disabled={isButtonDisable}>
                                                    <DropdownToggle caret color='primary' disabled={(isButtonDisable || selectedProductData?.length <= 0)?true:false}>
                                                        Collect payment
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem header disabled={isButtonDisable}>
                                                            Payment Type
                                                        </DropdownItem>
                                                        <DropdownItem divider />
                                                        <DropdownItem className='mt-1' onClick={() => { completPayment('card') }} disabled={isButtonDisable}>
                                                            Credit card
                                                        </DropdownItem>
                                                        <DropdownItem className='mt-1' onClick={() => { completPayment('cash') }} disabled={isButtonDisable}>
                                                            Cash payment
                                                        </DropdownItem>
                                                        <DropdownItem className='mt-1' onClick={() => { completPayment('gift_card') }} disabled={isButtonDisable}>
                                                            Gift card
                                                        </DropdownItem>
                                                        <DropdownItem className='mt-1' onClick={() => { completPayment('pay_later') }} disabled={isButtonDisable}>
                                                            Pay later
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </ButtonGroup>

                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>

                </Col>
                <Col md={4}>
                {!editCustomerInformation &&
                    <Row>
                        <Col>
                            <Card className={classnames('',)}>
                                <CardHeader><h4>Customer </h4></CardHeader>
                                <CardBody className='pt-1'>
                                    <Row>
                                        <Col md={7}>
                                            <FormGroup>
                                                <Label for="name"></Label>
                                                <AsyncSelect
                                                    options={customerData}
                                                    placeholder={"Search customer"}
                                                    value={(selectedCustomer?.label) ? selectedCustomer : null}
                                                    onChange={(e) => {
                                                        setCustomerInfo(e?.value);
                                                        setSelectedCustomer(e);
                                                        getCustomerAddress(e?.value?.customerToken);
                                                        setEditCustomerInformation(true);
                                                    }} zIndex={99}
                                                    callback={(e) => {
                                                    }}
                                                    loadOptions={loadOptions}
                                                    defaultOptions={customerData}
                                                    className="mt-1"
                                                ></AsyncSelect>
                                            </FormGroup>
                                        </Col>
                                        <Col md={5}>
                                            <FormGroup className='mb-1'>
                                                <Label for="name"></Label>
                                            </FormGroup>
                                            <Label for="name " className='mr-2'> Or </Label>
                                            <Button color='primary' onClick={() => {
                                                setCustomerInfo({});
                                                toggleCustomerModal()
                                            }}>Create new</Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                }
                    <Row>
                        <Col>
                            {customerInfo?.customerFirstName && editCustomerInformation &&
                                <CustomerInfo customerInfo={customerInfo} editCallBack={()=>{
                                    setEditCustomerInformation(false)
                                }}></CustomerInfo>
                            }
                        </Col>
                    </Row>
                    
                    <Row>
                        {!editCustomerAddressInformation &&
                            <Col sm={12} lg={12} md={12}>
                                <Card className={classnames('',)}>
                                    <CardHeader><h4>Shipping Information</h4></CardHeader>
                                    <CardBody className='pt-1'>
                                    
                                        <Row>
                                            <Col md={7}>
                                                <FormGroup>
                                                    <Label for="name"></Label>
                                                    <AsyncSelect
                                                        options={customerAddress}
                                                        placeholder={"Select address"}
                                                        value={(selectedCustomerAddress?.label) ? selectedCustomerAddress : null}
                                                        onChange={(e) => {
                                                            setSelectedCustomerAddress(e);
                                                            setEditCustomerAddressInformation(true)
                                                        }}
                                                        zIndex={99}
                                                        callback={(e) => {
                                                        }}
                                                        defaultOptions={customerAddress}
                                                        isDisabled={(customerAddress?.length > 0) ? false : true}
                                                        className="mt-1"
                                                    ></AsyncSelect>
                                                </FormGroup>
                                            </Col>
                                            <Col md={5}>
                                                <FormGroup className='mb-1'>
                                                    <Label for="name"></Label>
                                                </FormGroup>
                                                <Label for="name " className='mr-2'>Or </Label>
                                                <Button color='primary' onClick={() => {
                                                    toggleShippingAddressModal()
                                                }} disabled={(selectedCustomer?.label) ? false : true}>Create new</Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        }
                        <Col sm={12} lg={12} md={12}>
                            {selectedCustomerAddress?.value?.address && editCustomerAddressInformation &&
                                <CustomerAddressInfo customerInfo={(selectedCustomerAddress)?selectedCustomerAddress?.value:{}} editCallback={()=>{setEditCustomerAddressInformation(false)}}>
                                </CustomerAddressInfo>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Modal isOpen={selectProductModal} toggle={toggleProductModal} size='lg' scrollable={true}>
                <ModalHeader toggle={toggleProductModal} tag='div' className='custom-header-modal'>
                    <h5>Search product</h5>
                    <Row>
                        <Col md={8}>
                            <FormGroup className='mb-0'>
                                <Input type="text"
                                    name="searchProduct"
                                    id="searchProduct"
                                    placeholder="Search product"
                                    value={searchTerm}
                                    onInput={(e) => {
                                        setSearchTerm(e.target.value);
                                        serachData(e.target.value);
                                    }}
                                    disabled={isCustomProduct}
                                />
                            </FormGroup>
                        </Col>
                        <Col className='d-flex justify-content-center'><p className='m-0 d-flex align-items-center'> Or </p></Col>
                        <Col md={3}>
                            <FormGroup className='mb-0'>
                                <Button color='primary' onClick={() => {
                                    setIsCustomProduct(!isCustomProduct);
                                }}>{(isCustomProduct) ? "Hide Custom" : "Add Custom"} </Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            {!isCustomProduct &&
                                <div className='productList-container p-1'>
                                    {!isProductLoading && productData?.length > 0 && productData?.map((item, index) => {
                                        return <div className='productList mt-2' key={index + "_" + item.prodToken}>
                                            <div className=' d-inline-flex w-100 p-2 bg-light'>
                                                <div className='mr-2'>
                                                    <FormGroup check>
                                                        <Input type='checkbox' id={item.prodToken} value={item.prodToken} name='product_variant' data-type='product' onClick={(e) => { tempSelectProduct(e, item) }} checked={(commonHelper.checkProductExistsInCart(tempSelectedProduct, item.prodToken)) ? true : false}></Input>
                                                    </FormGroup>
                                                </div>
                                                <div>
                                                    <div className="rounded prod-img">
                                                        <img className='img-thumbnail' src={getFS1Image(item?.prodImage?.file, 50)} alt="" />
                                                    </div>
                                                </div>
                                                <div className='prod-name'>
                                                    <p>{item?.prodName?.[0]}</p>
                                                </div>
                                            </div>
                                            <ListGroup className='ml-3 mt-2'>
                                                {item?.variantInfo && item?.variantInfo?.map((variant, index) => {
                                                    return <ListGroupItem className='border-0' key={index + "_" + variant.variantToken}>
                                                        <Row>
                                                            <Col md={7}>
                                                                <div className=' d-inline-flex w-100'>
                                                                    <div className='mr-2'>
                                                                        <FormGroup check>
                                                                            <Input type='checkbox' id={variant.variantToken} value={variant.variantToken} data-type='variant' onClick={(e) => { tempSelectProduct(e, item, variant) }} checked={(commonHelper.checkItemExistsInCart(tempSelectedProduct, variant.variantToken)) ? true : false}></Input>
                                                                        </FormGroup>
                                                                    </div>
                                                                    <div>
                                                                        <div className="rounded prod-img">
                                                                            {/* <img className='img-thumbnail' src={getFS1Image(variant.variantImage.file,50)} alt="" /> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className='prod-name'>
                                                                        <p className='mb-0'>{variant.variantName}</p>
                                                                        {variant?.variantOptions?.length > 0 &&
                                                                            <small>
                                                                                {variant?.variantOptions?.map((options) => {
                                                                                    return options.optionName + ": " + options.optionValue + " , "
                                                                                })}
                                                                            </small>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col md={5}>
                                                                <div className=' d-inline-flex w-100 justify-content-end'>
                                                                    <div className='prod-name'>
                                                                        <p>{variant.inventoryCount} available</p>
                                                                    </div>
                                                                    <div className='prod-name ml-4'>
                                                                        <p>${variant.variantPrice}</p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </ListGroupItem>
                                                })}
                                            </ListGroup>
                                        </div>
                                    })}
                                    {!isProductLoading && !productData?.length &&
                                        <div className='productList mt-2' key={"no-record-found-0"}>
                                            <div className=' d-inline-flex w-100 p-2 bg-light text-center'>
                                                <h4 className='w-100'>No record found!</h4>
                                            </div>
                                        </div>
                                    }
                                    {isProductLoading &&
                                        <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
                                            <div>
                                                <h4>Loading Quotes...</h4>
                                                <div className="bouncing-loader">
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            {isCustomProduct &&
                                <div className='productList-container p-1'>
                                    <NewProductForm newProductCallback={(data) => {
                                        const addNewProduct = [...selectedProductData];
                                        addNewProduct.push(data);
                                        setSelectedProductData(addNewProduct);
                                        setTimeout(() => {
                                            setSubTotalAmount(commonHelper.calculateCartSubtotal(addNewProduct));
                                            setIsCustomProduct(false);
                                            toggleProductModal();
                                        }, 500);
                                    }}></NewProductForm>
                                </div>
                            }
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={()=>{addProductToCart()}}>
                        Add to cart
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={customerModal} toggle={toggleCustomerModal} size='md'>
                <ModalHeader toggle={toggleCustomerModal}><h4>Add new customer</h4></ModalHeader>
                <ModalBody>
                    <NewCustomer newCustomerCallBack={(data) => newCustomerCallBack(data)} closeModal={toggleCustomerModal}></NewCustomer>
                </ModalBody>
            </Modal>
            <Modal isOpen={discountModal} toggle={toggleDiscountModal} size='lg'>
                <ModalHeader toggle={toggleDiscountModal}>Add Discount</ModalHeader>
                <ModalBody>
                    <DiscountForm discountDataCallback={(e) => { discountDataCallback(e) }} objDiscountData={discountData} totalAmount={commonHelper.calCulateTotal(subTotalAmount, shippingData?.shippingValue, discountData, 0)}></DiscountForm>
                </ModalBody>
            </Modal>
            <Modal isOpen={shippingChargeModal} toggle={toggleShippingChargeModal} size='lg'>
                <ModalHeader toggle={toggleShippingChargeModal}>Shipping and delivery options</ModalHeader>
                <ModalBody>
                    <ShippingChargeForm shippingCallbackForm={(data) => { shippingCallbackForm(data) }} data={commonHelper.calCulateTotal(subTotalAmount, shippingData?.shippingValue, discountData, 0)} objShippingData={shippingData}></ShippingChargeForm>
                </ModalBody>
            </Modal>
            <Modal isOpen={shippingAddressModal} toggle={toggleShippingAddressModal} size='lg'>
                <ModalHeader toggle={toggleShippingAddressModal}><h4>Add shipping address</h4></ModalHeader>
                <ModalBody>
                    <ShippingAddressForm customer={selectedCustomer} shippingAddressCallbackForm={(data) => {
                        setShippingAddresCallback(data);
                    }}></ShippingAddressForm>
                </ModalBody>
            </Modal>
            <Modal isOpen={markAsPaidModal} toggle={toggleMarkAsPaidModal} size='md'>
                <ModalHeader toggle={toggleMarkAsPaidModal}>
                    <h3>Manual Order Payment</h3>
                    <p class="font-weight-normal">This payment will not be captured by Omni. Process payment manually if you received payment for this order outside of Omni</p>
                </ModalHeader>
                <ModalBody>
                    <MarkAsPaidModalForm data={commonHelper.calCulateTotal(subTotalAmount, shippingData?.shippingValue, discountData, 0)} paymentCallCallbackForm={(data) => { paymentCallbackData(data); toggleMarkAsPaidModal() }}></MarkAsPaidModalForm>
                </ModalBody>
            </Modal>
            <Modal isOpen={payViaCreditCradModal} toggle={togglePayViaCreditCradModal} size='md'>
                <ModalHeader toggle={togglePayViaCreditCradModal}><h4>Payment Via Credit Card</h4></ModalHeader>
                <ModalBody>
                    <PaymentViaCreditCard data={commonHelper.calCulateTotal(subTotalAmount, shippingData?.shippingValue, discountData, 0)} paymentCallCallbackForm={(data) => { paymentCallbackData(data); togglePayViaCreditCradModal() }} billingAddress={selectedCustomerAddress?.value}></PaymentViaCreditCard>
                </ModalBody>
            </Modal>
            <Modal isOpen={payViaGiftCardModal} toggle={togglePayGiftCradModal} size='lg' centered>
                <ModalHeader toggle={togglePayGiftCradModal}><h4>Payment Via Gift card</h4></ModalHeader>
                <ModalBody>
                    <ViaGiftCard data={commonHelper.calCulateTotal(subTotalAmount, shippingData?.shippingValue, discountData, 0)} paymentCallCallbackForm={(data) => { paymentCallbackData(data); togglePayGiftCradModal() }}></ViaGiftCard>
                </ModalBody>
            </Modal>
        </>
    )

}
const epochToLocal = (epoch) => {
    let d = new Date(0);
    return d.setUTCSeconds(epoch);
}
const CustomerInfo = props => {
    const [editInfoModal, setEditInfoModal] = useState(false);
    const [isTaxExempt, setIsTaxExempt] = useState((props?.customerInfo?.taxExempt) ? props?.customerInfo?.taxExempt : false);
    /*:: toggle: () => void */
    const toggle = () => {
        setEditInfoModal(!editInfoModal)
    }

    const { customerInfo, editCallBack } = props;

    return (
        <>
            <Card className={classnames('widget-flat',)} style={{ height: '269.5px' }}>
                <CardBody>
                    <Row className="d-flex justify-content-between">
                        <h4 className="header-title mt-2 ml-2 inline-block">Customer Information</h4>
                        <Button color='primary' onClick={()=>{editCallBack()}}>
                            <Icon width={25} height={25} icon={circleEditOutline} />
                        </Button>
                    </Row>
                    <hr />
                    <Row>
                        <Col>

                            <div className="text-left">
                                <p className="text-muted">
                                    <strong>Full Name :</strong> <span className="ml-2">{customerInfo.customerFirstName} {customerInfo.customerLastName}</span>
                                    <small className="text-muted">
                                        <span className="mx-1">⚬</span>
                                        <span id="date-joined-tooltip">Customer for {moment(epochToLocal(customerInfo.dateCreated)).fromNow(true)}</span></small>
                                </p>

                                <p className="text-muted">
                                    <strong>Company:</strong> <span className="ml-2">{customerInfo.customerCompanyName}</span>
                                </p>

                                <p className="text-muted">
                                    <strong>Email :</strong> <span className="ml-2">{customerInfo.customerEmail}</span>
                                </p>

                                <p className="text-muted">
                                    <strong>Phone :</strong>
                                    <span className="ml-2">{customerInfo.customerPhone}</span>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <UncontrolledTooltip placement="top" target="date-joined-tooltip">
                Date joined: {moment(customerInfo.createdAt).format('ll')}
            </UncontrolledTooltip>
        </>
    )
}
const CustomerAddressInfo = props => {
    const [editInfoModal, setEditInfoModal] = useState(false);
    const [isTaxExempt, setIsTaxExempt] = useState((props?.customerInfo?.taxExempt) ? props?.customerInfo?.taxExempt : false);
    /*:: toggle: () => void */
    const toggle = () => {
        setEditInfoModal(!editInfoModal)
    }

    const { customerInfo, customerAddressCallBack,useAsBillingAddress,editCallback } = props;
    const onUseAsBillingAddress=(params)=>{
        customerAddressCallBack({isChecked:params,billingAddress:customerInfo});
    }
    return (
        <>
            <Card className={classnames('widget-flat',)}>
                <CardBody>
                    <Row className="d-flex justify-content-between">
                        <h4 className="header-title mt-2 ml-2 inline-block">Shipping Address Information</h4>
                        <Button color='primary' onClick={()=>{editCallback()}}>
                            <Icon width={25} height={25}  icon={circleEditOutline} />
                        </Button>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            <div className="text-left">
                            <ListGroup className="address-card row flex-row" flush>
                                <ListGroupItem tag="li" href="#" action>
                                    <ListGroupItemHeading>{customerInfo?.addressFirstName} {customerInfo?.addressLastName}</ListGroupItemHeading>
                                    <ListGroupItemText>
                                        {customerInfo?.address}, {customerInfo?.address2}<br />
                                        {customerInfo?.addressCity}, {customerInfo?.addressState} {customerInfo?.addressZip}<br />
                                        <b>Phone number: {customerInfo?.phoneNumber}</b>
                                    </ListGroupItemText>
                                </ListGroupItem>
                            </ListGroup>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <UncontrolledTooltip placement="top" target="date-joined-tooltip">
                Date joined: {moment(customerInfo?.createdAt).format('ll')}
            </UncontrolledTooltip>
        </>
    )
}
const NewCustomer = props => {
    const [isDisable, setIsDisable] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [validateState, setValidateState] = useState({
        fname: false,
        lname: false
    });
    const [newCustomer, setNewCustomer] = useState({
        fname: '',
        lname: '',
        email: '',
        phone: '',
        company: ''
    });
    const resetForm = () => {
        setNewCustomer({
            fname: '',
            lname: '',
            email: '',
            phone: '',
            company: ''
        });
        closeModal();
    }
    const saveNewCustomer = () => {
        setValidateState({ fname: false, lname: false })
        if (newCustomer?.fname !== "" && newCustomer?.lname !== "") {
            if (isEmailValid) {
                let customerSavingToast = toast.loading('Saving customer...', {
                    theme: 'light',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsDisable(true);
                CommonApiRequest.createNewCustomer({
                    customerFirstName: newCustomer?.fname,
                    customerLastName: newCustomer?.lname,
                    customerEmail: newCustomer?.email,
                    customerPhone: newCustomer?.phone,
                    customerCompanyName: newCustomer?.company
                }).then((response) => {
                    if (response?.customerToken) {
                        toast.update(customerSavingToast,
                            {
                                render: "Customer created successfully!",
                                type: "success",
                                isLoading: false,
                                autoClose: null,
                                hideProgressBar: null,
                                closeOnClick: null,
                            });
                        newCustomerCallBack(response);
                    }
                }).catch(() => {
                    toast.error("Please fill the required field");
                })
            }
        } else {
            const validationStatus = {};
            if (newCustomer?.fname === "") {
                validationStatus.fname = true
            }
            if (newCustomer?.lname === "") {
                validationStatus.lname = true
            }
            setValidateState(validationStatus);
        }

    }
    const handleInputChange = (e) => {
        const target = e.target;
        const name = target.name;
        setNewCustomer({ ...newCustomer, [name]: target?.value });
    }
    const { customerInfo, newCustomerCallBack, closeModal } = props;

    return (
        <>
            <Row>
                <Col>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup>
                                <Label for="name">First Name <span className='required-field'>*</span></Label>
                                <Input name="fname" id="fname" value={newCustomer?.fname}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                    }}
                                    placeholder=""
                                />
                                {validateState.fname ? <div className="invalid-text">Customer first name required</div> : ''}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup>
                                <Label for="name">Last Name</Label>
                                <Input name="lname" id="lname" value={newCustomer?.lname}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                    }}
                                    placeholder=""
                                />
                                {validateState.lname ? <div className="invalid-text">Customer last name required</div> : ''}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup>
                                <Label for="name">Email</Label>
                                <Input name="email" id="email" value={newCustomer?.email}
                                    onChange={(e) => {
                                        handleInputChange(e);

                                    }}
                                    onBlur={(e) => {
                                        var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                                        if (e.target.value !== "") {
                                            if (!regex.test(e.target.value)) {
                                                setIsEmailValid(false)
                                            } else {
                                                setIsEmailValid(true)
                                            }
                                        } else {
                                            setIsEmailValid(true)
                                        }
                                    }}
                                    placeholder=""
                                />
                                {!isEmailValid ? <div className="invalid-text">Customer valid email address</div> : ''}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup>
                                <Label for="name">Phone</Label>
                                <MaskedInput
                                    mask={[
                                        '(',
                                        /[1-9]/,
                                        /\d/,
                                        /\d/,
                                        ')',
                                        ' ',
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        '-',
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                    ]}
                                    name="phone"
                                    id="phone"
                                    value={newCustomer.phone}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder="(___) ___-____"
                                    className="form-control"
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup>
                                <Label for="name">Company</Label>
                                <Input name="company" id="company" value={newCustomer?.company}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                    }}
                                    placeholder=""
                                />

                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={12} sm={12} className='d-flex justify-content-end'>
                            <Button
                                color="dark"
                                type="reset"
                                onClick={() => { resetForm() }}
                                className="export-csv-btn btn btn-primary mr-3"
                                disabled={isDisable}>
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                type="button"
                                onClick={() => { saveNewCustomer() }}
                                className="export-csv-btn btn btn-primary mr-2"
                                disabled={isDisable}>
                                Save
                            </Button>
                        </Col>
                    </Row>

                </Col>
            </Row>
        </>
    )
}
const DiscountForm = props => {
    const { discountDataCallback, objDiscountData, totalAmount } = props;
    const [objDiscountType, setBbjDiscountType] = useState([
        { label: 'Amount', value: 'Amount' }, { label: 'Percentage', value: 'Percentage' }
    ]);
    const [selectedDiscountType, setSelectedDiscountType] = useState(objDiscountType[0]);
    const [discountValue, setDiscountValue] = useState("");
    const [reasonForDiscount, setReasonForDiscount] = useState("");
    const [isDisable, setIsDisable] = useState(false);
    const [validateState, setValidateState] = useState({
        discountType: false,
        discountValue: false,
        disCountAmount: false
    });
    const saveDiscountData = () => {
        if (discountValue !== "" && selectedDiscountType !== "" && parseFloat(discountValue) <= parseFloat(totalAmount)) {
            const discountDataReturn = {
                type: selectedDiscountType,
                value: discountValue,
                reason: reasonForDiscount,
            }
            discountDataCallback(discountDataReturn)
        } else {
            const validationStatus = {};
            if (discountValue === "") {
                if (discountValue <= totalAmount) {
                    validationStatus.discountValue = true
                }
            }
            if (selectedDiscountType === "") {
                validationStatus.discountValue = true
            }
            if (discountValue > totalAmount) {

                validationStatus.disCountAmount = true
            }

            setValidateState(validationStatus);
        }
    }
    useEffect(() => {
        if (objDiscountData?.type) {
            setSelectedDiscountType(objDiscountData?.type)
        }
        if (objDiscountData?.value) {
            setDiscountValue(objDiscountData?.value)
        }
        if (objDiscountData?.reason) {
            setReasonForDiscount(objDiscountData?.reason)
        }
    }, [])
    return (
        <>
            <Row>
                <Col>
                    <Row>
                        <Col sm={12} md={6} lg={6}>
                            <FormGroup>
                                <Label for="discount">Discount Type <span className='required-field'>*</span></Label>
                                <Select
                                    name={"discount"}
                                    id={"discount"}
                                    placeholder={"Select discount type"}
                                    defaultValue={objDiscountType[0]}
                                    options={objDiscountType}
                                    onChange={(e) => { setSelectedDiscountType(e) }}></Select>
                                {validateState.discountType ? <div className="invalid-text">Please select discount type</div> : ''}

                            </FormGroup>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                            <FormGroup>
                                <Label for="discountValue">Discount Value <span className='required-field'>*</span></Label>
                                <InputGroup>
                                    <InputGroupText className='border-radius-right-0'>
                                        {commonHelper.returnDiscountType(selectedDiscountType?.label)}
                                    </InputGroupText>
                                    <Input name="discountValue" id="discountValue" value={discountValue}
                                        onChange={(e) => {
                                            setDiscountValue(e.target.value);
                                        }}
                                        placeholder="0.00"
                                    />
                                </InputGroup>
                                {validateState.discountValue ? <div className="invalid-text">Please enter discount value</div> : ''}
                                {!validateState.discountValue && validateState.disCountAmount ? <div className="invalid-text">Can not add discount more than ${totalAmount}</div> : ''}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="reasonForDiscount">Reason for discount</Label>
                                <Input name="reasonForDiscount" id="reasonForDiscount" value={reasonForDiscount}
                                    onChange={(e) => {
                                        setReasonForDiscount(e.target.value)
                                    }}
                                    placeholder="e.g. example..."
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={12} sm={12} className='d-flex justify-content-end'>
                            <Button
                                color="dark"
                                type="reset"
                                onClick={() => { }}
                                className="export-csv-btn btn btn-primary mr-3"
                                disabled={isDisable}>
                                Reset
                            </Button>
                            <Button
                                color="primary"
                                type="button"
                                onClick={() => { saveDiscountData() }}
                                className="export-csv-btn btn btn-primary mr-2"
                                disabled={isDisable}>
                                Save
                            </Button>
                        </Col>
                    </Row>

                </Col>
            </Row>
        </>
    )
}
const ShippingChargeForm = props => {
    const { shippingCallbackForm, objShippingData,data } = props;
    const [objDiscountType, setBbjDiscountType] = useState([
        { label: 'Amount', value: 'Amount' }, { label: 'Percentage', value: 'Percentage' }
    ]);
    const [selectedDiscountType, setSelectedDiscountType] = useState(objDiscountType[0]);
    const [discountValue, setDiscountValue] = useState("");
    const [reasonForDiscount, setReasonForDiscount] = useState("");
    const [isDisable, setIsDisable] = useState(false);
    const [shippingType, setShippingType] = useState("custom");
    const [shippingName, setShippingName] = useState("");
    const [shippingValue, setShippingValue] = useState(0);
    const [shippingMethoadId, setShippingMethoadId] = useState(undefined);
    const [selectedPredefinedValue, setSelectedPredefinedValue] = useState(null);
    const [preDefinedData, setPreDefinedData] = useState([]);
    const [validateState, setValidateState] = useState({
        shippingName: false,
        shippingValue: false
    });
    const saveDiscountData = () => {
        if (shippingName !== "" && parseFloat(shippingValue) > 0) {
            const shippingDataReturn = {
                type: shippingType,
                shippingName: shippingName,
                shippingValue: shippingValue,
                methodId:shippingMethoadId
            }
            setValidateState({
                shippingName: false,
                shippingValue: false
            });
            console.log(shippingDataReturn);
            shippingCallbackForm(shippingDataReturn);
        } else {
            const validationStatus = {};
            if (shippingName === "") {
                validationStatus.shippingName = true
            }
            if (!parseFloat(shippingValue) || parseFloat(shippingValue) <= 0) {
                validationStatus.shippingValue = true
            }
            setValidateState(validationStatus);
        }
    }
    useEffect(() => {
        console.log(objShippingData);
        if (objShippingData?.type) {
            setShippingType(objShippingData?.type)
        }
        if (objShippingData?.shippingValue) {
            setShippingValue(objShippingData?.shippingValue);
            setSelectedPredefinedValue(preDefinedData?.find(option => option.label === objShippingData?.shippingName));
        }
        if (objShippingData?.shippingName) {
            setShippingName(objShippingData?.shippingName)
        }
        getPreDefineMethoads();
    }, []);
    const getPreDefineMethoads = ()=>{
        const dataNew=[];
        CommonApiRequest.getShippingMethoads().then((response)=>{
            console.log(response);
            if(response?.data){
                setShippingType('predefined');

                response.data.map((item)=>{
                    if(item.markupType==='fixed'){
                        item.markupData = item.markup
                    } else {
                        const getPercentage = (parseFloat(data)*parseFloat(item.markup))/100;
                        item.markupData = getPercentage
                    }
                    dataNew.push({ label: item?.methodName+" - "+"$"+item.markupData, value: item });
                    setPreDefinedData(dataNew);
                    if(objShippingData?.type==='predefined' && item?.methodName+" - "+"$"+item.markupData===objShippingData?.shippingName){
                        setSelectedPredefinedValue({ label: item?.methodName+" - "+"$"+item.markupData, value: item })
                    }
                })
            }
        })
    }
    return (
        <>
            <Row>
                <Col>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup check className='mb-2'>
                                <Input type='radio' name='type' value={'custom'} check onChange={(e) => { setShippingType(e.target.value) }} checked={(shippingType === 'custom') ? true : false}></Input>
                                <Label for="discount" check>Custom</Label>
                            </FormGroup>
                        </Col>
                        {shippingType === 'custom' &&
                            <Col sm={12} md={12} lg={12} className='pl-4'>
                                <Row>
                                    <Col sm={12} md={6} lg={6}>
                                        <FormGroup>
                                            <Label for="customShippingName">Name <span className='required-field'>*</span></Label>
                                            <Input name="customShippingName" id="customShippingName" value={shippingName}
                                                onChange={(e) => {
                                                    setShippingName(e.target.value);
                                                }}
                                                placeholder="Example...."
                                            />
                                            {validateState.shippingName ? <div className="invalid-text">Please enter shipping name</div> : ''}
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} md={6} lg={6}>
                                        <FormGroup>
                                            <Label for="customShippingValue">Price <span className='required-field'>*</span></Label>
                                            <InputGroup>
                                            <InputGroupAddon addonType='prepend'>$</InputGroupAddon>
                                                <Input name="customShippingValue" id="customShippingValue" value={shippingValue}
                                                    onChange={(e) => {
                                                        if (parseFloat(e.target.value)) {
                                                            setShippingValue(parseFloat(e.target.value));
                                                        } else {
                                                            setShippingValue(parseFloat(0.0));
                                                        }
                                                    }}
                                                    placeholder="0.00"
                                                />
                                            </InputGroup>
                                            {validateState.shippingValue ? <div className="invalid-text">Please enter shipping value</div> : ''}
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        }
                    </Row>
                    <Row className='mt-2'>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup check>
                                <Input type='radio' name='type' value={'predefined'} check onChange={(e) => { setShippingType(e.target.value) }} checked={(shippingType === 'predefined') ? true : false}></Input>
                                <Label for="discount" check>Predefined</Label>
                            </FormGroup>
                        </Col>
                        {shippingType === 'predefined' &&
                            <Col sm={12} md={12} lg={12}>
                                <FormGroup className='mt-2 mb-3 pl-4'>
                                    <Label for="discount">Select Shipping Methoad</Label>
                                    <Select
                                        id="shippingMethoad"
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        onChange={(data)=>{
                                            setSelectedPredefinedValue(data);
                                            setShippingName(data?.label)
                                            setShippingValue(parseFloat(data?.value?.markupData))
                                            setShippingMethoadId(data?.value?.methodId);
                                        }}
                                        value={preDefinedData?.find(option => option.value === selectedPredefinedValue?.value)}
                                        isSearchable={true}
                                        options={preDefinedData}
                                        name={'shippingMethoad'}>
                                        </Select>
                                </FormGroup>
                            </Col>
                        }
                    </Row>
                    <Row>
                        <Col md={12} lg={12} sm={12} className='d-flex justify-content-end'>
                            <Button
                                color="dark"
                                type="reset"
                                onClick={() => { }}
                                className="export-csv-btn btn btn-primary mr-3"
                                disabled={isDisable}>
                                Reset
                            </Button>
                            <Button
                                color="primary"
                                type="button"
                                onClick={() => { saveDiscountData() }}
                                className="export-csv-btn btn btn-primary"
                                disabled={isDisable}>
                                Save
                            </Button>
                        </Col>
                    </Row>

                </Col>
            </Row>
        </>
    )
}
const NewProductForm = props => {
    const { newProductCallback, objDiscountData } = props;
    const [objDiscountType, setBbjDiscountType] = useState([
        { label: 'Amount', value: 'Amount' }, { label: 'Percentage', value: 'Percentage' }
    ]);
    const [selectedDiscountType, setSelectedDiscountType] = useState(objDiscountType[0]);
    const [discountValue, setDiscountValue] = useState("");
    const [productName, setProductName] = useState("");
    const [productPrice, setProductPrice] = useState(0);
    const [isTaxable, setIsTaxable] = useState(false);
    const [shippingCoast, setShippingCoast] = useState(0);
    const [qyt, setQyt] = useState(1);
    const [productWeight, setProductWeight] = useState(0);
    const [isDisable, setIsDisable] = useState(false);
    const [validateState, setValidateState] = useState({
        productName: false,
        productPrice: false,
        qyt: false
    });
    const saveDiscountData = () => {
        if (productName !== "" && productPrice > 0 && qyt > 0) {
            const variant = {};
            variant.prodToken = "prd_" + commonHelper.makeid(10);
            variant.prodImage = "";
            variant.brandName = "";
            variant.price = parseFloat(productPrice);
            variant.variantToken = "vrt_" + commonHelper.makeid(10);
            variant.variantOptions = [];
            variant.spec = [];
            variant.variantName = productName
            variant.qyt = qyt;
            newProductCallback(variant);
            // const discountDataReturn = {
            //     type: selectedDiscountType,
            //     value: discountValue,
            // }
            //discountDataCallback(discountDataReturn)
        } else {
            const validationStatus = {};
            if (productName === "") {
                validationStatus.productName = true
            }
            if (!parseFloat(productPrice) || parseFloat(productPrice) <= 0) {
                validationStatus.productPrice = true
            }
            if (!parseInt(qyt) || parseInt(qyt) <= 0) {
                validationStatus.qyt = true
            }
            setValidateState(validationStatus);
        }
    }
    useEffect(() => {
        if (objDiscountData?.type) {
            setSelectedDiscountType(objDiscountData?.type)
        }
        if (objDiscountData?.value) {
            setDiscountValue(objDiscountData?.value)
        }
    }, [])
    return (
        <>
            <Row>
                <Col>
                    <Row>
                        <Col sm={12} md={6} lg={6}>
                            <FormGroup>
                                <Label for="productName">Item Name <span className='required-field'>*</span></Label>
                                <Input name='productName' id='productName' placeholder='Enter item name' value={productName} onInput={(e) => { setProductName(e.target.value) }}></Input>
                                {validateState?.productName ? <div className="invalid-text">Please enter item name</div> : ''}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                            <FormGroup>
                                <Label for="productPrice">Price <span className='required-field'>*</span></Label>
                                <InputGroup>
                                    <InputGroupText className='border-radius-right-0'>
                                        $
                                    </InputGroupText>
                                    <Input name="productPrice" id="productPrice" value={productPrice}
                                        onChange={(e) => {
                                            setProductPrice(e.target.value);
                                        }}
                                        placeholder="0.00"
                                    />
                                </InputGroup>
                                {validateState?.productPrice ? <div className="invalid-text">Please enter product price</div> : ''}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={6} lg={6}>
                            <FormGroup>
                                <Label for="qyt">Quantity  <span className='required-field'>*</span></Label>
                                <Input name="qyt" id="qyt" type='number' value={qyt}
                                    onChange={(e) => {
                                        setQyt(e.target.value)
                                    }}
                                    placeholder="e.g. 1..."
                                />
                                {validateState?.qyt ? <div className="invalid-text">Please enter product quantity</div> : ''}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                            <FormGroup>
                                <Label for="qyt">Shipping Cost</Label>
                                <InputGroup>
                                    <InputGroupText className='border-radius-right-0'>
                                        $
                                    </InputGroupText>
                                    <Input name="shippingCost" id="shippingCost" value={shippingCoast}
                                        onChange={(e) => {
                                            setShippingCoast(e.target.value);
                                        }}
                                        placeholder="0.00"
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={6} lg={6}>
                            <FormGroup>
                                <Label for="productWeight">Weight </Label>
                                <Input name="productWeight" id="productWeight" type='float' value={productWeight}
                                    onChange={(e) => {
                                        setProductWeight(e.target.value)
                                    }}
                                    placeholder="e.g. 1..."
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={6} lg={6} className=' align-items-center d-flex'>
                            <FormGroup check>
                                <Input type='checkbox' name='isTaxable' id='isTaxable' onClick={(e) => {
                                    setIsTaxable(e.target.checked);
                                }}></Input>
                                <Label for="isTaxable" check>Taxable</Label>

                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={12} sm={12} className='d-flex justify-content-end'>
                            <Button
                                color="primary"
                                type="button"
                                onClick={() => { saveDiscountData() }}
                                className="export-csv-btn btn btn-primary mr-2"
                                disabled={isDisable}>
                                Save
                            </Button>
                        </Col>
                    </Row>

                </Col>
            </Row>
        </>
    )
}
const ShippingAddressForm = props => {
    const { shippingAddressCallbackForm, customer } = props;
    const [addressInfo, setAddressInfo] = useState({
        addressFirstName: '',
        addressLastName: '',
        address: '',
        address2: '',
        addressCity: '',
        addressState: '',
        addressZip: '',
        mobileNumber: '',
        addressCountry: 'USA',
        addressLabel: ''
    })
    const [isDisable, setIsDisable] = useState(false);
    const [shippingValue, setShippingValue] = useState(0);
    const [validateState, setValidateState] = useState({
        addressFirstName: false,
        addressLastName: false,
        address: false,
        address2: false,
        addressCity: false,
        addressState: false,
        addressZip: false,
        mobileNumber: false
    });
    const saveAddress = () => {
        console.log(customer);
        if (
            addressInfo.addressFirstName !== "" &&
            addressInfo.addressLastName !== "" &&
            addressInfo.address !== "" &&
            addressInfo.addressCity !== "" &&
            addressInfo.addressState !== "" &&
            addressInfo.addressZip !== "" &&
            addressInfo.mobileNumber !== ""
        ) {
            setIsDisable(true);
            let addressSavingToast = toast.loading('Saving address please wait...', {
                theme: 'light',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            CommonApiRequest.createCustomerAddress(
                {
                    addressFirstName: addressInfo.addressFirstName,
                    addressLastName: addressInfo.addressLastName,
                    address: addressInfo.address,
                    address2: addressInfo.address2,
                    addressCity: addressInfo.addressCity,
                    addressState: (addressInfo?.addressState?.[0]?.label) ? addressInfo?.addressState?.[0]?.label : '',
                    addressZip: addressInfo.addressZip,
                    mobileNumber: addressInfo.mobileNumber,
                    addressCountry: 'USA',
                    addressLabel: addressInfo.addressLabel,
                    customerToken: customer?.value?.customerToken
                }
            ).then((response) => {
                setIsDisable(false);
                toast.update(addressSavingToast,
                    {
                        render: "Address saved successfully!",
                        type: "success",
                        isLoading: false,
                        autoClose: null,
                        hideProgressBar: null,
                        closeOnClick: null,
                    });
                shippingAddressCallbackForm(response)
            }).catch((error) => {
                console.log(error);
                setIsDisable(false);
            });
            //shippingAddressCallbackForm(addressInfo)
        } else {
            const validationStatus = {};
            if (addressInfo?.addressFirstName === "") {
                validationStatus.addressFirstName = true
            }
            if (addressInfo?.addressLastName === "") {
                validationStatus.addressLastName = true
            }
            if (addressInfo?.address === "") {
                validationStatus.address = true
            }
            if (addressInfo?.addressCity === "") {
                validationStatus.addressCity = true
            }
            if (addressInfo?.addressState === "") {
                validationStatus.addressState = true
            }
            if (addressInfo?.addressZip === "") {
                validationStatus.addressZip = true
            }
            if (addressInfo?.mobileNumber === "") {
                validationStatus.mobileNumber = true
            }
            setValidateState(validationStatus);
        }
    }
    const onStateSelectChange = (selection) => {
        setAddressInfo({
            ...addressInfo,
            addressState: selection
        })
    }
    const handleInputChange = (e) => {
        const target = e.target;
        const name = target.name;
        setAddressInfo({ ...addressInfo, [name]: target?.value });
    }
    return (
        <>
            <Row>
                <Col>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup>
                                <Label for="fname">Address Label</Label>
                                <Input name="addressLabel" id="addressLabel" value={addressInfo?.addressLabel}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                    }}
                                    placeholder=""
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup>
                                <Label for="addressFirstName">First Name <span className='required-field'>*</span></Label>
                                <Input name="addressFirstName" id="addressFirstName" value={addressInfo?.addressFirstName}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                    }}
                                    placeholder=""
                                />
                                {validateState.addressFirstName ? <div className="invalid-text">First name required</div> : ''}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup>
                                <Label for="addressLastName">Last Name <span className='required-field'>*</span></Label>
                                <Input name="addressLastName" id="addressLastName" value={addressInfo?.addressLastName}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                    }}
                                    placeholder=""
                                />
                                {validateState.addressLastName ? <div className="invalid-text">last name required</div> : ''}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup>
                                <Label for="address">Address <span className='required-field'>*</span></Label>
                                <Input name="address" id="address" value={addressInfo?.address}
                                    onChange={(e) => {
                                        handleInputChange(e);

                                    }}
                                    placeholder=""
                                />
                                {validateState.address ? <div className="invalid-text">Address required</div> : ''}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup>
                                <Label for="address2">Address 2</Label>
                                <Input name="address2" id="address2" value={addressInfo?.address2}
                                    onChange={(e) => {
                                        handleInputChange(e);

                                    }}
                                    placeholder=""
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup>
                                <Label for="addressCity">City <span className='required-field'>*</span></Label>
                                <Input name="addressCity" id="addressCity" value={addressInfo?.addressCity}
                                    onChange={(e) => {
                                        handleInputChange(e);

                                    }}
                                    placeholder=""
                                />
                                {validateState.addressCity ? <div className="invalid-text">City required</div> : ''}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup>
                                <Label for="addressState">State <span className='required-field'>*</span></Label>
                                <Typeahead
                                    paginate
                                    maxResults={15}
                                    id="select2"
                                    labelKey="label"
                                    multiple={false}
                                    options={StateList.map(state => state)}
                                    // emptyLabel={<div onClick={() => alert('clicked')}>hello</div>}
                                    onChange={onStateSelectChange}
                                    selected={addressInfo.addressState}
                                    placeholder="Select state..."
                                    name={'addressState'}
                                />
                                {validateState.addressState ? <div className="invalid-text">State required</div> : ''}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup>
                                <Label for="addressZip">Zip  <span className='required-field'>*</span></Label>
                                <Input name="addressZip" id="addressZip" value={addressInfo?.addressZip}
                                    onChange={(e) => {
                                        handleInputChange(e);

                                    }}
                                    placeholder=""
                                />
                                {validateState.addressZip ? <div className="invalid-text">Zip required</div> : ''}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup>
                                <Label for="mobileNumber">Phone <span className='required-field'>*</span></Label>
                                <MaskedInput
                                    mask={[
                                        '(',
                                        /[1-9]/,
                                        /\d/,
                                        /\d/,
                                        ')',
                                        ' ',
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        '-',
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                    ]}
                                    name="mobileNumber"
                                    id="mobileNumber"
                                    value={addressInfo.mobileNumber}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder="(___) ___-____"
                                    className="form-control"
                                />
                                {validateState.mobileNumber ? <div className="invalid-text">Phone required</div> : ''}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={12} sm={12} className='d-flex justify-content-end'>
                            <Button
                                color="primary"
                                type="button"
                                onClick={() => { saveAddress() }}
                                className="export-csv-btn btn btn-primary mr-2"
                                disabled={isDisable}>
                                Save
                            </Button>
                        </Col>
                    </Row>

                </Col>
            </Row>
        </>
    )
}
const MarkAsPaidModalForm = props => {
    const { paymentCallCallbackForm, data } = props;
    const [paymentInfo, setPaymentInfo] = useState({
        amount: '',
        remaining: 0
    })
    const [isDisable, setIsDisable] = useState(true);
    const [validateState, setValidateState] = useState({
        amount: false,
    });
    const payViaCash = () => {
        if (parseFloat(paymentInfo?.remaining) >= 0) {
            const returnToCallback = {type:'cash',paymentInfo:paymentInfo};
            paymentCallCallbackForm(returnToCallback);
        } else {

        }
    }
    const handleInputChange = (e) => {
        const target = e.target;
        const info = { ...paymentInfo };
        info.amount = parseFloat(target?.value);
        info.remaining = (parseFloat(target?.value)) ? parseFloat(target?.value) - parseFloat(data) : 0;
        info.remaining = info.remaining.toFixed(2)
        setPaymentInfo(info);
        if (info.amount >= parseFloat(data)) {
            console.log(info)
            setIsDisable(false);
            setValidateState({ amount: false });
        } else {
            setIsDisable(true);
            setValidateState({ amount: true });
        }
    }
    const changeAmount = (type)=>{
        if(type==='increase'){
            let amount = parseFloat(paymentInfo.amount)?parseFloat(paymentInfo.amount)+1:1;
            const info = { ...paymentInfo };
            info.amount = amount;
            info.remaining = (parseFloat(amount)) ? parseFloat(amount) - parseFloat(data) : 0;
            info.remaining = info.remaining.toFixed(2)
            setPaymentInfo(info);
            if (info.amount >= parseFloat(data)) {
                console.log(info)
                setIsDisable(false);
                setValidateState({ amount: false });
            } else {
                setIsDisable(true);
                setValidateState({ amount: true });
            }
        } else {
            let amount = parseFloat(paymentInfo.amount)?parseFloat(paymentInfo.amount)-1:0;
            const info = { ...paymentInfo };
            info.amount = amount;
            info.remaining = (parseFloat(amount)) ? parseFloat(amount) - parseFloat(data) : 0;
            info.remaining = info.remaining.toFixed(2)
            setPaymentInfo(info);
            if (info.amount >= parseFloat(data)) {
                console.log(info)
                setIsDisable(false);
                setValidateState({ amount: false });
            } else {
                setIsDisable(true);
                setValidateState({ amount: true });
            }
        }
        
    }
    return (
        <>
            <Row>
                <Col>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <h3 className='text-center'>Total Due : {commonHelper.returnWithCurrency(data, false)}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} lg={3}></Col>
                        <Col sm={12} md={6} lg={6}>
                            <FormGroup>
                                <Label for="amount">Amount Received<span className='required-field'>*</span></Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <Button  onClick={()=>{changeAmount('increase')}}>+</Button>
                                    </InputGroupAddon>
                                    <Input name="amount" id="amount" value={paymentInfo.amount}
                                        onChange={(e) => {
                                            handleInputChange(e)
                                        }}
                                        placeholder=""
                                        type='number'
                                    />
                                    <InputGroupAddon addonType="append">
                                        <Button onClick={()=>{changeAmount('decrease')}}>-</Button>
                                    </InputGroupAddon>
                                </InputGroup>
                                {validateState.amount ? <div className="invalid-text">Amount received must be grater than or equal to total amount</div> : ''}
                            </FormGroup>
                        </Col>
                        <Col md={3} lg={3}></Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <h3 className='text-center'>Change due : {commonHelper.returnWithCurrency(paymentInfo?.remaining)}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={12} sm={12} className='d-flex justify-content-end'>
                            <Button
                                color="primary"
                                type="button"
                                onClick={() => { payViaCash() }}
                                className="btn btn-primary mr-2"
                                disabled={isDisable}>
                                Pay {commonHelper.returnWithCurrency(data)}
                            </Button>
                        </Col>
                    </Row>

                </Col>
            </Row>
        </>
    )
}
const PaymentViaCreditCard = props => {
    const { paymentCallCallbackForm, data,billingAddress } = props;
    const [cardInfo, setCardInfo] = useState({
        name: '',
        cardNumber: '',
        cardExp: '',
        securityCode: '',
    });
    const [addressInfo, setAddressInfo] = useState({
        address: '',
        address2: '',
        addressCity: '',
        addressState: '',
        addressZip: '',
        mobileNumber: '',
        addressCountry: 'USA',
    })
    const [isDisable, setIsDisable] = useState(false);
    const [shippingValue, setShippingValue] = useState(0);
    const [validateState, setValidateState] = useState({
        name: false,
        cardNumber: false,
        cardExp: false,
        securityCode: false,
        address: false,
        addressCity: false,
        addressState: false,
        addressZip: false,
        mobileNumber: false,
    });
    const [cartType, setCardType] = useState(null);
    const [isSameAsShipping, setIsSameAsShipping] = useState(false);
    const validateCard = () => {
        let valtedate = true;
        let newValidState = {
            ...validateState
        }
        var cardRegex = /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/;
        if (cardInfo.cardNumber.match(cardRegex)) {
            valtedate = true;
            newValidState.cardNumber = false
        } else {
            valtedate = false
            newValidState.cardNumber = true
        }
        if (cardInfo.cardExp.length >= 4) {
            valtedate = true
            newValidState.cardExp = false
        } else {
            valtedate = false
            newValidState.cardExp = true
        }
        if (cardInfo.securityCode.length >= 3) {
            valtedate = true
            newValidState.securityCode = false
        } else {
            valtedate = false
            newValidState.securityCode = true
        }
        if(!isSameAsShipping){
            if (addressInfo.address.length >= 3) {
                valtedate = true
                newValidState.address = false
            } else {
                valtedate = false
                newValidState.address = true
            }
            if (addressInfo.addressCity.length >= 3) {
                valtedate = true
                newValidState.addressCity = false
            } else {
                valtedate = false
                newValidState.addressCity = true
            }
            if (addressInfo.addressState) {
                valtedate = true
                newValidState.addressState = false
            } else {
                valtedate = false
                newValidState.addressState = true
            }
            if (addressInfo.addressZip) {
                valtedate = true
                newValidState.addressZip = false
            } else {
                valtedate = false
                newValidState.addressZip = true
            }
            if (addressInfo.mobileNumber.length >= 9) {
                valtedate = true
                newValidState.mobileNumber = false
            } else {
                valtedate = false
                newValidState.mobileNumber = true
            }
        }
        for (let state in newValidState) {
            if (typeof newValidState[state] === 'object') {
                for (let key in newValidState[state]) {
                    if (newValidState[state][key] === false)
                        valtedate = true
                }
            }
            else {
                if (newValidState[state] === true)
                    valtedate = false
            }
        }
        console.log(newValidState);
        setValidateState(newValidState);
        return valtedate;
    }
    const saveAddress = () => {
        
        if (validateCard()) {
            const returnToCallback = {type:'card',paymentInfo:{cardInfo:cardInfo,billingAddress:addressInfo}};
            console.log(returnToCallback);
            paymentCallCallbackForm(returnToCallback);
        } else {

        }
    }
    const onStateSelectChange = (selection) => {
        
        setAddressInfo({
            ...addressInfo,
            addressState: selection
        })
        console.log({
            ...addressInfo,
            addressState: selection
        });
    }
    const handleInputChange = (e) => {
        const target = e.target;
        const name = target.name;
        setCardInfo({ ...cardInfo, [name]: target?.value });
        if (name === 'cardNumber') {
            if (commonHelper.getCreditCardType(target?.value) && commonHelper.getCreditCardType(target?.value) !== 'unknown') {
                setCardType(commonHelper.getCreditCardType(target?.value));
            } else {
                setCardType(null);
            }
            commonHelper.getCreditCardType(target?.value);
        }
    }
    const handleInputChangeAddress = (e) => {
        const target = e.target;
        const name = target.name;
        setAddressInfo({ ...addressInfo, [name]: target?.value });
    }
    const setAssBillingAddrress=(isTrue)=>{
        setIsSameAsShipping(isTrue);
        if(isTrue){
            console.log(billingAddress?.addressState instanceof Array);
            if(billingAddress?.addressState){
                if(!billingAddress?.addressState instanceof Array){
                    if(StateList?.find(item=>item.value===billingAddress?.addressState || item.label===billingAddress?.addressState)){
                        billingAddress.addressState = [StateList?.find(item=>item.value===billingAddress?.addressState || item.label===billingAddress?.addressState)];
                    }
                }
                billingAddress.mobileNumber = billingAddress.phoneNumber;
                setAddressInfo(billingAddress);
            }
        } else {
            setAddressInfo({
                address: '',
                address2: '',
                addressCity: '',
                addressState: '',
                addressZip: '',
                mobileNumber: '',
                addressCountry: 'USA',
            });
        }
    }
    return (
        <>
            <Row>
                <Col>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup>
                                <Label for="name">Name on card<span className='required-field'>*</span></Label>
                                <Input name="name" id="name" value={cardInfo?.name}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                    }}
                                    placeholder=""
                                />
                                {validateState.name ? <div className="invalid-text">First name required</div> : ''}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup>
                                <Label for="cardNumber">Card Number<span className='required-field'>*</span></Label>
                                <div className='input-field-group'>
                                    {cartType &&
                                        <span className='card-icon'>
                                            <img alt='' className='img-fluid' src={cartType?.img} />
                                        </span>
                                    }
                                    <Input className={(cartType) ? 'has-icon' : ''} name="cardNumber" id="cardNumber" value={cardInfo?.cardNumber}
                                        onChange={(e) => {
                                            handleInputChange(e)
                                        }}
                                        placeholder=""
                                        type='number'
                                    />
                                </div>
                                {validateState.cardNumber ? <div className="invalid-text">Please enter valid card number</div> : ''}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                            <FormGroup>
                                <Label for="cardExp">Exp Date<span className='required-field'>*</span></Label>
                                <MaskedInput
                                    name="cardExp"
                                    id="cardExp"
                                    value={cardInfo?.cardExp}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                    }}
                                    placeholder=""
                                    mask={[
                                        /\d/,
                                        /[0-9]/,
                                        '/',
                                        /[1-9]/,
                                        /[0-9]/,
                                    ]}
                                    className="form-control"
                                />
                                {validateState.cardExp ? <div className="invalid-text">Please enter valid exp date</div> : ''}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                            <FormGroup>
                                <Label for="securityCode">Security code<span className='required-field'>*</span></Label>
                                <Input name="securityCode" id="securityCode" value={cardInfo?.securityCode}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                    }}
                                    placeholder=""
                                    type='number'
                                    maxLength={4}
                                />
                                {validateState.securityCode ? <div className="invalid-text">Please enter valid security code</div> : ''}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={6} lg={12} className='mb-2'>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <h4>Billing Address</h4>
                                </div>
                                {billingAddress?.address &&
                                    <FormGroup check className='text-right w-50'>
                                        <Input type='checkbox' id={"as_shippingAddress"} name="as_shippingAddress" value={1} data-type='product' checked={isSameAsShipping} onClick={(e)=>{setAssBillingAddrress(e?.target?.checked)}}></Input>
                                        <Label check for='as_shippingAddress'><strong>Same as shipping address</strong></Label>
                                    </FormGroup>
                                }
                            </div>
                        </Col>
                        {!isSameAsShipping &&
                            <>
                                <Col sm={12} md={12} lg={12}>
                                    <FormGroup>
                                        <Label for="address">Address <span className='required-field'>*</span></Label>
                                        <Input name="address" id="address" value={addressInfo?.address}
                                            onChange={(e) => {
                                                handleInputChangeAddress(e);

                                            }}
                                            placeholder=""
                                        />
                                        {validateState.address ? <div className="invalid-text">Address required</div> : ''}
                                    </FormGroup>
                                </Col>
                                <Col sm={12} md={12} lg={12}>
                                    
                                    <FormGroup>
                                        <Label for="address2">Address 2</Label>
                                        <Input name="address2" id="address2" value={addressInfo?.address2}
                                            onChange={(e) => {
                                                handleInputChangeAddress(e);

                                            }}
                                            placeholder=""
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                        <Label for="addressCity">City <span className='required-field'>*</span></Label>
                                        <Input name="addressCity" id="addressCity" value={addressInfo?.addressCity}
                                            onChange={(e) => {
                                                handleInputChangeAddress(e);

                                            }}
                                            placeholder=""
                                        />
                                        {validateState.addressCity ? <div className="invalid-text">City required</div> : ''}
                                    </FormGroup>
                                </Col>
                                <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                        <Label for="addressState">State <span className='required-field'>*</span></Label>
                                        <Typeahead
                                            paginate
                                            maxResults={15}
                                            id="select2"
                                            labelKey="label"
                                            multiple={false}
                                            options={StateList.map(state => state)}
                                            // emptyLabel={<div onClick={() => alert('clicked')}>hello</div>}
                                            onChange={onStateSelectChange}
                                            selected={(addressInfo?.addressState?.[0])?addressInfo.addressState:[]}
                                            placeholder="Select state..."
                                            name={'addressState'}
                                        />
                                        {validateState.addressState ? <div className="invalid-text">State required</div> : ''}
                                    </FormGroup>
                                </Col>
                                <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                        <Label for="addressZip">Zip  <span className='required-field'>*</span></Label>
                                        <Input name="addressZip" id="addressZip" value={addressInfo?.addressZip}
                                            onChange={(e) => {
                                                handleInputChangeAddress(e);

                                            }}
                                            placeholder=""
                                        />
                                        {validateState.addressZip ? <div className="invalid-text">Zip required</div> : ''}
                                    </FormGroup>
                                </Col>
                                <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                        <Label for="mobileNumber">Phone <span className='required-field'>*</span></Label>
                                        <MaskedInput
                                            mask={[
                                                '(',
                                                /[1-9]/,
                                                /\d/,
                                                /\d/,
                                                ')',
                                                ' ',
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                '-',
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                                /\d/,
                                            ]}
                                            name="mobileNumber"
                                            id="mobileNumber"
                                            value={addressInfo.mobileNumber}
                                            onChange={(e) => handleInputChangeAddress(e)}
                                            placeholder="(___) ___-____"
                                            className="form-control"
                                        />
                                        {validateState.mobileNumber ? <div className="invalid-text">Phone required</div> : ''}
                                    </FormGroup>
                                </Col>
                            </>
                        }
                    </Row>
                    <Row>
                        <Col md={12} lg={12} sm={12} className='d-flex justify-content-end'>
                            <Button
                                color="primary"
                                type="button"
                                onClick={() => { saveAddress() }}
                                className="export-csv-btn btn btn-primary mr-2"
                                disabled={isDisable}>
                                Pay {commonHelper.returnWithCurrency(data)}
                            </Button>
                        </Col>
                    </Row>

                </Col>
            </Row>
        </>
    )
}
const ViaGiftCard = props => {
    const { paymentCallCallbackForm, data } = props;
    const [paymentInfo, setPaymentInfo] = useState({
        gift_card: '',
        remaining: 0,
    })
    const [isDisable, setIsDisable] = useState(true);
    const [isCheckDisable, setIsCheckDisable] = useState(false);
    const [giftCardDataFromApi, setGiftCardDataFromApi] = useState(null);
    const [giftCardError, setGiftCardError] = useState("");
    const [giftCardData, setGiftCardData] = useState(null);
    const [validateState, setValidateState] = useState({
        gift_card: false,
    });
    const completPayment = () => {
        const giftCardData = {...giftCardDataFromApi};
        const returnToCallback = {type:'gift_card',paymentInfo:giftCardData};
        paymentCallCallbackForm(returnToCallback);
    }
    const handleInputChange = (e) => {
        const target = e.target;
        const info = { ...paymentInfo };
        info.gift_card = (target?.value);
        setPaymentInfo(info);
    }
    const checkGiftCardAmount=()=>{
        setIsCheckDisable(true);
        let checkingGiftCardToast = toast.loading('Please wait while fetching gift card detail ...', {
            theme: 'light',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
        CommonApiRequest.getGiftCardByToken(paymentInfo.gift_card).then((response)=>{
            toast.update(checkingGiftCardToast,
                {
                    render: "",
                    type: "success",
                    isLoading: false,
                    hideProgressBar: null,
                    closeOnClick: null,
                    autoClose: 10,
                });
            if(response?.id){
                let remaining = 0;
                const amount = response?.balance;
                if(parseFloat(amount) <= data){
                    remaining = parseFloat(data) - parseFloat(amount);
                }
                const giftCardData = {amount:amount,code:paymentInfo.gift_card,remaining:remaining};
                const dataGift = response;
                dataGift.amount = amount;
                setGiftCardDataFromApi(giftCardData);
                setPaymentInfo({...paymentInfo,remaining:remaining})
                setIsDisable(false);
                setGiftCardData(dataGift)
                setIsCheckDisable(false);
            }
        }).catch(()=>{
            toast.update(checkingGiftCardToast,
            {
                render: "",
                type: "danger",
                isLoading: false,
                hideProgressBar: null,
                closeOnClick: null,
                autoClose: 10,
            });
            setGiftCardError("Gift Card not found");
            setGiftCardData(null)
            setIsCheckDisable(false);
            setGiftCardDataFromApi(null);
        })
        
    }
    const handleHowMuchInputChange = (e) => {
        const target = e.target;
        const info = { ...giftCardDataFromApi };
        let remaining = 0;
        info.amount = (target?.value);
        if(parseFloat(target?.value) <= data){
            remaining = parseFloat(data) - parseFloat(target?.value);
        }
        info.remaining =remaining;
        if(parseFloat(giftCardData?.balance) >= parseFloat(target?.value) || parseFloat(target?.value)===0){
            setGiftCardDataFromApi(info);
        } 
    }
    return (
        <>
            <Row className='pl-5 pr-5 pb-5 pt-2'>
                <Col>
                    <Row>
                        <Col sm={12} md={12} lg={12} className='mb-4'>
                            <FormGroup className='text-center'>
                                <h1 className='text-center'>Total Due : {commonHelper.returnWithCurrency(data, false)}</h1>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2} lg={2}></Col>
                        <Col sm={12} md={8} lg={8}>
                            <FormGroup>
                                <Label for="amount">Gift Card Number<span className='required-field'>*</span></Label>
                                <InputGroup>
                                    <Input name="gift_card" id="gift_card" value={paymentInfo.gift_card}
                                        onChange={(e) => {
                                            handleInputChange(e)
                                        }}
                                        placeholder=""
                                        type='text'
                                    />
                                    <InputGroupAddon addonType="append">
                                        <Button  onClick={()=>{checkGiftCardAmount()}} disabled={isCheckDisable}>Validate</Button>
                                    </InputGroupAddon>
                                </InputGroup>
                                {validateState.amount ? <div className="invalid-text">Amount received must be grater than or equal to total amount</div> : ''}
                                {giftCardError!=="" && 
                                    <p className='mt-2 text-danger'>{giftCardError}</p>
                                }
                            </FormGroup>
                        </Col>
                        <Col md={2} lg={2}></Col>
                    </Row>
                    <Row className='mt-3 text-center'>
                    {(giftCardData?.initial_balance) &&
                        <Col>
                            <h2>Gift card details</h2>
                            <div className='h4'>
                                Original value : <strong>{commonHelper.returnWithCurrency(giftCardData?.initial_balance)}</strong>
                            </div>
                            <div className='h4'>
                                Available value : <strong>{commonHelper.returnWithCurrency(giftCardData?.balance)}</strong>
                            </div>
                        </Col>
                    }
                    </Row>
                    {(giftCardData?.initial_balance) &&
                     <Row className='mt-3'>
                        <Col md={2} lg={2}></Col>
                        <Col sm={12} md={8} lg={8}>
                            <FormGroup>
                                <Label for="amount">How much you want to use<span className='required-field'>*</span></Label>
                                <InputGroup>
                                    <Input name="remaining" id="remaining" value={parseFloat(giftCardDataFromApi?.amount)}
                                        placeholder=""
                                        type='number'
                                        max={parseFloat(giftCardData?.balance)}
                                        min={0}
                                        onChange={(e)=>handleHowMuchInputChange(e)}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col md={2} lg={2}></Col>
                        </Row> 
                    }
                </Col>
            </Row>
            <Row>
                <Col md={12} lg={12} sm={12} className='d-flex justify-content-end'>
                    <Button
                        color="primary"
                        type="button"
                        onClick={() => { completPayment() }}
                        className="btn btn-primary mr-2"
                        disabled={isDisable}>
                        Pay {commonHelper.returnWithCurrency(data)}
                    </Button>
                </Col>
            </Row>
        </>
    )
}
export default Sale;
