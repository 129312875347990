import React, { useState } from 'react';
import {
    Badge,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    CustomInput,
    Button
} from 'reactstrap';
import '../../src/App.css'; // Ensure CSS is correctly imported
import { useHistory } from 'react-router-dom';

function StatusFilter({ onStatusFilter }) {
    const customerStatuses = [
        { id: 'pending', name: 'Awaiting Approval' },
        { id: 'approved', name: 'Approved' },
        { id: 'regular', name: 'Regular' },
        { id: 'crazy', name: 'Crazy' },
        { id: 'toplevel', name: 'Top Level' },
        { id: 'wholesale', name: 'Wholesale' },
        { id: 'banned', name: 'Banned' },
        { id: 'fraudulent', name: 'Fraudulent' },
        { id: 'spam', name: 'Spam' },
    ];

    const [statusValues, setStatusValues] = useState({});
    const [archived, setArchived] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);

        const history = useHistory();
        const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const handleStatusChange = (id) => {
        setStatusValues(prevValues => ({
            ...prevValues,
            [id]: !prevValues[id]
        }));
    };

    const handleArchivedChange = () => {
        setArchived(!archived);
    };


    const resetFilters = () => {
        // React Router's useHistory hook to get history object
    
        // Clear internal component states
        setStatusValues({});
        setArchived(false);
        setDropdownOpen(false);
    
        // Access the current URL's search parameters
        const searchParams = new URLSearchParams(window.location.search);
    
        // Remove the filter-related search parameters
        searchParams.delete('fq');
        searchParams.delete('archive');
    
        // Update the URL in the browser without reloading the page
        const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
        history.push(newUrl);
    
        // Optionally, you might want to trigger a re-fetch or update of the component
        // if your application's data fetching logic depends on URL search parameters.
        // This can be achieved by calling the respective method here if needed.
    };
    
    

    const applyFilters = () => {
        // Extract selected statuses based on truthy values in statusValues
        const selectedStatuses = Object.entries(statusValues)
            .filter(([_, value]) => value)
            .map(([key]) => key);
    
        // Initialize the query object with filter query
        const query = {
            fq: selectedStatuses.join(',')
        };
    
        // Conditionally add the 'archived' parameter if it is true
        if (archived) {
            console.log('seelcted');
            query.archive = true; // Only add 'archived' to the query if it is selected
        }
    
        // Pass the query object to the parent component or handle it as needed
        onStatusFilter(query);
    
        // Optionally close the dropdown or modal
        setDropdownOpen(false);
    };
    

    const selectedStatusCount = Object.keys(statusValues).filter(key => statusValues[key]).length;

    return (
        <ButtonDropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle color="light" caret>
            Filter Customers
            {selectedStatusCount > 0 && <Badge color="primary" className="ml-2">{selectedStatusCount}</Badge>}
            {archived && <Badge color="secondary" className="ml-2">Archived</Badge>}
        </DropdownToggle>
        <DropdownMenu>
            <div className="dropdown-section">
                <div className="section-title">Filter by Customer Status</div>
                <div className="status-grid">
                    {customerStatuses.map(status => (
                        <CustomInput
                            key={status.id}
                            type="checkbox"
                            id={status.id}
                            label={status.name}
                            checked={!!statusValues[status.id]}
                            onChange={() => handleStatusChange(status.id)}
                            className="custom-input"
                        />
                    ))}
                </div>
            </div>
            <div className="px-3 py-2" style={{ fontWeight: 'bold' }}>Filter Archived Customer</div>
            <div className="dropdown-section archived-section">
                <CustomInput
                    id="archived"
                    label="Archived"
                    type="checkbox"
                    checked={archived}
                    onChange={handleArchivedChange}
                    className="custom-input"
                />
            </div>
            <div className="d-flex justify-content-between px-3 py-2">
                <Button color="secondary" onClick={resetFilters} className="mr-2">Reset</Button>
                <Button color="primary" onClick={applyFilters}>Apply</Button>
            </div>
        </DropdownMenu>
    </ButtonDropdown>
    );
}

export default StatusFilter;
