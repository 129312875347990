import React, { useEffect, useState, useContext } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    Badge
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import axios from 'axios';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { UserContext } from '../custom-contexts/UserContext';
import { Helmet } from 'react-helmet';
import { OmniApi } from '../customAPI/OmniApi';
import { commonHelper } from '../helpers/commonHelper';

const RemotePagination = ({ data, columns, page, sizePerPage, onTableChange, totalSize, showTotal = true, paginationTotalRenderer }) => {
    const { SearchBar } = Search;
    const { ExportCSVButton } = CSVExport;

    let history = useHistory();

    // The onCLick in the row event routes the user to the brand detail page when row is clicked on
    const rowEvents = {
        onClick: (e, row) => {

            const location = {
                pathname: `/omni/catalog/coupon/${row.couponToken}`,
                state: {
                    row
                }
            }

            history.push(location)
        }
    }

    const rowClasses = (row, rowIndex) => {
        return 'table-row';
    };

    const paginationOptions = {
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        sizePerPageList: [
            {
                text: '10',
                value: 10,
            },
            {
                text: '20',
                value: 20,
            },
            {
                text: '60',
                value: 60,
            },
            {
                text: '100',
                value: 100,
            },
        ], // A numeric array is also available. the purpose of above example is custom the text
    };

    return (
        <ToolkitProvider
            bootstrap4
            keyField="couponToken"
            data={data}
            columns={columns}
            search
            exportCSV={{ onlyExportFiltered: true, exportAll: false }}>
            {props => (
                <React.Fragment>
                    <Row className="mb-2">
                        <Col>
                            <Link to="/omni/catalog/coupon" className="btn btn-danger">
                                <i className="mdi mdi-plus-circle mr-2"></i>Add Coupon
                            </Link>
                        </Col>
                        <Col className="text-right">
                            <ExportCSVButton {...props.csvProps} className="export-csv-btn btn btn-primary mr-3">
                                Export CSV
                            </ExportCSVButton>
                            <SearchBar {...props.searchProps} placeholder='Search coupons' />
                        </Col>
                    </Row>

                    <BootstrapTable
                        {...props.baseProps}
                        remote
                        bordered={false}
                        headerClasses="thead-light"
                        // defaultSorted={ defaultSorted }
                        pagination={paginationFactory({ page, sizePerPage, totalSize, paginationTotalRenderer, showTotal })}
                        wrapperClasses="table-responsive"
                        classes="coupons-table"
                        rowEvents={rowEvents}
                        // selectRow={ selectRow }
                        rowClasses={rowClasses}
                        hover
                        onTableChange={onTableChange}
                    // noDataIndication={() => 'No results...'}
                    />
                </React.Fragment>
            )}
        </ToolkitProvider>
    );
}

const Coupons = () => {

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const history = useHistory();

    const { user } = useContext(UserContext);

    // const [selectedRows, setSelectedRows] = useState([]);
    const [coupons, setCoupons] = useState([]);

    const [paginationValues, setPaginationValues] = useState({
        page: parseInt(query.get('page')),
        data: [],
        sizePerPage: parseInt(query.get('sizePerPage')),
        totalSize: ''
    })

    // Gets the pagination query params from the url and sets the correct data to be displayed in that pages table
    useEffect(() => {

    }, [coupons])

    useEffect(() => {
        if (!location.search) {
            query.set('page', 1);
            query.set('sizePerPage', 15);
            query.set('sortOrder', 'asc');
            query.set('sortField', 'name');
            history.push(location.pathname + "?" + query.toString());
        }
        let userToken = localStorage.getItem('OMNI_TOKEN');
        const page = parseInt(query.get('page'));
        const sizePerPage = parseInt(query.get('sizePerPage'));
        const sortOrder = query.get('sortOrder');
        const sortField = query.get('sortField');
        const searchQuery = query.get('searchText');
        const getUrlParams = commonHelper.buildQueryParams(page, sizePerPage, sortOrder, sortField, searchQuery);
        OmniApi.get(`/couponcodes` + getUrlParams)
            .then(res => {
                setCoupons((res.data) ? res.data : res);
                const page = parseInt(query.get('page'));
                const sizePerPage = parseInt(query.get('sizePerPage'));
                const currentIndex = (page - 1) * sizePerPage;

                const dataObj = (res?.data) ? res?.data : res?.splice(0, sizePerPage);//await commonHelper.filterData(res,'limit',sizePerPage,'');
                const totaSize = (res?.total) ? res?.total : (res?.length) / sizePerPage;
                setPaginationValues({
                    ...paginationValues,
                    sortOrder,
                    sortField,
                    data: dataObj,
                    totalSize: (res?.total) ? res?.total : Math.round(totaSize),
                })
                commonHelper.goOnToOfThePage();
            });
    }, [location.search]);

    // Set default pagination query params if none are existent
    // useEffect(() => {
    //     if (!location.search){
    //         query.set('page', 1);
    //         query.set('sizePerPage', 15);
    //         console.log('query', query)
    //         history.push(location.pathname + "?" + query.toString());
    //     }
    // }, [])

    const columns = [
        {
            dataField: 'name',
            text: 'Coupon Code',
            sort: true,
            style: { verticalAlign: 'middle' },
            formatter: (cell, row) => (
                <strong>{cell}</strong>
            )
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: false,
            style: { verticalAlign: 'middle' },
            formatter: (cell, row) => (
                row.status ?
                    <Badge color="primary">
                        Active
                    </Badge>
                    :
                    <Badge color="secondary">
                        Inactive
                    </Badge>
            )
        },
        {
            dataField: 'discountType',
            text: 'Coupon Type',
            sort: false,
            style: { verticalAlign: 'middle' },
            formatter: (cell, row) => {
                if (cell === 'freeshipping')
                    return 'Free Shipping'
                if (cell === 'percent')
                    return 'Percentage'
                if (cell === 'fixed')
                    return 'Fixed Amount'
            }
        },
        {
            dataField: 'discountValue',
            text: 'Amount',
            sort: false,
            style: { verticalAlign: 'middle' },
            formatter: (cell, row) => {
                if (row.discountType === 'freeshipping')
                    return '100%'
                if (row.discountType === 'percent')
                    return `${cell}%`
                if (row.discountType === 'fixed')
                    return `$${cell}`
            }
        }
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total ml-2">
            Showing <strong>{from}</strong> to <strong>{to}</strong> of <strong>{size}</strong> Results
        </span>
    );

    const [tableChangeType, setTableChangeType] = useState('');

    // When pagination query params change, update the pagination values
    useEffect(() => {
        if (!location.search) {
            query.set('page', 1);
            query.set('sizePerPage', 15);
            query.set('sortOrder', 'asc');
            query.set('sortField', 'name');
            history.push(location.pathname + "?" + query.toString());
        }
        const page = parseInt((query.get('page'))?query.get('page'):1);
        const sizePerPage = parseInt((query.get('sizePerPage'))?query.get('sizePerPage'):10);
        const currentIndex = (page - 1) * sizePerPage;
        const sortOrder = query.get('sortOrder');
        const sortField = query.get('sortField');
        const searchText = query.get('searchText')

        let result = coupons;

        if (searchText) {
            //  If user search something
            //  You can ignore this part, it's just some search logic
            if(result?.data){

            } else {
            result = result.filter((row) => {
                for (let cidx = 0; cidx < columns.length; cidx += 1) {
                    const column = columns[cidx];
                    let targetValue = row[column.dataField];
                    if (targetValue !== null && typeof targetValue !== 'undefined') {
                        targetValue = targetValue.toString().toLowerCase();
                        if (targetValue.indexOf(searchText) > -1) {
                            return true;
                        }
                    }
                }
                return false;
            });
        }
        }

        if (sortOrder === 'asc') {
            if (result?.data) {

            } else {
                result = result.sort((a, b) => {
                    if (a[sortField] > b[sortField]) {
                        return 1;
                    } else if (b[sortField] > a[sortField]) {
                        return -1;
                    }
                    return 0;
                });
            }
        } else {
            if (result?.data) {

            } else {
                result = result.sort((a, b) => {
                    console.log(a[sortField]);
                    console.log(b[sortField]);
                    if (a[sortField] > b[sortField]) {
                        return -1;
                    } else if (b[sortField] > a[sortField]) {
                        return 1;
                    }
                    return 0;
                });
            }
        }

        setPaginationValues({
            page: page,
            sizePerPage: sizePerPage,
            data: (result?.data) ? result?.data : result.slice(currentIndex, currentIndex + sizePerPage),
            totalSize: (result?.data) ? result?.total : result.length
        })

    }, [location.search, coupons])

    const onTableChange = (type, { sortField, sortOrder, page, sizePerPage, searchText, filters }) => {
        setTableChangeType(type);
        if (type === 'pagination') {
            query.set('page', page);
            query.set('sizePerPage', sizePerPage);
        }
        if (type === 'sort') {
            query.set('sortField', sortField);
            query.set('sortOrder', sortOrder);
        }
        if (type === 'search') {
            query.set('searchText', searchText);
        }
        history.replace(location.pathname + "?" + query.toString());
    }

    const { data, sizePerPage, page, totalSize } = paginationValues;

    if (user) {
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Coupons | {process.env.REACT_APP_HEADER_TITLE}</title>
                </Helmet>
                <h2 className="page-title">
                    Coupons
                </h2>
                <Card className="mt-2">
                    <CardBody>
                        <RemotePagination
                            data={data}
                            page={page}
                            columns={columns}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            onTableChange={onTableChange}
                            paginationTotalRenderer={customTotal}
                            showTotal={true}
                        />
                    </CardBody>
                </Card>
            </>
        )
    } else {
        return (
            <h1>Loading</h1>
        )
    }
}

export default Coupons;