
import React, { useEffect, useState, useContext } from 'react';
import PageTitle from "../components/PageTitle";
import { Helmet } from 'react-helmet';
import { Button, Card, Col, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import MaskedInput from 'react-text-mask';
import { useHistory} from 'react-router-dom';
import { CommonApiRequest } from '../services/commonApiRequest';
import { toast } from 'react-toastify';
const NewCustomer = props => { 
    const [isDisable,setIsDisable]=useState(false);
    const [isEmailValid,setIsEmailValid]=useState(true);
    const history = useHistory();
    const [validateState,setValidateState]=useState({
        fname:false
    });
    const [newCustomer,setNewCustomer]=useState({
        fname:'',
        lname:'',
        email:'',
        phone:'',
        company:''
    });
    const resetForm=()=>{
        setNewCustomer({
            fname:'',
            lname:'',
            email:'',
            phone:'',
            company:''
        });
    }
    const saveNewCustomer=()=>{
        setValidateState({fname:false,lname:false})
        if(newCustomer?.fname!=="" && newCustomer?.lname!==""){
            if(isEmailValid){
                let customerSavingToast = toast.loading('Saving customer...', {
                    theme: 'light',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsDisable(true);
                CommonApiRequest.createNewCustomer({
                    customerFirstName:newCustomer?.fname,
                    customerLastName:newCustomer?.lname,
                    customerEmail:newCustomer?.email,
                    customerPhone:newCustomer?.phone,
                    customerCompanyName:newCustomer?.company
                }).then((response)=>{
                    if(response?.customerToken){
                        toast.update(customerSavingToast,
                        {
                            render: "Customer created successfully!",
                            type: "success",
                            isLoading: false,
                            autoClose: null,
                            hideProgressBar: null,
                            closeOnClick: null,
                        });
                        history.push("/omni/customer/"+response?.customerToken);
                    }
                }).catch(()=>{
                    toast.error("Please fill the required field");
                })
            }
        } else {
            const validationStatus = {};
           if(newCustomer?.fname===""){
            validationStatus.fname=true
           }
           if(newCustomer?.lname===""){
            validationStatus.lname=true
           }
           setValidateState(validationStatus);
        }
        
    }
    const handleInputChange=(e)=>{
        const target = e.target;
        const name = target.name;
        setNewCustomer({...newCustomer,[name]:target?.value});
    }
    return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>New Customer | {process.env.REACT_APP_HEADER_TITLE}</title>
            </Helmet>
            <PageTitle
                    breadCrumbItems={[
                        { label: 'Customers', path: '/omni/customers' },
                        { label: 'New Customer', path: props.location.pathname, active: true },
                    ]}
                    title={"New Customer"}
                />
            <Card className='p-3'>
                <form>
                    <Row>
                        <Col sm={12} md={6} lg={4}>
                            <FormGroup>
                                <Label for="name">First Name <span className='required-field'>*</span></Label>
                                <Input name="fname" id="fname" value={newCustomer?.fname} 
                                onChange={(e)=>{
                                    handleInputChange(e)
                                }}
                                placeholder=""
                                />
                                {validateState.fname ? <div className="invalid-text">Customer first name required</div> : ''}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <FormGroup>
                                <Label for="name">Last Name <span className='required-field'>*</span></Label>
                                <Input name="lname" id="lname" value={newCustomer?.lname} 
                                onChange={(e)=>{
                                    handleInputChange(e)
                                }}
                                placeholder=""
                                />
                                {validateState.lname ? <div className="invalid-text">Customer last name required</div> : ''}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <FormGroup>
                                <Label for="name">Email</Label>
                                <Input name="email" id="email" value={newCustomer?.email} 
                                onChange={(e)=>{
                                    handleInputChange(e);
                                    
                                }}
                                onBlur={(e)=>{
                                    var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                                        if(e.target.value!==""){
                                        if(!regex.test(e.target.value)){
                                            setIsEmailValid(false)
                                        } else {
                                            setIsEmailValid(true) 
                                        }
                                    } else {
                                        setIsEmailValid(true) 
                                    }
                                }}
                                placeholder=""
                                />
                                {!isEmailValid ? <div className="invalid-text">Customer valid email address</div> : ''}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <FormGroup>
                                <Label for="name">Phone</Label>
                                <MaskedInput
                            mask={[
                                '(',
                                /[1-9]/,
                                /\d/,
                                /\d/,
                                ')',
                                ' ',
                                /\d/,
                                /\d/,
                                /\d/,
                                '-',
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                            ]}
                            name="phone"
                            id="phone"
                            value={newCustomer.phone}
                            onChange={(e) => handleInputChange(e)}
                            placeholder=""
                            className="form-control"
                        />
                            </FormGroup>
                            {/* (___) ___-____ */}
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <FormGroup>
                                <Label for="name">Company</Label>
                                <Input name="company" id="company" value={newCustomer?.company} 
                                onChange={(e)=>{
                                    handleInputChange(e)
                                }}
                                placeholder=""
                                />
                                
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={12} sm={12} className='d-flex justify-content-end'>
                        <Button
                                color="dark"
                                type="reset"
                                onClick={() => {resetForm()}}
                                className="export-csv-btn btn btn-primary mr-3"
                                disabled={isDisable}>
                                Reset
                            </Button>
                        <Button
                                color="primary"
                                type="button"
                                onClick={() => {saveNewCustomer()}}
                                className="export-csv-btn btn btn-primary mr-2"
                                disabled={isDisable}>
                                Save
                            </Button>
                        </Col>
                    </Row>
                </form>
            </Card>
        </>
    )
}
export default NewCustomer;