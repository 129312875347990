import React, { useContext } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';

// lazy load all the views

//components
import Reports from '../components/Reports';
import AngularMessage from '../components/AngularMessage';
//custom-components
import Table from '../custom-components/Table';
import Brands from '../custom-components/Brands';
import Dashboard from '../custom-components/Dashboard';
import Collections from '../custom-components/Collections';
import Navigation from '../custom-components/Navigation';
import Products from '../custom-components/Products';
import BrandDetail from '../custom-components/BrandDetail';
import Orders from '../custom-components/Orders';
import Customers from '../custom-components/Customers';
import Container from '../custom-components/RemoteFilter';
import Coupons from '../custom-components/Coupons';
import CustomerProfile from '../custom-components/CustomerProfile';
import { UserContext } from '../custom-contexts/UserContext';
import OrderDetail from '../custom-components/OrderDetail';
import Test from '../custom-components/Test';
import Product from '../custom-components/Product';
import VariantDetail from '../custom-components/VariantDetail';
import ImagePoolDragAndDrop from '../custom-components/ImagePoolDragAndDrop';
import VariantTable from '../custom-components/VariantTable';
import VariantDragInput from '../custom-components/VariantDragInputs';
import CollectionCards from '../custom-components/CollectionCards';
import Collection from '../custom-components/Collection';
import NavigationDetail from '../custom-components/NavigationDetail';
import Coupon from '../custom-components/Coupon';
import InventoryList from '../custom-components/InventoryList';
import FloatingSaveButton from '../custom-components/FloatingSaveButton';
import Settings from '../custom-components/Settings';
import CurrencyInput from '../utility-input-components/CurrencyInput';
import Apps from '../custom-components/Apps';
import CustomCKEditor from '../utility-components/CustomCKEditor';
import Loader from '../components/Loader';
// Error Boundary and Component
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallbackModal from '../utility-components/ErrorFallbackModal';
import Quotes from '../custom-components/Quotes';
import ResetPassword from '../pages/auth/ResetPassword';
import QuoteDetail from '../custom-components/QuoteDetail';
import NewCustomer from '../custom-components/NewCustomer';
import Discount from '../custom-components/Discount';
import Discounts from '../custom-components/Discounts';
import GiftCards from '../custom-components/GiftCards';
import GiftCard from '../custom-components/GiftCard';
import Sale from '../custom-components/Sale';
import DraftSale from '../custom-components/DraftSale';
// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
// dashboard
const EcommerceDashboard = React.lazy(() => import('../pages/dashboards/Ecommerce'));
const CRMDashboard = React.lazy(() => import('../pages/dashboards/CRM'));
const AnalyticsDashboard = React.lazy(() => import('../pages/dashboards/Analytics'));
// apps
const CalendarApp = React.lazy(() => import('../pages/apps/Calendar'));

// handle auth and authorization

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
    const { isLoggedIn, checkingUserStatus, user } = useContext(UserContext)
    return (
        <Route
            {...rest}
            render={props => {
                if (checkingUserStatus) {
                    return <Loader />
                } else {
                    if (!user) {
                        return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />
                    } else {
                        // authorised so return component
                        // add error boundary to every rendered component to catch errors
                        return (
                            <ErrorBoundary
                                FallbackComponent={ErrorFallbackModal}
                            >
                                <Component {...props} />
                            </ErrorBoundary>
                        )
                    }
                }
            }}
        />
    )
};

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/omni/dashboard" />,
    route: PrivateRoute,
};

// dashboards
// const dashboardRoutes = {
//     path: '/dashboard',
//     name: 'Dashboards',
//     icon: 'uil-desktop',
//     children: [
//         {
//             path: '/dashboard/analytics',
//             name: 'Analytics',
//             component: AnalyticsDashboard,
//             route: PrivateRoute,
//         },
//         {
//             path: '/dashboard/crm',
//             name: 'CRM',
//             component: CRMDashboard,
//             route: PrivateRoute,
//         },
//         {
//             path: '/dashboard/ecommerce',
//             name: 'Ecommerce',
//             badge: {
//                 variant: 'success',
//                 text: '3',
//             },
//             component: EcommerceDashboard,
//             route: PrivateRoute,
//         },
//         {
//             path: '/dashboard/project',
//             name: 'Project',
//             component: ProjectDashboard,
//             route: PrivateRoute,
//         },
//     ],
// };

const dashboardRoutes = {
    path: '/omni/dashboard',
    name: 'Dashboard',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-desktop',
    component: Dashboard
};

const ordersAppRoutes = {
    path: '/omni/orders/',
    name: 'Orders',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-store',
    component: Orders,
    children: [
        {
            path: '/omni/orders/',
            name: 'Orders',
            component: Orders,
            route: PrivateRoute,
        },

       /*  {
            path: '/omni/order/draft/list',
            name: 'Drafts List',
            component: DraftSale,
            route: PrivateRoute,
        } */

    ],
};

const quotesAppRoutes = {
    path: '/omni/quotes',
    name: 'Quotes',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-clipboard-notes',
    component: Quotes
};

const catalogAppRoutes = {
    path: '/omni/catalog',
    name: 'Catalog',
    icon: 'uil-tag-alt',
    header: <hr />,
    children: [
        {
            path: '/omni/catalog/products',
            name: 'Products',
            component: Products,
            route: PrivateRoute,
        },
        {
            path: '/omni/catalog/collections',
            name: 'Collections',
            component: CollectionCards,
            route: PrivateRoute,
        },
        {
            path: '/omni/catalog/brands',
            name: 'Brands',
            component: Brands,
            route: PrivateRoute,
        },
        {
            path: '/omni/catalog/coupons',
            name: 'Coupons',
            component: Coupons,
            route: PrivateRoute,
        },
        // {
        //     path: '/omni/catalog/discounts',
        //     name: 'Discounts',
        //     component: Discounts,
        //     route: PrivateRoute,
        // },

        /* {
            path: '/omni/catalog/giftcards',
            name: 'Gift Cards',
            component: GiftCards,
            route: PrivateRoute,
        }, */

        {
            path: '/omni/catalog/inventory',
            name: 'Inventory',
            component: InventoryList,
            route: PrivateRoute,
        },
    ],
};

const customersAppRoutes = {
    path: '/omni/customers',
    name: 'Customers',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-user',
    component: Customers
};

const appsAppRoutes = {
    path: '/omni/apps',
    name: 'Apps',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-apps',
    header: <hr />,
    component: Apps
};

/* const saleRoutes = {
    path: '#',//'/omni/order/new',
    name: 'Point of Sale',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-apps',
    header: <hr />,
    component: Sale, */
    // children: [
    //     {
    //         path: '/omni/catalog/order/draft/list',
    //         name: 'Drafts',
    //         component: DraftSale,
    //         route: PrivateRoute,
    //     },
    //     {
    //         path: '/omni/catalog/order/draft/new',
    //         name: 'New Order',
    //         component: Sale,
    //         route: PrivateRoute,
    //     },
    // ],
//};
const reportsAppRoutes = {
    path: '/omni/reports',
    name: 'Reports',
    route: PrivateRoute,
    roles: ['Admin'],
    icon: 'uil-file-alt',
    component: Reports
};

// const tableAppRoutes = {
//     path: '/apps/table',
//     name: 'Table',
//     route: Route,
//     roles: ['Admin'],
//     icon: 'uil-rss',
//     component: Table,
// };

// const formAppRoutes = {
//     path: '/apps/form',
//     name: 'Form',
//     route: Route,
//     roles: ['Admin'],
//     icon: 'uil-rss',
//     component: FormPage,
// };

const navigationAppRoutes = {
    path: '/omni/navigations',
    name: 'Navigation',
    route: Navigation,
    roles: ['Admin'],
    icon: 'uil-location-arrow',
    component: AngularMessage
};

const settingsAppRoutes = {
    path: '/omni/settings',
    name: 'Settings',
    icon: 'uil-cog',
    header: <hr />,
    component: Settings,
    route: PrivateRoute
    // children: [
    //     {
    //         path: '/omni/settings',
    //         name: 'placeholder',
    //         component: Settings,
    //         route: PrivateRoute,
    //     },
    //     {
    //         path: '/omni/catalog/collections',
    //         name: 'placeholder',
    //         component: EcommerceDashboard,
    //         route: PrivateRoute,
    //     },
    // ],
};
let appRoutes = [];
if ((process.env.REACT_APP_ENV === 'development')) {
    appRoutes = [
        ordersAppRoutes,
        customersAppRoutes,
        catalogAppRoutes,
        appsAppRoutes,
        navigationAppRoutes,
        quotesAppRoutes,
        reportsAppRoutes,

        //saleRoutes,

        settingsAppRoutes,
        
        // tableAppRoutes,
        // formAppRoutes
        // chatAppRoutes,
        // ecommerceAppRoutes,
        // emailAppRoutes,
        // projectAppRoutes,
        // socialAppRoutes,
        // taskAppRoutes,
    ];
} else {
    appRoutes = [
        ordersAppRoutes,
        customersAppRoutes,
        catalogAppRoutes,
        appsAppRoutes,
        navigationAppRoutes,
        quotesAppRoutes,
        reportsAppRoutes,

       // saleRoutes,

        settingsAppRoutes,
        
        // tableAppRoutes,
        // formAppRoutes
        // chatAppRoutes,
        // ecommerceAppRoutes,
        // emailAppRoutes,
        // projectAppRoutes,
        // socialAppRoutes,
        // taskAppRoutes,
    ];
}
// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: PrivateRoute,
        },
        {
            path: '/account/register',
            name: 'Register',
            component: Register,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
        {
            path: '/account/reset-password/:hash',
            name: 'Reset Password',
            component: ResetPassword,
            route: Route,
        },
    ],
};

// unassigned routes
const testRoutes = {
    path: '/detail',
    name: 'Detail',
    children: [
        {
            path: ['/test/input'],
            name: 'Customer',
            component: CurrencyInput,
            route: Route,
        },
        {
            path: ['/test/table'],
            name: 'Customer',
            component: VariantTable,
            route: Route,
        },
        {
            path: ['/test/input'],
            name: 'Customer',
            component: VariantDragInput,
            route: Route,
        },
        {
            path: ['/test/collection'],
            name: 'Customer',
            component: Collection,
            route: Route,
        },
        {
            path: ['/test/button'],
            name: 'Button',
            component: FloatingSaveButton,
            route: Route,
        },
        {
            path: ['/test/ckeditor'],
            name: 'CKEDITOR',
            component: CustomCKEditor,
            route: Route,
        }
    ],
};

const detailRoutes = {
    path: '/detail',
    name: 'Detail',
    children: [
        {
            path: ['/omni/catalog/brand/:brandToken', '/omni/catalog/brand'],
            name: 'BrandDetail',
            component: BrandDetail,
            route: PrivateRoute,
            exact: true
        },
        {
            path: ['/omni/catalog/order/:orderToken'],
            name: 'Order Detail',
            component: OrderDetail,
            route: PrivateRoute,
            exact: true
        },
        {
            path: ['/omni/customer/new'],
            name: 'New Customer',
            component: NewCustomer,
            route: PrivateRoute,
        },
        {
            path: ['/omni/customer/:customerToken'],
            name: 'Customer Profile',
            component: CustomerProfile,
            route: PrivateRoute,
        },
        {
            path: ['/omni/quote/:quoteToken'],
            name: 'Quote Detail',
            component: QuoteDetail,
            route: PrivateRoute,
        },
        {
            path: ['/omni/catalog/product/:prodToken', '/omni/catalog/product'],
            name: 'Product Detail',
            component: Product,
            route: PrivateRoute,
            exact: true
        },
        {
            path: ['/omni/catalog/collection/:collectionToken', '/omni/catalog/collection'],
            name: 'Product Detail',
            component: Collection,
            route: PrivateRoute,
            exact: true
        },
        {
            path: ['/omni/catalog/product/:prodToken/:variantToken'],
            name: 'Variant Detail',
            component: VariantDetail,
            route: PrivateRoute,
            exact: true
        },
        {
            path: ['/omni/navigation/:navigationToken', '/omni/navigation'],
            name: 'Navigation Detail',
            component: NavigationDetail,
            route: PrivateRoute,
            exact: true
        },
        {
            path: ['/omni/catalog/coupon/:couponToken', '/omni/catalog/coupon'],
            name: 'Coupon Detail',
            component: Coupon,
            route: PrivateRoute,
            exact: true
        },
        // {
        //     path: ['/omni/catalog/discount/:discountToken', '/omni/catalog/discount'],
        //     name: 'Coupon Detail',
        //     component: Discount,
        //     route: PrivateRoute,
        //     exact: true
        // },
         {
            path: ['/omni/catalog/giftcard/:discountToken', '/omni/catalog/giftcard'],
            name: 'GiftCards Detail',
            component: GiftCard,
            route: PrivateRoute,
            exact: true
        },
        {
            path: '/omni/catalog/order/draft/edit/:token',
            name: 'Edit Order',
            component: Sale,
            route: PrivateRoute,
        },
        {
            path: '/omni/order/draft/new',
            name: 'New Order',
            component: Sale,
            route: PrivateRoute,
        },
    ],
};


// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];
    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [rootRoute, dashboardRoutes, ...appRoutes, authRoutes, testRoutes, detailRoutes];

const authProtectedRoutes = [dashboardRoutes, ...appRoutes];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
