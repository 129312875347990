import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    CardBody,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ListGroup,
    ListGroupItem,
    ListGroupItemHeading,
    ListGroupItemText,
    Table,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Media,
    UncontrolledDropdown,
    CustomInput,
    FormGroup,
    Label,
    Input,
    InputGroup,
    InputGroupAddon,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    UncontrolledTooltip,
    UncontrolledPopover, PopoverHeader, PopoverBody, CardHeader
} from 'reactstrap';
import PageTitle from '../components/PageTitle';
import { Icon, InlineIcon } from '@iconify/react';
import checkIcon from '@iconify-icons/uil/check';
import multiplyIcon from '@iconify-icons/uil/multiply';
import creditCard from '@iconify-icons/uil/credit-card';
import processIcon from '@iconify-icons/uil/process';

// import images
import AmexImg from '../assets/images/payments/american-express.png';
import MastercardImg from '../assets/images/payments/master.png';
import DiscoverImg from '../assets/images/payments/discover.png';
import VisaImg from '../assets/images/payments/visa.png';

import MaskedInput from 'react-text-mask';
import classnames from 'classnames';
import SimpleBar from 'simplebar-react';
import Select from 'react-select';
import profileImg from '../assets/images/users/avatar-2.jpg';
import FileUploader from '../components/FileUploader';
import moment from "moment";
import { useToasts } from 'react-toast-notifications';
import { toast } from 'react-toastify';


import axios from 'axios';
import { OmniApi } from '../customAPI/OmniApi';

import { UserContext } from '../custom-contexts/UserContext';
import { useHistory } from 'react-router-dom';
import informationOutline from '@iconify/icons-mdi/information-outline';
import { Typeahead } from 'react-bootstrap-typeahead';
import { StateList } from '../data/StateList';
import { CommonApiRequest } from '../services/commonApiRequest';


const CustomerProfile = props => {

    const { user } = useContext(UserContext);

    const { addToast } = useToasts();

    const [customerInfo, setCustomerInfo] = useState('');
    const [customerOrders, setCustomerOrders] = useState([]);
    const [customerAddresses, setCustomerAddresses] = useState([]);
    const [customerStatuses, setCustomerStatuses] = useState([]);
    const [customerInfoChanged, setCustomerInfoChanged] = useState(false);
    const [isTaxExempt,setIsTaxExempt] = useState(false);
    const [isSaving,setIsSaving] = useState(false);
    const [shippingAddressModal, setShippingAddressModal] = useState(false);
    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }
    function getCustomerData(customerToken){
        
        OmniApi.get(`/customers/${customerToken}`)
            .then(res => {
                if (isEmpty(res.data))
                    throw new Error('Invalid Customer Token');
                res.data.taxExempt = (res?.data?.taxExempt)?res.data.taxExempt:false;
                setIsTaxExempt(res.data.taxExempt);
                setCustomerInfo(res.data)
            })
            .catch(err => {
                props.history.replace('/omni/customers')
                toast.error(err.message)
            })
    }
    useEffect(() => {
        let customerToken = props.match.params.customerToken;
        // get customer details
        getCustomerData(customerToken);
        //get customers orders
        OmniApi.get(`/orders/${customerToken}`)
            .then(res => {
                if (res.data.error) {
                    return
                } else {
                    setCustomerOrders(res.data)
                }
                setIsSaving(false);
            })
            .catch(err => { setIsSaving(false);})
        // get customer statuses
        OmniApi.get(`/lists/customerstatuses`)
            .then(res => setCustomerStatuses(res.data))
            .catch(err => { })
        // get customer addresses
        OmniApi.get(`/addresses/${customerToken}`)
            .then(res => setCustomerAddresses(res.data))
            .catch(err => { })

    }, [])

    const handleCustomerInfoChange = (customerInfo) => {
        setCustomerInfo(customerInfo)
        setCustomerInfoChanged(true);
    }

    // this prevents the following useEffect from running the first time. Only runs on second render

    useEffect(() => {
        console.log('customerInfoChanged')
        console.log(customerInfoChanged);
        if (customerInfoChanged)
            saveCustomerInfo()

        setCustomerInfoChanged(false)

    }, [customerInfo])

    const saveCustomerInfo = (isTax=false) => {
            setIsSaving(true);
        let postCustomer = OmniApi.post(`/customers/${customerInfo.customerToken}`, customerInfo)
            .then(res => {
                //setCustomerInfo(res.data)
                getCustomerData(customerInfo.customerToken);
                setIsSaving(false);
            }).catch(()=>{
                setIsSaving(false);
            })
            if(isTax){
                toast.promise(
                    postCustomer,
                    {
                        pending: {
                            render() {
                                return "Saving customer..."
                            },
                            icon: true,
                        },
                        success: {
                            render(res) {
                                return `Customer info successfully saved`
                            },
                            // other options
                            icon: true,
                        },
                        error: {
                            render(err) {
                                // When the promise reject, data will contains the error
                                return `err`
                            },
                            icon: true,
                        }
                    }
                )
            }

    }

    const [activeTab, setActiveTab] = useState('tab1');

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }
    const toggleShippingAddressModal = () => {
        setShippingAddressModal(!shippingAddressModal)
    }
    const shippingAddressCallbackForm=(data)=>{
        const addresses = [...customerAddresses];
        addresses.push(data);
        console.log(addresses);
        setCustomerAddresses(addresses);
        toggleShippingAddressModal();
    }
    if (user) {
        return (
            <>
                <PageTitle
                    breadCrumbItems={[
                        { label: 'Customers', path: '/omni/customers' },
                        { label: `${(customerInfo.customerFirstName || customerInfo.customerLastName) ? customerInfo.customerFirstName + ' ' + customerInfo.customerLastName : '**Guest**'}`, path: props.location.pathname, active: true },
                    ]}
                    title={(customerInfo.customerFirstName || customerInfo.customerLastName) ? customerInfo.customerFirstName + ' ' + customerInfo.customerLastName : <i>**Guest**</i>}
                />
                {/* <div className="d-flex justify-content-between mt-2">
            <h2>{(customerInfo.customerFirstName || customerInfo.customerLastName) ? customerInfo.customerFirstName + ' ' + customerInfo.customerLastName : <i>**Guest**</i>}</h2>
            <AdditionalOptionsDropdown />
        </div> */}
                <Nav tabs className="nav-pills bg-nav-pills nav-justified my-1 mb-2">
                    <NavItem key={1}>
                        {/* <NavLink
                            href="#"
                            className={classnames({ active: activeTab === 'tab1' })}
                            onClick={() => toggle('tab1')}>
                            <span className="d-none d-lg-block"><strong>Profile</strong></span>
                        </NavLink> */}
                    </NavItem>
                    {/* <NavItem key={2}>
                        <NavLink
                            href="#"
                            className={classnames({ active: activeTab === 'tab2' })}
                            onClick={() => toggle('tab2')}>
                           
                            <span className="d-none d-lg-block"><strong>Notes</strong></span>
                        </NavLink>
                    </NavItem> */}
                    {/* <NavItem key={3}>
                        <NavLink
                            href="#"
                            className={classnames({ active: activeTab === 'tab3' })}
                            onClick={() => toggle('tab3')}>
                            
                            <span className="d-none d-lg-block"><strong>Documents</strong></span>
                        </NavLink>
                    </NavItem> */}
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={'tab1'}>
                        <Row>
                            <Col md={8}>
                                <CustomerInfo customerInfo={customerInfo} customerOrders={customerOrders} handleCustomerInfoChange={handleCustomerInfoChange} />
                                <RecentOrdersTable customerOrders={customerOrders} />
                                <CustomerShippingAddress customerAddresses={customerAddresses}  onOpenModal={()=>{toggleShippingAddressModal()}}/>
                            </Col>
                            <Col md={4}>
                                <StatisticsWidgets customerOrders={customerOrders} />
                                <Card>
                                    <CardBody>
                                        <CustomerStatusMultipleSelect handleCustomerInfoChange={handleCustomerInfoChange} customerInfo={customerInfo} customerStatuses={customerStatuses} />
                                    </CardBody>
                                </Card>
                                <Card className='main-card-group'>
                                    <CardHeader><h4 class="header-title mt-2 inline-block">Tax Settings</h4></CardHeader>
                                    <CardBody>
                                    <CustomInput type="checkbox" id="isTaxableId1"
                                            name="isTaxableId1" label="Tax Exempt" inline
                                            checked={isTaxExempt}
                                            onChange={(e) => {
                                                    setIsTaxExempt(e.target.checked);
                                                    customerInfo.taxExempt=(e.target.checked)?1:0;
                                                    //setCustomerInfo({...customerInfo,taxExempt:e.target.checked});
                                                    saveCustomerInfo();
                                                    //saveInfo(props.customerInfo);
                                                }
                                            }
                                        >
                                            
                                        </CustomInput>  
                                        {/* {isTaxExempt && */}
                                            <div className='main-group'>
                                                <FormGroup>
                                                    <Label for="firstName">Tax Exempt Id</Label>
                                                    <div className='input-wrap'>
                                                        <Input type="text"
                                                            name="taxExemptID"
                                                            id="taxExemptID"
                                                            value={customerInfo?.taxExemptID}
                                                            onChange={(e) => {
                                                                setCustomerInfo({...customerInfo,
                                                                    taxExemptID:e.target.value
                                                                });
                                                            }}
                                                            placeholder="Tax Exempt Id" 
                                                            disabled={(!isTaxExempt)?true:false}
                                                            />
                                                        {isSaving && 
                                                            <Button color="primary disabled" disabled={isSaving}>
                                                                Save Info
                                                            </Button>
                                                        }
                                                        {!isSaving && 
                                                            <Button color="primary" onClick={saveCustomerInfo} disabled={(!isTaxExempt)?true:false}>
                                                                Save Info
                                                            </Button>
                                                        }
                                                    </div>
                                                </FormGroup>
                                            </div>
                                        {/* } */}
                                    </CardBody>
                                </Card>
                                
                                <Modal isOpen={shippingAddressModal} toggle={toggleShippingAddressModal} size='lg'>
                                    <ModalHeader toggle={toggleShippingAddressModal}><h4>Add new address</h4></ModalHeader>
                                    <ModalBody>
                                        <ShippingAddressForm customer={customerInfo} shippingAddressCallbackForm={(data) => {
                                            shippingAddressCallbackForm(data);
                                        }}></ShippingAddressForm>
                                    </ModalBody>
                                </Modal>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col md={8}>
                                <StatisticsWidgets customerOrders={customerOrders} />
                            </Col>
                            <Col md={4}>
                                <CustomerShippingAddress customerAddresses={customerAddresses} />
                            </Col>
                        </Row> */}
                        {/* <Row>
                            <Col md={4}>
                                <CustomerPaymentMethods />
                            </Col>
                            <Col md={4}>
                                <CustomerShippingAddress customerAddresses={customerAddresses} />
                            </Col>
                        </Row> */}
                        {/* <Row>
                            <Col md={10}>
                                <RecentOrdersTable customerOrders={customerOrders} />
                            </Col>
                        </Row> */}
                    </TabPane>
                </TabContent>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={'tab2'}>
                        <h3>Notes coming soon...</h3>
                        {/* <Row>
                    <Col>
                        <Notes />
                    </Col>
                </Row> */}
                    </TabPane>
                </TabContent>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={'tab3'}>
                        <h3>Documents coming soon...</h3>
                        {/* <Row>
                <Col md={6}>
                    <FileUpload />
                </Col>
            </Row> */}
                    </TabPane>
                </TabContent>
            </>
        )
    } else {
        return (
            <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
                <div>
                    <h4>Loading customer...</h4>
                    <div className="bouncing-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        )
    }

}

const epochToLocal = (epoch) => {
    let d = new Date(0);
    return d.setUTCSeconds(epoch);
}


const CustomerInfo = props => {
    const [editInfoModal, setEditInfoModal] = useState(false);
    const [isTaxExempt,setIsTaxExempt] = useState((props?.customerInfo?.taxExempt)?props?.customerInfo?.taxExempt:false);
    /*:: toggle: () => void */
    const toggle = () => {
        setEditInfoModal(!editInfoModal)
    }


    const ModalWithForm = (props) => {
        const [tempCustomerInfo, setTempCustomerInfo] = useState({ ...customerInfo });

        const handleInputChange = (e) => {
            const target = e.target;
            const name = target.name;

            setTempCustomerInfo({
                ...tempCustomerInfo,
                [name]: e.target.value
            })
        }

        const saveTempCustomerInfo = (tempInfo) => {
            props.handleCustomerInfoChange(tempInfo);
            setTempCustomerInfo({})
            toggle()
        }

        return (
            <Modal isOpen={editInfoModal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Edit Contact Information</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="firstName">First Name</Label>
                                <Input type="text"
                                    name="customerFirstName"
                                    id="firstName"
                                    value={tempCustomerInfo.customerFirstName}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder="First name" />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="lastName">Last Name</Label>
                                <Input type="text" name="customerLastName" id="lastName" placeholder="Last name"
                                    value={tempCustomerInfo.customerLastName}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label for="company">Company</Label>
                        <Input type="text" name="customerCompanyName" id="company" placeholder="Company name"
                            value={tempCustomerInfo.customerCompanyName}
                            onChange={(e) => handleInputChange(e)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="lastName">Email</Label>
                        <Input type="email" name="customerEmail" id="email" placeholder="Email"
                            value={tempCustomerInfo.customerEmail}
                            onChange={(e) => handleInputChange(e)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Phone</Label>
                        <MaskedInput
                            mask={[
                                '(',
                                /[1-9]/,
                                /\d/,
                                /\d/,
                                ')',
                                ' ',
                                /\d/,
                                /\d/,
                                /\d/,
                                '-',
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                            ]}
                            name="customerPhone"
                            id="phone"
                            value={tempCustomerInfo.customerPhone}
                            onChange={(e) => handleInputChange(e)}
                            placeholder=" eg. +09876543213"
                            className="form-control"
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={(e) => saveTempCustomerInfo(tempCustomerInfo)}>
                        Save Info
                    </Button>{' '}
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    const { customerInfo, customerOrders } = props;
    const saveInfo  = (custInfo) => {
        console.log('custInfo');
        console.log(custInfo);
        saveCustomerInfo(custInfo);
    }
    const saveCustomerInfo = (customerInfo) => {
        console.log('save')

        let postCustomer = OmniApi.post(`/customers/${customerInfo.customerToken}`, customerInfo)
            .then(res => {
                //setCustomerInfo(res.data)
            })

        toast.promise(
            postCustomer,
            {
                pending: {
                    render() {
                        return "Saving customer..."
                    },
                    icon: true,
                },
                success: {
                    render(res) {
                        return `Customer successfully saved`
                    },
                    // other options
                    icon: true,
                },
                error: {
                    render(err) {
                        // When the promise reject, data will contains the error
                        return `err`
                    },
                    icon: true,
                }
            }
        )

    }
    return (
        <>
            <Card className={classnames('widget-flat',)} style={{ height: '269.5px' }}>
                <CardBody>
                    <Row className="d-flex">
                        <h4 className="header-title mt-2 ml-2 inline-block">Contact Information</h4>
                        <div className="text-center mt-sm-0 mt-3 text-sm-right  ml-auto mr-0">
                            <button className="btn btn-primary" onClick={toggle}>
                                <i className="mdi mdi-account-edit ml-1"></i> Edit Info
                            </button>
                        </div>
                        {/* <div className="text-center mt-sm-0 mt-3 text-sm-right  ml-3 mr-2 d-flex align-items-center">
                            <CustomInput type="checkbox" id="isTaxableId"
                                name="isTaxableId" label="Tax Exempt" inline
                                checked={isTaxExempt}
                                onChange={(e) => {
                                        setIsTaxExempt(e.target.checked);
                                        props.customerInfo.taxExempt=e.target.checked;
                                        saveInfo(props.customerInfo);
                                    }
                                }
                            >
                                <Icon icon={informationOutline} style={{ marginLeft: '2px' }} color="#39afd1" id="checkVariantTaxable" />
                                <UncontrolledTooltip placement="top" target="checkVariantTaxable">
                                    Make  checked if want to apply tax exempt
                                </UncontrolledTooltip>
                            </CustomInput>
                        </div> */}
                    </Row>
                    <hr />
                    <Row>
                        <Col>

                            <div className="text-left">
                                <p className="">
                                    <strong>Full Name :</strong> <span className="ml-2">{customerInfo.customerFirstName} {customerInfo.customerLastName}</span>
                                    <small className="">
                                        <span className="mx-1">⚬</span>
                                        <span id="date-joined-tooltip">Customer for {moment(epochToLocal(customerInfo.dateCreated)).fromNow(true)}</span></small>
                                </p>

                                <p className="">
                                    <strong>Company:</strong> <span className="ml-2">{customerInfo.customerCompanyName}</span>
                                </p>

                                <p className="">
                                    <strong>Email :</strong> <span className="ml-2">{customerInfo.customerEmail}</span>
                                </p>

                                <p className="">
                                    <strong>Phone :</strong>
                                    <span className="ml-2">{customerInfo.customerPhone}</span>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <UncontrolledTooltip placement="top" target="date-joined-tooltip">
                Date joined: {moment(customerInfo.createdAt).format('ll')}
            </UncontrolledTooltip>
            <ModalWithForm handleCustomerInfoChange={props.handleCustomerInfoChange}/>
        </>
    )
}

const CustomerShippingAddress = props => {

    const { customerAddresses,onOpenModal } = props;

    const addresses = [
        {
            name: 'Johhny John',
            usedForShipping: true,
            usedForBilling: true,
            avsVerified: false,
            zipVerified: false
        },
        {
            name: 'Jake Jordan',
            usedForShipping: true,
            usedForBilling: true,
            avsVerified: false,
            zipVerified: true
        },
        {
            name: 'Jake Jordan',
            usedForShipping: true,
            usedForBilling: true,
            avsVerified: false,
            zipVerified: false
        },
        {
            name: 'Jake Jordan',
            usedForShipping: false,
            usedForBilling: false,
            avsVerified: false,
            zipVerified: false
        },
        {
            name: 'Jake Jordan',
            usedForShipping: false,
            usedForBilling: true,
            avsVerified: true,
            zipVerified: true
        }
    ]

    return (
        <Card>
            <CardHeader>
                <div className='d-flex'>
                    <h4 className="header-title mt-2 ml-2 inline-block">Saved Addresses</h4>
                    <div className="text-center mt-sm-0 mt-3 text-sm-right  ml-auto mr-0">
                        <button className="btn btn-primary" onClick={()=>{onOpenModal()}}>
                            <i className="mdi mdi-account-box ml-1"></i> Add new address
                        </button>
                    </div>
                </div>
            </CardHeader>
            <CardBody>
                    
                    <ListGroup className="address-card row flex-row" flush>
                        {customerAddresses.length ?
                            customerAddresses.map((address, i) => (
                                <ListGroupItem tag="li" href="#" action key={i} className='col-md-4'>
                                    <ListGroupItemHeading>{address.addressFirstName} {address.addressLastName}</ListGroupItemHeading>
                                    <ListGroupItemText>
                                        {address.address}, {address.address2}<br />
                                        {address.addressCity}, {address.addressState} {address.addressZip}<br />
                                        {address.phoneNumber}
                                    </ListGroupItemText>
                                </ListGroupItem>
                            ))
                            :
                            <ListGroupItem>
                                <ListGroupItemHeading>No Saved Addresses</ListGroupItemHeading>
                            </ListGroupItem>
                        }
                        {/* {addresses.map((address, i) => (
                        <ListGroupItem tag="a" href="#" action key={i}>
                            <ListGroupItemHeading>{address.name}</ListGroupItemHeading>
                                <ListGroupItemText>
                                    Doesn't matter right now <br />
                                    {address.usedForShipping && 
                                        <span className="badge badge-secondary-lighten badge-pill mr-1">
                                        Shipping 
                                        </span>
                                    }
                                    {address.usedForBilling && 
                                        <span className="badge badge-secondary-lighten badge-pill mr-1">
                                        Billing 
                                            {address.avsVerified ? <Icon icon={checkIcon} color="green" width="11" height="11" /> : <Icon icon={multiplyIcon} color="red" width="9" height="9" />}
                                            {address.zipVerified ? <Icon icon={checkIcon} color="green" width="11" height="11" /> : <Icon icon={multiplyIcon} color="red" width="9" height="9" />}
                                    </span>}
                                    {(!address.usedForShipping && !address.usedForBilling) && 
                                    <span className="badge badge-secondary-lighten badge-pill mr-1">
                                        Never Used 
                                    </span>}
                                </ListGroupItemText>
                        </ListGroupItem>
                        ))} */}
                    </ListGroup>
            </CardBody>
        </Card>
    )
}

const CustomerPaymentMethods = props => {

    // payment method name, brand, digits only hsow xses and last 4 didigits, exp, link billing address opens to small pop up

    const paymentMethodsdata = [
        {
            id: '1',
            name: 'Jason',
            brand: 'visa',
            digits: '**************4323', //digits must have *'s representing all the numbers with the last 4 digits visible
            exp: '12/25',
            address: [{
                streetAddress: '4323 Main Street',
                city: 'Monsey',
                state: 'NY',
                zip: '766544',
                avsVerified: false,
                zipVerified: false
            }],
        },
        {
            id: '2',
            name: 'Ernie',
            brand: 'american-express',
            digits: '**************4321',
            exp: '2/25',
            address: [{
                streetAddress: '4323 Main Street',
                city: 'Monsey',
                state: 'NY',
                zip: '766544',
                avsVerified: true,
                zipVerified: false
            },
            {
                streetAddress: '4323 Main Street',
                city: 'Monsey',
                state: 'NY',
                zip: '766544',
                avsVerified: true,
                zipVerified: false
            }]
        },
        {
            id: '3',
            name: 'Eric',
            brand: 'discover',
            digits: '**************4323',
            exp: '12/25',
            address: [{
                streetAddress: '4323 Main Street',
                city: 'Monsey',
                state: 'NY',
                zip: '766544',
                avsVerified: false,
                zipVerified: false
            }],
        },
        {
            id: '3',
            name: 'Eric',
            brand: 'mastercard',
            digits: '**************4323',
            exp: '12/25',
            address: [{
                streetAddress: '4323 Broad Street',
                city: 'Los Angeles',
                state: 'California',
                zip: '4334232',
                avsVerified: false,
                zipVerified: true
            }],
        }
    ]

    const getPaymentImage = (ccBrand) => {
        if (ccBrand === 'american-express')
            return AmexImg;
        if (ccBrand === 'visa')
            return VisaImg;
        if (ccBrand === 'discover')
            return DiscoverImg;
        if (ccBrand === 'mastercard')
            return MastercardImg;
        else
            return null;
    }

    const [displayBillingAddress, setDisplayBillingAddressModal] = useState(false);

    /*:: toggle: () => void */
    const openModal = (e, address) => {
        e.preventDefault()
        setDisplayBillingAddressModal(address)
    }


    const BillingAddressModal = () => {

        const addresses = displayBillingAddress;

        return (
            <Modal isOpen={Boolean(displayBillingAddress)} toggle={() => setDisplayBillingAddressModal(null)} size='sm'>
                <ModalHeader toggle={() => setDisplayBillingAddressModal(null)}>{addresses && addresses.length > 1 ? 'Billing Addresses' : 'Billing Address'}</ModalHeader>
                <ModalBody>
                    {addresses &&
                        addresses.map((address, i) => (
                            <div className="text-center" key={address.addressToken}>
                                {i > 0 ? <hr /> : ''}
                                <p className="text-muted">
                                    {address.streetAddress} <br />
                                    {address.city}, {address.state} <br />
                                    {address.zip}
                                </p>
                                <span className="badge badge-secondary-lighten badge-pill mr-1">
                                    AVS Verified: {address.avsVerified ? <Icon icon={checkIcon} color="green" width="11" height="11" /> : <Icon icon={multiplyIcon} color="red" width="9" height="9" />}
                                </span>
                                <span className="badge badge-secondary-lighten badge-pill mr-1">
                                    ZIP Verified: {address.zipVerified ? <Icon icon={checkIcon} color="green" width="11" height="11" /> : <Icon icon={multiplyIcon} color="red" width="9" height="9" />}
                                </span>
                            </div>
                        ))}
                </ModalBody>
            </Modal>
        );
    }

    return (
        <Card>
            <CardBody>
                <h4 className="header-title ml-2">Payment Methods</h4>
                <SimpleBar style={{ maxHeight: 300 }}>
                    <ListGroup className="address-card" flush>
                        {paymentMethodsdata.map((payment, i) => (
                            <ListGroupItem key={i}>
                                <ListGroupItemHeading>Name: {payment.name}</ListGroupItemHeading>
                                <ListGroupItemText style={{ lineHeight: '2.2' }}>
                                    <img src={getPaymentImage(payment.brand)} height="24" alt="card-img" /> : {payment.digits} <br />
                                    <strong>Exp :</strong> {payment.exp} <br />
                                    <a onClick={(e) => openModal(e, payment.address)} href="#">View address...</a>
                                </ListGroupItemText>
                            </ListGroupItem>
                        ))}
                        <BillingAddressModal />
                    </ListGroup>
                </SimpleBar>
            </CardBody>
        </Card>
    )
}

const RecentOrdersTable = props => {

    const { customerOrders } = props;

    const defaultNumOfOrders = 20;

    const [numOfOrders, setNumOfOrders] = useState(defaultNumOfOrders);

    const history = useHistory();

    const addOrders = (e) => {
        e.preventDefault();
        setNumOfOrders(numOfOrders + numOfAdditionalOrders());
    }

    const numOfAdditionalOrders = () => {
        if ((customerOrders.length - numOfOrders) >= defaultNumOfOrders) {
            return defaultNumOfOrders;
        } else if ((customerOrders.length > defaultNumOfOrders) && (customerOrders.length - numOfOrders) < defaultNumOfOrders) {
            return customerOrders.length - numOfOrders;
        } else {
            return 0;
        }
    }

    return (
        <Card>
            <CardBody>
                <h4 className="header-title mb-3">Recent Orders</h4>
                <SimpleBar style={{ maxHeight: 300 }}>
                    <Table className="table-centered mb-0" hover responsive>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Order #</th>
                                <th>Status</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {customerOrders ? customerOrders.slice(0, numOfOrders).map(order => (
                                <tr onClick={() => history.push(`/omni/catalog/order/${order?.orderToken}`)} key={order?.orderToken}>
                                    <td>{moment(epochToLocal(order?.dateCreated)).format('ll')}</td>
                                    <td>{order?.orderNumber}</td>
                                    <td>
                                        <span className="badge badge-primary"
                                            style={{ backgroundColor: `${order?.orderStatusValue?.color}` }}>{order?.orderStatusValue?.name}</span>
                                    </td>
                                    <td>${order?.checkoutObject.pricing.total}</td>
                                </tr>
                            ))
                                :
                                null
                            }
                            {
                                numOfAdditionalOrders() ? 
                                <tr>
                                    <td align='center' colSpan='4'>
                                        <a href="#" onClick={(e) => addOrders(e)}>Display an additional {numOfAdditionalOrders()} orders...</a> 
                                    </td>
                                </tr>
                            : customerOrders?.length === 0 ? 
                                <tr>
                                    <td align='center' colSpan='4'>
                                        <p className='pt-2 mb-0'>No orders</p>
                                    </td>
                                </tr>
                                : 

                                <tr>
                                    <td align='center' colSpan='4'>
                                        <p className='mb-0'>End of Orders</p>
                                    </td>
                                </tr>

                            }
                        </tbody>
                    </Table>
                </SimpleBar>
            </CardBody>
        </Card>
    )
}

const StatisticsWidgets = props => {

    const { customerOrders } = props;

    const getLifeTimeSpent = () => {

        let liftimeSpent = customerOrders.reduce((total, order) => total + Number(order.orderTotal), 0);

        if (liftimeSpent)
            return parseFloat(liftimeSpent).toFixed(2);
        else return 0;
    }

    const getAverageOrder = () => {
        let avgOrder = customerOrders && getLifeTimeSpent() / customerOrders.length

        if (avgOrder)
            return parseFloat(avgOrder).toFixed(2);
        else return 0;
    }

    const epochToLocal = (epoch) => {
        let d = new Date(0);
        return d.setUTCSeconds(epoch);
    }

    const get365Spent = () => {
        const newDate = new Date;

        let lastYearsOrdersTotal = customerOrders && customerOrders.filter(order => moment(epochToLocal(order.dateCreated))
            .isAfter(moment(newDate.toLocaleDateString()).subtract(365, 'days')))
            .reduce((total, order) => total + Number(order.orderTotal), 0)

        if (lastYearsOrdersTotal)
            return parseFloat(lastYearsOrdersTotal).toFixed(2);
        else return 0;

    }

    return (
        <>
            {/* <Col sm={2}>
            <Card className="tilebox-one">
                <CardBody>
                    <i className="dripicons-user float-right text-muted"></i>
                    <h6 className="text-muted text-uppercase mt-0">Last Order</h6>
                    <h4 className="m-b-20">{customerOrders.length && moment(epochToLocal(customerOrders[0].dateCreated)).format('l')}</h4>
                </CardBody>
            </Card>
        </Col> */}

            {/* <Col sm={3} className="">
                <Card className="tilebox-one">
                    <CardBody>
                        <Row>
                            <Col>
                        <div>
                            <i className="dripicons-store float-right text-muted"></i>
                            <h6 className="text-muted text-uppercase mt-0">Total Orders</h6>
                            <h2 className="m-b-20">{customerOrders && customerOrders.length}</h2>
                        </div>
                        <div>
                            <i className="dripicons-user float-right text-muted"></i>
                            <h6 className="text-muted text-uppercase mt-0">Last Order</h6>
                            <h2 className="m-b-20">{customerOrders.length && moment(epochToLocal(customerOrders[0].dateCreated)).format('l')}</h2>
                        </div>
                        <div>
                            <i className="dripicons-shopping-bag float-right text-muted"></i>
                            <h6 className="text-muted text-uppercase mt-0">Avg. Order</h6>
                            <h2 className="m-b-20">
                                <span>${getAverageOrder()}</span>
                            </h2>
                        </div>
                        <div>
                            <i className="dripicons-calendar float-right text-muted"></i>
                            <h6 className="text-muted text-uppercase mt-0">Total spent 365</h6>
                            <h2 className="m-b-20">${get365Spent()}</h2>
                        </div>
                        <div>
                            <i className="dripicons-user float-right text-muted"></i>
                            <h6 className="text-muted text-uppercase mt-0">Lifetime Spent</h6>
                            <h2 className="m-b-20">${getLifeTimeSpent().toString()}</h2>
                        </div>
                        </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col> */}
            <Row>
                <Col sm={6} className="">
                    <Card className="tilebox-one" style={{ height: '114px' }}>
                        <CardBody>
                            <i className="dripicons-store float-right text-muted"></i>
                            <h6 className="text-muted text-uppercase mt-0">Total Orders</h6>
                            <h2 className="mb-1">{(customerOrders && customerOrders.length) ? customerOrders.length : '0'}</h2>
                            <span className=""><h6 className="text-muted text-uppercase d-inline">Last Order :  </h6><strong>{customerOrders.length ? moment(epochToLocal(customerOrders[0].dateCreated)).format('l') : null}</strong></span>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm={6}>
                    <Card className="tilebox-one">
                        <CardBody>
                            <i className="dripicons-shopping-bag float-right text-muted"></i>
                            <h6 className="text-muted text-uppercase mt-0">Avg. Order</h6>
                            <h2 className="m-b-20">
                                <span>${getAverageOrder()}</span>
                            </h2>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <Card className="tilebox-one">
                        <CardBody>
                            <i className="dripicons-calendar float-right text-muted"></i>
                            <h6 className="text-muted text-uppercase mt-0">Total spent 365</h6>
                            <h2 className="m-b-20">${get365Spent()}</h2>
                        </CardBody>
                    </Card>
                </Col>

                <Col sm={6}>
                    <Card className="tilebox-one">
                        <CardBody>
                            <i className="dripicons-user float-right text-muted"></i>
                            <h6 className="text-muted text-uppercase mt-0">Lifetime Spent</h6>
                            <h2 className="m-b-20">${getLifeTimeSpent().toString()}</h2>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

const tabContents = [
    {
        id: '1',
        title: 'Home',
        icon: 'mdi mdi-home-variant',
        text:
            'dsafsakdnskamdsamdkasnsjbcjnskcnkascnksadnkbvdhbckscmsla,;ds,lmsnfjbvbsacnknjsbajdsnak',
    },
    {
        id: '2',
        title: 'Profile',
        icon: 'mdi mdi-account-circle',
        text:
            'Profile - Food truck quinoa dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.',
    },
    {
        id: '3',
        title: 'Settings',
        icon: 'mdi mdi-settings-outline',
        number: '4324343212321123',
        brand: "AMEX",
        date: '12/23'
    },
    {
        id: '4',
        title: 'Settings',
        icon: 'mdi mdi-settings-outline',
        number: '4142143563545',
        brand: "Visa",
        date: '12/23'
    },
];

const PaymentTabs = () => {

    const [activeTab, setActiveTab] = useState('2')

    /**
     * Toggle the tab
     */
    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    };

    return (
        <Card>
            <CardBody>
                <h4 className="header-title mb-3">Payment Methods</h4>
                <div style={{ borderRadius: '3px', border: '1px #FCFCFC solid', overflow: 'hidden' }}>
                    <div style={{ backgroundColor: '#FCFCFC' }}>
                        <Row>
                            <Col sm={4} className="mb-2 mb-sm-0 border-right pr-0">
                                <Nav tabs vertical pills className="flex-column border-bottom-0 justify-content-center">
                                    {tabContents.map((tab, index) => {
                                        return (
                                            <NavItem key={index}>
                                                <NavLink
                                                    href="#"
                                                    className={classnames({
                                                        active: activeTab === tab.id,
                                                    })}
                                                    onClick={() => {
                                                        toggle(tab.id);
                                                    }}>
                                                    <i
                                                        className={classnames(
                                                            tab.icon,
                                                            'd-lg-none',
                                                            'd-block',
                                                            'mr-1'
                                                        )}></i>
                                                    <span className="d-none d-lg-block">{tab.title}</span>
                                                </NavLink>
                                            </NavItem>
                                        );
                                    })}
                                </Nav>
                            </Col>
                            <Col sm={8}>
                                <TabContent activeTab={activeTab}>
                                    {tabContents.map((tab, index) => {
                                        return (
                                            <TabPane tabId={tab.id} key={index}>
                                                <Row>
                                                    <Col sm="12">
                                                        <p className="ml-1 mt-2"><strong>Name :</strong> <span className="ml-2">John Joe</span></p>
                                                        <p className="ml-1 mt-2"><strong>Number :</strong> <span className="ml-2">${tab.number}</span></p>
                                                        <p className="ml-1 mt-2"><strong>Brand :</strong> <span className="ml-2">{tab.brand}</span></p>
                                                        <p className="ml-1 mt-2"><strong>Exp :</strong> <span className="ml-2">{tab.exp}</span></p>
                                                        <p className="ml-1 mt-2"><strong>CVV :</strong> <span className="ml-2">111</span></p>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        );
                                    })}
                                </TabContent>
                            </Col>
                        </Row>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}

const FileUpload = () => {
    return (
        <Card>
            <CardBody>
                <h4 className="header-title mb-3">Dropzone File Upload</h4>

                <p className="text-muted font-13 m-b-30">
                    Upload Documents
                </p>

                <FileUploader
                    onFileUpload={files => {
                        console.log(files);
                    }}
                />
            </CardBody>
        </Card>
    );
};

const Notes = () => {

    const { user } = useContext(UserContext);

    const [notes, setNotes] = useState([
        {
            name: 'Jake',
            dateTime: '12-3-20',
            content: 'Lots of ncanscbsadiojksa;ndbvghoacnsacjmksabfbeadsivojm;pc,ksnbfasdf'
        },
        {
            name: 'Robert',
            dateTime: '12-2-20, 4:15',
            content: 'fdakvuhdsjoachubgvudbhvisajcohudsvbdsivjsoadjcuasgyvcsicjdihubckjcabhcbusjacs'
        }
    ]);

    const newDate = new Date;

    const [noteForm, setNoteForm] = useState({
        name: user.name,
        dateTime: newDate.toLocaleString(),
        content: ''
    })

    useEffect(() => {
        setNoteForm({
            ...noteForm,
            name: user.name
        })
    }, [user])

    const submitNote = (e) => {
        e.preventDefault()

        setNotes([
            noteForm,
            ...notes
        ])

        setNoteForm({
            ...noteForm,
            content: ''
        })
    }

    return (
        <Card>
            <CardBody className="pb-1">
                <h4>Admin Notes</h4>
                <form action="#" className="comment-area-box mb-3 border">
                    <textarea
                        rows="4"
                        className="form-control border-0"
                        style={{ resize: 'none' }}
                        value={noteForm.content}
                        onChange={(e) => setNoteForm({
                            ...noteForm,
                            content: e.target.value
                        })}
                        placeholder="Add note..."></textarea>
                    <div className="p-1 bg-light d-flex  align-items-center justify-content-between">
                        <FormGroup className="d-flex align-items-center mb-0">
                            <CustomInput type="checkbox" id="exampleCustomInline" label="Visible to admin only" inline />
                            <CustomInput type="checkbox" id="exampleCustomInline2" label="Visible to customer" inline />
                            <CustomInput type="checkbox" id="exampleCustomInline3" label="Notify customer" inline />
                        </FormGroup>
                        <button className="btn btn-sm btn-success" onClick={(e) => submitNote(e)}>
                            <i className="uil uil-message mr-1"></i>Submit
                        </button>
                    </div>
                </form>
                {notes.map((note, i) => (
                    <Media className="mt-3 mx-2" key={i}>
                        <img className="mr-2 rounded" src="https://picsum.photos/50" alt="" height="32" />
                        <Media body>
                            <div className="d-flex justify-content-between">
                                <h5 className="m-0">{note.name}</h5>
                                <UncontrolledDropdown className="float-right">
                                    <DropdownToggle tag="button" className="btn btn-sm btn-link no-arrow card-drop p-0">
                                        <i className="mdi mdi-dots-horizontal"></i>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem>Edit</DropdownItem>
                                        <DropdownItem>Delete</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                            <p className="text-muted mb-0">
                                <small>{note.dateTime}</small>
                            </p>

                            <p className="my-1">{note.content}</p>

                            <div className="d-flex">
                                <div>
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-link text-muted p-0">
                                        <i className="uil uil-heart mr-1"></i>{' '}
                                        Like
                                    </Link>
                                    <Link to="#" className="btn btn-sm btn-link text-muted p-0 pl-2">
                                        <i className="uil uil-comments-alt mr-1"></i> Reply
                                    </Link>
                                </div>
                                <div>
                                    <span className="badge badge-light badge-pill ml-4">Admin only</span>
                                    <span className="badge badge-light badge-pill ml-1">Visible to customer</span>
                                    <span className="badge badge-light badge-pill ml-1">Customer notified</span>
                                </div>
                            </div>
                        </Media>
                    </Media>
                ))}
            </CardBody>
        </Card>
    )
}


const FormPost = () => {
    return (
        <form action="#" className="comment-area-box mb-3 border">
            <textarea
                rows="4"
                className="form-control border-0"
                style={{ resize: 'none' }}
                placeholder="Add note..."></textarea>
            <div className="p-1 bg-light d-flex  align-items-center justify-content-end">
                <button type="submit" className="btn btn-sm btn-success">
                    <i className="uil uil-message mr-1"></i>Save
                </button>
            </div>
        </form>
    );
};

const CustomerStatusMultipleSelect = props => {

    const { customerInfo, customerStatuses, handleCustomerInfoChange } = props;

    const [selectedStatus, setSelectedStatus] = useState([]);

    console.log(selectedStatus)

    useEffect(() => {
        // this is used to set the value of the customer type select
        if (customerInfo && customerStatuses.length) {
            if (!customerInfo.customerStatus)
                return
            let customerStatus = customerStatuses.find(customer => customer.id === customerInfo.customerStatus);

            setSelectedStatus({
                value: customerStatus.id,
                label: customerStatus.name
            })
        }
    }, [customerInfo, customerStatuses.length])

    const onSelectChange = (selection) => {

        let newCustomerInfo = {
            ...customerInfo
        }
        setSelectedStatus(selection)

        console.log(selection.value)

        if (selection) {
            newCustomerInfo.customerStatus = selection.value;
        } else {
            newCustomerInfo.customerStatus = ''
        }

        console.log(newCustomerInfo.customerStatus)

        handleCustomerInfoChange(newCustomerInfo)
    }

    return (
        <>
            <p className="text-muted font-14">Customer Status</p>
            <Select
                isMulti={false}
                isSearchable={false}
                options={
                    customerStatuses && customerStatuses.map(status => {
                        return { value: status.id, label: status.name }
                    })
                }
                onChange={(s) => onSelectChange(s)}
                value={selectedStatus}
                className="react-select"
                classNamePrefix="react-select"></Select>
        </>
    )
}

const CustomerGroupsMultipleSelect = props => {

    const { customerStatuses } = props;

    return (
        <>
            <p className="text-muted font-14">Customer Groups</p>
            <Select
                isMulti={true}
                options={
                    customerStatuses && customerStatuses.map(status => {
                        return { value: status.id, label: status.name }
                    })
                }
                className="react-select"
                classNamePrefix="react-select"></Select>
        </>
    )
}

const BalanceAndCredit = () => {

    const [modal, setModal] = useState(false);

    /*:: toggle: () => void */
    const toggle = () => {
        setModal(!modal)
    }

    const ModalWithForm = () => {

        return (
            <Modal isOpen={modal} toggle={toggle} size="sm">
                <ModalHeader toggle={toggle}>Sign Up</ModalHeader>
                <ModalBody>
                    <form className="pl-3 pr-3" action="#">
                        <div className="form-group">
                            <label htmlFor="username">Name</label>
                            <input
                                className="form-control"
                                type="email"
                                id="username"
                                required=""
                                placeholder="Michael Zenaty"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="emailaddress">Email address</label>
                            <input
                                className="form-control"
                                type="email"
                                id="emailaddress"
                                required=""
                                placeholder="john@deo.com"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input
                                className="form-control"
                                type="password"
                                required=""
                                id="password"
                                placeholder="Enter your password"
                            />
                        </div>

                        <div className="form-group">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                <label className="custom-control-label" htmlFor="customCheck1">
                                    I accept <a href="/">Terms and Conditions</a>
                                </label>
                            </div>
                        </div>

                        <div className="form-group text-center">
                            <button className="btn btn-primary" type="submit">
                                Sign Up Free
                            </button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        );
    }

    return (
        <Row className="d-flex">
            <div>
                <p className="text-muted ml-2">
                    <strong>Balance :</strong>
                    <span className="ml-2">$32,443</span>
                </p>

                <p className="text-muted ml-2">
                    <strong>Credit Limit :</strong> <span className="ml-2">$5433</span>
                </p>
            </div>
            <div className="text-center mt-sm-0 mt-3 text-sm-right  ml-auto mr-2">
                <button className="btn btn-primary" onClick={toggle}>
                    <i className="mdi mdi-account-cash ml-1"></i> Edit
                </button>
            </div>
            <ModalWithForm />
        </Row>
    )
}

const ResetPassword = () => {

    const [password, setPassword] = useState('bhbdsaydv43232')

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    }

    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = (checked) => {
        if (checked)
            setShowPassword(true)
        else
            setShowPassword(false)
    }

    return (
        <Card>
            <CardBody>
                <h4 className="header-title mb-3">Customer Password</h4>
                <FormGroup>
                    <Label for="examplePassword">Password</Label>
                    <InputGroup>
                        <Input
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            id="examplePassword"
                            placeholder="password placeholder"
                            value={password}
                            disabled
                        />
                        <InputGroupAddon addonType="append">
                            <Button id="CopiedTooltip" color="secondary" onClick={() => copyToClipboard(password)}>Copy Password</Button>
                        </InputGroupAddon>
                        <UncontrolledTooltip placement="top" target="CopiedTooltip" trigger="legacy">
                            Copied!
                        </UncontrolledTooltip>
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <CustomInput type="checkbox" id="show-password" name="show-password" label="Show password" onClick={(e) => toggleShowPassword(e.target.checked)} />
                </FormGroup>
                <Button color={'primary'}>
                    Reset Password
                </Button>
            </CardBody>
        </Card>
    )
}

const AdditionalOptionsDropdown = () => {

    const [modal, setModal] = useState(false);

    const toggle = () => {
        setModal(!modal);
    };

    const ResetPasswordModal = props => {

        const { addToast } = useToasts();

        const [generatedPassword, setGeneratedPassword] = useState('Generating...');

        const generatePassword = (e) => {
            e.preventDefault()
            let randomString = Math.random().toString(36).slice(-8);
            navigator.clipboard.writeText(randomString);
            setGeneratedPassword(randomString)
        }

        return (
            <Modal
                isOpen={props.modal}
                toggle={props.toggle}
                size={'md'}>
                <ModalHeader toggle={props.toggle}>Reset Password</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>Reset password: <br />
                            <small>Customer will get a reset password email at customer@test.com</small>
                        </Label>
                        <Button type="button" color="success">
                            Reset Password<i className="uil-fast-mail"></i>
                        </Button>
                    </FormGroup>
                    <FormGroup>
                        <small>Or generate new password and copy to clipboard:
                            <a href="#" className="ml-1" id="generatePasswordTooltip" onClick={(e) => generatePassword(e)}>
                                Generate password instead
                            </a></small>
                        <UncontrolledTooltip placement="top" target="generatePasswordTooltip" trigger="legacy">
                            '{generatedPassword}' was copied to clipboard!
                        </UncontrolledTooltip>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={props.toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    return (
        <>
            <UncontrolledButtonDropdown className="mt-3">
                <DropdownToggle color="secondary" caret style={{ height: '35px' }}>
                    Additional Customer Options
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-animated">
                    <DropdownItem header>Header</DropdownItem>
                    <DropdownItem onClick={toggle}>Reset Password</DropdownItem>
                    <DropdownItem>Another Action</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>Another Action</DropdownItem>
                </DropdownMenu>
            </UncontrolledButtonDropdown>
            <ResetPasswordModal toggle={toggle} modal={modal} />
        </>
    )
}

const ShippingAddressForm = props => {
    const { shippingAddressCallbackForm, customer } = props;
    console.log(customer);
    const [addressInfo, setAddressInfo] = useState({
        addressFirstName: '',
        addressLastName: '',
        address: '',
        address2: '',
        addressCity: '',
        addressState: '',
        addressZip: '',
        mobileNumber: '',
        addressCountry: 'USA',
        addressLabel: ''
    })
    const [isDisable, setIsDisable] = useState(false);
    const [shippingValue, setShippingValue] = useState(0);
    const [validateState, setValidateState] = useState({
        addressFirstName: false,
        addressLastName: false,
        address: false,
        address2: false,
        addressCity: false,
        addressState: false,
        addressZip: false,
        mobileNumber: false
    });
    const saveAddress = () => {
        if (
            addressInfo.addressFirstName !== "" &&
            addressInfo.addressLastName !== "" &&
            addressInfo.address !== "" &&
            addressInfo.addressCity !== "" &&
            addressInfo.addressState !== "" &&
            addressInfo.addressZip !== "" &&
            addressInfo.mobileNumber !== ""
        ) {
            setIsDisable(true);
            let addressSavingToast = toast.loading('Saving address please wait...', {
                theme: 'light',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            CommonApiRequest.createCustomerAddress(
                {
                    addressFirstName: addressInfo.addressFirstName,
                    addressLastName: addressInfo.addressLastName,
                    address: addressInfo.address,
                    address2: addressInfo.address2,
                    addressCity: addressInfo.addressCity,
                    addressState: (addressInfo?.addressState?.[0]?.label) ? addressInfo?.addressState?.[0]?.label : '',
                    addressZip: addressInfo.addressZip,
                    mobileNumber: addressInfo.mobileNumber,
                    addressCountry: 'USA',
                    addressLabel: addressInfo.addressLabel,
                    customerToken: customer?.customerToken
                }
            ).then((response) => {
                setIsDisable(false);
                toast.update(addressSavingToast,
                    {
                        render: "Address saved successfully!",
                        type: "success",
                        isLoading: false,
                        autoClose: null,
                        hideProgressBar: null,
                        closeOnClick: null,
                    });
                shippingAddressCallbackForm(response)
            }).catch((error) => {
                console.log(error);
                setIsDisable(false);
            });
            //shippingAddressCallbackForm(addressInfo)
        } else {
            const validationStatus = {};
            if (addressInfo?.addressFirstName === "") {
                validationStatus.addressFirstName = true
            }
            if (addressInfo?.addressLastName === "") {
                validationStatus.addressLastName = true
            }
            if (addressInfo?.address === "") {
                validationStatus.address = true
            }
            if (addressInfo?.addressCity === "") {
                validationStatus.addressCity = true
            }
            if (addressInfo?.addressState === "") {
                validationStatus.addressState = true
            }
            if (addressInfo?.addressZip === "") {
                validationStatus.addressZip = true
            }
            if (addressInfo?.mobileNumber === "") {
                validationStatus.mobileNumber = true
            }
            setValidateState(validationStatus);
        }
    }
    const onStateSelectChange = (selection) => {
        setAddressInfo({
            ...addressInfo,
            addressState: selection
        })
    }
    const handleInputChange = (e) => {
        const target = e.target;
        const name = target.name;
        setAddressInfo({ ...addressInfo, [name]: target?.value });
    }
    return (
        <>
            <Row>
                <Col>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup>
                                <Label for="fname">Address Label</Label>
                                <Input name="addressLabel" id="addressLabel" value={addressInfo?.addressLabel}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                    }}
                                    placeholder=""
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup>
                                <Label for="addressFirstName">First Name <span className='required-field'>*</span></Label>
                                <Input name="addressFirstName" id="addressFirstName" value={addressInfo?.addressFirstName}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                    }}
                                    placeholder=""
                                />
                                {validateState.addressFirstName ? <div className="invalid-text">First name required</div> : ''}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup>
                                <Label for="addressLastName">Last Name <span className='required-field'>*</span></Label>
                                <Input name="addressLastName" id="addressLastName" value={addressInfo?.addressLastName}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                    }}
                                    placeholder=""
                                />
                                {validateState.addressLastName ? <div className="invalid-text">last name required</div> : ''}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup>
                                <Label for="address">Address <span className='required-field'>*</span></Label>
                                <Input name="address" id="address" value={addressInfo?.address}
                                    onChange={(e) => {
                                        handleInputChange(e);

                                    }}
                                    placeholder=""
                                />
                                {validateState.address ? <div className="invalid-text">Address required</div> : ''}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup>
                                <Label for="address2">Address 2</Label>
                                <Input name="address2" id="address2" value={addressInfo?.address2}
                                    onChange={(e) => {
                                        handleInputChange(e);

                                    }}
                                    placeholder=""
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup>
                                <Label for="addressCity">City <span className='required-field'>*</span></Label>
                                <Input name="addressCity" id="addressCity" value={addressInfo?.addressCity}
                                    onChange={(e) => {
                                        handleInputChange(e);

                                    }}
                                    placeholder=""
                                />
                                {validateState.addressCity ? <div className="invalid-text">City required</div> : ''}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup>
                                <Label for="addressState">State <span className='required-field'>*</span></Label>
                                <Typeahead
                                    paginate
                                    maxResults={15}
                                    id="select2"
                                    labelKey="label"
                                    multiple={false}
                                    options={StateList.map(state => state)}
                                    // emptyLabel={<div onClick={() => alert('clicked')}>hello</div>}
                                    onChange={onStateSelectChange}
                                    selected={addressInfo.addressState}
                                    placeholder="Select state..."
                                    name={'addressState'}
                                />
                                {validateState.addressState ? <div className="invalid-text">State required</div> : ''}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup>
                                <Label for="addressZip">Zip  <span className='required-field'>*</span></Label>
                                <Input name="addressZip" id="addressZip" value={addressInfo?.addressZip}
                                    onChange={(e) => {
                                        handleInputChange(e);

                                    }}
                                    placeholder=""
                                />
                                {validateState.addressZip ? <div className="invalid-text">Zip required</div> : ''}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup>
                                <Label for="mobileNumber">Phone <span className='required-field'>*</span></Label>
                                <MaskedInput
                                    mask={[
                                        '(',
                                        /[1-9]/,
                                        /\d/,
                                        /\d/,
                                        ')',
                                        ' ',
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        '-',
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                    ]}
                                    name="mobileNumber"
                                    id="mobileNumber"
                                    value={addressInfo.mobileNumber}
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder="(___) ___-____"
                                    className="form-control"
                                />
                                {validateState.mobileNumber ? <div className="invalid-text">Phone required</div> : ''}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={12} sm={12} className='d-flex justify-content-end'>
                            <Button
                                color="primary"
                                type="button"
                                onClick={() => { saveAddress() }}
                                className="export-csv-btn btn btn-primary mr-2"
                                disabled={isDisable}>
                                Save
                            </Button>
                        </Col>
                    </Row>

                </Col>
            </Row>
        </>
    )
}

export default CustomerProfile;