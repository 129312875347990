import React, { useEffect, useContext, useState, useRef } from 'react';
import { FormGroup, Input, Row, Col, Label, Card, InputGroup, ButtonGroup, Button, CardBody, CustomInput, FormText, InputGroupAddon, ButtonToolbar, ListGroup, ListGroupItem, Badge } from 'reactstrap';
import { OmniApi } from '../customAPI/OmniApi';
import HyperDatepicker from '../components/Datepicker';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import Select from 'react-select';
import { Icon } from '@iconify/react';
import { Typeahead } from 'react-bootstrap-typeahead';
import accountStar from '@iconify/icons-mdi/account-star';
import closeBox from '@iconify/icons-mdi/close-box';
import { confirm } from './Confirm';
import { toast } from 'react-toastify';
import accountBox from '@iconify/icons-mdi/account-box';
import shoppingIcon from '@iconify/icons-mdi/shopping';
import shapeIcon from '@iconify/icons-mdi/shape';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { CountryList } from '../data/CountriesList';
import TimePicker from 'react-time-picker'
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { CommonApiRequest } from '../services/commonApiRequest';
import { commonHelper } from '../helpers/commonHelper';

const GiftCard = props => {

    const [coupon, setCoupon] = useState('');
    const [giftToken, setGiftToken] = useState(null);
    const [currentTime, setCurrentTime] = useState('');
    const [giftCode, setGiftCode] = useState('');
    const [buttonDisable, setButtonDisable] = useState(false);
    const [activeFromDate, setActiveFromDate] = useState({
        date: '',
        time: '',
        amOrPm: 'AM'
    })

    const [activeToDate, setActiveToDate] = useState({
        date: '',
        time: '',
        amOrPm: 'AM'
    })

    const [unsavedChanges, setUnsavedChanges] = useState(false)

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    useEffect(() => {
        let couponToken = props.match.params.discountToken;
        if (couponToken) {
            setGiftToken(couponToken);
            CommonApiRequest.getGiftCardDetail(couponToken)
                .then((res) => {
                    setGiftCode(res?.code);
                    let fromDate = (res.expiration_date) ? new Date(res.expiration_date) : commonHelper.addOneYear(new Date());
                    setActiveFromDate({
                        date: ((fromDate.getMonth() > 8) ? (fromDate.getMonth() + 1) : ('0' + (fromDate.getMonth() + 1))) + '/' + ((fromDate.getDate() > 9) ? fromDate.getDate() : ('0' + fromDate.getDate())) + '/' + fromDate.getFullYear(),
                        time: fromDate.getHours() + ":" + fromDate.getMinutes(),//fromDate.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'}),
                        amOrPm: fromDate.toLocaleTimeString().slice(-2)
                    });
                    setCoupon({
                        name: res?.name,
                        code: res?.code,
                        customerFirstName: res?.first_name,
                        customerLastName: res?.last_name,
                        customerEmail: res?.email,
                        amount: res?.balance,
                        active: res?.active,
                        giftcard_token: res?.giftcard_token
                    })
                })
                .catch(err => {

                })
        } else {
            let currentDateTime = commonHelper.addOneYear(new Date())
            setActiveFromDate({
                date: ((currentDateTime.getMonth() > 8) ? (currentDateTime.getMonth() + 1) : ('0' + (currentDateTime.getMonth() + 1))) + '/' + ((currentDateTime.getDate() > 9) ? currentDateTime.getDate() : ('0' + currentDateTime.getDate())) + '/' + currentDateTime.getFullYear(),
                time: currentDateTime.getHours() + ":" + currentDateTime.getMinutes(),//currentDateTime.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'}).slice(0, -3),
                amOrPm: currentDateTime.toLocaleTimeString().slice(-2)
            })
            setCoupon({
                name: '',
                code: '',
                customerFirstName: '',
                customerLastName: '',
                customerEmail: '',
                amount: '',
                active: 1
            })
        }
    }, [props.location])

    const handleActiveFromDateInputChange = (e, name = 'time') => {
        //const target = e.target;
        //const name = 'date';//target.name;

        setUnsavedChanges(true)

        if (name === 'date') {
            setValidState({
                ...validState,
                fromDate: moment(e).isValid()
            });
            const validDate = new Date(e);
            setActiveFromDate({
                ...activeFromDate,
                [name]: (validDate.getMonth() + 1) + '/' + validDate.getDate() + "/" + validDate.getFullYear()//e.target.value
            })
        }

        if (name === 'time') {
            // Matches HH:MM 12-hour format, optional leading 0
            let timeRegex = /^(([0-1][0-9])|([0-9][0-4]))*:[0-5][0-9]/
            setValidState({
                ...validState,
                fromTime: timeRegex.test(e)
            });
            setActiveFromDate({
                ...activeFromDate,
                [name]: e//e.target.value
            })
        }


    }

    const [endTimeEnabled, setEndTimeEnabled] = useState(false)

    const [limitCouponUsage, setLimitCouponUsage] = useState(false);
    const [selectedCollections, setSelectedCollections] = useState([])

    const deleteCoupon = async () => {
        console.log(coupon)
        if (await confirm(`Delete '${coupon.name}' from gift card?`, "Deleting gift card...", "Delete") === false) {
            return
        }

        let deleteCpn = CommonApiRequest.deleteGiftCard(`${coupon.giftcard_token}`)
            .then(res => props.history.replace('/omni/catalog/giftcards'))
            .catch(err => console.error(err))

        toast.promise(
            deleteCpn,
            {
                pending: {
                    render() {
                        return "Deleting gift card..."
                    },
                    icon: true,
                },
                success: {
                    render(res) {
                        return `'${coupon.name}' deleted from gift card`
                    },
                    // other options
                    icon: true,
                },
                error: {
                    render(err) {
                        // When the promise reject, data will contains the error
                        return `err`
                    },
                    icon: true,
                }
            }
        )

    }

    const [validState, setValidState] = useState({
        name: true,
        code: true,
        amount: true
    })

    const validateCoupon = () => {

        let newValidState = {
            ...validState
        }

        // if (!coupon.name)
        //     newValidState.name = false

        if (coupon.discountType === 'fixed' && parseInt(coupon.discountValue) <= 0 && !coupon?.discountValue?.match(/^[1-9][0-9]*$/)) {
            newValidState.discountValue = false
        }

        if (!coupon.code) {
            newValidState.code = false
        } else {
            newValidState.code = true
        }
        if (parseFloat(coupon.amount) >= 0) {
            newValidState.amount = true
        } else {
            newValidState.amount = false
        }

        let valid = true;

        for (let state in newValidState) {
            if (typeof newValidState[state] === 'object') {
                for (let key in newValidState[state]) {
                    if (newValidState[state][key] === false)
                        valid = false
                }
            }
            else {
                if (newValidState[state] === false)
                    valid = false
            }
        }
        setValidState(newValidState)
        return valid
    }
    const saveCoupon = async () => {

        if (await validateCoupon() === false) {
            return false
        } else {
            let newGiftCard = toast.loading("Please wait wile creating the gift card...", {
                theme: 'light',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            let newCoupon = { ...coupon }
            if (!newCoupon.couponToken)
                delete newCoupon.couponToken;

            let fromDate = `${activeFromDate.date} ${activeFromDate.time}`;//new Date(`${activeFromDate.date} ${activeFromDate.time}`);
            newCoupon.activeFromDate = fromDate;
            if (endTimeEnabled) {
                //let toDate = new Date(`${activeToDate.date} ${activeToDate.time}`).toISOString();
                const toDate = `${activeToDate.date} ${activeToDate.time}`;
                newCoupon.activeToDate = toDate;

            } else {
                newCoupon.activeToDate = "";
            }
            setButtonDisable(true);
            let couponToken = props.match.params.discountToken;
            console.log(couponToken);
            if (couponToken) {
                CommonApiRequest.updateGiftCard({
                    name: newCoupon?.name,
                    first_name: newCoupon?.customerFirstName,
                    last_name: newCoupon?.customerLastName,
                    email: newCoupon?.customerEmail,
                    balance: newCoupon?.amount,
                    expiration_date: newCoupon?.activeFromDate,
                    active: newCoupon.active
                }, couponToken).then((response) => {
                    setButtonDisable(false);
                    console.log(response);
                    if (response) {
                        toast.update(newGiftCard, {
                            theme: 'light',
                            render: "Gift card updated successfully!",
                            type: "success",
                            isLoading: false,
                            autoClose: null,
                            hideProgressBar: null,
                            closeOnClick: null,
                        });
                        //props.history.replace(`${props.location.pathname}${response.giftcard_token ?'/' + response.giftcard_token:''}`)
                    }
                }).catch((error) => {
                    setButtonDisable(false);
                    toast.update(newGiftCard, {
                        theme: 'light',
                        render: "Somethunug went wrong!",
                        type: "error",
                        isLoading: false,
                        autoClose: null,
                        hideProgressBar: null,
                        closeOnClick: null,
                    });
                })
            } else {
                CommonApiRequest.createNewGiftCard({
                    code: newCoupon?.code,
                    name: newCoupon?.name,
                    first_name: newCoupon?.customerFirstName,
                    last_name: newCoupon?.customerLastName,
                    email: newCoupon?.customerEmail,
                    balance: newCoupon?.amount,
                    expiration_date: newCoupon?.activeFromDate,
                    active: newCoupon.active
                }).then((response) => {
                    setButtonDisable(false);
                    console.log(response);
                    if (response) {
                        toast.update(newGiftCard, {
                            theme: 'light',
                            render: "Giftcard created successfully!",
                            type: "success",
                            isLoading: false,
                            autoClose: null,
                            hideProgressBar: null,
                            closeOnClick: null,
                        });
                        props.history.replace(`${props.location.pathname}${response.giftcard_token ? '/' + response.giftcard_token : ''}`)
                    }
                }).catch((error) => {
                    setButtonDisable(false);
                    console.log(error);
                    toast.update(newGiftCard, {
                        theme: 'light',
                        render: "Somethunug went wrong!",
                        type: "error",
                        isLoading: false,
                        autoClose: null,
                        hideProgressBar: null,
                        closeOnClick: null,
                    });
                })
            }
        }
        // let postCoupon = OmniApi.post(`/couponcodes${newCoupon.couponToken ? '/' + newCoupon.couponToken : ''}`, newCoupon)
        //     .then(res => {
        //         setUnsavedChanges(false)
        //         props.history.replace(`${props.location.pathname}${newCoupon.couponToken ? '' : '/' + res.data.couponToken}`)
        //     })


    }

    const generateCode = () => {
        let codeGenration = toast.loading("Please wait wile generating the code...", {
            theme: 'light',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
        setButtonDisable(true);
        CommonApiRequest.createCodeForGiftCard({}).then((response) => {
            setButtonDisable(false);
            if (response) {
                setGiftCode(response?.code);
                setCoupon({ ...coupon, code: response?.code });
                toast.update(codeGenration,
                    {
                        render: "Code created",
                        type: "success",
                        isLoading: false,
                        autoClose: null,
                        hideProgressBar: null,
                        closeOnClick: null,
                    });
            }
        }).catch((error) => {
            setButtonDisable(false);
            toast.update(codeGenration,
                {
                    render: error.response.data.error.message,
                    type: "error",
                    isLoading: false,
                    autoClose: null,
                    hideProgressBar: null,
                    closeOnClick: null,
                });
        });
    }
    const currentDateTime = new Date()
    if (coupon)

        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Gift Cards Detail | {process.env.REACT_APP_HEADER_TITLE}</title>
                </Helmet>
                <Row>
                    <Col className="d-flex align-items-center justify-content-between mt-1">
                        <h2>{props.match.params.discountToken ? 'Gift Card #'+giftCode : 'New Gift Card'}</h2>
                        <div className="d-flex bg-light shadow-sm" style={{ borderRadius: '30px', padding: '5px' }}>
                            <Button size="sm" color={coupon.active > 0 ? 'primary' : 'light'} className="btn-rounded"
                                onClick={() => setCoupon({
                                    ...coupon,
                                    active: 1
                                })}
                            >Active</Button>
                            <Button size="sm" color={coupon.active === 0 ? 'secondary' : 'light'} className="btn-rounded ml-1 no-arrow card-drop"
                                onClick={() => setCoupon({
                                    ...coupon,
                                    active: 0
                                })}
                            >Inactive</Button>
                        </div>
                    </Col>
                </Row>
                <hr className="mt-1" />
                {/* <Col>
                    <Row className="d-flex justify-content-center bg-light rounded mt-1 mb-2 mx-2">
                        <Card className="my-2">
                            <CardBody>
                                <Row>
                                    <Col>
                                        <div className="d-flex justify-content-between">
                                            <h4><u>Expire Date</u></h4>
                                            <div className="d-flex align-items-center">

                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup style={{ width: '140px' }}>
                                            <Label>Expire Date</Label>
                                            <DatePicker
                                                selectsStart
                                                minDate={new Date()}
                                                selected={(activeFromDate.date) ? new Date(activeFromDate.date) : new Date()}
                                                onChange={(e) => {
                                                    handleActiveFromDateInputChange(e, 'date');
                                                }}
                                                style={{ textAlign: 'left' }}
                                                className='form-control'
                                                dateFormat="MM/dd/yyyy"
                                                name="date"
                                            ></DatePicker>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Row>
                </Col> */}
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col sm={12} md={6} lg={6}>
                                        <FormGroup>
                                            <Label for="name">Gift card Name</Label>
                                            <Input name="name" id="name" value={coupon.name}
                                                className={`${!validState.name ? 'invalid' : ''}`}
                                                placeholder="e.g. SPRINGSALE..."
                                                onChange={(e) => {
                                                    setValidState({
                                                        ...validState,
                                                        name: true
                                                    })
                                                    setCoupon({
                                                        ...coupon,
                                                        name: e.target.value
                                                    })
                                                }} />
                                            {!validState.name ? <div className="invalid-text">Please enter the gift card name</div> : ''}
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} md={6} lg={6}>
                                        <Row>
                                            <Col className="d-flex flex-row">
                                                <FormGroup className='w-100'>
                                                    <Label for="giftCode">Code <span className='required-field'>*</span></Label>
                                                    <InputGroup>
                                                        <Input type="text" placeholder="Value..." id="giftCode"
                                                            className={`${!validState.code ? 'invalid' : ''}`}
                                                            name="discountValue"
                                                            value={giftCode}
                                                            onChange={(e) => {
                                                                setCoupon({
                                                                    ...coupon,
                                                                    code: e.target.value
                                                                })
                                                            }}
                                                            disabled={true}
                                                        />
                                                        {!giftToken &&
                                                            <InputGroupAddon className='add-button-group' addonType="append">
                                                                <Button color='primary' onClick={() => { generateCode() }} disabled={buttonDisable}>Generate Code</Button>
                                                            </InputGroupAddon>
                                                        }
                                                    </InputGroup>
                                                    {!validState.code ? <div className="invalid-text">Please generate the gift code</div> : ''}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={12} md={6} lg={6}>
                                        <Row>
                                            <Col className="d-flex flex-row">
                                                <FormGroup className='w-100'>
                                                    <Label for="amount">Value  <span className='required-field'>*</span></Label>
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="append">
                                                            $
                                                        </InputGroupAddon>
                                                        <Input type="text" placeholder="Value..." id="amount"
                                                            name="amount"
                                                            value={coupon.amount}
                                                            onKeyPress={(e) => {
                                                                if (!/[0-9]|[.]/.test(e.key)) {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                if (parseFloat(coupon.amount) >= 0) {
                                                                    setValidState({
                                                                        ...validState,
                                                                        amount: true
                                                                    })
                                                                } else {
                                                                    setValidState({
                                                                        ...validState,
                                                                        amount: false
                                                                    })
                                                                }
                                                                setCoupon({
                                                                    ...coupon,
                                                                    amount: e.target.value
                                                                })
                                                            }}
                                                        />
                                                    </InputGroup>
                                                    {!validState.amount ? <div className="invalid-text">Please enter the valid gift card value</div> : ''}
                                                </FormGroup>

                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={12} md={6} lg={6}>
                                        <FormGroup >
                                            <Label>Expire Date</Label>
                                            <DatePicker
                                                selectsStart
                                                minDate={new Date()}
                                                selected={(activeFromDate.date) ? new Date(activeFromDate.date) : new Date()}
                                                onChange={(e) => {
                                                    handleActiveFromDateInputChange(e, 'date');
                                                }}
                                                style={{ textAlign: 'left' }}
                                                className='form-control'
                                                dateFormat="MM/dd/yyyy"
                                                name="date"
                                            ></DatePicker>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className='mb-3 mt-2'>
                                    <Col>
                                        <h3>Customer Details</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={6} lg={6}>
                                        <FormGroup>
                                            <Label for="customerFirstName">First Name</Label>
                                            <Input name="customerFirstName" id="customerFirstName" value={coupon.customerFirstName}
                                                placeholder="e.g. SPRINGSALE..."
                                                onChange={(e) => {
                                                    setCoupon({
                                                        ...coupon,
                                                        customerFirstName: e.target.value
                                                    })
                                                }} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} md={6} lg={6}>
                                        <Row>
                                            <Col className="d-flex flex-row">
                                                <FormGroup className='w-100'>
                                                    <Label for="customerLastName">Last Name</Label>
                                                    <Input type="text" placeholder="Example..." id="customerLastName"
                                                        name="customerLastName"
                                                        value={coupon.customerLastName}
                                                        onChange={(e) => {
                                                            setCoupon({
                                                                ...coupon,
                                                                customerLastName: e.target.value
                                                            })
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={6} lg={6}>
                                        <FormGroup>
                                            <Label for="customerEmail">Email</Label>
                                            <Input name="customerEmail" id="customerEmail" value={coupon.customerEmail}
                                                placeholder="e.g. example@example.com..."
                                                onChange={(e) => {
                                                    setCoupon({
                                                        ...coupon,
                                                        customerEmail: e.target.value
                                                    })
                                                }} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} md={6} lg={6}>
                                        <FormGroup>
                                            <Label for="customerEmail">Note</Label>
                                            <Input name="customerEmail" id="customerEmail" value={coupon.customerEmail}
                                                placeholder="e.g. example@example.com..."
                                                onChange={(e) => {
                                                    setCoupon({
                                                        ...coupon,
                                                        customerEmail: e.target.value
                                                    })
                                                }} type='textarea'/>
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <div className="button-list d-flex justify-content-end">
                            <Button color="danger" size="lg" disabled={!giftToken}
                                onClick={deleteCoupon}
                            >Delete</Button>
                            <Button color="primary" size="lg" className="ml-3"
                                onClick={saveCoupon}
                                disabled={buttonDisable}
                            >Save</Button>
                        </div>
                    </Col>
                </Row>
            </>
        )
    else
        return (
            <div className='page-loading-loader-div w-100 d-flex justify-content-center align-items-center'>
                <div>
                    <h4>Loading coupon...</h4>
                    <div className="bouncing-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        )

}

export default GiftCard;
